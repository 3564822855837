<template>

    <div class="slds-align_absolute-center" style="padding-top: 20px; padding-bottom: 20px;">
        <div role="status" :class="'slds-spinner slds-spinner_' + size + ' slds-spinner_inline'" style="z-index: 0;">
            <span class="slds-assistive-text">Loading</span>
            <div class="slds-spinner__dot-a"></div>
            <div class="slds-spinner__dot-b"></div>
        </div>
    </div>

    
  <!-- <div class="slds-spinner_container">
    <div role="status" class="slds-spinner slds-spinner_medium">
      <span class="slds-assistive-text">Loading</span>
      <div class="slds-spinner__dot-a"></div>
      <div class="slds-spinner__dot-b"></div>
    </div>
  </div> -->

</template>

<script>
    export default {
        name: 'Spinner',
        props: {
            size: String
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>

