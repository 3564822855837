import Vuex from "vuex";
import axios from "axios";

// import { store } from "../store/store";
import { SET_INITIAL_INSTALL_INFO, SET_INSTALL_INFO, SET_INSTALL_INFO_ERROR, SET_SINGLE_INSTALL_INFO_ERROR } from "../store/store.js";

export const ApiEndpoints = {
  data: function () {
    return {};
  },
  methods: {
    getDemoComponents() {
      var authToken = this.$store.getters.getAccessToken;
      var listFilterValues = this.$store.getters.getListFilterValues;
      var paginationSettings = this.$store.getters.getPaginationSettings;

      axios
        .post(
          "/v1/getAllDemoComponents",
          JSON.stringify({
            token: authToken,
            listFilterValues: listFilterValues,
            paginationSettings: paginationSettings,
          })
        )
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getDemoComponents RETURNED :: ", res.data.data.dcs.demo_components);
            console.log("getDemoComponents COUNT :: ", res.data.data.dcs.count);
            this.$store.commit("setAllComponents", res.data.data.dcs.demo_components);

            // Set some of the pagination stuff while we're here..
            var paginationSettingsClone = JSON.parse(JSON.stringify(this.$store.getters.getPaginationSettings));
            paginationSettingsClone.totalPages = Math.floor(res.data.data.dcs.count / paginationSettingsClone.currentPageSizeOption);
            if (res.data.data.dcs.count / paginationSettingsClone.currentPageSizeOption > 0) {
              paginationSettingsClone.totalPages++;
            }
            paginationSettingsClone.totalDemoComponentCount = res.data.data.dcs.count;
            this.$store.commit("setPaginationSettings", paginationSettingsClone);
          } else {
            this.$store.commit("setAllPurposeToastObject", {
              toastState: "error",
              toastMessage: "ERROR_GET_ALL_DEMO_COMPONENTS",
              toastLengthInSeconds: 15,
            });
            console.log(res.data.error);
          }
        })
        .catch((err) => {
          this.$store.commit("setAllPurposeToastObject", {
            toastState: "error",
            toastMessage: "ERROR_GET_ALL_DEMO_COMPONENTS",
            toastLengthInSeconds: 15,
          });
          console.log(err);
        });
    },

    getFavoriteComponents() {
      // this.$store.commit('setFavoriteComponents', null);
      var authToken = this.$store.getters.getAccessToken;
      axios
        .post("/v1/getFavoriteComponents", JSON.stringify({ token: authToken }))
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getFavoriteComponents RETURNED :: ", res.data.data.dc_favourites);
            this.$store.commit("setFavoriteComponents", res.data.data.dc_favourites);
          } else {
            console.log(res.data.error);
            this.$store.commit("setAllPurposeToastObject", {
              toastState: "error",
              toastMessage: "ERROR_GET_FAVORITES",
              toastLengthInSeconds: 15,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("setAllPurposeToastObject", {
            toastState: "error",
            toastMessage: "ERROR_GET_FAVORITES",
            toastLengthInSeconds: 15,
          });
        });
    },

    getInstalledByYouComponents() {
      var authToken = this.$store.getters.getAccessToken;
      axios
        .post("/v1/getInstalledByYouComponents", JSON.stringify({ token: authToken }))
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getInstalledByYouComponents RETURNED :: ", res.data.data.dc_installed_by);
            this.$store.commit("setInstalledByYouComponents", res.data.data.dc_installed_by);
          } else {
            this.$store.commit("setAllPurposeToastObject", {
              toastState: "error",
              toastMessage: "GET_INSTALLED_BY_YOU_ERROR",
              toastLengthInSeconds: 15,
            });
            console.log(res.data.error);
          }
        })
        .catch((err) => {
          this.$store.commit("setAllPurposeToastObject", {
            toastState: "error",
            toastMessage: "GET_INSTALLED_BY_YOU_ERROR",
            toastLengthInSeconds: 15,
          });
          console.log(err);
        });
    },

    getFeaturedBundles() {
      var authToken = this.$store.getters.getAccessToken;
      axios
        .post("/v1/getBundles", JSON.stringify({ token: authToken, featured: true }))
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getFeaturedBundles RETURNED :: ", res.data.data.dc_bundles);
            this.$store.commit("setFeaturedBundles", res.data.data.dc_bundles);
          } else {
            this.$store.commit("setAllPurposeToastObject", {
              toastState: "error",
              toastMessage: "GET_BUNDLES_ERROR",
              toastLengthInSeconds: 15,
            });
            console.log(res.data.error);
          }
        })
        .catch((err) => {
          this.$store.commit("setAllPurposeToastObject", {
            toastState: "error",
            toastMessage: "GET_BUNDLES_ERROR",
            toastLengthInSeconds: 15,
          });
          console.log(err);
        });
    },

    getCreatedByYouComponents() {
      var authToken = this.$store.getters.getAccessToken;
      axios
        .post("/v1/getCreatedByYouComponents", JSON.stringify({ token: authToken }))
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getCreatedByYouComponents RETURNED :: ", res.data.data.dc_created_by);
            this.$store.commit("setOwnedComponents", res.data.data.dc_created_by);
          } else {
            console.log(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    qSearchDemoComponents() {
      var authToken = this.$store.getters.getAccessToken;
      var searchObj = JSON.parse(JSON.stringify(this.$store.getters.getSearchObj));
      var setToNull = searchObj.searchTerm == "" ? true : false;

      var payload = {
        authToken: authToken,
        searchTerm: searchObj.searchTerm,
        limit: parseInt(searchObj.componentsSearchLimit),
      };

      axios
        .post("/v1/qSearchDemoComponents", JSON.stringify(payload))
        .then((res) => {
          if (!res.data.error) {
            console.log("qSearchDemoComponents RETURNED :: ", res.data.data);
            if (setToNull) {
              this.$store.commit("setSearchedComponents", null);
            } else {
              if (res.data.data.qs == this.$store.getters.getSearchObj.searchTerm.toLowerCase()) {
                console.log('qSearchDemoComponents Setting components');
                this.$store.commit("setSearchedComponents", res.data.data.demo_components);
              }
            }
          } else {
            this.$store.commit("setAllPurposeToastObject", {
              toastState: "error",
              toastMessage: "QSEARCH_DEMO_COMPONETS_ERROR",
              toastLengthInSeconds: 15,
            });
            console.log(res.data.error);
          }
        })
        .catch((err) => {
          this.$store.commit("setAllPurposeToastObject", {
            toastState: "error",
            toastMessage: "QSEARCH_DEMO_COMPONETS_ERROR",
            toastLengthInSeconds: 15,
          });
          console.log(err);
        });
    },

    qSearchUsers() {
      var authToken = this.$store.getters.getAccessToken;
      var searchObj = JSON.parse(JSON.stringify(this.$store.getters.getSearchObj));
      var setToNull = searchObj.searchTerm == "" ? true : false;

      var payload = {
        authToken: authToken,
        searchTerm: searchObj.searchTerm,
      };

      axios
        .post("/v1/qSearchUsers", JSON.stringify(payload))
        .then((res) => {
          if (!res.data.error) {
            console.log("qSearchUsers RETURNED :: ", res.data.data);
            if (setToNull) {
              this.$store.commit("setSearchedUsers", null);
            } else {
              if (res.data.data.qs == this.$store.getters.getSearchObj.searchTerm.toLowerCase()) {
                this.$store.commit("setSearchedUsers", res.data.data.users);
              }
            }
          } else {
            this.$store.commit("setAllPurposeToastObject", {
              toastState: "error",
              toastMessage: "QSEARCH_USERS_ERROR",
              toastLengthInSeconds: 15,
            });
            console.log(res.data.error);
          }
        })
        .catch((err) => {
          this.$store.commit("setAllPurposeToastObject", {
            toastState: "error",
            toastMessage: "QSEARCH_USERS_ERROR",
            toastLengthInSeconds: 15,
          });
          console.log(err);
        });
    },

    qSearchBundles() {
      var authToken = this.$store.getters.getAccessToken;
      var searchObj = JSON.parse(JSON.stringify(this.$store.getters.getSearchObj));
      var setToNull = searchObj.searchTerm == "" ? true : false;

      var payload = {
        authToken: authToken,
        searchTerm: searchObj.searchTerm,
      };
      axios
        .post("/v1/qSearchBundles", JSON.stringify(payload))
        .then((res) => {
          if (!res.data.error) {
            console.log("qSearchBundles RETURNED :: ", res.data.data);
            if (setToNull) {
              this.$store.commit("setSearchedBundles", null);
            } else {
              if (res.data.data.qs == this.$store.getters.getSearchObj.searchTerm.toLowerCase()) {
                this.$store.commit("setSearchedBundles", res.data.data.bundles);
              }
            }
          } else {
            this.$store.commit("setAllPurposeToastObject", {
              toastState: "error",
              toastMessage: "QSEARCH_BUNDLES_ERROR",
              toastLengthInSeconds: 15,
            });
            console.log(res.data.error);
          }
        })
        .catch((err) => {
          this.$store.commit("setAllPurposeToastObject", {
            toastState: "error",
            toastMessage: "QSEARCH_BUNDLES_ERROR",
            toastLengthInSeconds: 15,
          });
          console.log(err);
        });
    },

    getRecentlyViewed() {
      var authToken = this.$store.getters.getAccessToken;
      axios
        .post("/v1/getRecentlyViewed", JSON.stringify({ token: authToken }))
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getRecentlyViewed RETURNED :: ", res.data.data.dcs_recently_viewed);
            this.$store.commit("setRecentlyViewedObjects", res.data.data.dcs_recently_viewed);
          } else {
            console.log(res.data.error);
            this.$store.commit("setRecentlyViewedObjects", []);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getENVs() {
      var authToken = this.$store.getters.getAccessToken;
      axios
        .get("/v1/getENVs", JSON.stringify({ token: authToken }))
        .then((res) => {
          if (!res.data.error) {
            this.$store.commit("setEnvVariables", res.data.data);
          } else {
            console.log(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deploy() {
      var authToken = this.$store.getters.getAccessToken;
      var orgAuthDetails = this.$store.getters.getSelectedOrgAuthDetails;
      var installResults = this.$store.getters.getInstallResults;

      for (var i = 0; i < installResults.length; i++) {
        if (installResults[i].demoComponent.package_url__c == null || installResults[i].demoComponent.package_url__c == "") {
          installResults[i].deployJobStatus = {
            complete: true,
            errors: ["No Github Package URL found on Demo Component. Please reach out to QBranch."],
            commandResults: [],
          };
          this.$store.dispatch(SET_INSTALL_INFO_ERROR, {
            deploy_results: installResults[i].deployJobStatus,
            component_id: installResults[i].demoComponent.sfid,
          });
        } else {
          try {
            // var repoUrl = installResults[i].demoComponent.package_url__c.replace('https://github.com/sfdc-qbranch/', '');
            var tmpString = installResults[i].demoComponent.package_url__c.replace("https://github.com/", "");
            var gitUsername = tmpString.split("/")[0];
            var repoUrl = tmpString.split("/")[1];

            var payload = {
              token: authToken,
              org_auth_token: orgAuthDetails.accessToken,
              org_url: orgAuthDetails.instanceUrl,
              org_id: orgAuthDetails.orgId,
              git_username: gitUsername,
              repo_url: repoUrl,
              demo_component_id: installResults[i].demoComponent.sfid,
            };
            var tmpJobStatus = JSON.parse(JSON.stringify(installResults[i]));
            axios
              .post("/v1/deploy", JSON.stringify(payload))
              .then((res) => {
                if (!res.data.error) {
                  console.log("deploy returned :: ", res.data);
                  console.log("deploy returned :: ", res.data.data.deploy.deployId);
                  this.$store.dispatch(SET_INITIAL_INSTALL_INFO, { deploy_id: res.data.data.deploy.deployId });
                } else {
                  console.log(res.data.error + " ::: ", res);
                  tmpJobStatus.deployJobStatus = {
                    complete: true,
                    errors: ["An unexpected error has occurred. Please contact QBranch."],
                    commandResults: [],
                  };
                  this.$store.dispatch(SET_INSTALL_INFO_ERROR, {
                    deploy_results: tmpJobStatus.deployJobStatus,
                    component_id: tmpJobStatus.demoComponent.sfid,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                tmpJobStatus.deployJobStatus = {
                  complete: true,
                  errors: ["An unexpected error has occurred. Please contact QBranch."],
                  commandResults: [],
                };
                this.$store.dispatch(SET_INSTALL_INFO_ERROR, {
                  deploy_results: tmpJobStatus.deployJobStatus,
                  component_id: tmpJobStatus.demoComponent.sfid,
                });
              });
          } catch (ex) {
            installResults[i].deployJobStatus = {
              complete: true,
              errors: [ex.message],
              commandResults: [],
            };
            this.$store.dispatch(SET_INSTALL_INFO_ERROR, {
              deploy_results: installResults[i].deployJobStatus,
              component_id: installResults[i].demoComponent.sfid,
            });
          }
        }
      }
    },

    deployStatus() {
      //console.log("deployStatus");
      var authToken = this.$store.getters.getAccessToken;
      var orgAuthDetails = this.$store.getters.getSelectedOrgAuthDetails;
      var installResults = this.$store.getters.getInstallResults;

      for (var i = 0; i < installResults.length; i++) {
        if (installResults[i].deployId != null) {
          var payload = {
            token: authToken,
            org_auth_token: orgAuthDetails.accessToken,
            org_url: orgAuthDetails.instanceUrl,
            deployJobId: installResults[i].deployId,
          };
          axios
            .post("/v1/deployStatus", JSON.stringify(payload))
            .then((res) => {
              if (!res.data.error) {
                this.$store.dispatch(SET_INSTALL_INFO, { deploy_results: res.data.data.deployJobStatus });
              } else {
                console.log(res.data.error);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    getMostInstalledComponents() {
      var authToken = this.$store.getters.getAccessToken;
      axios
        .post("/v1/getMostInstalledComponents", JSON.stringify({ token: authToken }))
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getMostInstalledComponents RETURNED :: ", res.data.data.dcs_most_installed);
            this.$store.commit("setMostInstalledComponents", res.data.data.dcs_most_installed);
          } else {
            console.log(res.data.error);
            // this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'ERROR_GET_FAVORITES', toastLengthInSeconds: 15 });
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'ERROR_GET_FAVORITES', toastLengthInSeconds: 15 });
        });
    },

    getMostFavoritedComponents() {
      var authToken = this.$store.getters.getAccessToken;
      axios
        .post("/v1/getMostFavoritedComponents", JSON.stringify({ token: authToken }))
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getMostFavoritedComponents RETURNED :: ", res.data.data.dcs_most_favourited);
            this.$store.commit("setMostFavoritedComponents", res.data.data.dcs_most_favourited);
          } else {
            console.log(res.data.error);
            // this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'ERROR_GET_FAVORITES', toastLengthInSeconds: 15 });
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'ERROR_GET_FAVORITES', toastLengthInSeconds: 15 });
        });
    },

    getRecentlyUsedComponents() {
      var authToken = this.$store.getters.getAccessToken;
      axios
        .post("/v1/getRecentlyUsedComponents", JSON.stringify({ token: authToken }))
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getRecentlyUsedComponents RETURNED :: ", res.data.data);
            this.$store.commit("setRecentlyUsedComponents", res.data.data.unrated_dcs);
          } else {
            console.log("ERROR getRecentlyUsedComponents ::", res.data.data);
            this.$store.commit("setRecentlyUsedComponents", []);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPredictionsForUser() {
      var authToken = this.$store.getters.getAccessToken;
      // var user_id = this.$store.getters.sessionDetails.user.sfid;
      var params = {
        token: authToken,
        dc_id: null,
      };
      axios
        .post("/v1/getPredictions", JSON.stringify(params))
        .then((res) => {
          if (!res.data.error && res.data.data.success) {
            console.log("getPredictionsForUser RETURNED :: ", res.data.data.predictions);
            this.$store.commit("setRecommendedComponents", res.data.data.predictions);
          } else {
            console.log("ERROR getPredictionsForUser :: ", res.data.data);
            // this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'ERROR_GET_FAVORITES', toastLengthInSeconds: 15 });
            this.$store.commit("setRecommendedComponents", []);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("setRecommendedComponents", []);
          // this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'ERROR_GET_FAVORITES', toastLengthInSeconds: 15 });
        });
    },

    // getQHelpFAQs() {
    //     axios.get("/v1/getQHelpFAQs").then(res => {
    //         if (!res.data.error) {
    //             console.log('RETURNED :: ' , res.data.menus);
    //             console.log('RETURNED :: ' , res.data.menu_items);
    //         } else {
    //             console.log('Error :: getQHelpFAQs :: ' + res.data.error);
    //             console.log('Error :: getQHelpFAQs :: ' + res.data.message);
    //         }
    //     }).catch(err => {
    //         console.log('Exception :: getQHelpFAQs :: ' + err);
    //     });
    // },

    // showToast(toastState, toastTitle, toastMessage, toastLengthInSeconds) {
    //     var toastObj = {
    //         toastState: toastState,
    //         toastTitle: toastTitle,
    //         toastMessage: toastMessage,
    //         toastLengthInSeconds: toastLengthInSeconds
    //     }
    //     this.$store.commit('setAllPurposeToastObject', toastObj);
    // }

    doCallbackFunction(callbackFunction) {
      if (callbackFunction != undefined && callbackFunction != null) {
        console.log("callbackFunction is :: " + callbackFunction);
        this.getDemoComponents();
      }
    },
  },
};