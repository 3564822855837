<template>
    <div>
        <section id="markdown-preview_modal" role="dialog" tabindex="-1" aria-labelledby="markdown-preview_modal-heading" aria-modal="true" aria-describedby="markdown-preview_modal-content" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="$emit('closeModal')">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="markdown-preview_modal-heading" class="slds-text-heading_medium slds-hyphenate">Markdown Preview</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="markdown-preview_modal-content">
                    <div class="slds-box slds-box_small slds-theme_shade">
                        <div class="slds-text-longform is-markdown" v-html="markdownHtml">
                            <!-- {{ placeholder_markdown_description }} -->
                        </div>
                    </div>
                </div>
                <footer class="slds-modal__footer">
                    <button class="slds-button slds-button_brand close-modal_button" @click="$emit('closeModal')">Ok</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    export default {
        name: 'MarkdownPreviewModal',
        props: {
            markdownHtml: String
        },
        components: {

        },
        methods: {
            
        },
        created() {
            console.log('markdownHtml :: ' + this.markdownHtml);
        }
    }
</script>

