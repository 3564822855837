<template>

    <div class="demo-only demo--inverse" style="height:6rem">
        <div class="slds-spinner_container">
            <div role="status" class="slds-spinner slds-spinner_brand slds-spinner_medium">
                <span class="slds-assistive-text">Loading</span>
                <div class="slds-spinner__dot-a"></div>
                <div class="slds-spinner__dot-b"></div>
            </div>
            <div v-if="showMessage==true" class="slds-align_absolute-center slds-p-top_xx-large slds-m-top_x-large slds-grid slds-grid_vertical" style="height: 100%">
                <span class="slds-text-heading_large slds-m-bottom_xxs-small">{{message}}</span>
                <span class="slds-text-heading_small">Thanks for bearing with us</span>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'SpinnerLarge',
        props: {
            size: String,
            message: String,
            showMessage: Boolean
        },
        components: {

        },
        methods: {
            
        },
        created() {
            this.showMessage = false;
        }
    }
</script>

