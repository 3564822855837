<template>
    <div class="slds-grid slds-gutters_direct-xx-small slds-grid_align-spread">
        <div class="slds-col slds-text-align_left">
            <button id="favorite-demo-cmp_button" :class="isFavorited ? 'slds-button slds-button_icon slds-button_icon-border-filled slds-m-bottom_xx-small slds-is-selected' : 'slds-button slds-button_icon slds-button_icon-border-filled slds-m-bottom_xx-small'" aria-pressed="false" title="Favorite it!" @click="$emit('favoriteClicked')">
                <svg class="slds-button__icon slds-text-" aria-hidden="true">
                    <use xlink:href="/assets/images/custom-icons-sprite.svg#favorite"></use>
                </svg>
                <span class="slds-assistive-text">Like</span>
            </button>
            <div class="slds-text-body_small slds-text-color_weak">
                <span class="favorited_count">{{ demoComponent.number_of_favorites__c }}</span>&nbsp;<span class="is_plural">favorite</span>
            </div>
        </div>
        <div class="slds-col">
            <button class="slds-button slds-button_brand slds-m-bottom_xx-small install-component_button" @click="$emit('installClicked')" :disabled="(demoComponent.package_url__c == null || demoComponent.package_url__c == '')">
                <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#download"></use>
                </svg>
                Install on my Org
            </button>
            <ul class="slds-list_horizontal slds-has-dividers_left slds-text-body_small slds-text-color_weak slds-grid_align-center">
                <li class="slds-item">
                    <span class="favorited_count">{{ totalViews }}</span>&nbsp;<span class="is_plural" style="padding-left: 3px;">view</span>
                </li>
                <li class="slds-item">
                    <span class="favorited_count">{{ demoComponent.usage__c }}</span>&nbsp;<span class="is_plural" style="padding-left: 3px;">install</span>
                </li>
            </ul>
            <center v-if="hasGitPackageUrl">
                <a title="Download Source" @click="$emit('downloadClicked')">
                    Download source code
                </a>
            </center>
        </div>
        <div class="slds-col slds-text-align_right">
            <button id="share_button" class="slds-button slds-button_icon slds-button_icon-border-filled slds-m-bottom_xx-small" title="Share" @click="$emit('copyClicked')">
                <svg class="slds-button__icon" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#share"></use>
                </svg>
                <span class="slds-assistive-text">Share</span>
            </button>
            <div class="slds-text-body_small slds-text-color_weak">
                Share
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DemoCmpDetailControls',
        props: {
            demoComponent: Object,
            totalViews: Number,
            isFavorited: Boolean
        },
        computed:{
            hasGitPackageUrl() {
                return (this.demoComponent != null && this.demoComponent.package_url__c != null && this.demoComponent.package_url__c != '') ? true : false;
            },
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>

