<template>

    <article id="feedback-card" class="slds-card">
            <div class="slds-card__header slds-grid">
                <header class="slds-media slds-media_center slds-has-flexi-truncate">
                    <div class="slds-media__figure">
                        <span class="slds-icon_container slds-icon-standard-opportunity" title="Feedback">
                            <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#reward"></use>
                            </svg>
                            <span class="slds-assistive-text">Feedback</span>
                        </span>
                    </div>
                    <div class="slds-media__body">
                        <h2 class="slds-card__header-title">
                            <a href="javascript:void(0);" class="slds-card__header-link slds-truncate" title="Feedback">
                                <span>Feedback</span>
                            </a>
                        </h2>
                    </div>
                </header>
            </div>
            <div class="slds-card__body slds-card__body_inner">
                <a id="feedback-card_rating-avg" href="javascript:void(0);" class="slds-grid slds-grid_vertical-align-start slds-gutters_direct-xx-small slds-m-bottom_medium" v-if="feedback != null" @click="$emit('viewAll')">
                    <!-- <div class="slds-col {{ 'slds-hide' if is_empty }} is-empty_toggle"> -->
                    <div class="slds-col is-empty_toggle" v-if="demoComponent.rating_average__c != undefined && demoComponent.rating_average__c != null">
                        <span class="rating-avg_value slds-text-heading_small">{{ demoComponent.rating_average__c }}</span>
                    </div>
                    <div class="slds-col">
                        <!-- <span class="{{ 'slds-hide' if is_empty }} is-empty_toggle"> -->
                        <span class="is-empty_toggle" v-if="feedback != null && feedback.length == 0">
                            <!-- {{ rating.rating_stars_static("rating-avg_preview-empty_" + demo_cmp.Id, demo_cmp.rating, 'x-small') }} -->
                            <rating-stars :demoComponent="demoComponent" :isStatic="true" :size="'x-small'" :staticRatingValue="0"/>
                        </span>
                        <!-- <span class="{{ 'slds-hide' if not is_empty }} is-empty_toggle"> -->
                        <span class="is-empty_toggle" v-if="feedback != null && feedback.length > 0">
                            <!-- {{ rating.rating_stars_static("rating-avg_preview-rated_" + demoComponent.id, 0, 'x-small') }} -->
                            <rating-stars :demoComponent="demoComponent" :isStatic="true" :size="'x-small'" />
                        </span>
                    </div>
                    <div class="slds-col" v-if="feedback != null && feedback.length > 0">
                        <!-- (<span id="feedback-card_reviews-count" data-reviews-count="{{ demo_cmp.reviews | length }}">{{ '0' if is_empty else demo_cmp.reviews | length }}</span>)&nbsp;<span class="review-count_label {{ 'is_plural' if not is_empty }}">review</span> -->
                        (<span id="feedback-card_reviews-count" :data-reviews-count="feedback.length">{{ feedback.length }}</span>)&nbsp;<span :class="feedback.length > 0 ? 'review-count_label is_plural' : 'review-count_label'">review</span>
                    </div>
                </a>
                <div class="slds-grid slds-grid_align-spread slds-grid_vertical-align-end slds-m-bottom_medium">
                    <div class="slds-col">
                        <div class="slds-form-element">
                            <label class="slds-form-element__label slds-p-bottom_xx-small" for="feedback-card_rating-input">Your Rating</label>
                            <div class="slds-form-element__control">
                                <!-- {{ rating.rating_stars_input("feedback-card_rating-input", 0, 'medium') }} -->
                                <rating-stars :isStatic="false" :size="'medium'" 
                                    :demoComponent="demoComponent"
                                    :ratingValue="currentRating"
                                    :tmpRatingValue="tmpRatingValue"
                                    v-on:starClicked="openRatingModal" 
                                    v-on:starMouseOver="setTempRatingValue"
                                    v-on:starMouseOff="resetTmpRatingValue" />

                                <!-- :demoComponent="demoComponent"  -->
                            </div>
                        </div>
                    </div>
                    <div class="slds-col">
                        <!-- matching label will show here below  -->
                        {{ ratingLabel }}
                        <p id="feedback-card_rating-input_label" class="slds-text-align_right"></p>
                    </div>
                </div>

                <div id="feedback-card_reviews" class="slds-m-top_medium slds-border_top slds-p-top_medium is-empty_toggle" v-if="feedback != null && feedback.length > 0">
                    <!-- {% for review in demo_cmp.reviews %}
                    {% if loop.index0 < 2 %}
                        {{ feedback.review_item(review) }}
                    {% endif %}
                    {% endfor %} -->
                    <review-item v-for="(obj, i) in feedback" :key="i" v-show="i < 2" :review="obj"/>
                </div>
            </div>
            <!-- <footer class="slds-card__footer {{ 'slds-hide' if is_empty }}"> -->
            <footer class="slds-card__footer" v-if="feedback != null && feedback.length > 0">
                <a id="feedback_view-all_button" class="slds-card__footer-action" href="javascript:void(0);" @click="$emit('viewAll')">View All
                    <span class="slds-assistive-text">Reviews</span>
                </a>
            </footer>
        </article>

</template>

<script>
    import RatingStars from './RatingStars.vue'
    import ReviewItem from './ReviewItem.vue'

    export default {
        name: 'CardsFeedback',
        props: {
            demoComponent: Object,
            feedback: Array,
            currentRating: Number,
            tmpRatingValue: Number
        },
        components: {
            ratingStars: RatingStars,
            reviewItem: ReviewItem
        },
        computed: {
            ratingLabel() {
                switch(this.tmpRatingValue) {
                    case 0: return '';
                    case 1: return 'I hate it';
                    case 2: return 'I don\'t like it';
                    case 3: return 'It\'s okay';
                    case 4: return 'I like it';
                    case 5: return 'I love it';
                    default: return '';
                }
                return '';
            }
        },
        methods: {
            openRatingModal(val) { this.$emit('openRatingModal', val); },
            setTempRatingValue(val) { this.$emit('setTempRatingValue', val); },
            resetTmpRatingValue() { this.$emit('resetTmpRatingValue'); },
        },
        created() {

        }
    }
</script>

