<template>
    <div>
        <section id="collaborators_modal" role="dialog" tabindex="-1" aria-labelledby="collaborators_modal-heading" aria-modal="true" aria-describedby="collaborators_modal-content" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="$emit('close-modal')">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="collaborators_modal-heading" class="slds-text-heading_medium slds-hyphenate">Manage Collaborators</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="collaborators_modal-content" style="overflow: initial;">
                    <div class="slds-form-element slds-m-bottom_large">
                        <div class="slds-form-element__control">
                            <div class="slds-combobox_container">
                                <div :class="searchInFocus ? 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click'" aria-expanded="false" aria-haspopup="listbox" role="combobox">
                                    <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                        <input type="text" class="slds-input slds-combobox__input" id="combobox-collaborators" aria-autocomplete="list" aria-controls="listbox-collaborators" autoComplete="off" role="textbox" placeholder="Search people to add as collaborators..." v-model="searchTerm" v-on:focus="searchFocused()" v-on:focusout="searchFocusedOut()"/>



                                        <!-- <span class="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
                                            <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                                            </svg>
                                        </span> -->



                                        <div class="slds-input__icon-group slds-input__icon-group_right">
                                            <div role="status" class="slds-spinner slds-spinner_brand slds-spinner_x-small slds-input__spinner" v-if="searchTerm != '' && searchedUsers == null">
                                                <span class="slds-assistive-text">Loading</span>
                                                <div class="slds-spinner__dot-a"></div>
                                                <div class="slds-spinner__dot-b"></div>
                                            </div>
                                            <!-- <button class="slds-button slds-button_icon slds-input__icon slds-input__icon_right" title="Remove selected option" v-show="searchValue != null && searchValue != '' && device == 'desktop'" v-on:click="clearSearch()">
                                                <svg class="slds-button__icon" aria-hidden="true">
                                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                                </svg>
                                              <span class="slds-assistive-text">Remove selected option</span>
                                            </button> -->
                                            <span class="slds-input__icon slds-input__icon_right">
                                                <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                                                </svg>
                                            </span>
                                        </div>


                                    </div>
                                    <div id="listbox-collaborators" class="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid" role="listbox" v-if="searchedUsers != null && searchedUsers.length > 0">
                                        <ul class="slds-listbox slds-listbox_vertical" role="presentation">
                                            <!-- {% set counter = 0 %}
                                            {% for user in people %}
                                            {% if not user.is_collaborator and counter < 3 %}
                                            {% set counter = counter + 1 %} -->
                                            <li role="presentation" class="slds-listbox__item" v-for="(usr, index) in searchedUsers" :key="index" v-show="index < 5" @click="addCollaborator(usr)">
                                                <div id="option1" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                                    <span class="slds-media__figure slds-listbox__option-icon">
                                                        <span class="slds-icon_container slds-icon-standard-people">
                                                            <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                                <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#people"></use>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    <span class="slds-media__body">
                                                        <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ usr.firstname }} {{ usr.lastname }}</span>
                                                        <span class="slds-listbox__option-text slds-listbox__option-text_entity slds-text-color_weak">{{ usr.email }}</span>
                                                    </span>
                                                </div>
                                            </li>
                                            <!-- {% endif %}
                                            {% endfor %} -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="collaborators_list">
                        <!-- {% for user in people %}
                        {% if user.is_owner %} -->
                        <div class="slds-media slds-media_center slds-m-bottom_medium">
                            <div class="slds-media__figure">
                                <span class="slds-avatar slds-avatar_circle">
                                    <abbr class="slds-avatar__initials slds-icon-standard-user" :title="demoComponent.author__c">{{ getUserInitials(demoComponent.author__c) }}</abbr>
                                </span>
                            </div>
                            <div class="slds-media__body slds-grid slds-grid_align-spread slds-grid_vertical-align-center slds-wrap">
                                <div class="slds-col">
                                    <!-- <p>{{ user.firstname }} {{ user.lastname }}</p> -->
                                    <!-- <p class="slds-body_small slds-text-color_weak">{{ user.email }}</p> -->
                                    <p>{{ demoComponent.author__c }}</p>
                                </div>
                                <div>
                                    <em>Owner</em>
                                </div>
                            </div>
                        </div>
                        <!-- {% endif %}
                        {% endfor %}
                        {% for user in people %}
                        {% if not user.is_owner and user.is_collaborator %} -->
                        <div class="slds-media slds-media_center slds-m-bottom_medium" v-for="(collaborator, index) in collaborators" :key="index">
                            <div class="slds-media__figure" v-if="collaborator.author != null">
                                <span class="slds-avatar slds-avatar_circle">
                                    <abbr class="slds-avatar__initials slds-avatar__initials_inverse" :title="collaborator.author.firstname + ' ' + collaborator.author.lastname">{{ getUserInitials(collaborator.author.firstname + ' ' + collaborator.author.lastname) }}</abbr>
                                </span>
                            </div>
                            <div class="slds-media__body slds-grid slds-grid_align-spread slds-grid_vertical-align-center slds-wrap" v-if="collaborator.author != null">
                                <div class="slds-col">
                                    <!-- <p>{{ user.firstname }} {{ user.lastname }}</p> -->
                                    <p>{{ collaborator.author.firstname }} {{ collaborator.author.lastname }}</p>
                                    <ul class="slds-list_horizontal slds-has-dividers_left slds-has-block-links slds-text-body_small slds-text-color_weak">
                                        <li class="slds-item ">{{ collaborator.author.email }}</li>
                                        <li class="slds-item slds-show_medium">{{ getCreatedDateAsString(collaborator.createddate) }}</li>
                                    </ul>
                                </div>
                                <div class="slds-col">
                                    <div :class="selectedDropdown != null && selectedDropdown.author.email == collaborator.author.email ? 'slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-dropdown-trigger slds-dropdown-trigger_click'" v-on:focusout="dropdownFocusOut"><!-- slds-is-open -->
                                        <button class="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-small" aria-haspopup="true" title="Show More" @click="setSelectedDropdown(collaborator)">
                                            <svg class="slds-button__icon" aria-hidden="true">
                                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                                            </svg>
                                            <span class="slds-assistive-text">Show More</span>
                                        </button>
                                        <div class="slds-dropdown slds-dropdown_right slds-dropdown_bottom">
                                            <ul class="slds-dropdown__list" role="menu" aria-label="Show More">
                                                <li class="slds-dropdown__item" role="presentation" v-if="isOwner" @click="transferOwnership(collaborator.author)"><!-- This item should only be seen by owners -->
                                                    <a href="javascript:void(0);" role="menuitem" tabindex="0">
                                                        <span class="slds-truncate" title="Transfer Ownership">Transfer Ownership</span>
                                                    </a>
                                                </li>
                                                <li class="slds-dropdown__item" role="presentation" @click="removeCollaborator(collaborator.author)">
                                                    <a href="javascript:void(0);" role="menuitem" tabindex="-1">
                                                        <span class="slds-truncate" title="Remove">Remove from Collaborators</span>
                                                    </a>
                                                </li>
                                                <!-- <li class="slds-dropdown__item" role="presentation">
                                                    <a href="javascript:void(0);" role="menuitem" tabindex="0">
                                                        <span class="slds-truncate" title="Send Notification Emails">Send Notification Emails</span>
                                                    </a>
                                                </li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- {% endif %}
                        {% endfor %} -->
                    </div>
                </div>
                <footer class="slds-modal__footer">
                    <button class="slds-button slds-button_neutral" @click="$emit('close-modal')">Cancel</button>
                    <button class="slds-button slds-button_brand" @click="$emit('close-modal')">Done</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    import axios from "axios";
    
    export default {
        name: 'CollaboratorsModal',
        data() {
            return {
                searchTerm: '',
                searchedUsers: null,

                dropdownInFocus: false,
                searchInFocus: false,

                selectedDropdown: null
            }
        },
        props: {
            demoComponent: Object,
            collaborators: Array,
            isOwner: Boolean,
            isCollaborator: Boolean
        },
        components: {

        },
        methods: {
            getCreatedDateAsString(input) {
                if(input == null) {
                    return 'New Collaborator';
                }
                var monthsArray = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
                var dateObj = new Date(input);
                return 'Collaborator since ' + monthsArray[dateObj.getUTCMonth()] + ' ' + dateObj.getUTCDate() + ', ' + dateObj.getUTCFullYear();
            },
            getUserInitials(input) {
                var splitName = input.split(' ');
                if(splitName.length >= 2) {
                    var firstInitial = splitName[0].charAt(0);
                    var secondInitial = splitName[1].charAt(0);
                    return firstInitial + secondInitial;
                } else {
                    return splitName[0].charAt(0);
                }
            },
            searchAndSetCollaborators() {
                var qSearchAuthToken = this.$store.getters.getQSearchAuthToken;
                var setToNull = (this.searchTerm == '') ? true : false;
                var payload = { 
                    authToken: qSearchAuthToken, 
                    searchTerm: this.searchTerm
                };
                axios.post("/v1/qSearchUsers", JSON.stringify(payload)).then((res) => {
                    if (!res.data.error) {
                        if(setToNull) {
                            this.searchedUsers = null;
                        } else {
                            if(res.data.data.qs == this.searchTerm) {
                                this.searchedUsers = res.data.data.response;
                            } 
                        }
                    } else {
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            addCollaborator(usr) {             
                this.searchTerm = '';
                console.log('addCollaborator :: ' + usr.sfid);
                this.collaboratorActionCall(usr.sfid, 'Add');
            },
            transferOwnership(usr) {
                console.log('transferOwnership :: ' + usr.sfid);
                this.collaboratorActionCall(usr.sfid, 'Transfer');
            },
            removeCollaborator(usr) {
                console.log('removeCollaborator :: ' + usr.sfid);
                this.collaboratorActionCall(usr.sfid, 'Remove');
            },
            sendNotificationEmail(usr) {
                this.collaboratorActionCall(usr.sfid, 'Send');
            },
            collaboratorActionCall(usrId, action) {
                var payload = {
                    token: this.$store.getters.getAccessToken,
                    userId: usrId, 
                    cmpId: this.demoComponent.sfid,
                    action: action
                };
                axios.post("/v1/collaboratorActions", JSON.stringify(payload)).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('SUCCESS... :: ' , res.data);
                        this.$emit('refresh-collaborators');
                        this.collaboratorActionHandler(true, action);
                    } else {
                        this.$emit('refresh-collaborators');
                        this.collaboratorActionHandler(false, action);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.$emit('refresh-collaborators');
                    this.collaboratorActionHandler(false, action);
                });
            },
            collaboratorActionHandler(isSuccess, action) {
                var toastState = (isSuccess) ? 'success' : 'error';
                var _failed = (isSuccess) ? '' : '_ERROR';
                if(action == 'Add') {
                    this.$store.commit('setAllPurposeToastObject', { toastState: toastState, toastMessage: 'NEW_COLLABORATOR' + _failed, toastLengthInSeconds: 5 });
                }
                if(action == 'Transfer') {
                    this.$store.commit('setAllPurposeToastObject', { toastState: toastState, toastMessage: 'TRANSFER_COLLABORATOR' + _failed, toastLengthInSeconds: 5 });
                }
                if(action == 'Remove') {
                    this.$store.commit('setAllPurposeToastObject', { toastState: toastState, toastMessage: 'REMOVE_COLLABORATOR' + _failed, toastLengthInSeconds: 5 });
                }
            },
            searchFocused() {
                this.searchInFocus = true;
                this.dropdownInFocus = false;
            },
            searchFocusedOut() {
                var self = this;
                setTimeout(function() { self.searchInFocus = false; }, 200);
            },
            setSelectedDropdown(input) {
                this.selectedDropdown = input;
            },
            dropdownFocusOut() {
                setTimeout(() => {
                    this.selectedDropdown = null;
                }, 200);
            },
            
        },
        created() {

        },
        watch: {
            searchTerm(newVal, oldVal) {
                var tmpNewVal = newVal;
                setTimeout(() => {
                    if(tmpNewVal == this.searchTerm) {
                        this.searchAndSetCollaborators();
                    }
                }, 500);
            }
        }
    }
</script>

