<template>

    <!-- {% macro reviews_modal(reviews) %} -->
    <!-- slds-fade-in-open -->
    <div>
        <section id="modal_reviews" role="dialog" tabindex="-1" aria-labelledby="modal-heading_reviews" aria-modal="true" aria-describedby="modal-content_reviews" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="$emit('closeModal')">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="modal-heading_reviews" class="slds-modal__title slds-hyphenate">Reviews ({{ feedback.length }})</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="modal-content_reviews" v-if="feedback != null && feedback.length > 0">
                    <div class="slds-m-bottom_large" v-for="(obj, i) in feedback" :key="i">
                        <review-item :review="obj"/>
                    </div>
                </div>
                <div class="slds-modal__content slds-p-around_medium" id="modal-content_reviews" v-if="feedback != null && feedback.length == 0">
                    <div class="slds-m-bottom_large">
                        <div class="slds-illustration slds-illustration_large">
                            <illustrations :image="'desert'" />
                            <div class="slds-text-longform">
                                <h3 class="slds-text-heading_medium">No feedback just yet, why not leave some?</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="slds-modal__footer">
                    <button class="slds-button slds-button_brand close-modal_button" @click="$emit('closeModal')">Ok</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
    <!-- {% endmacro %} -->

</template>

<script>
    import ReviewItem from "./ReviewItem.vue";
    import Illustrations from "./Illustrations.vue";

    export default {
        name: 'ReviewsModals',
        props: {
            feedback: Array,
        },
        components: {
            reviewItem: ReviewItem,
            illustrations: Illustrations
        },
        methods: {
            
        },
        created() {

        }
    }
</script>

