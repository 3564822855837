<template>
        <!-- {% import "macros/_rating-stars.html" as rating with context %} -->
        <!-- {% macro review_item(review) %} -->

    <div class="slds-grid slds-wrap slds-m-bottom_medium">
        <div class="slds-col slds-size_1-of-1 slds-grid slds-grid_align-spread slds-m-bottom_xx-small">
            <div class="slds-col">
                <!-- {{ rating.rating_stars_static("rating_preview-" + review.id, review.rating_value__c, 'x-small') }} -->
                <rating-stars :isStatic="true" :size="'x-small'" :staticRatingValue="review.rating_value__c" />
            </div>
            <div class="slds-col slds-text-body_small slds-text-color_weak">
                <!-- <a href="javascript:void(0);" class="slds-text-link_reset">by&nbsp;<span class="slds-text-link">{{ review.author.name }}</span></a> -->
                <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: review.author.sfid }}">
                    {{ review.author.name }}
                </router-link> -->
                <user-link :userName="review.author.name" :userSFID="review.author.sfid" />
            </div>
        </div>
        <div class="slds-col slds-size_1-of-1 slds-grid slds-grid_align-spread ">
            <p class="slds-col">{{ review.body__c }}</p>
            <p class="slds-col slds-text-body_small slds-text-color_weak">{{ createdDate }}</p>
        </div>
    </div>
    <!-- {% endmacro %} -->

</template>

<script>
    import RatingStars from './RatingStars.vue'
    import UserLink from './UserLink.vue'

    export default {
        name: 'ReviewItem',
        props: {
            review: Object
        },
        components: {
            ratingStars: RatingStars,
            userLink: UserLink
        },
        methods: {
            
        },
        computed: {
            createdDate() {
                if(this.review.createddate == null) {
                    return 'Just Now';
                }

                var retVal;
                var createdDate = new Date(this.review.createddate);
                var todaysDate = new Date();
                var seconds = (todaysDate.getTime() - createdDate.getTime()) / 1000;

                if(seconds > 31557600) {
                    var x = Math.floor(seconds / 31557600).toString();
                    retVal = x >= 2 ? x + ' years ago' : x + ' year ago';
                } else if(seconds > 2629800) {
                    var x = Math.floor(seconds / 2629800).toString();
                    retVal = x >= 2 ? x + ' months ago' : x + ' month ago';
                } else if(seconds > 604800) {
                    var x = Math.floor(seconds / 2629800).toString();
                    if(x == 0) {
                        x = 1;
                    }
                    retVal = x >= 2 ? x + ' weeks ago' : x + ' week ago';
                } else if(seconds > 87660) {
                    var x = Math.floor(seconds / 87660).toString();
                    retVal = x >= 2 ? x + ' days ago' :  x + ' day ago';
                } else if(seconds > 3600) {
                    var x = Math.floor(seconds / 3600).toString();
                    retVal = x >= 2 ? x + ' hours ago' : x + ' hour ago';
                } else if(seconds > 60) {
                    var x = Math.floor(seconds / 60).toString();
                    retVal = x >= 2 ? x + ' minutes ago' : x + ' minute ago';
                } else {
                    var x = Math.floor(seconds).toString();
                    retVal = x >= 2 ? x + ' seconds ago' : x + ' second ago';
                }
                return retVal;
            },
        },
        created() {

        }
    }
</script>

