<template>
    <div>
        <section id="outdated_modal" role="dialog" tabindex="-1" aria-labelledby="outdated_modal-heading" aria-modal="true" aria-describedby="outdated_modal-content" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="$emit('closeModal')">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="outdated_modal-heading" class="slds-text-heading_medium slds-hyphenate">Report as Outdated</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="outdated_modal-content">
                    <div class="slds-form-element">
                        <div class="slds-form-element__control">
                            <textarea id="textarea_feedback-body" class="slds-textarea" placeholder="Why is this Demo Component outdated?" rows="4" v-model="outdatedReason"></textarea>
                        </div>
                    </div>
                </div>
                <footer class="slds-modal__footer">
                    <button class="slds-button slds-button_neutral" @click="$emit('closeModal')">Cancel</button>
                    <button class="slds-button slds-button_brand" @click="$emit('submitOutdated', outdatedReason)" :disabled="!canSubmit">Submit</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    export default {
        name: 'OutdatedModal',
        data() {
            return {
                outdatedReason: ''
            }
        },
        computed: {
            canSubmit() {
                return this.outdatedReason.length > 0 ? true : false;
            }
        }
    }
</script>

