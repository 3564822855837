<template>

    <article id="discussion-card" class="slds-card">
            <div class="slds-card__header slds-grid">
                <header class="slds-media slds-media_center slds-has-flexi-truncate">
                    <div class="slds-media__figure">
                        <span class="slds-icon_container slds-icon-standard-feedback" title="Discussion">
                            <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#feedback"></use>
                            </svg>
                            <span class="slds-assistive-text">Discussion</span>
                        </span>
                    </div>
                    <div class="slds-media__body">
                        <h2 class="slds-card__header-title">
                            <a href="javascript:void(0);" class="slds-card__header-link slds-truncate" title="Discussion">Discussion</a>
                        </h2>
                    </div>
                </header>
            </div>
            <div class="slds-card__body slds-p-bottom_xx-small">
                <div class="slds-media slds-media_center slds-p-around_medium slds-border_top slds-border_bottom slds-theme_shade">
                    <div class="slds-media__body slds-form-element">
                        <div class="slds-form-element__control">
                            <input type="text" id="post_input" placeholder="What would you like to share?" class="slds-input" v-model="newPost" />
                        </div>
                    </div>
                    <div class="slds-media__figure slds-media__figure_reverse">
                        <button id="discussion-post_button" class="slds-button slds-button_brand" :disabled="newPost == ''" v-on:click="$emit('submitPost', newPost)">Post</button>
                    </div>
                </div>
                <div class="empty-state" v-if="discussion != null && discussion.length == 0">
                    <div class="slds-illustration slds-illustration_small slds-p-top_x-large slds-p-bottom_medium slds-m-horizontal_medium">
                        <!-- {{ illustrations_macro.fishing() }} -->
                        <illustrations :image="'fishing'"/>
                        <div class="slds-text-longform">
                            <h3 class="slds-text-heading_medium">Start the discussion</h3>
                            <p class="slds-text-body_regular">Suggest how you have used this component or if you recommend any improvements</p>
                        </div>
                    </div>
                </div>
                <!-- v-if="discussion != null && discussion.length > 0" -->
                <div class="listing slds-p-horizontal_medium" v-if="discussion != null && discussion.length > 0">

                    <!-- <div class=" {{ 'slds-p-bottom_medium slds-border_bottom' if loop.index0 < 2 }}" v-for="(obj, i) in feedback" :key="i"> -->
                    <div :class="i < 2 && (i+1) != discussion.length ? 'slds-p-bottom_medium slds-border_bottom' : ''" v-for="(obj, i) in discussion" :key="i" v-show="i < 3">
                        <!-- {{ discussion.post_item(post) }} -->
                        <discussion-post-item :postItem="obj" :collapsed="true" v-on:open-discussion-modal="openDiscussionModal" v-on:reply-discussion-modal="replyDiscussionModal" />
                    </div>
                </div>
            </div>
            <footer class="slds-card__footer" v-if="discussion != null && discussion.length > 0">
                <a class="slds-card__footer-action discussion_view-all_button" href="javascript:void(0);" @click="$emit('open-discussion-modal')">View All
                    <span class="slds-assistive-text">Comments</span>
                </a>
            </footer>
        </article>

</template>

<script>
    import Illustrations from './Illustrations.vue'
    import DiscussionPostItem from './DiscussionPostItem.vue'

    export default {
        name: 'CardsDiscussion',
        props: {
            demoComponent: Object,
            discussion: Array,
            newPost: String
        },
        components: {
            illustrations: Illustrations,
            discussionPostItem: DiscussionPostItem
        },
        methods: {
            openDiscussionModal(input) { this.$emit('open-discussion-modal-with-item', input); },
            replyDiscussionModal(input) { this.$emit('reply-discussion-modal', input); }
        },
    }
</script>

