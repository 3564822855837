<template>
<div>
    <section id="signup-agreement_prompt" role="alertdialog" tabindex="-1" aria-labelledby="prompt-heading-signup_agreement" aria-describedby="prompt-body-signup_agreement" class="slds-modal slds-modal_prompt slds-fade-in-open" aria-modal="true">
        <!-- slds-fade-in-open -->
        <div class="slds-modal__container">
            <header class="slds-modal__header slds-theme_warning slds-theme_alert-texture">
                <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" v-on:click="closeModal()">
                    <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close" />
                    </svg>
                    <span class="slds-assistive-text">Close</span>
                </button>
                <h2 class="slds-text-heading_medium" id="prompt-heading-signup_agreement">Customer Org Install</h2>
            </header>
            <div class="slds-modal__content slds-p-around_medium" id="prompt-body-signup_agreement">
                <!-- <p class="slds-text-align_center slds-text-heading_small">I confirm that I will not give customers access to this demo org.</p> -->
                <p class="slds-text-align_left">Demo Components are not suitable for production environments and may mess up with customer's data and metadata.</p>
                <p class="slds-text-align_left">You are engaging your own responsibility by moving forward.</p>
            </div>
            <footer class="slds-modal__footer slds-theme_default">
                <button class="slds-button slds-button_neutral modal-close-button" v-on:click="closeModal()">Abort</button>
                <button class="slds-button slds-button_brand modal-submit-button" v-on:click="installAnyway()">Install Anyway</button>
            </footer>
        </div>
    </section>
    <div class="slds-backdrop slds-backdrop_open"></div>
</div>
</template>

<script>
    // import { ApiEndpoints } from "../mixins/ApiEndpoints";

    export default {
        // mixins: [ ApiEndpoints ],
        name: "SelectOrgWarningModal",
        props: {
    
        },
        data: function() {
            return {
    
            };
        },
        components: {
    
        },
        created() {
    
        },
        computed: {
    
        },
        methods: {
            closeModal() {
                this.$emit('cancelClicked');
            },
            installAnyway() {
                this.$emit('installAnyway');
            }
        },
        watch: {
    
        }
    };
</script>
