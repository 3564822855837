import Vue from 'vue'
import Router from 'vue-router'
import Home from '../components/Home.vue'
import InstalledByYouDetail from '../components/InstalledByYouDetail.vue'
import FavoritesDetail from '../components/FavoritesDetail.vue'
import DemoCmpDetail from '../components/DemoCmpDetail.vue'
import DemoCmpNew from '../components/DemoCmpNew.vue'
import SearchResults from '../components/SearchResults.vue'
import CreatedByOthersDetail from '../components/CreatedByOthersDetail.vue'
import CreatedByYouDetail from '../components/CreatedByYouDetail.vue'
import UserNotFound from '../components/UserNotFound.vue'
import Bundles from '../components/Bundles.vue'
import BundleDetail from '../components/BundleDetail.vue'
import RecommendedForYou from '../components/RecommendedForYou.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '*',
            redirect: '/'
        },
        {
            path: '/installed-by-you',
            name: 'InstalledByYouDetail',
            component: InstalledByYouDetail
        },
        {
            path: '/favorites',
            name: 'FavoritesDetail',
            component: FavoritesDetail
        },
        {
            path: '/demo-component-detail/:sfid',
            name: 'DemoCmpDetail',
            component: DemoCmpDetail
        },
        {
            path: '/new',
            name: 'DemoCmpNew',
            component: DemoCmpNew
        },
        {
            path: '/edit/:sfid',
            name: 'DemoCmpEdit',
            component: DemoCmpNew
        },
        {
            path: '/search',
            name: 'SearchResults',
            component: SearchResults
        },
        {
            path: '/created-by/:sfid',
            name: 'CreatedByOthersDetail',
            component: CreatedByOthersDetail
        },
        {
            path: '/created-by-you',
            name: 'CreatedByYouDetail',
            component: CreatedByYouDetail 
        },
        {
            path: '/bundles',
            name: 'Bundles',
            component: Bundles 
        },
        {
            path: '/bundle-detail/:sfid',
            name: 'BundleDetail',
            component: BundleDetail 
        },
        {
            path: '/user-not-found',
            name: 'UserNotFound',
            component: UserNotFound
        },
        {
            path: '/recommended',
            name: 'RecommendedForYou',
            component: RecommendedForYou
        },
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})