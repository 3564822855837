<template>
    
    <div class="slds-tile slds-media slds-media_center" :data-id="demoComponent.id">
        <div class="slds-media__figure">
            <figure class="slds-image slds-image_card slds-avatar_large slds-theme_shade">
                <!-- <a href="./demo-cmp-detail.html" class="slds-image__crop slds-image__crop_1-by-1">
                    <img v-if="demoComponent.image_url__c != null" :src="demoComponent.image_url__c" />
                    <img v-if="demoComponent.image_url__c == null" src="/assets/images/placeholder.png" />
                </a> -->

                <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: demoComponent.sfid }}" class="slds-image__crop slds-image__crop_1-by-1">
                    <img v-if="demoComponent.image_url__c != null" :src="demoComponent.image_url__c" />
                    <img v-if="demoComponent.image_url__c == null" src="/assets/images/placeholder.png" />
                </router-link>

            </figure>
        </div>
        <div class="slds-media__body">
            <h3 class="">
                <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: demoComponent.sfid }}">
                    {{ demoComponent.name }}
                </router-link>
            </h3>
            <div class="slds-tile__detail slds-text-body_small">
                <p>{{ componentLastUsedDate }}</p>
            </div>
        </div>
    </div>


</template>

<script>
    export default {
        name: 'DemoCmpListItemForCard',
        data() {
            return {

            }
        },
        props: {
            demoComponent: Object
        },
        components: {

        },
        computed: {
            componentLastUsedDate() {
                if(this.demoComponent.last_used == null || this.demoComponent.last_used == false) {
                    return 'Never Used'
                } else {
                    var retVal = 'Last used';
                    var todaysDate = new Date();
                    var lastUsedTime = new Date(this.demoComponent.last_used);
                    var seconds = (todaysDate.getTime() - lastUsedTime.getTime()) / 1000;

                    if(seconds > 31557600) {
                        var x = Math.floor(seconds / 31557600).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' years ago' : retVal + ' ' + x + ' year ago';
                    } else if(seconds > 2629800) {
                        var x = Math.floor(seconds / 2629800).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' months ago' : retVal + ' ' + x + ' month ago';
                    } else if(seconds > 604800) {
                        var x = Math.floor(seconds / 2629800).toString();
                        if(x == 0) { x = 1; }
                        retVal = x >= 2 ? retVal + ' ' + x + ' weeks ago' : retVal + ' ' + x + ' week ago';
                    } else if(seconds > 87660) {
                        var x = Math.floor(seconds / 87660).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' days ago' : retVal + ' ' + x + ' day ago';
                    } else if(seconds > 3600) {
                        var x = Math.floor(seconds / 3600).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' hours ago' : retVal + ' ' + x + ' hour ago';
                    } else if(seconds > 60) {
                        var x = Math.floor(seconds / 60).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' minutes ago' : retVal + ' ' + x + ' minute ago';
                    } else {
                        var x = Math.floor(seconds).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' seconds ago' : retVal + ' ' + x + ' second ago';
                    }
                    return retVal;
                }
            }
        },
        methods: {
            
        },
        created() {
 
        }
    }
</script>

<style scoped>
    .q-approved_icon:hover #q-approved_icon_tooltip {
        display: block !important;
    }
</style>


