<template>


    <div class="slds-grid slds-wrap slds-gutters_direct-x-small slds-grid_vertical-stretch">
        <!-- <div class="slds-col slds-size_1-of-1 {{ 'slds-medium-size_1-of-3' if not two_third else 'slds-medium-size_1-of-2' }} slds-m-bottom_medium slds-grid slds-grid_vertical-stretch"> -->
        <div :class="twoThirds ? 'slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-m-bottom_medium slds-grid slds-grid_vertical-stretch' : 'slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-m-bottom_medium slds-grid slds-grid_vertical-stretch'" v-for="(bundle, index) in bundles" :key="index">
            <router-link :to="{ name: 'BundleDetail', params: { sfid: bundle.sfid }}" class="slds-col slds-size_1-of-1 slds-box slds-box_link slds-box_small slds-show">
                <bundle-card :bundle="bundle" />
            </router-link>
        </div>
    </div>

</template>

<script>
    import BundleCard from './BundleCard.vue'

    export default {
        name: 'BundleList',
        props: {
            twoThirds: Boolean,
            bundles: Object
        },
        components: {
            bundleCard: BundleCard
        },
        methods: {
            
        },
        created() {

        }
    }
</script>

