<template>
    <div>
        <section id="share_modal" role="dialog" tabindex="-1" aria-labelledby="share_modal-heading" aria-modal="true" aria-describedby="share_modal-content" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="$emit('closeModal')">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="share_modal-heading" class="slds-text-heading_medium slds-hyphenate">Share with URL</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="share_modal-content">
                    <div class="slds-media slds-media_center">
                        <div class="slds-media__body slds-form-element">
                            <div class="slds-form-element__control">
                                <!-- <input type="text" id="article-url_text-input" placeholder="" class="slds-input" value="https://demo-components.qlabs.sfdc.sh/demo-component/428" /> -->
                                <input type="text" id="article-url_text-input" placeholder="" class="slds-input" :value="shareUrl" />
                            </div>
                        </div>
                        <div class="slds-media__figure slds-media__figure_reverse">
                            <button id="copy-link_button" class="slds-button slds-button_brand" @click="copyClicked()">Copy&nbsp;Link</button>
                        </div>
                    </div>
                    <div v-show="copied" class="slds-p-around_small slds-text-align_center slds-text-color_success">
                        Link Copied
                    </div>
                </div>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    export default {
        name: 'DemoCmpShareModal',
        data: () => {
			return {
				copied: false
			};
        },
        props: {
            demoComponent: Object
        },
        components: {

        },
        computed: {
            shareUrl() {
                // return 'NEED_TO_CHANGE_THIS/' + this.demoComponent.sfid;
                return window.location.protocol + '//' + window.location.hostname + '/#/demo-component-detail/' + this.demoComponent.sfid;
            }
        },
        methods: {
            copyClicked() {
                const el = document.createElement('textarea');
                el.value = this.shareUrl;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.copied = true;
            }
        },
        created() {

        }
    }
</script>

