<template>
<!-- <section class="slds-col slds-size_1-of-1 slds-medium-size_2-of-3 slds-order_3 slds-medium-order_2 slds-m-bottom_x-large"> -->
    <!-- {% import "macros/_filters.html" as filters with context %} -->
    <section class="slds-grid slds-wrap slds-m-bottom_medium">
        <div class="slds-p-bottom_x-small" v-if="showSortBy">
            <!-- {{ filters.sort_by_dropdown() }} -->
            <filters :type="'sortByDropdown'" :scope="'sort'" :value="values['sortBy']" v-on:filterChange="filterChange"/>
        </div>
        <div class="slds-col_bump-left slds-grid slds-show_medium slds-p-bottom_x-small">
            <!-- slds-hide -->
            <div id="clear-filter_button" class="slds-p-right_x-small slds-m-right_x-small slds-border_right" v-show="filterCount > 0">
                <button class="slds-button" v-on:click="clearFilters()">
                    <div class="slds-media slds-media_center slds-media_small">
                        <div class="slds-media__body">
                            <span>Clear</span>
                        </div>
                        <div class="slds-media__figure slds-media__figure_reverse slds-m-right_none">
                            <span class="filter-counter">{{ filterCount }}</span>
                        </div>
                    </div>
                </button>
            </div>
            <div class="list-filter_dropdown slds-form-element slds-m-right_xx-small">
                <div class="slds-form-element__control">
                    <div :class="currentDropdown == 'product' ? 'slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-dropdown-trigger slds-dropdown-trigger_click'"><!-- slds-is-open -->
                        <button :class="values['clouds'] != '' ? 'slds-button slds-button_brand' : 'slds-button slds-button_neutral'" aria-haspopup="true" title="Product" v-on:click="dropdownClicked('product')" v-on:focusout="lostFocus">
                            Product
                            <svg class="slds-button__icon slds-button__icon_right slds-icon-text-default" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                            </svg>
                        </button>
                        <div class="slds-dropdown slds-dropdown_right slds-dropdown_small slds-p-horizontal_medium slds-p-vertical_x-small" v-show="currentDropdown == 'product'">
                            <!-- {{ filters.checkbox_dropdown_list('product', clouds) }} -->
                            <filters :type="'checkboxDropdownList'" :scope="'product'" :value="values['clouds']" :values="clouds" v-on:filterChange="filterChange" v-on:focusOffList="lostFocus"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-filter_dropdown slds-form-element slds-m-right_xx-small">
                <div class="slds-form-element__control">
                    <div :class="currentDropdown == 'industry' ? 'slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-dropdown-trigger slds-dropdown-trigger_click'"><!-- slds-is-open -->
                        <button :class="values['industries'] != '' ? 'slds-button slds-button_brand' : 'slds-button slds-button_neutral'" aria-haspopup="true" title="Industry" v-on:click="dropdownClicked('industry')" v-on:focusout="lostFocus">
                            Industry
                            <svg class="slds-button__icon slds-button__icon_right slds-icon-text-default" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                            </svg>
                        </button>
                        <div class="slds-dropdown slds-dropdown_right slds-dropdown_medium slds-p-horizontal_medium slds-p-vertical_x-small">
                            <!-- {{ filters.checkbox_dropdown_list('industry', industries) }} -->
                            <filters :type="'checkboxDropdownList'" :scope="'industry'" :value="values['industries']" :values="industries" v-on:filterChange="filterChange" v-on:focusOffList="lostFocus"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-filter_dropdown slds-form-element">
                <div class="slds-form-element__control">
                    <div :class="currentDropdown == 'more' ? 'slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-dropdown-trigger slds-dropdown-trigger_click'"><!-- slds-is-open -->
                        <button :class="(values['features'] != '' || values['stars'] != null) ? 'slds-button slds-button_brand' : 'slds-button slds-button_neutral'" aria-haspopup="true" title="More Filters" v-on:click="dropdownClicked('more')" v-on:focusout="lostFocus">
                            <svg class="slds-button__icon slds-button__icon_left slds-icon-text-default" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#filterList"></use>
                            </svg>
                            More Filters
                            <svg class="slds-button__icon slds-button__icon_right slds-icon-text-default" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                            </svg>
                        </button>
                        <div class="slds-dropdown slds-dropdown_right slds-dropdown_large slds-p-horizontal_medium">
                            <div class="slds-grid slds-gutters_direct-x-small">
                                <div class="slds-col slds-size_1-of-2 slds-section slds-is-open">
                                    <h3 class="slds-section__title slds-theme_shade">
                                        <span class="slds-truncate slds-p-horizontal_small" title="Rating">Rating</span>
                                    </h3>
                                    <div aria-hidden="false" class="slds-section__content">
                                        <!-- {{ filters.rating() }} -->
                                        <filters :type="'rating'" :scope="'rating'" :value="values['stars']" v-on:filterChange="filterChange" v-on:focusOffList="lostFocus" v-on:focusOnStars="dontLoseFocus"/>
                                    </div>
                                </div>
                                <div class="slds-col slds-size_1-of-2 slds-section slds-is-open">
                                    <h3 class="slds-section__title slds-theme_shade">
                                        <span class="slds-truncate slds-p-horizontal_small" title="Rating">Feature</span>
                                    </h3>
                                    <div aria-hidden="false" class="slds-section__content">
                                        <!-- {{ filters.checkbox_dropdown_list('feature', features) }} -->
                                        <filters :type="'checkboxDropdownList'" :scope="'feature'" :value="values['features']" :values="features" v-on:filterChange="filterChange" v-on:focusOffList="lostFocus"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!-- </section> -->
</template>

<script>
    import Filters from './macros/Filters.vue'
    import cloudJson from '../json/clouds.json';
    import featuresJson from '../json/features.json';
    import industriesJson from '../json/industries.json';

    export default {
        name: 'ListFilters',
        data: function () {
            return {
                currentDropdown: '',
                allowLoseFocus: true
            }
        },
        props: {
            values: Object,
            showSortBy: {
                type: Boolean,
                default: true
            }
        },
        components: {
            filters: Filters
        },
        computed: {
            clouds() { return cloudJson.clouds; },
            features() { return featuresJson.features; },
            industries() { return industriesJson.industries; },
            filterCount() {
                var count = 0;
                if(this.values['clouds'] != '') {
                    count = count + this.values['clouds'].split(';').length;
                }
                if(this.values['industries'] != '') {
                    count = count + this.values['industries'].split(';').length;
                }
                if(this.values['features'] != '') {
                    count = count + this.values['features'].split(';').length;
                }
                if(this.values['stars'] != null) {
                    count = count + 1;
                }
                return count;
            }
        },
        methods: {
            filterChange(scope, value) {
                this.allowLoseFocus = false;
                var valuesClone = JSON.parse(JSON.stringify(this.values));
                if(scope == 'sort') { valuesClone['sortBy'] = value; }
                if(scope == 'product') { valuesClone['clouds'] = value; }
                if(scope == 'industry') { valuesClone['industries'] = value; }
                if(scope == 'feature') { valuesClone['features'] = value; }
                if(scope == 'rating') { valuesClone['stars'] = value; }
                this.$emit('listFilterChange', valuesClone);
            },
            dropdownClicked(val) {
                this.allowLoseFocus = false;
                this.currentDropdown = (val == this.currentDropdown) ? '' : val;
                var self = this;
                setTimeout(function() { self.allowLoseFocus = true; }, 200);
            },

            lostFocus() {
                var self = this;
                setTimeout(function() { 
                    if(self.allowLoseFocus) { self.currentDropdown = ''; }
                    self.allowLoseFocus = true;
                }, 200);
            },

            clearFilters() {
                var valuesClone = JSON.parse(JSON.stringify(this.values));
                valuesClone['clouds'] = '';
                valuesClone['industries'] = '';
                valuesClone['features'] = '';
                valuesClone['stars'] = null;
                this.currentDropdown = '';
                this.$emit('listFilterChange', valuesClone);
            },

            dontLoseFocus() {
                this.allowLoseFocus = false;
            }
        },
        created() {
            
        }
    }
</script>

