<template>
    <div class="slds-grid slds-wrap slds-gutters_direct-x-small">

        <!-- <div class="slds-col slds-size_1-of-3">
            <figure class="slds-image slds-image_card">
                <a href="javascript:void(0);" class="slds-image__crop slds-image__crop_4-by-3 demo-cmp-screenshot" data-index="0">
                    <img :src="demoComponent.image_url__c" alt="" />
                </a>
            </figure>
        </div> -->

        <!-- {% for screenshot in demo_cmp.screenshots %}
        {% if loop.index0 <= 2 %} -->
        <div class="slds-col slds-size_1-of-3" v-for="(obj, i) in screenshots" :key="i" v-show="screenshots.length <= 3" @click="$emit('screenshotClicked', i)">
            <figure class="slds-image slds-image_card">
                <a href="javascript:void(0);" class="slds-image__crop slds-image__crop_4-by-3 demo-cmp-screenshot" :data-index="i + 1">
                    <img :src="obj.url__c" alt="" />
                </a>
            </figure>
        </div>
        <!-- {% endif %}
        {% endfor %} -->


        <!-- {% for screenshot in demo_cmp.screenshots %}
        {% if loop.index0 <= 2 %} -->

        <!-- <div class="slds-col slds-size_1-of-3" v-for="(obj, i) in screenshots" :key="i" v-show="screenshots.length > 2 && i < 2">
            <figure class="slds-image slds-image_card">
                <a href="javascript:void(0);" class="slds-image__crop slds-image__crop_4-by-3 demo-cmp-screenshot" :data-index="i + 1">
                    <div v-if="i == 1" class="slds-image--overlay"></div>
                    <img :src="obj.url__c" alt="" />
                </a>
                <figcaption v-if="i == 1" class="slds-image__title slds-image__title_overlay slds-align_absolute-center slds-text-heading_large">
                    <span class="slds-image__text slds-truncate" :title="screenshots.length - 2 + '+'">{{ screenshots.length - 2 }}+
                        <span class="slds-assistive-text">more files</span>
                    </span>
                </figcaption>
            </figure>
        </div> -->

        <div class="slds-col slds-size_1-of-3" v-for="(obj, i) in screenshots" :key="i" v-show="screenshots.length > 3 && i < 3" @click="$emit('screenshotClicked', i)">
            <figure class="slds-image slds-image_card">
                <a href="javascript:void(0);" class="slds-image__crop slds-image__crop_4-by-3 demo-cmp-screenshot" :data-index="i + 1">
                    <div v-if="i == 2" class="slds-image--overlay"></div>
                    <img :src="obj.url__c" alt="" />
                </a>
                <figcaption v-if="i == 2" class="slds-image__title slds-image__title_overlay slds-align_absolute-center slds-text-heading_large">
                    <span class="slds-image__text slds-truncate" :title="screenshots.length - 3 + '+'">{{ screenshots.length - 3 }}+
                        <span class="slds-assistive-text">more files</span>
                    </span>
                </figcaption>
            </figure>
        </div>

        <!-- {% endif %}
        {% endfor %} -->


    </div>
</template>

<script>
    export default {
        name: 'DemoCmpScreenshots',
        props: {
            demoComponent: Object,
            screenshots: Array
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>
