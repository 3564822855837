<template>
    
     <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    <div>

        <!--
            IMPORTANT NOTE: Due to browsers inconsistencies, clip-path cannot be referenced from an external file.
            Therefore some SVGs in this module have to be clipped with an inline SVG definition
            that should be defined once in the HTML body (page-template.html).
            Make sure you copy the SVG structure there and have it only once in your final app
            Further Reference: https://bugs.chromium.org/p/chromium/issues/detail?id=109212
        -->

        <!-- {% macro favorite(is_favorited=false, counter=0) %} -->
        <!-- <a href="javascript:void(0);" class="slds-text-link_reset favorite_inline-action nowrap noselect {{ 'is_favorited' if is_favorited==true }}"> -->
        <a :class="isFavorite ? 'slds-text-link_reset favorite_inline-action nowrap noselect is_favorited' : 'slds-text-link_reset favorite_inline-action nowrap noselect'" v-if="type == 'favorite'" @click="$emit('favoriteClicked')">
            <span class="slds-icon_container slds-m-right_xx-small" title="Favorite">
                <svg class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true" viewBox="0 0 24 24">
                    <use xlink:href="/assets/images/custom-icons-sprite.svg#favoriteShape" clip-path="url(#favoriteClip)" />
                </svg>
                <span class="slds-assistive-text">Favorite Demo Component</span>
            </span>
            <span class="slds-text-body_small slds-text-color_weak">{{ demoComponent.number_of_favorites__c }}</span>
        </a>
        <!-- {% endmacro %} -->


        <!-- {% macro install(is_installed=false, counter=0) %} -->
        <!-- <a href="javascript:void(0);" class="slds-text-link_reset install_inline-action install-component_button nowrap noselect {{ 'is_installed' if is_installed==true }}"> -->
        <a href="javascript:void(0);" class="slds-text-link_reset install_inline-action install-component_button nowrap noselect is_installed" v-if="type == 'install'" @click="installClicked">
            <span class="slds-icon_container slds-m-right_xx-small" title="Install">
                <svg class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true" :class="(demoComponent.package_url__c == null || demoComponent.package_url__c == '') ? 'slds-icon-text-light' : 'slds-icon-text-default'">
                    <use xlink:href="/assets/images/custom-icons-sprite.svg#install" />
                </svg>
                <span class="slds-assistive-text">Install Demo Component</span>
            </span>
            <span class="slds-text-body_small slds-text-color_weak">{{ demoComponent.usage__c }}</span>
        </a>
        <!-- {% endmacro %} -->

    </div>

</template>

<script>
    export default {
        name: 'InlineActions',
        props: {
            demoComponent: Object,
            type: String, // favorite / install,
            isFavorite: Boolean
        },
        components: {

        },
        methods: {
            installClicked() {
                if(this.demoComponent.package_url__c != null && this.demoComponent.package_url__c != ''){
                    var tmpArray = [];
                    tmpArray.push(this.demoComponent);
                    this.$store.commit('setComponentsToInstall' , tmpArray);
                }                
            },
        },
        created() {

        }
    }
</script>

