<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    <div>

        <!-- {% macro pagination(total_pages=204) %} -->
        <div class="slds-grid slds-grid_align-end slds-grid_vertical-align-center slds-gutters_direct-xxx-small slds-p-bottom_medium">
            <div class="slds-col slds-col_bump-right">
                <div class="slds-form-element">
                    <div class="slds-form-element__control">
                        <div class="slds-combobox_container slds-size_x-small">
                            <div :class="showItemsPerPageDropdown ? 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click'" aria-expanded="true" aria-haspopup="listbox" role="combobox">
                                <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                    <input type="text" class="slds-input slds-combobox__input slds-combobox__input-value" id="combobox-items_per_page" aria-controls="listbox-items_per_page" autoComplete="off" role="textbox" placeholder="Select an Option" readonly="" :value="paginationSettings.currentPageSizeOption +  ' per page'" v-on:click="dropdownClicked()" v-on:focusout="dropdownLoseFocus()" :disabled="demoComponents == null || (demoComponents != null && demoComponents.length == 0)"/>
                                    <span class="slds-icon_container slds-icon-utility-down slds-input__icon slds-input__icon_right" title="Items per page">
                                        <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                                        </svg>
                                        <span class="slds-assistive-text">Items per page</span>
                                    </span>
                                </div>
                                <div id="listbox-items_per_page" role="listbox" class="slds-dropdown slds-dropdown_fluid">
                                    <ul class="slds-listbox slds-listbox_vertical" role="presentation">
                                        <!-- {% for items in [8, 16, 32, 64] %} -->
                                        <li v-for="(obj, i) in paginationSettings.pagesSizeOptions" :key="i" role="presentation" class="slds-listbox__item" v-on:click="dropdownOptionClicked(obj)">
                                            <div :id="'listbox-option_items-' + obj" :class="obj == paginationSettings.currentPageSizeOption ? 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center slds-is-selected' : 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center'" role="option">
                                                <span class="slds-media__figure">
                                                    <svg class="slds-icon slds-icon_x-small slds-listbox__icon-selected" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                                    </svg>
                                                </span>
                                                <span class="slds-media__body">
                                                    <span class="slds-truncate" :title="obj + ' per page'">
                                                        <span class="slds-assistive-text" v-show="obj == paginationSettings.currentPageSizeOption">Current Selection:</span>{{ obj }} per page
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                        <!-- {% endfor %} -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slds-col">
                <button class="slds-button slds-button_icon-border" id="paginationPrevious" :disabled="(demoComponents == null || (demoComponents != null && demoComponents.length == 0)) || (paginationSettings.currentPage == 1)" @click="pageButtonClicked('subtract')">
                    <svg aria-hidden="true" class="slds-button__icon">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevronleft"></use>
                    </svg>
                    <span class="slds-assistive-text">Previous</span>
                </button>
            </div>
            <div class="slds-col">
                <div class="slds-form-element">
                    <div class="slds-form-element__control">
                        <div class="slds-select_container">
                            <select id="pages" class="slds-select" :disabled="demoComponents == null || (demoComponents != null && demoComponents.length == 0)" v-on:change="pageSelectionChanged" :value="paginationSettings.currentPage">
                                <!-- {% for page in range(1, total_pages+1) %} -->
                                <!-- :value="i" -->
                                <option :value="i + 1" v-for="(obj, i) in paginationSettings.totalPages" :key="i">page {{ i + 1 }} of {{ paginationSettings.totalPages }}</option>
                                <!-- {% endfor %} -->
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slds-col">
                  <button class="slds-button slds-button_icon-border" id="paginationNext" :disabled="(demoComponents == null || (demoComponents != null && demoComponents.length == 0)) || (paginationSettings.currentPage == paginationSettings.totalPages)" @click="pageButtonClicked('add')">
                      <svg aria-hidden="true" class="slds-button__icon">
                          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevronright"></use>
                      </svg>
                      <span class="slds-assistive-text">Next</span>
                  </button>
            </div>
        </div>
        <!-- {% endmacro %} -->


        <!-- {% macro load_more_button() %}
        onclick: disabled="disabled"
        <button class="slds-button slds-button_neutral slds-button_stretch load-more-results_button">
            Load More Results

            onclick: remove ".slds-hide"
            <div class="slds-spinner_container slds-hide">
                <div role="status" class="slds-spinner slds-spinner_x-small">
                    <span class="slds-assistive-text">Loading</span>
                    <div class="slds-spinner__dot-a"></div>
                    <div class="slds-spinner__dot-b"></div>
                </div>
            </div>
        </button>
        {% endmacro %} -->

    </div>

</template>

<script>
    export default {
        name: 'Pagination',
        data: function () {
            return {
                showItemsPerPageDropdown: false,
            }
        },
        props: {
            demoComponents: Array,
            paginationSettings: Object
        },
        components: {

        },
        methods: {
            dropdownClicked() {
                this.showItemsPerPageDropdown = !this.showItemsPerPageDropdown;
            },
            dropdownLoseFocus() {
                var self = this;
                setTimeout(function() { self.showItemsPerPageDropdown = false; }, 200);
            },
            dropdownOptionClicked(val) {
                var paginationSettingsClone = JSON.parse(JSON.stringify(this.paginationSettings));
                paginationSettingsClone.currentPageSizeOption = val;
                paginationSettingsClone.currentPage = 1;
                this.$emit('paginationSettingsChanged', paginationSettingsClone);
            },
            pageSelectionChanged(evt) {
                var paginationSettingsClone = JSON.parse(JSON.stringify(this.paginationSettings));
                paginationSettingsClone.currentPage = parseInt(evt.target.value);
                this.$emit('paginationSettingsChanged', paginationSettingsClone);
            },
            pageButtonClicked(val) {
                var paginationSettingsClone = JSON.parse(JSON.stringify(this.paginationSettings));
                if(val == 'add') { paginationSettingsClone.currentPage++; }
                if(val == 'subtract') { paginationSettingsClone.currentPage--; }
                this.$emit('paginationSettingsChanged', paginationSettingsClone);
            }
        },
        created() {

        }
    }
</script>

