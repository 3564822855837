<template>
    <div>
        <section id="cancel_modal" role="dialog" tabindex="-1" aria-labelledby="cancel_modal-heading" aria-modal="true" aria-describedby="cancel_modal-content" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="cancel_modal-heading" class="slds-text-heading_medium slds-hyphenate">Cancel Demo Component Creation</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="cancel_modal-content">
                    <p>Are you sure you want to leave this page? You progress creating this component will be lost.</p>
                </div>
                <footer class="slds-modal__footer">
                    <button class="slds-button slds-button_neutral close-modal_button">Back</button>
                    <button class="slds-button slds-button_brand close-modal_button">Ok</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    export default {
        name: 'CancelModal',
        props: {
        
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>

