<template>
    
    <div>
        <section id="modal_discussion" role="dialog" tabindex="-1" aria-labelledby="modal-heading_discussion" aria-modal="true" aria-describedby="modal-content_discussion" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="$emit('close-modal')">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="modal-heading_discussion" class="slds-modal__title slds-hyphenate">Discussion</h2>
                </header>
                <div class="slds-modal__content slds-grid slds-grid_vertical slds-grid_vertical-stretch" id="modal-content_reviews">
                    <div class="slds-media slds-media_center slds-p-around_medium slds-border_bottom slds-theme_shade">
                        <div class="slds-media__body slds-form-element">
                            <div class="slds-form-element__control">
                                <input type="text" id="modal-post_input" placeholder="What would you like to share?" class="slds-input" v-model="newPost"/>
                            </div>
                        </div>
                        <div class="slds-media__figure slds-media__figure_reverse">
                            <button class="slds-button slds-button_brand" @click="submitPost">Post</button>
                        </div>
                    </div>
                    <div class="slds-col slds-p-horizontal_medium slds-p-bottom_medium slds-scrollable_y">
                        <discussion-post-item :id="'post_' + obj.sfid" 
                            :postItem="obj" 
                            :collapsed="false"
                            :doFocusOnLoad="doAutoFocus(obj.sfid)"
                            v-for="(obj, i) in discussion" 
                            :key="i" 
                            v-on:submit-comment="submitComment" />
                    </div>
                </div>
                <footer class="slds-modal__footer">
                  <button class="slds-button slds-button_brand close-modal_button" @click="$emit('close-modal')">Close</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>

</template>

<script>
    import DiscussionPostItem from './DiscussionPostItem.vue'
    
    export default {
        name: 'DiscussionModal',
        data() {
            return {
                newPost: ''
            }
        },
        props: {
            discussion: Array,
            tmpDiscussionItem: Object
        },
        components: {
            discussionPostItem: DiscussionPostItem
        },
        computed: {
            
        },
        methods: {
            submitPost() {
                this.$emit('submit-post', this.newPost);
                this.newPost = '';
            },
            submitComment(comment, postItem) {
                this.$emit('submit-comment', comment, postItem);
            },
            doAutoFocus(input) {
                return (this.tmpDiscussionItem != null && 
                    this.tmpDiscussionItem.action == 'reply' &&
                    this.tmpDiscussionItem.item.sfid == input);
            }
        },
        created() {
            setTimeout(() => {
                if(this.tmpDiscussionItem != null) {
                    let el = document.getElementById('post_' + this.tmpDiscussionItem.item.sfid);
                    el.scrollIntoView();
                }
                this.$emit('clear');
            }, 200);
        },
        computed: {
            
        }
    }
</script>

