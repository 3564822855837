<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->

    <div class="slds-size_1-of-1 slds-container_x-large slds-grid slds-wrap slds-gutters_direct-medium">
        <!-- {% extends "page-template.html" %} -->

        <!-- {% block content %}
        {% import "macros/_filters.html" as filters with context %}
        {% import "macros/_bundles.html" as bundle_macro with context %}
        {% import "macros/_demo-comp-list.html" as demo_cmp_list with context %}
        {% import "macros/_pagination.html" as pagination with context %} -->

        <section class="slds-col slds-size_1-of-1 slds-medium-size_1-of-3">
            <div class="slds-m-bottom_medium">
                <!-- {% include "_back-button.html" %} -->
                <back-button />
            </div>
            <!-- <div class="slds-m-bottom_medium slds-show_medium">
                <filters :type="'sortByDropdown'" :scope="'sort'" :value="searchValues['sortBy']" :fluid="true" v-on:filterChange="filterChange"/>
            </div> -->
            <div class="slds-show_medium">
                <div :class="showContentTypeOptions ? 'slds-section slds-is-open slds-m-top_none' : 'slds-section slds-m-top_none'">
                    <h3 class="slds-section__title ">
                        <button aria-controls="expando_content-type" aria-expanded="true" class="slds-button slds-section__title-action" @click="showContentTypeOptions = !showContentTypeOptions">
                            <svg class="slds-section__title-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                            </svg>
                            <span class="slds-truncate" title="Content Type">Content Type</span>
                        </button>
                    </h3>
                    <div aria-hidden="false" class="slds-section__content slds-p-horizontal_x-small" id="expando_content-type">
                        <fieldset class="slds-form-element">
                            <div class="slds-form-element__control">
                                <!-- {% for type in search_scopes %} -->
                                <span class="slds-radio" v-for="(obj, i) in searchObj['options']" :key="i">
                                    <input type="radio" :id="'radio-' + obj" :value="'radio-' + obj" name="search-scope" :checked="searchObj['searchCategory'] == obj" v-on:change="filterChange('contentType', obj)" />
                                    <label class="slds-radio__label" :for="'radio-' + obj">
                                        <span class="slds-radio_faux"></span>
                                        <span class="slds-form-element__label">{{ obj }}</span>
                                    </label>
                                </span>
                                <!-- {% endfor %} -->
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div :class="showProductOptions ? 'slds-section slds-is-open' : 'slds-section'" v-show="searchObj.searchCategory != 'Users'">
                    <h3 class="slds-section__title">
                        <button aria-controls="expando_product" aria-expanded="true" class="slds-button slds-section__title-action" @click="showProductOptions = !showProductOptions">
                            <svg class="slds-section__title-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                            </svg>
                            <span class="slds-truncate" title="Product">Product</span>
                        </button>
                    </h3>
                    <div aria-hidden="false" class="slds-section__content slds-p-horizontal_x-small" id="expando_product">
                        <fieldset class="slds-form-element">
                            <!-- {{ filters.checkbox_group('cloud', clouds) }} -->
                            <filters :type="'checkboxGroup'" :scope="'product'" :value="searchValues['clouds']" :values="clouds" v-on:filterChange="filterChange" />
                        </fieldset>
                    </div>
                </div>
                <div :class="showIndustryOptions ? 'slds-section slds-is-open' : 'slds-section'" v-show="searchObj.searchCategory != 'Users'">
                    <h3 class="slds-section__title">
                        <button aria-controls="expando_industry" aria-expanded="true" class="slds-button slds-section__title-action" @click="showIndustryOptions = !showIndustryOptions">
                            <svg class="slds-section__title-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                            </svg>
                            <span class="slds-truncate" title="Industry">Industry</span>
                        </button>
                    </h3>
                    <div aria-hidden="false" class="slds-section__content slds-p-horizontal_x-small" id="expando_industry">
                        <fieldset class="slds-form-element">
                            <!-- {{ filters.checkbox_group('industry', industries) }} -->
                            <filters :type="'checkboxGroup'" :scope="'industry'" :value="searchValues['industries']" :values="industries" v-on:filterChange="filterChange" />
                        </fieldset>
                    </div>
                </div>
                <div :class="showFeatureOptions ? 'slds-section slds-is-open' : 'slds-section'" v-show="searchObj.searchCategory != 'Users' && searchObj.searchCategory != 'Bundles'">
                    <h3 class="slds-section__title">
                        <button aria-controls="expando_feature" aria-expanded="true" class="slds-button slds-section__title-action" @click="showFeatureOptions = !showFeatureOptions">
                            <svg class="slds-section__title-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                            </svg>
                            <span class="slds-truncate" title="Feature">Feature</span>
                        </button>
                    </h3>
                    <div aria-hidden="false" class="slds-section__content slds-p-horizontal_x-small" id="expando_product">
                        <fieldset class="slds-form-element">
                            <!-- {{ filters.checkbox_group('feature', features) }} -->
                            <filters :type="'checkboxGroup'" :scope="'feature'" :value="searchValues['features']" :values="features" v-on:filterChange="filterChange" />
                        </fieldset>
                    </div>
                </div>

                <div :class="showRatingOptions ? 'slds-section slds-is-open' : 'slds-section'" v-show="searchObj.searchCategory != 'Users' && searchObj.searchCategory != 'Bundles'">
                    <h3 class="slds-section__title">
                        <button aria-controls="expando_rating" aria-expanded="true" class="slds-button slds-section__title-action" @click="showRatingOptions = !showRatingOptions">
                            <svg class="slds-section__title-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                            </svg>
                            <span class="slds-truncate" title="Rating">Rating</span>
                        </button>
                    </h3>
                    <div aria-hidden="false" class="slds-section__content slds-p-horizontal_x-small" id="expando_rating">
                        <!-- {{ filters.rating() }} -->
                        <filters :type="'rating'" :scope="'rating'" :value="searchValues['stars']" v-on:filterChange="filterChange"/>
                    </div>
                </div>
            </div>
        </section>

        <section class="slds-col slds-size_1-of-1 slds-medium-size_2-of-3">
            <div class="slds-m-bottom_medium">
                <p v-if="searchObj.searchTerm != null && searchObj.searchTerm != ''" class="slds-text-heading_small slds-m-vertical_xx-small">Search results for "<a href="javascript:void(0);">{{ searchObj.searchTerm }}</a>"</p>
                <div class="search-filter-tags">
                    <span v-show="searchValues['clouds'] != '' || searchValues['industries'] != '' || searchValues['features'] != ''" class="slds-pill slds-pill_link">
                        <a href="javascript:void(0);" class="slds-pill__action" title="Clear all filters">
                            <span class="slds-pill__label">Clear All</span>
                        </a>
                        <button class="slds-button slds-button_icon slds-button_icon slds-pill__remove" title="Remove" @click="clearAllFilters">
                            <svg class="slds-button__icon" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                            </svg>
                            <span class="slds-assistive-text">Remove</span>
                        </button>
                    </span>
                    <span v-show="searchValues['clouds'] != ''" class="slds-pill slds-pill_link" v-for="(obj, i) in searchValues['clouds'].split(';')" :key="i">
                        <a href="javascript:void(0);" class="slds-pill__action" :title="obj">
                            <span class="slds-pill__label">{{ obj }}</span>
                        </a>
                        <button class="slds-button slds-button_icon slds-button_icon slds-pill__remove" title="Remove" @click="clearFilterItem('product', obj)">
                            <svg class="slds-button__icon" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                            </svg>
                            <span class="slds-assistive-text">Remove</span>
                        </button>
                    </span>
                    <span v-show="searchValues['industries'] != ''" class="slds-pill slds-pill_link" v-for="(obj, i) in searchValues['industries'].split(';')" :key="i">
                        <a href="javascript:void(0);" class="slds-pill__action" :title="obj">
                            <span class="slds-pill__label">{{ obj }}</span>
                        </a>
                        <button class="slds-button slds-button_icon slds-button_icon slds-pill__remove" title="Remove" @click="clearFilterItem('industry', obj)">
                            <svg class="slds-button__icon" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                            </svg>
                            <span class="slds-assistive-text">Remove</span>
                        </button>
                    </span>
                    <span v-show="searchValues['features'] != ''" class="slds-pill slds-pill_link" v-for="(obj, i) in searchValues['features'].split(';')" :key="i">
                        <a href="javascript:void(0);" class="slds-pill__action" :title="obj">
                            <span class="slds-pill__label">{{ obj }}</span>
                        </a>
                        <button class="slds-button slds-button_icon slds-button_icon slds-pill__remove" title="Remove" @click="clearFilterItem('feature', obj)">
                            <svg class="slds-button__icon" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                            </svg>
                            <span class="slds-assistive-text">Remove</span>
                        </button>
                    </span>

                </div>
            </div>
    
            <div>

                <div class="empty_state slds-m-vertical_xx-large" v-if="(searchObj.searchTerm == '') || (searchedComponents != null && searchedComponents.length == 0)">
                    <!-- {% import "macros/_illustrations.html" as illustrations with context %} -->
                    <div class="slds-illustration slds-illustration_large">
                        <!-- {{ illustrations.desert() }} -->
                        <illustrations :image="'desert'" />
                        <div class="slds-text-longform" v-if="envVars != null">
                            <h3 class="slds-text-heading_medium">No Results Found</h3>
                            <p class="slds-text-body_regular">
                                Have new ideas for demo component?
                                <br />
                                <span class="slds-text-body_small">Submit your thoughts to <a :href="envVars.innovationBoxComponents">innovation BOX</a></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="search-results slds-m-top_x-large slds-m-bottom_large">
                    <div class="user_results slds-m-bottom_xx-large" v-if="(searchObj.searchTerm != '') && (searchedUsers != null && searchedUsers.length > 0) && (searchObj.searchCategory == 'All' || searchObj.searchCategory == 'Users')">
                        <!-- Limit to 5 results unless "Content Type" filter is set to "Users" -->
                        <!-- Number in parenthesis should read "5+" if there's more than 5 results and previous condition is matched -->
                        <h3 class="slds-text-heading_small slds-m-bottom_medium">Users ({{ searchedUsers.length }})</h3>
                        <ul>
                            <!-- {% for index in search_results.users %}
                            {% set user = people[index] %} -->
                            <li class="slds-media slds-m-bottom_small" v-for="(usr, index) in searchedUsers" :key="index" v-show="index < 10">
                                <span class="slds-media__figure">
                                    <span class="slds-avatar slds-avatar_circle">
                                        <abbr class="slds-avatar__initials slds-icon-standard-user" :title="usr.firstname + ' ' + usr.lastname">{{ getUserInitials(usr.firstname + ' ' + usr.lastname) }}</abbr>
                                    </span>
                                </span>
                                <div class="slds-media__body">
                                    <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: usr.sfid }}">
                                        {{ usr.firstname + ' ' + usr.lastname }}
                                    </router-link> -->
                                    <user-link :userName="usr.firstname + ' ' + usr.lastname" :userSFID="usr.sfid" />
                                    <ul class="slds-list_horizontal slds-has-dividers_left slds-has-block-links slds-text-body_small slds-text-color_weak">
                                        <!-- <li class="slds-item ">4 Demo Components</li> -->
                                        <li class="slds-item slds-show_medium">Creator since {{ getDateAsString(usr.createddate) }}</li>
                                    </ul>
                                </div>
                            </li>
                            <!-- {% endfor %} -->
                        </ul>
                    </div>
                    <div class="user_results slds-m-bottom_xx-large" v-if="(searchObj.searchTerm != '') && (searchedUsers == null) && (searchObj.searchCategory == 'All' || searchObj.searchCategory == 'Users')">
                        <h3 class="slds-text-heading_small slds-m-bottom_medium">Users</h3>
                        <spinner :size="'x-small'" />
                    </div>

                    <div class="bundle_results slds-m-bottom_x-large" v-if="(searchObj.searchTerm != '') && (displayedBundles != null && displayedBundles.length > 0) && (searchObj.searchCategory == 'All' || searchObj.searchCategory == 'Bundles')">
                        <!-- Limit to 4 results at a time unless "Content Type" filter is set to "Bundles" -->
                        <!-- Number in parenthesis should read "4+" if there's more than 4 results and previous condition is matched -->

                        <h3 class="slds-text-heading_small slds-m-bottom_medium">Bundles ({{ displayedBundles.length }})</h3>
                        <!-- {{ bundle_macro.bundles_list(search_results.bundles, true) }}
                        {{ pagination.load_more_button() }} -->

                        <bundle-list :bundles="displayedBundles" :twoThirds="true" />
                        <load-more-button v-if="localBundlesLimit < bundles.length"
                            v-on:load-more="loadMoreBundlesHandler"/>
                    </div>


                    <div class="component_results" v-if="(searchObj.searchTerm != '') && (displayedComponents != null && displayedComponents.length > 0) && (searchObj.searchCategory == 'All' || searchObj.searchCategory == 'Components')">
                        <h3 class="slds-text-heading_small slds-m-bottom_medium">Components ({{ displayedComponents.length }})</h3>
                        <demo-cmp-list :demoComponents="displayedComponents"/>
                        <load-more-button v-if="localComponentsLimit < demoComponents.length"
                            v-on:load-more="loadMoreComponentsHandler"/>
                    </div>
                    <div class="component_results" v-if="(searchObj.searchTerm != '') && (searchedComponents == null) && (searchObj.searchCategory == 'All' || searchObj.searchCategory == 'Components')">
                        <h3 class="slds-text-heading_small slds-m-bottom_medium">Components</h3>
                       <spinner :size="'x-small'" />
                    </div>
                </div>
            </div>
        </section>

        <!-- {% include "_demo-cmp-share-modal.html" %}
        {% include "_install-destination-modal.html" %}
        {% include "_install-prompt.html" %}
        {% include "_install-success-modal.html" %}
        {% endblock %} -->

    </div>

</template>

<script>
    import BackButton from './BackButton.vue'
    import Filters from './macros/Filters.vue'
    import DemoCmpList from './macros/DemoCmpList.vue'
    import Illustrations from './macros/Illustrations.vue'
    import Spinner from './macros/Spinner.vue'
    import LoadMoreButton from './macros/LoadMoreButton.vue'
    import cloudJson from '../json/clouds.json';
    import featuresJson from '../json/features.json';
    import industriesJson from '../json/industries.json';
    import { ApiEndpoints } from "./../mixins/ApiEndpoints";
    import BundleList from "./macros/BundleList.vue";
    import UserLink from "./macros/UserLink.vue"

    export default {
        name: 'SearchResults',
        mixins: [ ApiEndpoints ],
        data() {
            return {
                demoComponents: null,
                bundles: null,
                users: null,

                showContentTypeOptions: true,
                showProductOptions: true,
                showIndustryOptions: true,
                showFeatureOptions: true,
                showRatingOptions: true,

                localComponentsLimit: 8,
                localBundlesLimit: 4
            }
        },
        props: {
        
        },
        components: {
            backButton: BackButton,
            filters: Filters,
            demoCmpList: DemoCmpList,
            illustrations: Illustrations,
            loadMoreButton: LoadMoreButton,
            spinner: Spinner,
            bundleList: BundleList,
            userLink: UserLink
        },
        computed: {
            clouds() { return cloudJson.clouds; },
            features() { return featuresJson.features; },
            industries() { return industriesJson.industries; },
            searchValues () { return this.$store.getters.getSearchValues; },

            searchObj () { return this.$store.getters.getSearchObj; },
            searchedComponents() { return this.$store.getters.getSearchedComponents; },
            searchedUsers() { return this.$store.getters.getSearchedUsers; },
            searchedBundles() { return this.$store.getters.getSearchedBundles; },
            favoriteComponents() { return this.$store.getters.getFavoriteComponents; },

            hasFilters() { return !(this.searchValues.clouds == '' && this.searchValues.industries == '' && this.searchValues.features == '' && this.searchValues.stars == null); },

            displayedComponents() { return this.demoComponents.slice(0, this.localComponentsLimit); },
            displayedBundles() { return this.bundles.slice(0, this.localBundlesLimit); },

            envVars() { return this.$store.getters.getEnvVariables; },
        },
        methods: {
            doInit() {
                this.demoComponents = this.filterComponents();
                this.bundles = this.filterBundles();
                this.searchObj.componentsSearchLimit = 100;
                this.searchObj.bundlesSearchLimit = 100;
                this.$store.commit('setSearchObj' , this.searchObj);
                this.qSearchDemoComponents();
                this.qSearchUsers();
                this.qSearchBundles();

                if(this.favoriteComponents == null) {
                    this.getFavoriteComponents();
                }
            },
            filterChange(scope, value) {
                this.localComponentsLimit = 8;
                this.localBundlesLimit = 4;
                if(scope == 'contentType') { this.searchObj['searchCategory'] = value; }
                if(scope == 'sort') { this.searchValues['sortBy'] = value; }
                if(scope == 'product') { this.searchValues['clouds'] = value; }
                if(scope == 'industry') { this.searchValues['industries'] = value; }
                if(scope == 'feature') { this.searchValues['features'] = value; }
                if(scope == 'rating') { this.searchValues['stars'] = value; }
                this.$store.commit('setSearchObj' , this.searchObj);
                this.$store.commit('setSearchValues' , this.searchValues);
                this.demoComponents = this.filterComponents();
                this.bundles = this.filterBundles();
            },
            clearFilterItem(scope, value) {
                this.localComponentsLimit = 8;
                this.localBundlesLimit = 4;
                if(scope == 'product') { this.searchValues['clouds'] = this.searchValues['clouds'].split(';').filter(e => e != value).sort().join(';'); }
                if(scope == 'industry') { this.searchValues['industries'] = this.searchValues['industries'].split(';').filter(e => e != value).sort().join(';'); }
                if(scope == 'feature') { this.searchValues['features'] = this.searchValues['features'].split(';').filter(e => e != value).sort().join(';'); }
                if(scope == 'rating') { this.searchValues['stars'] = null }
                this.demoComponents = this.filterComponents();
                this.bundles = this.filterBundles();
            },
            clearAllFilters() {
                this.localComponentsLimit = 8;
                this.localBundlesLimit = 4;
                this.searchValues['clouds'] = '';
                this.searchValues['industries'] = '';
                this.searchValues['features'] = '';
                this.searchValues['stars'] = null;
                this.demoComponents = this.filterComponents();
                this.bundles = this.filterBundles();
            },
            compareArrays(arr1, arr2) {
                return arr2.every(v => arr1.includes(v));
            },
            filterComponents() {
                if(this.searchedComponents == null) {
                    return [];
                }

                var retArr = [];
                var cloudsArr = (this.searchValues.clouds != null && this.searchValues.clouds != '') ? this.searchValues.clouds.split(';') : [];
                var featuresArr = (this.searchValues.features != null && this.searchValues.features != '') ? this.searchValues.features.split(';') : [];
                var industriesArr = (this.searchValues.industries != null && this.searchValues.industries != '') ? this.searchValues.industries.split(';') : [];

                for(var i = 0; i < this.searchedComponents.length; i++) {
                    console.log('Loop this.searchedComponents[i] : ', this.searchedComponents[i]);
                    var cloudsCmp = (this.searchedComponents[i].cloud__c != null) ? this.searchedComponents[i].cloud__c.split(';') : [];
                    var featuresCmp = (this.searchedComponents[i].feature__c != null) ? this.searchedComponents[i].feature__c.split(';') : [];
                    var industriesCmp = (this.searchedComponents[i].industry__c != null) ? this.searchedComponents[i].industry__c.split(';') : [];

                    var hasClouds = (cloudsArr.length > 0) ? this.compareArrays(cloudsCmp, cloudsArr) : true;
                    var hasFeatures = (featuresCmp.length > 0) ? this.compareArrays(featuresCmp, featuresArr) : true;
                    var hasIndustries = (industriesArr.length > 0) ? this.compareArrays(industriesCmp, industriesArr) : true;
                    var hasStarRating = (this.searchValues.stars != null) ? (this.searchedComponents[i].rating_average__c != null && this.searchedComponents[i].rating_average__c >= this.searchValues.stars) : true;

                    if(hasClouds && hasFeatures && hasIndustries && hasStarRating && hasStarRating) {
                        retArr.push(this.searchedComponents[i]);
                    }
                }
                if(this.favoriteComponents != null) {
                    for(var i = 0; i < retArr.length; i++) {
                        for(var j = 0; j < this.favoriteComponents.length; j++) {
                            if(retArr[i].sfid == this.favoriteComponents[j].sfid) {
                                retArr[i].is_favourite = true;
                            }
                        }
                    }
                }
                return retArr;
            },
            filterBundles() {
                if(this.searchedBundles == null) {
                    return [];
                }

                var retArr = [];
                var cloudsArr = (this.searchValues.clouds != null && this.searchValues.clouds != '') ? this.searchValues.clouds.split(';') : [];
                var featuresArr = (this.searchValues.features != null && this.searchValues.features != '') ? this.searchValues.features.split(';') : [];
                var industriesArr = (this.searchValues.industries != null && this.searchValues.industries != '') ? this.searchValues.industries.split(';') : [];

                for(var i = 0; i < this.searchedBundles.length; i++) {
                    var cloudsCmp = (this.searchedBundles[i].clouds__c != null) ? this.searchedBundles[i].clouds__c.split(';') : [];
                    var featuresCmp = (this.searchedBundles[i].features__c != null) ? this.searchedBundles[i].features__c.split(';') : [];
                    var industriesCmp = (this.searchedBundles[i].industries__c != null) ? this.searchedBundles[i].industries__c.split(';') : [];

                    var hasClouds = (cloudsArr.length > 0) ? this.compareArrays(cloudsCmp, cloudsArr) : true;
                    var hasFeatures = (featuresCmp.length > 0) ? this.compareArrays(featuresCmp, featuresArr) : true;
                    var hasIndustries = (industriesArr.length > 0) ? this.compareArrays(industriesCmp, industriesArr) : true;

                    if(hasClouds && hasFeatures && hasIndustries) {
                        retArr.push(this.searchedBundles[i]);
                    }
                }
                return retArr;
            },
            loadMoreComponentsHandler() {
                this.localComponentsLimit = this.localComponentsLimit + 8;
                this.demoComponents = this.filterComponents();
            },
            loadMoreBundlesHandler() {
                this.localBundlesLimit = this.localBundlesLimit + 4;
                this.bundles = this.filterBundles();
            },
            getUserInitials(name) {
                var splitName = name.split(' ');
                if(splitName.length >= 2) {
                    var firstInitial = splitName[0].charAt(0);
                    var secondInitial = splitName[1].charAt(0);
                    return firstInitial + secondInitial;
                } else {
                    return splitName[0].charAt(0);
                }
            },
            getDateAsString(input) {
                var monthsArray = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
                var dateObj = new Date(input);
                return monthsArray[dateObj.getUTCMonth()] + ' ' + dateObj.getUTCDate() + this.getNthValueForDateString(dateObj.getUTCDate()) + ', ' + dateObj.getUTCFullYear();
            },
            getNthValueForDateString(d) {
                if (d > 3 && d < 21) return 'th';
                switch (d % 10) {
                    case 1:  return "st";
                    case 2:  return "nd";
                    case 3:  return "rd";
                    default: return "th";
                }
            },
        },
        created() {
            this.doInit();
        },
        watch: {
            searchedComponents(newVal, oldVal) {
                this.demoComponents = this.filterComponents();
            },
            searchedBundles(newVal, oldVal) {
                this.bundles = this.filterBundles();
            },
            hasFilters(newVal, oldVal) {
                if(newVal && !oldVal) {
                    console.log('IS NOW TRUE AND OLD VALUE WAS FALSE!');
                    this.searchObj.componentsSearchLimit = 100;
                    this.searchObj.bundlesSearchLimit = 100;
                    this.$store.commit('setSearchObj' , this.searchObj);
                }
                this.qSearchDemoComponents();
                this.qSearchBundles();
            }
        }
    }   
</script>
