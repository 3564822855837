<template>

    <div class="slds-size_1-of-1 slds-container_x-large slds-grid slds-wrap slds-gutters_direct-medium">
        <!-- {% extends "page-template.html" %}

        {% block content %} -->

        <!-- {% import "macros/_carousels.html" as carousels with context %}
        {% import 'macros/_feedback.html' as feedback with context %} -->

        <!-- v-if="recentlyUsedComponents != null && recentlyUsedComponents.length > 0" -->
        <section class="slds-col slds-size_1-of-1 slds-m-bottom_x-large" v-if="recentlyUsedComponents != null && recentlyUsedComponents.length > 0">
            <!-- {% include "_rating-cards.html" %} -->
            <rating-cards />
        </section>
        <section class="slds-col slds-size_1-of-1 slds-m-bottom_x-small">
            <!-- {{ carousels.bundles_carousel(bundles) }} -->
            <bundles-carousel :bundles="featuredBundles" />
        </section>
        <section class="slds-col slds-size_1-of-1 slds-order_1 slds-m-bottom_large">
                <carousels :type="'generic'" 
                        :title="'Recommended for you'"
                        :demoComponents="displayRecommendedComponents"
                        :einstein="true"
                        :hasViewAll="true"
                        :fullPage="true" />
        </section>


        <!-- <section class="slds-col slds-size_1-of-1 slds-medium-size_2-of-3 slds-order_3 slds-medium-order_2 slds-m-bottom_x-large"> -->
            <!-- {% include "_list-filters.html" %}
            {% import "macros/_demo-comp-list.html" as dc_listing with context %}
            {{ dc_listing.list() }} -->
        <!-- </section> -->
        
        <section class="slds-col slds-size_1-of-1 slds-medium-size_2-of-3 slds-order_3 slds-medium-order_2 slds-m-bottom_x-large">
            <list-filters :values="listFilterValues" v-on:listFilterChange="filtersChanged"/>
            <demo-cmp-list :demoComponents="allComponents" :paginationSettings="paginationSettings" v-on:paginationSettingsChanged="paginationSettingsChanged"/>
            <!-- <demo-cmp-list :demoComponents="displayAllComponents" /> -->
        </section>



        <!-- <section class="slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-order_2 slds-medium-order_3 slds-m-bottom_x-large"> -->
            <!-- {% include "_home-side-section.html" %} -->
            <home-side-section :favoriteComponents="favoriteComponents"
                                :installedComponents="installedComponents"
                                :ownedComponents="ownedComponents" />
        <!-- </section> -->



        <!-- {{ feedback.rating_modal() }}
        {% include "_demo-cmp-share-modal.html" %}
        {% include "_install-destination-modal.html" %}
        {% include "_install-prompt.html" %}
        {% include "_install-success-modal.html" %}
        {% endblock %} -->

    </div>

</template>

<script>
    import axios from "axios";
    import { ApiEndpoints } from "../mixins/ApiEndpoints";
    import DemoCmpList from './macros/DemoCmpList.vue'
    import Carousels from './macros/Carousels.vue'
    import ListFilters from './ListFilters.vue'
    import HomeSideSection from './HomeSideSection.vue'
    import RatingCards from './RatingCards.vue'
    import BundlesCarousel from './macros/BundlesCarousel.vue'

    export default {
        name: 'Home',
        mixins: [ ApiEndpoints ],
        computed: {
            accessToken() { return this.$store.getters.getAccessToken; },
            allComponents() { return this.$store.getters.getAllComponents; },
            listFilterValues() { return this.$store.getters.getListFilterValues; },
            favoriteComponents() { return this.$store.getters.getFavoriteComponents; },
            installedComponents() { return this.$store.getters.getInstalledByYouComponents; },
            ownedComponents() { return this.$store.getters.getOwnedComponents; },
            recommendedComponents() { return this.$store.getters.getRecommendedComponents; },
            paginationSettings() { return this.$store.getters.getPaginationSettings; },
            featuredBundles() { return this.$store.getters.getFeaturedBundles; },
            recentlyUsedComponents() { return this.$store.getters.getRecentlyUsedComponents; },

            displayRecommendedComponents() {
                if(this.recommendedComponents == null) {
                    return null;
                } else {
                    var retVal = [];
                    for(var i =0; i < this.recommendedComponents.length; i++) {
                        retVal.push(this.recommendedComponents[i]);
                    }
                    return retVal;
                }
            }

        },
        components: {
            demoCmpList: DemoCmpList,
            listFilters: ListFilters,
            homeSideSection: HomeSideSection,
            carousels: Carousels,
            ratingCards: RatingCards,
            bundlesCarousel: BundlesCarousel
        },
        methods: {
            doInit() {

                // this.qSearchDemoComponents();


                this.getDemoComponents();
                this.getFavoriteComponents();
                this.getInstalledByYouComponents();
                this.getFeaturedBundles();
                this.getCreatedByYouComponents();
                this.getRecentlyUsedComponents();
                this.getPredictionsForUser();

                console.log(this.$store.state.sessionDetails);

            },
            filtersChanged(val) {
                var paginationSettingsClone = JSON.parse(JSON.stringify(this.paginationSettings));
                paginationSettingsClone.currentPage = 1;
                paginationSettingsClone.totalPages = 1;
                this.$store.commit('setPaginationSettings', paginationSettingsClone);
                this.$store.commit('setListFilterValues', val);
                this.$store.commit('setAllComponents', null);
                this.getDemoComponents();
            },
            paginationSettingsChanged(val) {
                this.$store.commit('setPaginationSettings', val);
                this.$store.commit('setAllComponents', null);
                this.getDemoComponents();
            },
        },
        created() {
            this.doInit();
        }
    }
</script>

