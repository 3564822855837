<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->

    <div class="slds-size_1-of-1 slds-container_x-large slds-grid slds-wrap slds-gutters_direct-medium">
        <!-- {% extends "page-template.html" %}
        {% block content %} -->

        <spinner-large :size="'medium'" v-show="demoComponent == null"/>

        <!-- {% import 'macros/_carousels.html' as carousels with context %}
        {% import 'macros/_cards.html' as cards with context %}
        {% import 'macros/_feedback.html' as feedback with context %}
        {% import 'macros/_discussion.html' as discussion with context %}
        {% set demo_cmp = demo_components[0] %} -->

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
            <!-- {% include "_back-button.html" %} -->
            <back-button />
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_xx-small slds-grid slds-wrap" v-if="demoComponent != null">
            <div class="slds-col slds-size_1-of-1">
                <div class="slds-grid slds-gutters_direct-x-small slds-grid_align-spread">
                    <div class="slds-col slds-m-bottom_medium">
                        <h1 class="slds-text-heading_large slds-m-right_x-small slds-show_inline slds-align-middle">{{ demoComponent.name }}</h1>
                        <div class="slds-show_inline slds-align-middle" v-if="demoComponent.q_approved__c">
                            <span id="q-approved_badge" class="slds-badge slds-badge_inverse slds-theme_alt-inverse">
                                <span class="slds-badge__icon slds-badge__icon_left slds-m-right_x-small slds-badge__icon_inverse">
                                    <span class="slds-icon_container" title="This component is up to date and has been reviewed by Q Branch.">
                                        <svg class="slds-icon slds-icon_xx-small" aria-hidden="true">
                                            <use xlink:href="/assets/images/q-logo.svg#q-logo_mini-inverse"></use>
                                        </svg>
                                    </span>
                                </span>Reviewed by Q Branch
                            </span>
                        </div>
                    </div>
                    <div class="slds-col slds-show_medium" v-if="isOwner || isCollaborator">
                        <router-link :to="{ name: 'DemoCmpEdit', params: { sfid: demoComponent.sfid }}">
                            <button class="slds-button slds-button_icon slds-button_icon-border-filled" title="Edit">
                                <svg class="slds-button__icon" aria-hidden="true">
                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit"></use>
                                </svg>
                                <span class="slds-assistive-text">Edit</span>
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="slds-col">
                <div class="slds-m-bottom_xx-small">
                    <collaborators-list :demoComponent="demoComponent"
                        :isOwner="isOwner"
                        :isCollaborator="isCollaborator"
                        :collaborators="collaborators" 
                        v-on:collaborators-button-clicked="currentModal = 'collaborators'" />
                </div>
                <div class="slds-m-bottom_small">
                    <div class="slds-media slds-media_center">
                        <div class="slds-media__figure">
                            <span class="slds-icon_container slds-icon-utility-clock" title="Timestamp">
                                <svg class="slds-icon slds-icon-text-default slds-icon_xx-small" aria-hidden="true">
                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#clock"></use>
                                </svg>
                                <span class="slds-assistive-text">Timestamp</span>
                            </span>
                        </div>
                        <div class="slds-media__body">
                            <ul class="slds-list_horizontal slds-has-dividers_left slds-has-block-links slds-text-body_small slds-text-color_weak">
                                <li class="slds-item">{{ componentLastModifiedDate }}</li>
                                <li class="slds-item slds-show_medium">{{ componentLastUsedDate }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slds-col slds-col_bump-left slds-text-align_right slds-grid slds-wrap slds-gutters_direct-xx-small">
                <div class="slds-col slds-size_1-of-1 slds-m-bottom_xxx-small" v-show="demoComponent.under_review__c || demoComponent.draft__c">
                    <!-- &nbsp;<span class="slds-badge {{ 'slds-hide' if demo_cmp.is_under_review == false and demo_cmp.Status__c == 'Active' }}">{{ 'Under Review' if demo_cmp.is_under_review else 'Inactive' }}</span> -->
                    &nbsp;
                    <!-- ACTIVE -->
                    <!-- <span class="slds-badge {{ 'slds-hide' if demo_cmp.is_under_review == false and demo_cmp.Status__c == 'Active' }}">{{ 'Under Review' if demo_cmp.is_under_review else 'Inactive' }}</span> -->
                    <!-- UNDER REVIEW -->
                    <span class="slds-badge" v-show="demoComponent.under_review__c">Under Review</span>
                    <!-- INACTIVE -->
                    <span class="slds-badge" v-show="demoComponent.draft__c">Inactive</span>
                </div>
                <div class="slds-col slds-size_1-of-1 ">
                    <button id="outdated_button" class="slds-button slds-text-color_weak slds-text-body_small" @click="outdatedClicked()">
                        <svg class="slds-button__icon slds-button__icon_left slds-button__icon_small slds-icon-text-default" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#archive"></use>
                        </svg>
                        Report as Outdated
                    </button>
                </div>
            </div>
        </section>
        <section class="slds-col slds-size_1-of-1 slds-medium-size_2-of-3 slds-m-bottom_large" v-if="demoComponent != null">
            <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium" v-if="screenshots != null && screenshots.length > 0">
                <div class="slds-box slds-box_small slds-theme_shade">
                    <!-- {% include "_demo-cmp-screenshots.html" %} -->
                    <demo-cmp-screenshots :screenshots="screenshots" :demoComponent="demoComponent" v-on:screenshotClicked="showScreenshot"/>
                </div>
            </section>
            <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
                <!-- {% include "_demo-cmp-detail-controls.html" %} -->
                <demo-cmp-detail-controls :demoComponent="demoComponent" :totalViews="totalViews" :isFavorited="isFavorited" 
                    v-on:favoriteClicked="favoriteClicked" v-on:copyClicked="copyUrlClicked" v-on:installClicked="installComponentHandler" v-on:downloadClicked="downloadRepo"/>
            </section>
            <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
                <div class="slds-box slds-box_small slds-theme_shade">
                    <div class="slds-text-longform is-markdown slds-m-bottom_large" v-html="componentDescriptionMarkdown">
                        <!-- {{ demo_cmp.markdown_description | safe }} -->
                        <!-- {{ componentDescriptionMarkdown }} -->
                    </div>
                    <!-- <div id="demoCompsCategories" class="" v-if="demoComponent.cloud__c != null || demoComponent.feature__c != null || demoComponent.industry__c != null"> -->
                    <div id="demoCompsCategories" class="">
                        <span class="slds-badge slds-badge_lightest slds-m-left_none slds-m-right_xx-small slds-m-bottom_xx-small slds-show_inline-block" v-for="(obj, i) in pills" :key="i">{{ obj }}</span>
                    </div>
                </div>
            </section>
            <section class="slds-col slds-size_1-of-1 slds-m-top_x-large slds-m-bottom_large slds-show_medium">
                <!-- {{ carousels.generic_carousel(id='similar_desktop', dataset=recommendations, title='Similar Demo Components', einstein=true, has_view_all=false, two_third=true) }} -->
                <carousels :type="'generic'" 
                    :title="'Similar Demo Components'"
                    :demoComponents="recommendedComponents"
                    :einstein="true"
                    :hasViewAll="false"
                    :fullPage="false" />
            </section>
        </section>
        <section class="slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-m-bottom_x-large" v-if="demoComponent != null">
            <!-- {{ cards.feedback_card(demo_cmp, true) }}
            {{ cards.discussion_card(demo_cmp, true) }} -->

            <cards-feedback :demoComponent="demoComponent" 
                            :feedback="feedback" 
                            :currentRating="currentRating" 
                            :tmpRatingValue="tmpStarRating" 
                            v-on:viewAll="viewAllFeedback" 
                            v-on:openRatingModal="openRatingModal" 
                            v-on:setTempRatingValue="setTempRatingValue" 
                            v-on:resetTmpRatingValue="resetTmpRatingValue" />

            <cards-discussion :demoComponent="demoComponent" 
                            :discussion="discussion" 
                            :newPost="newDiscussionPost" 
                            v-on:viewAll="viewAllDiscussion" 
                            v-on:submitPost="submitDiscussionPost" 
                            v-on:open-discussion-modal="currentModal = 'discussion'"  
                            v-on:open-discussion-modal-with-item="openDiscussionItem" 
                            v-on:reply-discussion-modal="replyToDiscussionItem" />

        </section>
        <section class="slds-col slds-size_1-of-1 slds-m-bottom_large slds-hide_medium">
            <!-- {{ carousels.generic_carousel(id='similar_mobile', dataset=recommendations, title='Similar Demo Components', einstein=true, has_view_all=false, two_third=false) }} -->
            <carousels :type="'generic'" 
                    :title="'Similar Demo Components'"
                    :demoComponents="recommendedComponents"
                    :einstein="true"
                    :hasViewAll="false"
                    :fullPage="false" />
        </section>

        <!-- {{ feedback.reviews_modal(demo_cmp.reviews) }}
        {{ feedback.rating_modal() }}
        {{ discussion.discussion_modal(demo_cmp.discussion) }} -->

        <!-- {% include "_collaborators-modal.html" %}
        {% include "_outdated-modal.html" %} -->

        <!-- {% include "_demo-cmp-share-modal.html" %}
        {% include "_install-destination-modal.html" %}
        {% include "_install-prompt.html" %}
        {% include "_install-success-modal.html" %} -->

        <!-- {% endblock %} -->

        <photo-swipe :screenshots="screenshots" 
            ref="screenshotCmp"/>
        <reviews-modals :feedback="feedback" 
            v-on:closeModal="closeAllModals" 
            v-if="currentModal == 'feedback'" />
        <outdated-modal v-on:submitOutdated="submitOutdatedModal" 
            v-on:closeModal="closeAllModals" 
            v-if="currentModal == 'outdated'" />
        <demo-cmp-share-modal :demoComponent="demoComponent" 
            v-if="currentModal == 'copyUrl'" 
            v-on:closeModal="closeAllModals" />
        <rating-modal :demoComponent="demoComponent" 
            :ratingValue="tmpStarRatingModal" 
            :ratingComment="tmpRatingComment" 
            v-if="currentModal == 'rating'" 
            v-on:submit="submitFeedbackModal" 
            v-on:closeModal="closeAllModals" />
        <collaborators-modal :demoComponent="demoComponent" 
            :collaborators="collaborators"
            :isOwner="isOwner"
            :isCollaborator="isCollaborator"
            v-if="currentModal == 'collaborators'" 
            v-on:close-modal="currentModal = ''" 
            v-on:refresh-collaborators="refreshCollaboratorsHandler"/>
        <discussion-modal :tmpDiscussionItem="tmpDiscussionItem" 
            v-if="currentModal == 'discussion'" 
            v-on:close-modal="closeAllModals" 
            v-on:clear="tmpDiscussionItem = null" 
            :discussion="discussion" 
            v-on:submit-post="submitDiscussionPost" 
            v-on:submit-comment="submitDiscussionComment" />

    </div>

</template>

<script>
    import axios from "axios";
    import BackButton from "./BackButton.vue";
    import CollaboratorsList from "./CollaboratorsList.vue";
    import SpinnerLarge from "./macros/SpinnerLarge.vue";
    import DemoCmpScreenshots from "./DemoCmpScreenshots.vue";
    import DemoCmpDetailControls from "./DemoCmpDetailControls.vue";
    import Carousels from "./macros/Carousels.vue";
    import CardsFeedback from "./macros/CardsFeedback.vue";
    import PhotoSwipe from "./PhotoSwipe.vue";
    import DemoCmpShareModal from "./DemoCmpShareModal.vue";
    import ReviewsModals from "./macros/ReviewsModals.vue";
    import OutdatedModal from "./OutdatedModal.vue";
    import CardsDiscussion from "./macros/CardsDiscussion.vue";
    import RatingModal from "./macros/RatingModal.vue";
    import CollaboratorsModal from "./CollaboratorsModal.vue";
    import DiscussionModal from "./macros/DiscussionModal.vue";

    export default {
        name: 'DemoCmpDetail',
        data() {
            return {
                demoComponent: null,
                attachments: null,
                screenshots: null,
                collaborators: null,
                feedback: null,
                discussion: null,
                totalViews: null,
                isFavorited: null,
                currentModal: '', // copyUrl / feedback / outdated / discussion / collaborators

                tmpStarRating: 0,
                tmpStarRatingModal: 0,
                tmpRatingComment: '',
                myStarRating: 0,

                newDiscussionPost: '',
                tmpDiscussionItem: null,

                recommendedComponents: null

                // isCreator: false,
                // isCollaborator: false,
            }
        },
        props: {
        
        },
        components: {
            spinnerLarge: SpinnerLarge,
            backButton: BackButton,
            collaboratorsList: CollaboratorsList,
            demoCmpScreenshots: DemoCmpScreenshots,
            demoCmpDetailControls: DemoCmpDetailControls,
            carousels: Carousels,
            cardsFeedback: CardsFeedback,
            photoSwipe: PhotoSwipe,
            demoCmpShareModal: DemoCmpShareModal,
            reviewsModals: ReviewsModals,
            outdatedModal: OutdatedModal,
            cardsDiscussion: CardsDiscussion,
            ratingModal: RatingModal,
            collaboratorsModal: CollaboratorsModal,
            discussionModal: DiscussionModal
        },
        computed: {
            accessToken() { return this.$store.getters.getAccessToken; },
            // recommendedComponents() { return this.$store.getters.getRecommendedComponents; },
            currentUser() { return this.$store.getters.getCurrentUser; },
            sessionDetails() { return this.$store.getters.sessionDetails; },
            isOwner() {
                return (this.demoComponent != null && this.demoComponent.ownerid == this.sessionDetails.user.sfid) ? true : false;
            },
            isCollaborator() {
                return ((this.collaborators != null) && (this.collaborators.filter((x) => { return (x.author != null && x.author.sfid == this.sessionDetails.user.sfid) }).length > 0)) ? true : false;
            },
            displayRecommendedComponents() {
                if(this.recommendedComponents == null) {
                    return null;
                } else {
                    var retVal = [];
                    for(var i =0; i < this.recommendedComponents.length; i++) {
                        retVal.push(this.recommendedComponents[i]);
                    }
                    return retVal;
                }
            },
            componentDescriptionMarkdown() {
                if(this.demoComponent.description__c == null) {
                    return '';
                }

                var showdown = require('showdown');
                showdown.extension('targetlink', function() {
                    return [
                        {
                            type: 'html',
                            regex: /(<a [^>]+?)(>.*<\/a>)/g,
                            replace: '$1 target="_blank"$2'
                        }
                    ]
                });
                var converter = new showdown.Converter({
                    strikethrough: true,
                    tables: true,
                    extensions: ['targetlink']
                });
                var html = converter.makeHtml(this.demoComponent.description__c);
                html = html.replace(/<h1/g, '<h1 class="slds-text-heading--large"');
                html = html.replace(/<h2/g, '<h2 class="slds-text-heading--medium"');
                html = html.replace(/<h3/g, '<h3 class="slds-text-heading--small"');
                html = html.replace(/<h4/g, '<h4 class="slds-text-heading--caps"');
                html = html.replace(/<h5/g, '<h5 class="slds-text-title"');
                html = html.replace(/<ul/g, '<ul class="slds-list_dotted"');
                html = html.replace(/\[button-brand\]<a\s/g, '<a class="slds-button slds-button--brand" target="_blank"');
                html = html.replace(/\[button\]<a\s/g, '<a class="slds-button slds-button--neutral" target="_blank"');
                return html;
            },
            componentLastUsedDate() {
                if(this.demoComponent.most_recent_usage_date__c == null) {
                    return 'Never installed'
                } else {
                    var retVal = 'Last installed';
                    var todaysDate = new Date();
                    var lastUsedTime = new Date(this.demoComponent.most_recent_usage_date__c);
                    var seconds = (todaysDate.getTime() - lastUsedTime.getTime()) / 1000;

                    if(seconds > 31557600) {
                        var x = Math.floor(seconds / 31557600).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' years ago' : retVal + ' ' + x + ' year ago';
                    } else if(seconds > 2629800) {
                        var x = Math.floor(seconds / 2629800).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' months ago' : retVal + ' ' + x + ' month ago';
                    } else if(seconds > 604800) {
                        var x = Math.floor(seconds / 2629800).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' weeks ago' : retVal + ' ' + x + ' week ago';
                    } else if(seconds > 87660) {
                        var x = Math.floor(seconds / 87660).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' days ago' : retVal + ' ' + x + ' day ago';
                    } else if(seconds > 3600) {
                        var x = Math.floor(seconds / 3600).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' hours ago' : retVal + ' ' + x + ' hour ago';
                    } else if(seconds > 60) {
                        var x = Math.floor(seconds / 60).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' minutes ago' : retVal + ' ' + x + ' minute ago';
                    } else {
                        var x = Math.floor(seconds).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' seconds ago' : retVal + ' ' + x + ' second ago';
                    }
                    return retVal;
                }
            },
            componentLastModifiedDate() {
                var retVal;
                var updatedTime;
                if((this.demoComponent.listing_last_modified_date__c == null) || (this.demoComponent.listing_last_modified_date__c == this.demoComponent.createddate)) {
                    updatedTime = new Date(this.demoComponent.createddate);
                    retVal = 'Created';
                } else {
                    updatedTime = new Date(this.demoComponent.listing_last_modified_date__c);
                    retVal = 'Edited';
                }
                
                var todaysDate = new Date();
                var seconds = (todaysDate.getTime() - updatedTime.getTime()) / 1000;

                if(seconds > 31557600) {
                    var x = Math.floor(seconds / 31557600).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' years ago' : retVal + ' ' + x + ' year ago';
                } else if(seconds > 2629800) {
                    var x = Math.floor(seconds / 2629800).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' months ago' : retVal + ' ' + x + ' month ago';
                } else if(seconds > 604800) {
                    var x = Math.floor(seconds / 604800).toString();
                    if(x == 0) { x = 1; }
                    retVal = x >= 2 ? retVal + ' ' + x + ' weeks ago' : retVal + ' ' + x + ' week ago';
                } else if(seconds > 87660) {
                    var x = Math.floor(seconds / 87660).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' days ago' : retVal + ' ' + x + ' day ago';
                } else if(seconds > 3600) {
                    var x = Math.floor(seconds / 3600).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' hours ago' : retVal + ' ' + x + ' hour ago';
                } else if(seconds > 60) {
                    var x = Math.floor(seconds / 60).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' minutes ago' : retVal + ' ' + x + ' minute ago';
                } else {
                    var x = Math.floor(seconds).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' seconds ago' : retVal + ' ' + x + ' second ago';
                }
                return retVal;
            },
            pills() {
                if(this.demoComponent == null) {
                    return [];
                } else {
                    var retVal = [];
                    if(this.demoComponent.cloud__c != null && this.demoComponent.cloud__c != '') {
                        retVal = retVal.concat(this.demoComponent.cloud__c.split(';'));
                    }
                    if(this.demoComponent.feature__c != null && this.demoComponent.feature__c != '') {
                        retVal = retVal.concat(this.demoComponent.feature__c.split(';'));
                    }
                    if(this.demoComponent.industry__c != null && this.demoComponent.industry__c != '') {
                        retVal = retVal.concat(this.demoComponent.industry__c.split(';'));
                    }
                    return retVal;
                }
            }
        },
        methods: {
            doInit() {
                this.getDemoComponentBySFID();
                this.getDemoComponentCollaboratorsBySFID();
                this.getDemoComponentFeedbackBySFID();
                this.getDemoComponentDiscussionBySFID();
                this.getRecommendedComponentsForThisComponent();

                // get recommended components should be added here.
            },

            // API Endpoints for this page
            getDemoComponentBySFID() {
                var self = this;
                var componentId = this.$route.params.sfid;
                axios.post("/v1/getDemoComponentDetail", JSON.stringify({ token: this.accessToken, componentId: componentId })).then((res) => {
                    if (!res.data.error) {
                        console.log('getDemoComponentBySFID :: ' , res.data.data);
                        self.demoComponent = res.data.data;
                        self.attachments = res.data.data.attachments;
                        self.screenshots = res.data.data.screenshots;
                        self.totalViews = res.data.data.total_views;
                        self.isFavorited = res.data.data.is_favourite;
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_ERROR', toastLengthInSeconds: 15 });
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_ERROR', toastLengthInSeconds: 15 });
                    console.log(err);
                });
            },
            getDemoComponentCollaboratorsBySFID() {
                var self = this;
                var componentId = this.$route.params.sfid;
                axios.post("/v1/getDemoComponentCollaborators", JSON.stringify({ token: this.accessToken, componentId: componentId })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('getDemoComponentCollaboratorsBySFID :: ' , res.data.data.dc_collaborators);
                        self.collaborators = JSON.parse(res.data.data.dc_collaborators);
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_COLLABORATORS_ERROR', toastLengthInSeconds: 15 });
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_COLLABORATORS_ERROR', toastLengthInSeconds: 15 });
                    console.log(err);
                });
            },
            getDemoComponentFeedbackBySFID() {
                var self = this;
                var componentId = this.$route.params.sfid;
                axios.post("/v1/getDemoComponentFeedback", JSON.stringify({ token: this.accessToken, componentId: componentId })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('getDemoComponentFeedbackBySFID :: ' , res.data.data.feedback);
                        self.feedback = res.data.data.feedback;
                        self.setMyStarRating();
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_FEEDBACK_ERROR', toastLengthInSeconds: 15 });
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_FEEDBACK_ERROR', toastLengthInSeconds: 15 });
                    console.log(err);
                });
            },
            getDemoComponentDiscussionBySFID() {
                var self = this;
                var componentId = this.$route.params.sfid;
                axios.post("/v1/getDemoComponentDiscussion", JSON.stringify({ token: this.accessToken, componentId: componentId })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('getDemoComponentDiscussionBySFID :: ' , res.data.data.discussion);
                        self.discussion = res.data.data.discussion;
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_DISCUSSION_ERROR', toastLengthInSeconds: 15 });
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_DISCUSSION_ERROR', toastLengthInSeconds: 15 });
                    console.log(err);
                });
            },
            favoriteClicked() {
                var self = this;
                var componentId = this.$route.params.sfid;
                this.isFavorited = !this.isFavorited;

                if(this.isFavorited) {
                    this.demoComponent.number_of_favorites__c = this.demoComponent.number_of_favorites__c + 1;
                } else {
                    this.demoComponent.number_of_favorites__c = ((this.demoComponent.number_of_favorites__c - 1) > 0) ? this.demoComponent.number_of_favorites__c - 1 : 0;
                }

                axios.post("/v1/favoriteComponent", JSON.stringify({ token: this.accessToken, componentId: componentId, favorite: this.isFavorited })).then((res) => {
                    if (!res.data.error) {
                        // self.getDemoComponentBySFID();
                    } else {
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            setFeedback(starRating, feedbackText) {
                var self = this;
                var componentId = this.$route.params.sfid;
                axios.post("/v1/postFeedback", JSON.stringify({ token: this.accessToken, componentId: componentId, rating: starRating, comment: feedbackText })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('setFeedback RETURNED :: ' , res.data.data);
                        self.getDemoComponentFeedbackBySFID();
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'success', toastMessage: 'FEEDBACK_SUCCESS', toastLengthInSeconds: 5 });
                    } else {
                        self.getDemoComponentFeedbackBySFID();
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'FEEDBACK_ERROR', toastLengthInSeconds: 10 });
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'FEEDBACK_ERROR', toastLengthInSeconds: 10 });
                    console.log(err);
                });
            },
            reportComponent(comment) {
                var self = this;
                var componentId = this.$route.params.sfid;
                axios.post("/v1/report", JSON.stringify({ token: this.accessToken, componentId: componentId, comment: comment })).then((res) => {
                    if (!res.data.error) {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'success', toastMessage: 'REPORT_SUCCESS', toastLengthInSeconds: 5 });
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'REPORT_ERROR', toastLengthInSeconds: 10 });
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'REPORT_ERROR', toastLengthInSeconds: 10 });
                    console.log(err);
                });
            },
            postDiscussion(comment) {
                console.log('postDiscussion :: ' + comment);
                var self = this;
                var componentId = this.$route.params.sfid;
                axios.post("/v1/postDiscussion", JSON.stringify({ token: this.accessToken, componentId: componentId, comment: comment })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('postDiscussion RETURNED :: ' , res.data.data);
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'success', toastMessage: 'DISCUSSION_SUCCESS', toastLengthInSeconds: 10 });
                        self.getDemoComponentDiscussionBySFID();
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'DISCUSSION_ERROR', toastLengthInSeconds: 10 });
                        self.getDemoComponentDiscussionBySFID();
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'DISCUSSION_ERROR', toastLengthInSeconds: 10 });
                    console.log(err);
                });
            },
            postComment(comment, postItem) {
                console.log('postComment :: ' + comment);
                var self = this;
                var discussionId = postItem.sfid;
                axios.post("/v1/postComment", JSON.stringify({ token: this.accessToken, discussionId: discussionId, comment: comment })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('postComment RETURNED :: ' , res.data.data);
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'success', toastMessage: 'COMMENT_SUCCESS', toastLengthInSeconds: 10 });
                        self.getDemoComponentDiscussionBySFID();
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'COMMENT_ERROR', toastLengthInSeconds: 10 });
                        self.getDemoComponentDiscussionBySFID();
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'COMMENT_ERROR', toastLengthInSeconds: 10 });
                    console.log(err);
                });
            },
            getRecommendedComponentsForThisComponent() {
                console.log('getRecommendedComponentsForThisComponent called.');
                var params = {
                    token: this.accessToken,
                    dc_id: this.$route.params.sfid
                };
                axios.post("/v1/getPredictions", JSON.stringify(params)).then((res) => {
                    console.log('getRecommendedComponentsForThisComponent :::: ' , res.data);
                    if (!res.data.error && res.data.data.success) {
                        console.log('getRecommendedComponentsForThisComponent success :: ' , res.data.data.predictions);
                        this.recommendedComponents = res.data.data.predictions;
                    } else {
                        console.log('getRecommendedComponentsForThisComponent error.');
                        // this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'COMMENT_ERROR', toastLengthInSeconds: 10 });
                        this.recommendedComponents = [];
                    }
                }).catch((err) => {
                    // this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'COMMENT_ERROR', toastLengthInSeconds: 10 });
                    console.log(err);
                });
            },
            downloadRepo() {
                console.log('downloadRepo called.');
                var params = {
                    packageUrl: this.demoComponent.package_url__c
                };
                axios.post("/v1/downloadRepoZip", JSON.stringify(params)).then((res) => {
                    if (!res.data.error && res.data) {
                        var url = "data:application/zip;base64,"+res.data.data
                        fetch(url)
                        .then(res => res.blob())
                        .then(result => {
                            var fileURL = window.URL.createObjectURL(result);
                            var fileLink = document.createElement('a');
                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', 'source.zip');
                            document.body.appendChild(fileLink);
                            fileLink.click();
                        });                       
                    } else {
                        console.log('downloadRepoZip error.');
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },







            setMyStarRating() {
                // var userEmail = this.currentUser.federationIdentifier;
                var userEmail = this.sessionDetails.user.email;
                var myRating = this.feedback.filter(function(item) {
                    return item.author.email == userEmail;
                });
                if(myRating.length > 0) {
                    this.myStarRating = myRating[0].rating_value__c;
                    this.tmpStarRating = myRating[0].rating_value__c;
                } else {
                    this.myStarRating = 0;
                    this.tmpStarRating = 0;
                }
            },


            // Feedback - Star Rating
            setTempRatingValue(val) {
                this.tmpStarRating = val;
            },
            resetTmpRatingValue() {
                this.tmpStarRating = this.myStarRating;
            },








            showScreenshot(indexVal) {
                this.$refs.screenshotCmp.doInit(indexVal);
            },
            copyUrlClicked() {
                this.currentModal = 'copyUrl';
            },
            closeAllModals() {
                this.currentModal = '';
                this.tmpDiscussionItem = null;
                this.tmpRatingComment = '';
                this.setMyStarRating();
            },
            openModal(val) {
                this.currentModal = val;
            },
            viewAllFeedback() {
                this.currentModal = 'feedback';
            },
            viewAllDiscussion() {
                this.currentModal = 'discussion';
            },
            outdatedClicked() {
                this.currentModal = 'outdated';
            },
            openRatingModal(val) {
                this.tmpStarRatingModal = val;
                this.myStarRating = val;

                var existingFeedback = this.feedback.filter((item) => { return item.author.email == this.sessionDetails.user.email; });
                if(existingFeedback.length > 0) {
                    this.tmpRatingComment = existingFeedback[0].body__c;
                }

                this.currentModal = 'rating';
            },
            submitFeedbackModal(starRating, feedbackText) {
                this.setFeedback(starRating, feedbackText);
                this.closeAllModals();
            },
            submitOutdatedModal(outdatedReason) {
                this.reportComponent(outdatedReason);
                this.closeAllModals();
            },
            submitDiscussionPost(comment) {
                this.postDiscussion(comment);
                this.newDiscussionPost = '';
            },
            submitDiscussionComment(comment, postItem) {
                console.log('submitDiscussionComment');
                console.log(comment);
                console.log(postItem);
                this.postComment(comment, postItem);
            },
            openDiscussionItem(input) {
                this.tmpDiscussionItem = {
                    action: 'open',
                    item: input
                };
                this.currentModal = 'discussion';
            },
            replyToDiscussionItem(input) {
                this.tmpDiscussionItem = {
                    action: 'reply',
                    item: input
                };
                this.currentModal = 'discussion';
            },




            resetPageDefaultData() {
                this.demoComponent = null;
                this.attachments = null;
                this.screenshots = null;
                this.collaborators = null;
                this.feedback = null;
                this.discussion = null;
                this.totalViews = null;
                this.isFavorited = null;
                this.currentModal = '';
                this.tmpStarRating = 0;
                this.tmpStarRatingModal = 0;
                this.myStarRating = 0;
                this.newDiscussionPost = '';
                this.tmpDiscussionItem = null;
                this.recommendedComponents = null;
            },

            installComponentHandler() {
                var tmpArray = [];
                tmpArray.push(this.demoComponent);
                this.$store.commit('setComponentsToInstall' , tmpArray);
            },
            refreshCollaboratorsHandler() {
                this.getDemoComponentCollaboratorsBySFID();
                this.getDemoComponentBySFID();
            }
        },
        created() {
            this.doInit();
        },
        watch: { 
            $route(to, from) {  
                if(to !== from) { 
                    // location.reload();
                    this.resetPageDefaultData();
                    this.doInit();
                } 
            }
        }
    }
</script>

