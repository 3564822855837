<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    <div>

        <div class="slds-grid slds-grid_vertical-align-end slds-gutters_direct-xx-small slds-m-bottom_x-small">
            <h2 class="slds-col slds-text-heading_small">Featured Bundles</h2>
            <span class="slds-col slds-text-body_small slds-text-color_weak slds-show_medium">Bundle of components selected by editors</span>
            <router-link to="Bundles" class="slds-col slds-col_bump-left">
                View All
            </router-link>
        </div>
        <div id="bundles_carousel" class="slds-carousel" v-if="bundles != null">
            <div class="slds-carousel__stage">
                <transition-group name="list" tag="div" class="slds-carousel__panels slds-is-relative slds-grid slds-gutters_direct-x-small" v-bind:style="{ transform: activePanelTransform }">
                    <div :id="'bundle-' + bundle.id" class="slds-col slds-carousel__panel slds-size_1-of-1 slds-medium-size_1-of-3 slds-list_horizontal list-item" role="tabpanel" aria-hidden="false" v-for="(bundle, index) in bundles" v-bind:key="index">
                        <router-link :to="{ name: 'BundleDetail', params: { sfid: bundle.sfid }}" class="slds-carousel__panel-action slds-text-link_reset slds-p-around_medium slds-container_fluid">
                            <bundle-card :bundle="bundle" />
                        </router-link>
                    </div>
                </transition-group>
                <ul class="slds-carousel__indicators " role="tablist" v-if="bundles != null">
                    <li class="slds-carousel__indicator" role="presentation">
                        <a id="indicator-id-01" :class="activePanel == 0 ? 'slds-carousel__indicator-action slds-is-active' : 'slds-carousel__indicator-action'" href="javascript:void(0);" role="tab" tabindex="0" aria-selected="true" aria-controls="bundle-id-01" title="Demo Components Bundle Panel #1" data-panel="0" v-on:click="indicatorClicked(0)">
                            <span class="slds-assistive-text">Demo Components Bundle Panel #1</span>
                        </a>
                    </li>
                    <li class="slds-carousel__indicator" role="presentation">
                        <a id="indicator-id-02" :class="activePanel == 1 ? 'slds-carousel__indicator-action slds-is-active' : 'slds-carousel__indicator-action'" href="javascript:void(0);" role="tab" tabindex="-1" aria-selected="false" aria-controls="bundle-id-02" title="Demo Components Bundle Panel #2" data-panel="1" v-on:click="indicatorClicked(1)" v-show="!(bundles.length <= 3)">
                            <span class="slds-assistive-text">Demo Components Bundle Panel #2</span>
                        </a>
                    </li>
                    <li class="slds-carousel__indicator" role="presentation">
                        <a id="indicator-id-03" :class="activePanel == 2 ? 'slds-carousel__indicator-action slds-is-active' : 'slds-carousel__indicator-action'" href="javascript:void(0);" role="tab" tabindex="-1" aria-selected="false" aria-controls="bundle-id-03" title="Demo Components Bundle Panel #3" data-panel="2" v-on:click="indicatorClicked(2)" v-show="!(bundles.length <= 6)">
                            <span class="slds-assistive-text">Demo Components Bundle Panel #3</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div id="bundles_carousel" class="slds-carousel" v-if="bundles == null">
            <spinner :size="'small'"/>
        </div>

    </div>

</template>

<script>
    import Spinner from './Spinner.vue'
    import BundleCard from './BundleCard.vue'

    export default {
        name: 'BundlesCarousel',
        data() {
            return {
                activePanel: 0,
                activePanelTransform: 'translateX(-0%)',
            }
        },
        props: {
            bundles: Array,
            fullPage: Boolean, // 5 across if true, 3 otherwise
        },
        components: {
            spinner: Spinner,
            bundleCard: BundleCard
        },
        methods: {
            indicatorClicked(val) {
                this.activePanel = val;
                this.activePanelTransform = 'translateX(' + val * -100 + '%)';
            },
        },
    }
</script>

<style scoped>
    .list-enter-active, .list-leave-active {
        transition: all 0.5s;
    }
    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(50px);
    }
</style>