<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    <div id="demoComponentList">
    <!-- <section class="slds-col slds-size_1-of-1 slds-medium-size_2-of-3 slds-order_3 slds-medium-order_2 slds-m-bottom_x-large"> -->

        <!-- {% import "macros/_demo-cmp-list-item.html" as dc_list_item with context %} -->

        <!-- Macro that renders a list of demo components with responsive pagination -->
        <!-- The only argument it takes is a list of indexes to be mapped againsted the complete list of demo components available in the data.json file. -->

        <!-- {% macro list(demo_component_indexes=range(0,20), has_pagination=true, is_recommended=false) %} -->


        <div class="demo_comps-list" v-if="demoComponents != null">
            <!-- <demo-cmp-list-item :demoComponent="obj.demo_component" :isFavorite="obj.is_favourite" v-for="(obj, i) in demoComponents" :key="i" :type="'list'" /> -->
            <demo-cmp-list-item :demoComponent="obj" :isFavorite="obj.is_favourite" v-for="(obj, i) in demoComponents" :key="i" :type="'list'" />
        </div>

        <!-- <transition-group name="list" tag="div" class="demo_comps-list" v-if="demoComponents != null">
            <demo-cmp-list-item :demoComponent="obj" v-for="(obj) in demoComponents" :key="obj" :type="'list'" class="list-item"/>
        </transition-group> -->



        <div class="demo_comps-list slds-is-relative" v-if="demoComponents == null">
            <spinner :size="'small'"/>
        </div>

        <div class="empty_state slds-m-vertical_xx-large" v-if="demoComponents != null && demoComponents.length == 0">
            <div class="slds-illustration slds-illustration_large">
                <illustrations :image="'desert'" />
                <div class="slds-text-longform">
                    <h3 class="slds-text-heading_medium">No Results Found</h3>
                </div>
            </div>
        </div>

        <!-- {# BEGIN= This is for prototyping purpose only. Meant to show accurate numbers in pagination module #}
        {% if demo_component_indexes.length > 20 %}
          {% set total_pages = 206 %}
        {% else %}
          {# IRL it should look closer to this: #}
          {% set total_pages = (demo_component_indexes.length // 8) %}
          {% if (demo_component_indexes.length % 8) > 0 %}
            {% set total_pages = total_pages + 1 %}
          {% endif %}
        {% endif %}
        {# END= This is for prototyping purpose only. #} -->

        <!-- {% if has_pagination %} -->
        <div class="slds-m-top_xx-large" v-if="paginationSettings != undefined && paginationSettings != null">
            <!-- {% import "macros/_pagination.html" as pagination with context %}
            {{ pagination.pagination(total_pages) }} -->
            <pagination :demoComponents="demoComponents" :paginationSettings="paginationSettings" v-on:paginationSettingsChanged="paginationSettingsChanged" />
        </div>
        <!-- {% endif %}

        {% endmacro %} -->

    </div>


    <!-- </section> -->

</template>

<script>
    import DemoCmpListItem from './DemoCmpListItem.vue'
    import Pagination from './Pagination.vue'
    import Spinner from './Spinner.vue'
    import Illustrations from './Illustrations.vue'

    export default {
        name: 'DemoCmpList',
        props: {
            demoComponents: Array,
            paginationSettings: Object,
        },
        components: {
            demoCmpListItem: DemoCmpListItem,
            spinner: Spinner,
            pagination: Pagination,
            illustrations: Illustrations
        },
        methods: {
            paginationSettingsChanged(val) {
                this.$emit('paginationSettingsChanged', val);

                // try {
                //     setTimeout(() => {
                //         let el = document.getElementById('demoComponentList');
                //         el.scrollIntoView();
                //     }, 200);
                // } catch(ex) {
                //     console.log('ex :: ' + ex.message);
                // }
            }
        },
        watch: {
            // demoComponents(newVal, oldVal) {
            //     if(newVal != null && oldVal == null) {
            //         try {
            //             setTimeout(() => {
            //                 let el = document.getElementById('demoComponentList');
            //                 el.scrollIntoView();
            //             }, 200);
            //         } catch(ex) {
            //             console.log('ex :: ' + ex.message);
            //         }
            //     }
            // }
        }
    }
</script>

<style scoped>
    .list-enter-active, .list-leave-active {
        transition: all 0.5s;
    }
    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(50px);
        /* transform: translateX(-100px); */
    }
</style>




