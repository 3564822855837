<template>
    <div class="slds-media slds-media_center">
        <div class="slds-media__figure">
            <span class="slds-icon_container slds-icon-utility-user" title="Collaborators">
                <svg class="slds-icon slds-icon-text-default slds-icon_xx-small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#user"></use>
                </svg>
                <span class="slds-assistive-text">Collaborators</span>
            </span>
        </div>
        <div class="slds-media__body">
            <ul class="slds-list_horizontal slds-has-dividers_left slds-has-block-links">
                <li class="slds-item">
                    <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: demoComponent.ownerid }}">
                        {{ demoComponent.author__c }}
                    </router-link> -->
                    <user-link :userName="demoComponent.author__c" :userSFID="demoComponent.ownerid" />
                </li>
                <li class="slds-item slds-show_medium" v-if="collaborators != null && collaborators[0]">
                    <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: collaborators[0].author.sfid }}">
                        {{ collaborators[0].author.name  }}
                    </router-link> -->
                    <user-link :userName="collaborators[0].author.name" :userSFID="collaborators[0].author.sfid" />
                </li>
                <li class="slds-item slds-show_medium" v-if="collaborators != null && collaborators[1]">
                    <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: collaborators[1].author.sfid }}">
                        {{ collaborators[1].author.name  }}
                    </router-link> -->
                    <user-link :userName="collaborators[1].author.name" :userSFID="collaborators[1].author.sfid" />
                </li>
                <li class="slds-item slds-show_medium" v-if="collaborators != null && collaborators.length > 2">
                    <span>+{{ (collaborators.length) - 2 }} <span class="">Collaborator</span></span>
                </li>
                <li class="slds-item slds-show_medium" v-if="isOwner || isCollaborator">
                    <button id="manage-collaborators_button" class="slds-button slds-button_neutral" @click="$emit('collaborators-button-clicked')">
                        <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#adduser"></use>
                        </svg>
                        Manage Collaborators
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import UserLink from './macros/UserLink.vue'
    // import UserLink from './UserLink.vue'

    export default {
        name: 'CollaboratorsList',
        props: {
            demoComponent: Object,
            collaborators: Array,
            isOwner: Boolean,
            isCollaborator: Boolean
        },
        components: {
            userLink: UserLink
        },
        methods: {
            
        },
        created() {

        }
    }
</script>

