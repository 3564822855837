<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    <!-- v-on:focusout="lostFocus()" -->
    <div v-on:focusout="lostFocus()">

        <!-- {% import "macros/_rating-stars.html" as rating_stars with context %} -->

        <!-- {% macro sort_by_dropdown(fluid=false) %} -->
        <!-- v-if="type == 'sortByDropdown'" -->
        <div class="slds-form-element" v-if="type == 'sortByDropdown'">
            <div class="slds-form-element__control slds-input-has-fixed-addon">
                <span class="slds-form-element__addon slds-m-left_none" id="fixed-text-addon-pre">
                    <label class="slds-form-element__label" for="combobox-sort_by" style="min-width: 55px;">Sort&nbsp;by:</label>
                </span>
                <!-- <div class="slds-combobox_container {{ 'slds-size_x-small' if not fluid else 'slds-container_fluid' }}"> -->
                <div :class="fluid ? 'slds-combobox_container slds-container_fluid' : 'slds-combobox_container slds-size_x-small'" v-on:focusout="hideSortByDropdown()">
                    <div :class="showSortByDropdown ? 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click'" aria-expanded="true" aria-haspopup="listbox" role="combobox">
                        <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                            <input type="text" class="slds-input slds-combobox__input slds-combobox__input-value" id="combobox-sort_by" aria-controls="listbox-sort_by" autoComplete="off" role="textbox" placeholder="Select an Option" readonly="" :value="value" v-on:click="toggleSortByDropdown()"/>
                            <span class="slds-icon_container slds-icon-utility-down slds-input__icon slds-input__icon_right" title="Sort by">
                                <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                                </svg>
                                <span class="slds-assistive-text">Sort by</span>
                            </span>
                        </div>
                        <div id="listbox-sort_by" role="listbox" class="slds-dropdown slds-dropdown_fluid">
                          <ul class="slds-listbox slds-listbox_vertical" role="presentation">
                            <li role="presentation" class="slds-listbox__item" v-on:click="clickedSortByDropdown('Trending')">
                                <div id="listbox-option-trending" :class="value == 'Trending' ? 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center slds-is-selected' : 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center'" role="option">
                                    <span class="slds-media__figure">
                                        <svg class="slds-icon slds-icon_x-small slds-listbox__icon-selected" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                        </svg>
                                    </span>
                                    <span class="slds-media__body">
                                        <span class="slds-truncate" title="Trending">
                                        <span class="slds-assistive-text" v-show="value == 'Trending'">Current Selection:</span> Trending</span>
                                    </span>
                                </div>
                            </li>
                            <li role="presentation" class="slds-listbox__item" v-on:click="clickedSortByDropdown('Most Recent')">
                                <div id="listbox-option-most_recent" :class="value == 'Most Recent' ? 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center slds-is-selected' : 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center'" role="option">
                                    <span class="slds-media__figure">
                                        <svg class="slds-icon slds-icon_x-small slds-listbox__icon-selected" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                        </svg>
                                    </span>
                                    <span class="slds-media__body">
                                        <span class="slds-truncate" title="Most Recent">
                                        <span class="slds-assistive-text" v-show="value == 'Most Recent'">Current Selection:</span> Most Recent</span>
                                    </span>
                                </div>
                            </li>
                          </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- {% endmacro %} -->

        <!-- {% macro rating() %} -->
        <fieldset class="slds-form-element" v-if="type == 'rating'">
                <!-- slds-hide -->
                <button class="clear-rating-filters_button slds-button slds-button_neutral slds-button_full-width slds-p-left_xx-small slds-m-bottom_xxx-small slds-text-color_weak" v-show="value != null" v-on:click="clearStarRating()">
                    &#10005;&nbsp;Clear rating
                </button>
            <div class="slds-form-element__control">
                <label class="slds-radio" for="5-star-rating-radio">
                    <input name="rating-filters" type="radio" id="5-star-rating-radio" value="5" v-model="value"/>
                    <span class="slds-radio_faux"></span>
                    <span class="slds-form-element__label slds-p-top_xx-small">
                        <div class="slds-grid slds-list_inline slds-grid_vertical-align-start">
                            <div class="slds-col">
                                <!-- {{ rating_stars.rating_stars_static('rating-filter_5', 5, 'x-small', true) }} -->
                                <rating-stars :isStatic="true" :size="'x-small'" :grayed="true" :staticRatingValue="5"/>
                            </div>
                            <div class="slds-col">
                                <span class="slds-text-body_small slds-text-color_weak slds-p-left_xx-small">&nbsp;</span>
                            </div>
                        </div>
                    </span>
                </label>
            </div>
            <div class="slds-form-element__control">
                <label class="slds-radio" for="4-star-rating-radio">
                    <input name="rating-filters" type="radio" id="4-star-rating-radio" value="4" v-model="value"/>
                    <span class="slds-radio_faux"></span>
                    <span class="slds-form-element__label slds-p-top_xx-small">
                        <div class="slds-grid slds-list_inline slds-grid_vertical-align-start">
                            <div class="slds-col">
                                <!-- {{ rating_stars.rating_stars_static('rating-filter_4', 4, 'x-small', true) }} -->
                                <rating-stars :isStatic="true" :size="'x-small'" :grayed="true" :staticRatingValue="4"/>
                            </div>
                            <div class="slds-col">
                                <span class="slds-text-body_small slds-text-color_weak slds-p-left_xx-small">&amp; up</span>
                            </div>
                        </div>
                    </span>
                </label>
            </div>
            <div class="slds-form-element__control">
                <label class="slds-radio" for="3-star-rating-radio">
                    <input name="rating-filters" type="radio" id="3-star-rating-radio" value="3" v-model="value"/>
                    <span class="slds-radio_faux"></span>
                    <span class="slds-form-element__label slds-p-top_xx-small">
                        <div class="slds-grid slds-list_inline slds-grid_vertical-align-start">
                            <div class="slds-col">
                                <!-- {{ rating_stars.rating_stars_static('rating-filter_3', 3, 'x-small', true) }} -->
                                <rating-stars :isStatic="true" :size="'x-small'" :grayed="true" :staticRatingValue="3"/>
                            </div>
                            <div class="slds-col">
                                <span class="slds-text-body_small slds-text-color_weak slds-p-left_xx-small">&amp; up</span>
                            </div>
                        </div>
                    </span>
                </label>
            </div>
            <div class="slds-form-element__control">
                <label class="slds-radio" for="2-star-rating-radio">
                    <input name="rating-filters" type="radio" id="2-star-rating-radio" value="2" v-model="value"/>
                    <span class="slds-radio_faux"></span>
                    <span class="slds-form-element__label slds-p-top_xx-small">
                        <div class="slds-grid slds-list_inline slds-grid_vertical-align-start">
                            <div class="slds-col">
                                <!-- {{ rating_stars.rating_stars_static('rating-filter_2', 2, 'x-small', true) }} -->
                                <rating-stars :isStatic="true" :size="'x-small'" :grayed="true" :staticRatingValue="2"/>
                            </div>
                            <div class="slds-col">
                                <span class="slds-text-body_small slds-text-color_weak slds-p-left_xx-small">&amp; up</span>
                            </div>
                        </div>
                    </span>
                </label>
            </div>
            <div class="slds-form-element__control">
                <label class="slds-radio" for="1-star-rating-radio">
                    <input name="rating-filters" type="radio" id="1-star-rating-radio" value="1" v-model="value"/>
                    <span class="slds-radio_faux"></span>
                    <span class="slds-form-element__label slds-p-top_xx-small">
                        <div class="slds-grid slds-list_inline slds-grid_vertical-align-start">
                            <div class="slds-col">
                                <!-- {{ rating_stars.rating_stars_static('rating-filter_1', 1, 'x-small', true) }} -->
                                <rating-stars :isStatic="true" :size="'x-small'" :grayed="true" :staticRatingValue="1"/>
                            </div>
                            <div class="slds-col">
                                <span class="slds-text-body_small slds-text-color_weak slds-p-left_xx-small">&amp; up</span>
                            </div>
                        </div>
                    </span>
                </label>
            </div> 
        </fieldset>
        <!-- {% endmacro %} -->


        <!-- {% macro checkbox_dropdown_list(scope='cloud', dataset=clouds) %} -->
        <ul class="slds-dropdown__list" role="menu" :aria-label="scope" v-if="type == 'checkboxDropdownList'">
            <!-- {% for item in dataset | sort(false, false, 'label') %} -->
            <li class="slds-dropdown__item" role="presentation" v-for="(obj, i) in values" :key="i">
                <div class="slds-form-element">
                    <div class="slds-form-element__control">
                        <div class="slds-checkbox">
                            <input type="checkbox" :name="scope + '_filter'" :id="'checkbox-' + scope + '_' + obj.value" v-on:change="clickCheckboxDropdownList(obj.value)" :checked="value != null && value.includes(obj.value)" />
                            <label class="slds-checkbox__label" :for="'checkbox-'  + scope + '_' + obj.value">
                                <span class="slds-checkbox_faux"></span>
                                <span class="slds-form-element__label">{{ obj.label }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </li>
            <!-- {% endfor %} -->
        </ul>
        <!-- {% endmacro %} -->

        <!-- {% macro timeframe_dropdown(scope='', label='Installed Demo Components by Industry in', selected_index=3) %} -->
        <!-- <div class="slds-form-element" v-if="type == 'timeframe_dropdown'">
            <div class="slds-form-element__control slds-input-has-fixed-addon">
                <span class="slds-form-element__addon slds-m-left_none slds-has-flexi-truncate" id="fixed-text-addon-pre">
                    <label class="slds-form-element__label slds-truncate" for="combobox-timeframe_{{ scope }}">{{ label }}</label>
                </span>
                <div class="slds-combobox_container slds-size_x-small">
                    slds-is-open
                    <div class="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click" aria-expanded="true" aria-haspopup="listbox" role="combobox">
                        <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                            <input type="text" class="slds-input slds-combobox__input slds-combobox__input-value" id="combobox-timeframe_{{ scope }}" aria-controls="listbox-timeframe_{{ scope }}" autoComplete="off" role="textbox" placeholder="Select an Option" readonly="" value="{{ data_timeframes[selected_index].label }}" />
                            <span class="slds-icon_container slds-icon-utility-down slds-input__icon slds-input__icon_right" title="{{ label }}">
                                <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                                </svg>
                                <span class="slds-assistive-text">{{ label }}</span>
                            </span>
                        </div>
                        <div id="listbox-timeframe_{{ scope }}" role="listbox" class="slds-dropdown slds-dropdown_fluid">
                          <ul class="slds-listbox slds-listbox_vertical" role="presentation">
                            {% for timeframe in data_timeframes %}
                            <li role="presentation" class="slds-listbox__item">
                                <div id="listbox-option-{{ value }}" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center {{ 'slds-is-selected' if loop.index0 == selected_index }}" role="option">
                                    <span class="slds-media__figure">
                                        <svg class="slds-icon slds-icon_x-small slds-listbox__icon-selected" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                        </svg>
                                    </span>
                                    <span class="slds-media__body">
                                        <span class="slds-truncate" title="{{ timeframe.label }}">
                                        {% if loop.index0 == selected_index %}<span class="slds-assistive-text">Current Selection:</span>{% endif %} {{ timeframe.label }}</span>
                                    </span>
                                </div>
                            </li>
                            {% endfor %}
                          </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- {% endmacro %} -->

        <!-- {% macro checkbox_group(scope='cloud', dataset=clouds) %} -->
        <!-- v-if="type == 'checkboxGroup'" -->
        <div class="slds-form-element__control" v-if="type == 'checkboxGroup'">
            <!-- {% for item in dataset | sort(false, false, 'label') %} -->
                <div class="slds-checkbox" v-for="(obj, i) in values" :key="i">
                    <input type="checkbox" :name="scope + '_category'" :id="'checkbox-' +  scope + '_' +  obj.value" :value="obj.value" :checked="value != null && value.includes(obj.value)" v-on:change="clickCheckboxDropdownList(obj.value)" />
                    <label class="slds-checkbox__label" :for="'checkbox-' +  scope + '_' +  obj.value">
                        <span class="slds-checkbox_faux"></span>
                        <span class="slds-form-element__label">{{ obj.label }}</span>
                    </label>
                </div>
            <!-- {% endfor %} -->
        </div>
        <!-- {% endmacro %} -->

    </div>

</template>

<script>
    import RatingStars from './RatingStars.vue'

    export default {
        name: 'Filters',
        props: {
            type: String, // sortByDropdown / rating / checkboxDropdownList / checkboxGroup
            scope: String, // product / industry / feature / rating
            value: String,
            values: Array,
            fluid: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                showSortByDropdown: false
            }
        },
        components: {
            ratingStars: RatingStars
        },
        methods: {
            toggleSortByDropdown() {
                this.$emit('focusOffList');
                this.showSortByDropdown = !this.showSortByDropdown;
            },
            hideSortByDropdown() {
                this.$emit('focusOffList');
                var self = this;
                setTimeout(function() { self.showSortByDropdown = false; }, 200);
            },

            clickedSortByDropdown(val) {
                this.$emit('filterChange', this.scope, val);
            },
            clickCheckboxDropdownList(val) {
                var valuesArray = this.value.split(';');
                if(valuesArray.includes(val)) {
                    valuesArray = valuesArray.filter(e => e != val);
                } else {
                    valuesArray.push(val);
                }
                var valuesArrayStr = valuesArray.join(';');
                if(valuesArrayStr.charAt(0) == ';') {
                    valuesArrayStr = valuesArrayStr.substr(1);
                }
                this.$emit('filterChange', this.scope, valuesArrayStr);
            },

            lostFocus() {
                this.$emit('focusOffList');
            },
            clearStarRating() {
                this.$emit('focusOnStars');
                this.$emit('filterChange', this.scope, null);
            }
        },
        watch: {
            value(newVal, oldVal) {
                if(this.scope == 'rating') {
                    this.$emit('focusOnStars');
                    this.$emit('filterChange', this.scope, newVal);
                }
            }
        }
    }
</script>

