<template>
    <!-- {% import "macros/_header-search.html" as header_search with context %} -->

    <header class="slds-global-header_container">
        <div class="slds-global-header slds-p-vertical_medium height-auto">
            <div class="slds-grid slds-grid_align-spread ">
                <div class="slds-global-header__item min-width">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media slds-media_center">
                            <div class="slds-media__figure">
                                <!-- <a href="./" class="slds-icon_container slds-icon-custom-custom43" title="Home"> -->
                                <router-link to="/" class="slds-icon_container slds-icon-custom-custom43" title="Home">
                                    <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/custom-sprite/svg/symbols.svg#custom43"></use>
                                    </svg>
                                    <span class="slds-assistive-text">Home</span>
                                </router-link>
                                <!-- </a> -->
                            </div>
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate" title="Demo Components">Demo Components</span>
                                        </h1>
                                    </div>
                                </div>
                                <p class="slds-page-header__name-meta slds-text-color_weak slds-show_small">Customized Components to add to your demo</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slds-global-header__item slds-global-header__item_search slds-show_large">
                    <!-- {{ header_search.search_field('desktop') }} -->
                    <header-search :device="'desktop'" />
                </div>
                <div class="slds-global-header__item">
                    <div class="slds-page-header__col-controls">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control slds-show_x-large">
                                <router-link to="/installed-by-you">
                                    <button class="slds-button slds-button_neutral nowrap recently-used_button">
                                        <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                            <use xlink:href="/assets/images/custom-icons-sprite.svg#recent"></use>
                                        </svg>Installed By You
                                    </button>
                                </router-link>
                            </div>
                            <div class="slds-page-header__control slds-show_x-large">
                                <router-link to="/favorites">
                                    <button class="slds-button slds-button_neutral nowrap favorites_button">
                                        <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                            <use xlink:href="/assets/images/custom-icons-sprite.svg#favorite"></use>
                                        </svg>Favorites
                                    </button>
                                </router-link>
                            </div>
                            <div class="slds-page-header__control slds-show_small">
                                <router-link to="/new">
                                    <a class="slds-button slds-button_brand nowrap new_button" href="">
                                        <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#add"></use>
                                        </svg>New
                                    </a>
                                </router-link>
                            </div>
                            <div class="slds-page-header__control slds-hide_large">
                                <button id="search_button" :class="showMobileSearch ? 'slds-button slds-button_icon slds-button_icon-border-filled slds-is-selected' : 'slds-button slds-button_icon slds-button_icon-border-filled'" title="Search" v-on:click.self="toggleMobileSearch()">
                                    <svg class="slds-button__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                                    </svg>
                                    <span class="slds-assistive-text">Search</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- slds-hide -->
            <div id="search_popover" class="slds-is-fixed slds-container_fluid slds-p-horizontal_x-small slds-p-top_small slds-hide_large" style="z-index:1000" v-if="showMobileSearch">
                <section aria-describedby="search_dialog-body" class="slds-popover slds-nubbin_top-right slds-popover_full-width" role="dialog">
                    <button class="slds-button slds-button_icon slds-button_icon-small slds-float_right slds-popover__close" title="Close dialog" v-on:click.self="toggleOff()">
                        <svg class="slds-button__icon" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close dialog</span>
                    </button>
                    <div class="slds-popover__body" id="search_dialog-body">
                        <header-search :device="'mobile'" ></header-search>
                    </div>
                </section>
            </div>
        </div>
    </header>
</template>

<script>
    import HeaderSearch from "./macros/HeaderSearch.vue"
    import { setTimeout } from 'timers';

    export default {
        name: 'PageHeader',
        data() {
            return {
                showMobileSearch: false
            }
        },
        props: {
        
        },
        components: {
            headerSearch: HeaderSearch
        },
        methods: {
            toggleMobileSearch(event) {
                this.showMobileSearch = !this.showMobileSearch;
            },
            toggleOff() {
                this.showMobileSearch = false;
            }
        },
        created() {

        }
    }
</script>

