<template>
    <div>
        <section id="markdown-help_modal" role="dialog" tabindex="-1" aria-labelledby="markdown-help_modal-heading" aria-modal="true" aria-describedby="markdown-help_modal-content" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="$emit('closeModal')">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="markdown-help_modal-heading" class="slds-text-heading_medium slds-hyphenate">Markdown Help</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="markdown-help_modal-content">
                    <table class="slds-table slds-table_bordered slds-table_cell-buffer">
                        <thead>
                            <tr class="slds-text-title_caps">
                                <th scope="col">
                                    <div class="slds-truncate" title="Markdown">Markdown</div>
                                </th>
                                <th scope="col">
                                    <div class="slds-truncate" title="Result">Result</div>
                                </th>
                            </tr>
                        </thead>
                        <!-- <tbody>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">**text**</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">**Bold**</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">*text*</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">*Emphasize*</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">~~text~~</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">~~Strikethrough~~</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">`code`</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">`Inline Code`</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">![alt](http://)</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate">Image</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">* item</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate">List</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">&gt; item</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate">Blockquote</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate"># Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"># H1 Heading</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">## Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">## H2 Heading</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">### Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">### H3 Heading</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">#### Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">#### H4 Heading</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">##### Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">##### H5 Heading</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">[title](http://)</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">[Link](#)</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">[button][title](http://)</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">[button][Neutral Button](javascript:void(0);)</span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">[button-brand][title](http://)</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown">[button-brand][Brand Button](javascript:void(0);)</span></div>
                                </td>
                            </tr>
                    </tbody> -->

                    <tbody>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">**text**</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><b>Bold</b></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">*text*</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><em>Emphasize</em></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">~~text~~</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><strike>Strikethrough</strike></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">`code`</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><code>Inline Code</code></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">![alt](http://)</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate">Image</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">* item</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate">List</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">&gt; item</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate">Blockquote</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate"># Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><h1 class="slds-text-heading_large">H1 Heading</h1></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">## Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><h2 class="slds-text-heading_medium">H2 Heading</h2></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">### Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><h3 class="slds-text-heading_small">H3 Heading</h3></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">#### Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><h4 class="slds-text-title_caps">H4 Heading</h4></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">##### Heading</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><h5 class="slds-text-title">H5 Heading</h5></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">[title](http://)</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><a href="#" onclick="return false;">Link</a></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">[button][title](http://)</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><a class="slds-button slds-button_neutral" href="#" onclick="return false;">Neutral Button</a></span></div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" data-label="Markdown">
                                    <div class="slds-truncate">[button-brand][title](http://)</div>
                                </th>
                                <td data-label="Result">
                                    <div class="slds-truncate"><span class="is-markdown"><a class="slds-button slds-button_brand" href="#" onclick="return false;">Brand Button</a></span></div>
                                </td>
                            </tr>
                        </tbody>
                        
                  </table>
                  <div class="slds-p-around_large">
                    For further Markdown syntax reference: <a href="https://github.com/showdownjs/showdown/wiki/Showdown's-Markdown-syntax" target="_blank">Markdown documentation</a>
                  </div>
                </div>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    export default {
        name: 'MarkdownHelpModal',
        props: {
        
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>

