<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    <div>

        <!-- {% macro generic_title(label='Favorites', size=favorites.length, icon_lib='custom', icon_id='custom44') %} -->
        <div class="slds-media slds-media_center slds-m-vertical_medium" v-if="type == 'generic'">
            <div class="slds-media__figure">
                <span :class="'slds-icon_container slds-icon-' + iconLib + '-' + iconId" :title="label">
                    <svg class="slds-icon slds-icon_medium" aria-hidden="true">
                        <use :xlink:href="'/assets/icons/' + iconLib +  '-sprite/svg/symbols.svg#' + iconId"></use>
                    </svg>
                </span>
                <span class="slds-assistive-text">{{ label }}</span>
            </div>
            <div class="slds-media__body">
                <h2 class="slds-col slds-text-heading_medium">{{ label }} ({{ size }})</h2>
            </div>
        </div>
        <!-- {% endmacro %} -->

        <!-- {% macro person_title(name='Deborah Olson', initials='DO', size=owned.length, creator_since='2016') %} -->
        <div class="slds-media slds-media_center slds-m-vertical_medium" v-if="type == 'person'">
            <div class="slds-media__figure">
                <span class="slds-avatar">
                    <abbr class="slds-avatar__initials slds-icon-standard-user" :title="name">{{ initials }}</abbr>
                </span>
                <span class="slds-assistive-text">{{ name }}</span>
            </div>
            <div class="slds-media__body">
                <h2 class="slds-col slds-text-heading_medium">{{ name }}</h2>
                <ul class="slds-list_horizontal slds-has-dividers_left slds-has-block-links slds-text-body_small slds-text-color_weak">
                    <li class="slds-item ">{{ size }} Demo Components</li>
                    <!-- <li class="slds-item slds-show_medium">Creator since {{ creatorSince }}</li> -->
                </ul>
            </div>
        </div>
        <!-- {% endmacro %} -->


        <!-- {% macro einstein_title(label='Recommended for You', size=recommendations.length) %} -->
        <div class="slds-grid slds-grid_vertical-align-center slds-gutters_direct-xx-small slds-m-vertical_medium" v-if="type == 'einstein'">
            <h2 class="slds-col slds-text-heading_medium">{{ label }} ({{ size }})</h2>
            <div class="slds-col">
                <span class="slds-media slds-media_center slds-media_small slds-m-left_x-small slds-show_medium">
                    <div class="slds-media__figure">
                        <span class="slds-icon_container" title="Powered by Einstein">
                            <img alt="Einstein icon" class="slds-icon slds-icon_small" src="/assets/images/einstein.svg" title="Powered by Einstein" />
                        </span>
                    </div>
                    <div class="slds-media__body">
                        <span class="slds-col slds-text-body_small slds-text-color_weak">Powered by Einstein</span>
                    </div>
                </span>
            </div>
        </div>
        <!-- {% endmacro %} -->

    </div>

</template>

<script>
    export default {
        name: 'ListViewTitles',
        props: {
            type: String, // generic / person / einstein
            iconLib: String,
            iconId: String,
            label: String,
            size: Number,
            creatorSince: String,

            name: String,
            initials: String,
            creatorSince: String
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>

