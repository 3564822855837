<template>
    <div>
        <section id="install-report_modal" role="dialog" tabindex="-1" aria-labelledby="install-report_modal-heading" aria-modal="true" aria-describedby="install-report_modal-content" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="closeModal">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 class="slds-text-heading_medium" id="install-report_modal-content">Install Report</h2>
                </header>
                <div class="slds-modal__content slds-p-around_none" id="dialog_prompt-result-wrapper">
                    <section>
                        <div class="slds-p-vertical_xx-small slds-p-horizontal_medium slds-m-top_medium">
                            <div class="slds-media slds-media_center">
                                <div class="slds-media__figure">
                                    <span class="slds-icon_container slds-icon-action-approval slds-icon_container_circle">
                                        <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                                            <use xlink:href="/assets/icons/action-sprite/svg/symbols.svg#approval"></use>
                                        </svg>
                                    </span>
                                </div>
                                <div class="slds-media__body">
                                    <p><strong>{{ successfulInstallCount }}</strong> components were installed successfully.</p>
                                </div>
                            </div>
                        </div>
                        <div class="slds-p-vertical_xx-small slds-p-horizontal_medium slds-m-bottom_medium">
                            <div class="slds-media slds-media_center">
                                <div class="slds-media__figure">
                                    <span class="slds-icon_container slds-icon-action-close slds-icon_container_circle">
                                        <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                                            <use xlink:href="/assets/icons/action-sprite/svg/symbols.svg#close"></use>
                                        </svg>
                                    </span>
                                </div>
                                <div class="slds-media__body">
                                    <p><strong>{{ errorInstallCount }}</strong> components failed to install.</p>
                                </div>
                            </div>
                        </div>
                        <div class="slds-p-around_none slds-scrollable">
                            <table class="slds-table slds-table_cell-buffer slds-table_fixed-layout slds-max-medium-table_stacked-horizontal slds-table_bordered">
                                <thead>
                                    <tr class="slds-line-height_reset">
                                        <th class="" scope="col" style="width:34%;">
                                            <div class="slds-truncate" title="Demo Component">Demo Component</div>
                                        </th>
                                        <th class="" scope="col" style="width:66%;">
                                            <div class="slds-truncate" title="Error Message">Error Message</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- {% for item in install_report %} -->
                                    <tr class="slds-hint-parent" v-for="(obj, index) in errorItems" :key="index">
                                        <td data-label="Demo Component">
                                            <div class="break-lines" title="">
                                                <!-- <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: obj.componentId }}">
                                                    {{ obj.componentName }}
                                                </router-link> -->
                                                <a :href="'/#/demo-component-detail/' + obj.componentId" target="_blank" title="Open in a new tab">
                                                    {{ obj.componentName }}
                                                </a>
                                            </div>
                                        </td>
                                        <td data-label="Error Message">
                                            <div class="break-lines slds-text-font_monospace slds-text-body_small" :title="obj.errorMessage">
                                                {{ obj.errorMessage }}
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- {% endfor %} -->
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
                <footer class="slds-modal__footer">
                    <button class="slds-button slds-button_neutral close-modal_button" @click="closeModal">Close</button>
                    <button class="slds-button slds-button_brand retry-install_button" @click="retryInstall">Retry Install</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    export default {
        name: 'InstallReportModal',
        props: {
        
        },
        computed: {
            installResults() { return this.$store.getters.getInstallResults; },
            successfulInstallCount() { return this.installResults.filter((x) => { return x.deployJobStatus.errors.length == 0; }).length; },
            errorInstallCount() { return this.installResults.filter((x) => { return x.deployJobStatus.errors.length > 0; }).length; },

            errorItems() {
                var retVal = [];
                var errorComponents = this.installResults.filter((x) => { return x.deployJobStatus.errors.length > 0; });
                for(var i = 0; i < errorComponents.length; i++) {
                    for(var j = 0; j < errorComponents[i].deployJobStatus.errors.length; j++) {
                        retVal.push({
                            componentName: errorComponents[i].demoComponent.name,
                            componentId: errorComponents[i].demoComponent.sfid,
                            errorMessage: errorComponents[i].deployJobStatus.errors[j],
                        });
                    }
                }
                return retVal;
            }
        },
        components: {

        },
        methods: {
            closeModal() { this.$emit('close-modal'); },
            retryInstall() { this.$emit('retry-install'); }
        },
        created() {

        }
    }
</script>

