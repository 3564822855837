<template>
  
    <div id="select-org-form_spinner" class="slds-spinner_container slds-align_absolute-center" v-bind:class="{ 'slds-hide': packageSpinner==false}">
        <div class="slds-media slds-media_center">
            <div class="slds-media__figure">
                <div role="status" class="slds-spinner slds-spinner_small slds-is-relative">
                    <span class="slds-assistive-text">Loading</span>
                    <div class="slds-spinner__dot-a"></div>
                    <div class="slds-spinner__dot-b"></div>
                </div>
            </div>
            <div class="slds-media__body slds-grid slds-grid_vertical">
                <span class="slds-text-heading_small">Authenticating to Org...</span>
                <!-- <span class="slds-text-body_small"><a href="javascript:void(0);" v-on:click="abort()">Abort, I meant to use another org.</a></span> -->
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'SelectOrgLoadingSpinner',
        props: {
        
        },
        computed: {            
            packageSpinner: function() {
                return this.$store.getters.getSelectOrgSpinner;
            }
        },
        components: {

        },
        methods: {
            abort: function(){
                this.$store.commit('setDemoOrgPackages', null);
                this.$store.commit('setSelectedOrgAuthDetails', null); 
                this.$store.commit('setSelectOrgSpinner', false);
            }
        },
        created() {
            
        }
    }
</script>

