<template>
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->

    <div class="slds-size_1-of-1 slds-container_x-large slds-grid slds-wrap slds-gutters_direct-medium">
        <!-- {% extends "page-template.html" %}
        {% block content %} -->

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
            <!-- {% include "_back-button.html" %} -->
            <back-button />
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium" v-if="recommendedComponents != null">
            <!-- {% import "macros/_list-view-titles.html" as list_view_title with context %}
            {{ list_view_title.generic_title('Favorites', favorites.length, 'custom', 'custom1') }} -->
            <list-view-titles :type="'einstein'" 
                                :label="'Recommended For You'" 
                                :size="initialRecommendedCount" />
        </section>

        <section class="slds-wrap slds-grid slds-col slds-size_1-of-1 slds-m-bottom_xx-large">
            <div class = "slds-col slds-size_1-of-1 slds-m-bottom_medium">
                <!-- {% include "_list-filters.html" %} -->
                <list-filters :values="listFilterValues"
                    :showSortBy="false" 
                    v-on:listFilterChange="filtersChanged"/>
            </div>


            <div class="slds-col slds-size_1-of-1">
                <!-- {% import "macros/_demo-comp-list.html" as dc_listing with context %}
                {{ dc_listing.list(favorites) }} -->

                <demo-cmp-list :demoComponents="displayedComponents"
                    :paginationSettings="paginationSettings"
                    v-on:paginationSettingsChanged="paginationSettingsChanged" />

            </div>

        </section>

        <!-- <section class="slds-col slds-size_1-of-1 slds-m-bottom_x-large" v-if="favoriteComponents != null && favoriteComponents.length == 0">
            <div class="slds-illustration slds-illustration_large">
                <illustrations :image="'open_chest'" />
                <div class="slds-text-longform">
                    <h3 class="slds-text-heading_medium">No favorites yet?</h3>
                    <p class="slds-text-body_regular">
                        You can favorite the Demo Components you like to access them easily next time.<br />
                        Click on
                        <span class="slds-show_inline-block">
                            <span class="favorite_inline-action nowrap noselect">
                                <span class="slds-icon_container slds-m-horizontal_xx-small" title="Favorite">
                                    <svg class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true" viewBox="0 0 24 24">
                                        <use xlink:href="/assets/images/custom-icons-sprite.svg#favoriteShape" clip-path="url(#favoriteClip)" />
                                    </svg>
                                    <span class="slds-assistive-text">Favorite icon</span>
                                </span>
                            </span>
                        </span>
                        to favorite demo components.
                    </p>
                </div>
            </div>
        </section> -->

    </div>
</template>

<script>
    import Illustrations from "./macros/Illustrations.vue";
    import BackButton from "./BackButton.vue";
    import ListFilters from "./ListFilters.vue";
    import ListViewTitles from "./macros/ListViewTitles.vue";
    import axios from "axios";
    import { ApiEndpoints } from "../mixins/ApiEndpoints";
    import DemoCmpList from './macros/DemoCmpList.vue'
    import Carousels from './macros/Carousels.vue'

    export default {
        name: 'RecommendedForYou',
        mixins: [ ApiEndpoints ],
        data() {
            return {
                demoComponents: null,
                filteredComponents: null,
                displayedComponents: null,
                listFilterValues: {
                    'sortBy': 'Trending',
                    'clouds': '',
                    'features': '',
                    'industries': '',
                    'stars': null
                },
                paginationSettings: {
                    "pagesSizeOptions": [8, 16, 32, 64],
                    "currentPageSizeOption": 8,
                    'totalDemoComponentCount': 1,
                    'currentPage': 1,
                    "totalPages": 1
                },
                initialRecommendedCount: 0
            }
        },
        props: {
        
        },
        computed: {
            accessToken() { return this.$store.getters.getAccessToken; },
            recommendedComponents() { return this.$store.getters.getRecommendedComponents; },
            favoriteComponents() { return this.$store.getters.getFavoriteComponents; },
        },
        components: {
            illustrations: Illustrations,
            backButton: BackButton,
            listViewTitles: ListViewTitles,
            listFilters: ListFilters,
            demoCmpList: DemoCmpList,
            carousels: Carousels
        },
        methods: {
            doInit() {
                if(this.recommendedComponents == null) {
                    this.getPredictionsForUser();
                } else {
                    this.demoComponents = JSON.parse(JSON.stringify(this.recommendedComponents));
                    this.filteredComponents = JSON.parse(JSON.stringify(this.recommendedComponents));
                    this.displayedComponents = JSON.parse(JSON.stringify(this.recommendedComponents));
                    this.initialRecommendedCount = this.demoComponents.length;
                    this.setThePaginationSettings();
                }
                if(this.favoriteComponents == null) {
                    this.getFavoriteComponents();
                } else {
                    this.setTheFavorites();
                }
            },
            setThePaginationSettings() {
                this.paginationSettings.totalPages = Math.floor(this.filteredComponents.length / this.paginationSettings.currentPageSizeOption);
                if((this.filteredComponents.length % this.paginationSettings.currentPageSizeOption) > 0) {
                    this.paginationSettings.totalPages++;
                }
                this.paginationSettings.totalDemoComponentCount = this.filteredComponents.length;
                this.setTheFilteredComponentsByPagination();
            },
            setTheFilteredComponentsByPagination() {
                var firstIndex = ((this.paginationSettings.currentPage - 1) * this.paginationSettings.currentPageSizeOption);
                var secondIndex = this.paginationSettings.currentPage * this.paginationSettings.currentPageSizeOption;
                if(secondIndex > this.paginationSettings.totalDemoComponentCount) {
                    secondIndex = this.paginationSettings.totalDemoComponentCount;
                }
                var tmpCmps = JSON.parse(JSON.stringify(this.filteredComponents));
                this.displayedComponents = tmpCmps.slice(firstIndex, secondIndex);
            },
            paginationSettingsChanged(input) {
                this.paginationSettings = input;
                this.paginationSettings = input;
                this.setThePaginationSettings();
            },
            filtersChanged(input) {
                this.listFilterValues = input;
                this.paginationSettings.currentPage = 1;
                this.filteredComponents = this.filterComponents();
                this.setThePaginationSettings();
            },
            filterComponents() {
                var retArr = [];
                var cloudsArr = (this.listFilterValues.clouds != null && this.listFilterValues.clouds != '') ? this.listFilterValues.clouds.split(';') : [];
                var featuresArr = (this.listFilterValues.features != null && this.listFilterValues.features != '') ? this.listFilterValues.features.split(';') : [];
                var industriesArr = (this.listFilterValues.industries != null && this.listFilterValues.industries != '') ? this.listFilterValues.industries.split(';') : [];

                for(var i = 0; i < this.demoComponents.length; i++) {
                    var cloudsCmp = (this.demoComponents[i].cloud__c != null) ? this.demoComponents[i].cloud__c.split(';') : [];
                    var featuresCmp = (this.demoComponents[i].feature__c != null) ? this.demoComponents[i].feature__c.split(';') : [];
                    var industriesCmp = (this.demoComponents[i].industry__c != null) ? this.demoComponents[i].industry__c.split(';') : [];

                    var hasClouds = (cloudsArr.length > 0) ? this.compareArrays(cloudsCmp, cloudsArr) : true;
                    var hasFeatures = (featuresCmp.length > 0) ? this.compareArrays(featuresCmp, featuresArr) : true;
                    var hasIndustries = (industriesArr.length > 0) ? this.compareArrays(industriesCmp, industriesArr) : true;
                    var hasStarRating = (this.listFilterValues.stars != null) ? (this.demoComponents[i].rating_average__c != null && this.demoComponents[i].rating_average__c >= this.listFilterValues.stars) : true;

                    if(hasClouds && hasFeatures && hasIndustries && hasStarRating) {
                        retArr.push(this.demoComponents[i]);
                    }
                }
                return retArr;
            },
            compareArrays(arr1, arr2) {
                return arr2.every(v => arr1.includes(v));
            },
            setTheFavorites() {
                var tmpDemoComponents = JSON.parse(JSON.stringify(this.recommendedComponents));
                if(this.favoriteComponents != null) {
                    for(var i = 0; i < tmpDemoComponents.length; i++) {
                        for(var j = 0; j < this.favoriteComponents.length; j++) {
                            if(tmpDemoComponents[i].sfid == this.favoriteComponents[j].sfid) {
                                tmpDemoComponents[i].is_favourite = true;
                            }
                        }
                    }
                }
                this.demoComponents = tmpDemoComponents;
                this.filteredComponents = tmpDemoComponents;
                this.displayedComponents = tmpDemoComponents;
                this.setThePaginationSettings();
            }
        },
        created() {
            this.doInit();
        },
        watch: {
            recommendedComponents(newVal, oldVal) {
                if(oldVal == null && newVal != null) {
                    this.demoComponents = JSON.parse(JSON.stringify(newVal));
                    this.filteredComponents = JSON.parse(JSON.stringify(newVal));
                    this.displayedComponents = JSON.parse(JSON.stringify(newVal));
                    this.initialRecommendedCount = this.demoComponents.length;
                    this.setTheFavorites();
                    this.setThePaginationSettings();
                }
            },
            favoriteComponents(newVal, oldVal) {
                if(oldVal == null && newVal != null && this.recommendedComponents != null) {
                    this.setTheFavorites();
                }
            }
        }
    }
</script>

