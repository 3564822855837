<template>
    
    <!-- {% macro post_item(post, collapsed=true) %} -->
    <!-- :id="'post_' + postItem.sfid" -->
    <div class="post slds-p-top_large">
        <!-- <a href="{{ 'javascript:void(0)' if not post.is_creator else 'created-by-others-detail.html' }}" class="author {{ 'slds-text-link_reset' if not post.is_creator }}"><strong>{{ post.author }}</strong></a> -->
        <!-- <a class="author slds-text-link_reset"><strong>{{ postItem.author.name }}</strong></a> -->
        <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: postItem.author.sfid }}">
            {{ postItem.author.name }}
        </router-link> -->
        <user-link :userName="postItem.author.name" :userSFID="postItem.author.sfid" />

        <p class="slds-m-bottom_xx-small">{{ postItem.body__c }}</p>
        <ul class="slds-list_horizontal slds-has-dividers_left slds-text-body_small slds-text-color_weak slds-m-bottom_small">
            <!-- <li class="slds-item">by&nbsp;</li> -->
            <li class="slds-item">{{ getDateString(postItem.createddate) }}</li>
            <li v-if="collapsed" class="slds-item">
                <a href="javascript:void(0);" class="post-reply_action" @click="$emit('reply-discussion-modal', postItem)">Reply</a>
            </li>
        </ul>


        <div v-if="collapsed" class="comments slds-m-left_large">
            <div v-if="collapsed && postItem.discussion_comments.length > 0" class="comment slds-m-bottom_small">

                <!-- COSHEA :: NEED TO CHANGE BELOW DEPENDING ON WHO'S LOGGED IN -->
                <!-- <a href="{{ 'javascript:void(0)' if not comment.is_creator else 'created-by-others-detail.html' }}" class="author {{ 'slds-text-link_reset' if not comment.is_creator }}"><strong>{{ comment.author }}</strong></a> -->
                <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: postItem.author.sfid }}">
                    {{ postItem.author.name }}
                </router-link> -->
                <user-link :userName="postItem.discussion_comments[0].author.name" :userSFID="postItem.discussion_comments[0].author.sfid" />
                <p class="slds-m-bottom_xx-small">{{ postItem.discussion_comments[0].body__c }}</p>
                <ul class="slds-list_horizontal slds-has-dividers_left slds-text-body_small slds-text-color_weak slds-m-bottom_x-small">
                    <!-- <li class="slds-item">{{ post.created_date }}</li> -->
                    <li class="slds-item">{{ getDateString(postItem.discussion_comments[0].createddate) }}</li>
                </ul>
            </div>
            <!-- <a v-if="collapsed && postItem.discussion_comments.length > 1" href="javascript:void(0)" class="more">View {{ (postItem.discussion_comments.length) - 1 }} more comments</a> -->
            <a v-if="collapsed && postItem.discussion_comments.length == 2" href="javascript:void(0)" class="more" @click="$emit('open-discussion-modal', postItem)">View {{ (postItem.discussion_comments.length) - 1 }} more comment</a>
            <a v-if="collapsed && postItem.discussion_comments.length > 2" href="javascript:void(0)" class="more" @click="$emit('open-discussion-modal', postItem)">View {{ (postItem.discussion_comments.length) - 1 }} more comments</a>
        </div>



        <div v-if="!collapsed" class="comments slds-m-left_large">
            <div class="comment slds-m-bottom_small" v-for="(comment, index) in postItem.discussion_comments" :key="index">
                <!-- COSHEA :: NEED TO CHANGE BELOW DEPENDING ON WHO'S LOGGED IN -->
                <!-- <a href="{{ 'javascript:void(0)' if not comment.is_creator else 'created-by-others-detail.html' }}" class="author {{ 'slds-text-link_reset' if not comment.is_creator }}"><strong>{{ comment.author }}</strong></a> -->
                <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: comment.author.sfid }}">
                    {{ comment.author.name }}
                </router-link> -->
                <user-link :userName="comment.author.name" :userSFID="comment.author.sfid" />
                <p class="slds-m-bottom_xx-small">{{ comment.body__c }}</p>
                <ul class="slds-list_horizontal slds-has-dividers_left slds-text-body_small slds-text-color_weak slds-m-bottom_x-small">
                    <li class="slds-item">{{ getDateString(comment.createddate) }}</li>
                </ul>
            </div>
        </div>



        


        <div v-if="!collapsed" class="slds-media slds-media_center slds-p-vertical_small slds-p-left_large slds-p-right_medium slds-border_top slds-border_bottom slds-theme_shade">
            <div class="slds-media__body slds-form-element">
                <div class="slds-form-element__control">
                    <input type="text" :id="'comment_input_post-' + postItem.sfid" placeholder="Write a comment..." class="slds-input" v-model="newComment"/>
                </div>
            </div>
            <div class="slds-media__figure slds-media__figure_reverse">
                <button class="slds-button slds-button_brand" @click="submitNewComment">Comment</button>
            </div>
        </div>

    </div>
    <!-- {% endmacro %} -->

</template>

<script>
    import UserLink from './UserLink.vue'

    export default {
        name: 'DiscussionPostItem',
        data() {
            return {
                newComment: ''
            }
        },
        props: {
            postItem: Object,
            collapsed: Boolean,
            doFocusOnLoad: Boolean
        },
        components: {
            userLink: UserLink
        },
        methods: {
            getDateString(input) {
                if(input == undefined || input == null || input == '') {
                    return 'Just Now';
                }

                var retVal;
                var updatedTime = new Date(input);
                var todaysDate = new Date();
                var seconds = (todaysDate.getTime() - updatedTime.getTime()) / 1000;

                if(seconds > 31557600) {
                    var x = Math.floor(seconds / 31557600).toString();
                    retVal = x >= 2 ? x + ' years ago' : x + ' year ago';
                } else if(seconds > 2629800) {
                    var x = Math.floor(seconds / 2629800).toString();
                    retVal = x >= 2 ? x + ' months ago' : x + ' month ago';
                } else if(seconds > 604800) {
                    var x = Math.floor(seconds / 2629800).toString();
                    if(x == 0) {
                        x = 1;
                    }
                    retVal = x >= 2 ? x + ' weeks ago' : x + ' week ago';
                } else if(seconds > 87660) {
                    var x = Math.floor(seconds / 87660).toString();
                    retVal = x >= 2 ? x + ' days ago' : x + ' day ago';
                } else if(seconds > 3600) {
                    var x = Math.floor(seconds / 3600).toString();
                    retVal = x >= 2 ? x + ' hours ago' : x + ' hour ago';
                } else if(seconds > 60) {
                    var x = Math.floor(seconds / 60).toString();
                    retVal = x >= 2 ? x + ' minutes ago' : x + ' minute ago';
                } else {
                    var x = Math.floor(seconds).toString();
                    retVal = x >= 2 ? x + ' seconds ago' : x + ' second ago';
                }
                return retVal;
            },
            submitNewComment() {
                this.$emit('submit-comment', this.newComment, this.postItem);
                this.newComment = '';
            }
        },
        created() {
            if(this.doFocusOnLoad) {
                setTimeout(() => {
                    let el = document.getElementById('comment_input_post-' + this.postItem.sfid);
                    el.focus();
                }, 200);
            }
        },
    }
</script>

