<template>

    <!-- {% macro input_switcher() %} -->
    <div class="input_switcher">
        <div v-if="source=='Stockpile'" class="slds-form slds-is-relative org_input stockpile_input">
            <div class="slds-form-element slds-form-element_stacked" v-bind:class="{ 'slds-has-error': stockpile_orgs.length==0}">
                <label class="slds-form-element__label" for="combobox-stockpile">Select an Org from Stockpile</label>
                <div class="slds-form-element__control">
                    <div class="slds-combobox_container" v-bind:class="{ 'slds-has-selection': selected_org.org_name!=''}" @focusout="dropdownFocusOut()">
                        <div class="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click" v-bind:class="{ 'slds-is-open': dropdown}" aria-expanded="false" aria-haspopup="listbox" role="combobox">
                            <div class="slds-combobox__form-element slds-input-has-icon" v-bind:class="{'slds-input-has-icon_right': selected_org.org_name =='', 'slds-input-has-icon_left-right': selected_org.org_name!=''}" role="none" v-on:click="openDropdown()">
                                <span v-if="selected_org.org_name!=''" class="slds-icon_container slds-icon-standard-orders slds-combobox__input-entity-icon">
                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#orders"></use>
                                    </svg>
                                </span>
                                <input type="text" class="slds-input slds-combobox__input" v-bind:class="{'slds-combobox__input-value': selected_org.org_name!=''}" id="combobox-stockpile" aria-autocomplete="list" aria-controls="listbox-stockpile" autoComplete="off" role="textbox" placeholder="Search org by name or username..." v-model="selected_org.org_name" v-bind:readonly="{'slds-combobox__input-value': selected_org.org_name!=''}" />
                                <span v-if="selected_org.org_name==''" class="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
                                    <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                                    </svg>
                                </span>
                                <button v-if="selected_org.org_name!=''" class="slds-button slds-button_icon slds-input__icon slds-input__icon_right" title="Remove selected option" v-on:click="clearSelectedOrg()">
                                    <svg class="slds-button__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                    </svg>
                                    <span class="slds-assistive-text">Remove selected option</span>  
                                </button>
                            </div>
                            <div id="listbox-stockpile" class="slds-dropdown slds-dropdown_length-7 slds-dropdown_fluid" role="listbox">
                                <ul class="slds-listbox slds-listbox_vertical" role="presentation">
                                    <!-- {% for org in stockpile_orgs %} -->
                                    <li role="presentation" class="slds-listbox__item" v-for="org in stockpile_orgs" :key="org.id" v-on:click="setSelectedOrg(org)">
                                        <!-- <div id="option{{ loop.index0 }}" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option"> -->
                                        <div class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-media__figure slds-listbox__option-icon">
                                                <span class="slds-icon_container slds-icon-standard-orders">
                                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#orders"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ org.name }}</span>
                                                <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">{{ org.username }} • Expiring on {{ org.expiring_date | dateFormat}}</span>
                                            </span>
                                        </div>
                                    </li>
                                    <!-- {% endfor %} -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slds-form-element__help" v-if="stockpile_orgs.length==0">No Orgs? Be sure to click "Log In" within the Stockpile app in order to store that password.</div>
            </div>
        </div>
        <div v-if="source=='Authenticate'" class="slds-form slds-is-relative org_input auth_input ">
            <div v-if="selected_org.username==''" class="slds-form-element slds-form-element_stacked">
                <label  class="slds-form-element__label" for="form-element-01" >Username</label>                                
                <div class="slds-form-element__control">
                    <input type="text" id="form-element-01" placeholder="" class="slds-input" v-model="org_username"/>
                </div>
                <label class="slds-form-element__label" for="form-element-02" >Password</label>                                
                <div class="slds-form-element__control">
                    <input type="password" id="form-element-02" placeholder="" class="slds-input" v-model="org_password"/>
                </div>
                <button id="log-in_button" class="slds-button slds-m-top_small slds-button_outline-brand" :disabled="org_username=='' || !org_username.includes('@') || org_password==''" v-on:click="setSelectedOrg(null)">Log In to a Salesforce Org
                    <svg class="slds-button__icon slds-button__icon_right" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#unlock"></use>
                    </svg>
                </button>  
            </div>
            <div v-if="selected_org.username!=''" class="slds-form-element slds-m-top_small slds-form-element_stacked">
                <div id="logged-in_details" class="slds-box slds-box_x-small">
                    <p>Authenticated with username <strong>{{ selected_org.username }}</strong>. <a href="javascript:void(0);" v-on:click="clearSelectedOrg()">Log in to a different org</a></p>
                </div>
            </div>
        </div>
        <div v-if="source=='Github'" class="slds-form slds-is-relative org_input git_input ">
            <div :class="{'slds-has-error' : !githubValid}" class="slds-form-element slds-form-element_stacked">
                <label class="slds-form-element__label" for="form-element-01">GitHub repo URL</label>
                <div class="slds-form-element__icon slds-show_medium" @mouseover="hover = false" @mouseleave="hover = true">
                    <button class="slds-button slds-button_icon help_button-icon">
                        <svg class="slds-button__icon" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
                        </svg>
                        <span class="slds-assistive-text">Help</span>
                    </button>
                </div>
                <div class="slds-popover slds-popover_tooltip slds-popover_large slds-nubbin_bottom-left" :class="{'slds-hide' : hover}" role="tooltip" id="help" style="position:absolute;top:-62px;left:90px;">
                    <div class="slds-popover__body">The URL to the Github Repo stored on SFDC-QBranch. Consult the help at the bottom of your screen to find out how to get access.</div>
                </div>
                <div class="slds-form-element__control">
                    <input type="url" id="form-element-01" placeholder="e.g. https://github.com/sfdc-qbranch/awaesomeStuff" class="slds-input" v-model="demoComponent.package_url__c"/>
                </div>
                <div v-if="!githubValid" class="slds-form-element__help" id="error-message-github-url">{{githubInvalidMessage}}</div>
            </div>
        </div>
    </div>
    <!-- {% endmacro %} -->

        

</template>

<script>
    import moment from 'moment'
    import axios from "axios";
    export default {
        name: 'SelectOrgInputSwitcher',
        data: function() {
            return {
                dropdown: false,
                selected_org: {org_name: '', username: ''},
                org_username: '',
                org_password: '',
                hover: true,
                githubValid : true,
                githubInvalidMessage : String
            }
        },
        props: {
            demoComponent: Object,
            initialSource: String,
        },
        watch: {
            demoComponent(newVal, oldVAl) {
                console.log('THE COMPONENT HAS CHANGED!!2');
            }
        },
        computed: {            
            stockpile_orgs: function() {
                return this.$store.getters.getStockpileOrgs;
            },
            source: function() {
                return this.$store.getters.getAuthSource;
            },
        },
        filters:{
            dateFormat: function(expirydate){
                if (!expirydate) return ''
                expirydate = expirydate.toString()
                return moment(String(expirydate)).format('MM-DD-YYYY')
            },
            orgSearch: function(){
                let orgs = this.stockpile_orgs;
                return orgs.filter(o =>{
                    return o.name.match(this.selected_org);
                });
            }
        },
        created: function() {
            if(this.initialSource){
                this.$store.commit('setAuthSource', this.initialSource);
            }
            else{
                this.$store.commit('setAuthSource', 'Stockpile');
            }
            var sessionDetails = this.$store.state.sessionDetails;            
            console.log(sessionDetails);
            axios
            .get("/v1/getStockpileOrgs", { params: {"email": sessionDetails.user.email} })
            .then(res => {
                if (!res.data.error) {
                    console.log('RETURNED :: ' , res);
                    let listOrgs = res.data.data.filter((f)=>{
                        return f.password!=null;
                    });  
                    this.$store.commit('setStockpileOrgs', listOrgs);
                } else {
                    console.log(res.data.error);
                }
            }).catch(err => {
                console.log('Exception :: getStockpileOrgs :: ' + err);
            })
        },
        methods: {
            openDropdown: function(){
                this.dropdown = (this.dropdown === true ? false : true);
                return this.dropdown;
            },
            setSelectedOrg: function(val){
                let username = '';
                let password = '';
                let org = null;                
                if(this.source == 'Stockpile'){
                    username = val.username; 
                    password = val.password;
                    //password = process.env.TEST_STOCKPILE_PW;
                    org = val;
                    this.openDropdown();
                }else{
                    username = this.org_username;
                    password = this.org_password;
                }
                //show spinner
                this.$store.commit('setSelectOrgSpinner', true);
                //make call to auth into selected org before setting the selected_org
                this.authenticateDemoOrg(username, password, org);
            },
            clearSelectedOrg: function(){                
                this.selected_org.org_name='';
                this.selected_org.username='';
                if(this.source == 'Authenticate'){
                    this.org_username = ''; 
                    this.org_password = '';                     
                }
                this.$store.commit('setDemoOrgPackages', null);
                this.$store.commit('setSelectedOrgAuthDetails', null);
            },
            dropdownFocusOut() {
                var self = this;
                setTimeout(function() { self.dropdown = false; }, 200);
            },
            authenticateDemoOrg: function(username, password, org){
                axios
                .post("/v1/getDemoOrgAuthToken", JSON.stringify({ username: username, password: password }))
                .then(res => {
                    console.log(res.data.success);
                    if (res.data.success) {
                        console.log('getDemoOrgAuthToken RETURNED :: ' , res);     
                        this.$store.commit('setSelectedOrgAuthDetails', { accessToken: res.data.accessToken, instanceUrl: res.data.instanceURL, orgId: res.data.orgId });                                                              
                        axios
                        .post("/v1/getDemoOrgPackages", JSON.stringify({ accessToken: res.data.accessToken, instanceUrl: res.data.instanceURL, orgId: res.data.orgId }))
                        .then(res => {
                            if (!res.data.error) {
                                console.log('getDemoOrgPackages RETURNED :: ' , res.data.data.listPackages);                                                                
                                if(res.data.data.listPackages.length == 0){
                                    //send a no packages toast
                                    console.log('no packages in this org');
                                }
                                this.$store.commit('setDemoOrgPackages', res.data.data.listPackages);
                                if(this.source == 'Stockpile'){
                                    this.selected_org.org_name= org.name;
                                }
                                else{
                                    this.selected_org.username= username;
                                }
                                
                                this.$store.commit('setSelectOrgSpinner', false);            
                            } else {
                                console.log(res.data);                            
                            }
                        })
                        .catch(err => {
                            console.log('Exception :: getDemoOrgPackages :: ' + err);
                            this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'DEMO_ORG_AUTH_ERROR' + err, toastLengthInSeconds: 5 });
                            this.$store.commit('setSelectOrgSpinner', false); 
                        })                        
                    } else {
                        console.log(res.data.error); 
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'DEMO_ORG_AUTH_ERROR', toastLengthInSeconds: 5 });
                        this.$store.commit('setSelectOrgSpinner', false); 
                    }
                }).catch(err => {
                    console.log('Exception :: getDemoOrgAuthToken :: ' + err);
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'DEMO_ORG_AUTH_ERROR', toastLengthInSeconds: 5 });
                    this.$store.commit('setSelectOrgSpinner', false); 
                })
            },
            checkValidity() {
                if((this.demoComponent.package_url__c.match(new RegExp("/", "g")) || []).length > 3){
                    var params = {
                        packageUrl: this.demoComponent.package_url__c
                    };
                    axios.post("/v1/checkSFDXFileExists", JSON.stringify(params)).then((res) => {
                        if (!res.data.error && res.data) {
                            console.log('checkValidity: ', res.data.error);
                            this.githubValid = true;
                        } else {
                            console.log('checkValidity error.', res.data.error);
                            this.githubValid = false;
                            this.githubInvalidMessage = 'Repo does not contain a valid SFDX project.';
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                }
                else{
                    this.githubValid = false;
                    this.githubInvalidMessage = 'Please enter a valid Github URL.';
                }
            },
        }
    }
</script>