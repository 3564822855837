<template>
    <div class="slds-form">
        <div class="slds-form-element slds-form-element_stacked">
            <label class="slds-form-element__label" for="combobox-package">Select a package</label>
                <div class="slds-form-element__control">
                    <div class="slds-combobox_container" v-bind:class="{ 'slds-has-selection': selectedPackageName!=''}" @focusout="packageDropdownFocusOut()">
                        <div class="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click" v-bind:class="{ 'slds-is-open': packageDropdown}" aria-expanded="false" aria-haspopup="listbox" role="combobox">
                            <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none" v-on:click="openPackageDropdown()">
                                <input type="text" class="slds-input slds-combobox__input" v-bind:class="{'slds-combobox__input-value': selectedPackageName!=''}" id="combobox-package" aria-autocomplete="list" aria-controls="listbox-package" autoComplete="off" role="textbox" placeholder="Search package by name or namespace..." v-model="selectedPackageName" v-bind:disabled="packages==null || packages.length==0" />
                                <!-- <input type="text" class="slds-input slds-combobox__input" id="combobox-package" aria-autocomplete="list" aria-controls="listbox-package" autoComplete="off" role="textbox" placeholder="Search package by name or namespace..." v-bind:disabled="packages==null || packages.length==0" /> -->
                                <span v-if="selectedPackageName==''" class="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
                                    <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                                    </svg>
                                </span>
                                <button v-if="selectedPackageName!=''" class="slds-button slds-button_icon slds-input__icon slds-input__icon_right" title="Remove selected option" v-on:click="clearSelectedPackage()">
                                    <svg class="slds-button__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                    </svg>
                                    <span class="slds-assistive-text">Remove selected option</span>  
                                </button>
                            </div>
                            <div v-if="packages!=undefined" id="listbox-package" class="slds-dropdown slds-dropdown_length-7 slds-dropdown_fluid" role="listbox">
                                <ul class="slds-listbox slds-listbox_vertical" role="presentation">
                                    <li role="presentation" class="slds-listbox__item" v-for="pack in packages" :key="pack.id" v-on:click="setSelectedPackage(pack)" >
                                        <div class="slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ pack.packageName }}</span>
                                            <!-- <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">v{{ pack.version }} • {{ pack.namespace }}</span> -->
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                <div class="slds-form-element__help">Note: Packages are extracted and stored into an external repository. Installs are operated with Salesforce DX. <a href="https://salesforce.quip.com/nPvTAVhH61W7" target="_blank">Read the FAQ</a> for more info.</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PackageInput',
        data: function() {
            return {
                packageDropdown: false,
                selectedPackage: null,
                selectedPackageName: ''
            }
        },
        props: {
            value: {
                type: String,
                required: true
            }
        },
        computed: {            
            packages: function() {                
                let p = this.$store.getters.getDemoOrgPackages;                
                if(p != undefined || p != null ){                    
                    return p;
                }
                else{
                    this.selectedPackage = null;
                    this.selectedPackageName = '';
                    return null;
                }
            }
        },
        components: {
        },
        methods: {
            openPackageDropdown: function(){
                this.packageDropdown = (this.packageDropdown === true ? false : true);
                return this.packageDropdown;
            },
            packageDropdownFocusOut() {
                var self = this;
                setTimeout(function() { self.packageDropdown = false; }, 200);
            },            
            setSelectedPackage: function(pack){
                this.openPackageDropdown();
                this.selectedPackageName = pack.packageName;
                this.selectedPackage = pack;                
                this.$store.commit('setSelectedOrgPackage', pack);
                
            },
            clearSelectedPackage: function(){   
                this.selectedPackageName  = '';           
                this.selectedPackage = null;                       
                this.$store.commit('setSelectedOrgPackage', null);
            },
        },
        created() {
        }
    }
</script>