<template>
    <div>
        <section role="dialog" tabindex="-1" class="slds-modal slds-fade-in-open" id="install-error_modal" aria-labelledby="install-error_modal-label" aria-describedby="install-error_modal-content" aria-modal="true">
            <div class="slds-modal__container">
                <header class="slds-modal__header slds-modal__header_empty">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="closeModal">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                </header>
                <div class="slds-modal__content slds-grid slds-grid_vertical" id="install-error_modal-content">
                    <div class="slds-col modal_illustration">
                        <img src="/assets/images/install-error_banner@2x.png" alt="Success Banner" />
                    </div>
                    <div class="slds-col slds-p-around_medium">
                        <div class="slds-welcome-mat__info-content slds-p-horizontal_small">
                            <h2 class="slds-welcome-mat__info-title" id="install-error_modal-label">Oops!</h2>
                            <div class="slds-welcome-mat__info-description">
                                <div class="slds-text-longform">
                                    <h3 class="slds-text-heading_small">Looks like things didn't exactly go according to plan.</h3>
                                    <p class="slds-text-body_regular slds-text-color_weak"><strong>{{ successfulInstallCount }}/{{ installResults.length }}</strong> Components have been succesfully installed. <a id="view-report_button" href="javascript:void(0)" @click="openReport">View&nbsp;detailed&nbsp;report</a></p>
                                </div>
                            </div>
                            <div class="slds-welcome-mat__info-actions slds-m-bottom_medium">
                                <button class="slds-button slds-button_neutral close-modal_button" @click="closeModal">Close</button>
                                <button class="slds-button slds-button_brand retry-install_button" @click="retryInstall">Retry Install</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    export default {
        name: 'InstallErrorModal',
        props: {
        
        },
        components: {

        },
        computed: {
            installResults() { return this.$store.getters.getInstallResults; },
            successfulInstallCount() {
                return this.installResults.filter((x) => { return (x.deployJobStatus.errors != null && x.deployJobStatus.errors.length == 0) }).length;
            },
            errorInstallCount() {
                return this.installResults.filter((x) => { return (x.deployJobStatus.errors != null && x.deployJobStatus.errors.length > 0) }).length; 
            },
        },
        methods: {
            closeModal() { this.$emit('close-modal'); },
            openReport() { this.$emit('open-report'); },
            retryInstall() { this.$emit('retry-install'); }
        },
        created() {

        }
    }
</script>

