<template>
    
    <!-- {% macro search_field(device) %} -->
    <div class="slds-form-element">
        <label class="slds-form-element__label slds-assistive-text" :for="'combobox_search-' + device">Search</label>
        <div class="slds-form-element__control">
            <div class="slds-combobox-group">
                <div class="slds-combobox_object-switcher slds-combobox-addon_start">
                    <div class="slds-form-element">
                        <label class="slds-form-element__label slds-assistive-text" :for="'scopeswitcher-combobox-' + device">Filter Search by:</label>
                        <div class="slds-form-element__control">
                            <div class="slds-combobox_container">
                                <div :class="dropdownInFocus ? 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click'" aria-controls="primary-search-combobox" aria-expanded="false" aria-haspopup="listbox" role="combobox">
                                    <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                        <!-- <input type="text" class="slds-input slds-combobox__input slds-combobox__input-value" :id="'scopeswitcher-combobox-' + device" aria-controls="scopeswitcher-listbox" autoComplete="off" role="textbox" placeholder=" " :value="selectedOption" readonly v-on:focus="dropdownFocused()" v-on:focusout="dropdownFocusedOut()"/> -->
                                        <input type="text" class="slds-input slds-combobox__input slds-combobox__input-value" :id="'scopeswitcher-combobox-' + device" aria-controls="scopeswitcher-listbox" autoComplete="off" role="textbox" placeholder=" " :value="searchObj.searchCategory" readonly v-on:focus="dropdownFocused()" v-on:focusout="dropdownFocusedOut()"/>
                                        <span class="slds-icon_container slds-icon-utility-down slds-input__icon slds-input__icon_right">
                                            <svg class="slds-icon slds-icon slds-icon_xx-small slds-icon-text-default" aria-hidden="true">
                                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                                            </svg>
                                        </span>
                                    </div>
                                    <div id="scopeswitcher-listbox" class="slds-dropdown slds-dropdown_length-5 slds-dropdown_x-small slds-dropdown_left" role="listbox">
                                        <ul class="slds-listbox slds-listbox_vertical" role="group" aria-label="Suggested for you">
                                            <li role="presentation" class="slds-listbox__item" v-for="(option, i) in options" :key="i" v-on:click="setSelectedOption(option)">
                                                <!-- <div :id="'scope_' + option" :class="option == selectedOption ? 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-is-selected' : 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small'" role="option"> -->
                                                <div :id="'scope_' + option" :class="option == searchObj.searchCategory ? 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-is-selected' : 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small'" role="option">
                                                    <span class="slds-media__figure slds-listbox__option-icon">
                                                        <!-- <span class="slds-icon_container slds-icon-utility-check slds-current-color" v-if="option == selectedOption"> -->
                                                        <span class="slds-icon_container slds-icon-utility-check slds-current-color" v-if="option == searchObj.searchCategory">
                                                            <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                                                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    <span class="slds-media__body">
                                                        <span class="slds-truncate" title="Title">
                                                            <span class="slds-assistive-text">Current Selection:</span> 
                                                            {{ option }}
                                                        </span>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slds-combobox_container slds-combobox-addon_end">
                    <div :class="(this.$route.path != '/search' && searchInFocus) ? 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click'" aria-expanded="false" aria-haspopup="listbox" id="primary-search-combobox" role="combobox">
                        <!-- slds-input-has-icon_left -->
                        <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right slds-global-search__form-element" role="none">
                            <span class="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_left">
                                <svg class="slds-icon slds-icon slds-icon_xx-small slds-icon-text-default" aria-hidden="true">
                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                                </svg>
                            </span>

                            <!-- START -->
                            <!-- <span class="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
                                <svg class="slds-icon slds-icon slds-icon_xx-small slds-icon-text-default" aria-hidden="true">
                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                </svg>
                            </span> -->
                            <!-- END -->

                            <input type="text" class="slds-input slds-combobox__input search_field" :id="'combobox_search-' + device" aria-autocomplete="list" aria-controls="search-listbox" autoComplete="off" role="textbox" placeholder="Search" v-on:focus="searchFocused()" v-on:focusout="searchFocusedOut()" v-on:keyup.enter="enterKeySearch()" v-model="searchValue"/>

                            <!-- <span class="slds-icon_container slds-icon-utility-close slds-input__icon slds-input__icon_right">
                                <svg class="slds-icon slds-icon slds-icon_xx-small slds-icon-text-default" aria-hidden="true">
                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                </svg>
                            </span> -->

                            <!-- <div role="status" class="slds-spinner slds-spinner_brand slds-spinner_x-small slds-input__spinner">
                                <span class="slds-assistive-text">Loading</span>
                                <div class="slds-spinner__dot-a"></div>
                                <div class="slds-spinner__dot-b"></div>
                             </div>

                            <button class="slds-button slds-button_icon slds-input__icon slds-input__icon_right" title="Remove selected option">
                                <svg class="slds-button__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                </svg>
                                <span class="slds-assistive-text">Remove selected option</span>
                            </button> -->


                            <!-- START -->
                            <div class="slds-input__icon-group slds-input__icon-group_right">
                                <!-- allSearchResults == null -->
                                <div role="status" class="slds-spinner slds-spinner_brand slds-spinner_x-small slds-input__spinner" v-if="allSearchResults == null && (searchValue != null && searchValue != '')">
                                    <span class="slds-assistive-text">Loading</span>
                                    <div class="slds-spinner__dot-a"></div>
                                    <div class="slds-spinner__dot-b"></div>
                                </div>
                                <button class="slds-button slds-button_icon slds-input__icon slds-input__icon_right" title="Remove selected option" v-show="searchValue != null && searchValue != '' && device == 'desktop'" v-on:click="clearSearch()">
                                    <svg class="slds-button__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                    </svg>
                                  <span class="slds-assistive-text">Remove selected option</span>
                                </button>
                            </div>
                            <!-- END -->

                        </div>

                        <!-- && recentlyViewedObjects != null && recentlyViewedObjects.length > 0 -->
                        <div v-show="selectedOption == 'All' && searchValue == '' && recentlyViewedObjects != null && recentlyViewedObjects.length > 0" id="search-listbox-primary-search" class="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid" role="listbox">
                            <ul class="slds-listbox slds-listbox_vertical" role="group" aria-label="Recent Items">
                                <li role="presentation" class="slds-listbox__item">
                                    <div id="option0" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_center" role="presentation">
                                        <h3 class="slds-listbox__option-header" role="presentation">Recently Viewed Components</h3>
                                    </div>
                                </li>
                                <li role="presentation" class="slds-listbox__item" v-for="(obj, index) in recentlyViewedObjects" :key="index" v-show="index < 5">
                                    <!-- v-if="obj.appexchange_url__c != undefined" -->
                                    <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: obj.sfid }}">
                                        <div id="option99" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-media__figure slds-listbox__option-icon">
                                                <span class="slds-icon_container slds-icon-custom-custom43">
                                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/custom-sprite/svg/symbols.svg#custom43"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ obj.name }}</span>
                                                <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">{{ obj.author__c }} • {{ getDateAsString(obj.createddate) }}</span>
                                            </span>
                                        </div>
                                    </router-link>
                                    <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: obj.sfid }}" v-if="obj.federationidentifier != undefined">
                                        <div id="option99" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-media__figure slds-listbox__option-icon">
                                                <span class="slds-icon_container slds-icon-standard-avatar">
                                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#avatar"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ obj.name }}</span>
                                                <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">{{ obj.email }}</span>
                                            </span>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div v-show="selectedOption == 'All' && searchValue == '' && recentlyViewedObjects == null" id="search-listbox-primary-search" class="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid" role="listbox">
                            <ul class="slds-listbox slds-listbox_vertical" role="group" aria-label="Recent Items" v-if="recentlyViewedObjects == null">
                                <li role="presentation" class="slds-listbox__item">
                                    <div id="option0" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_center" role="presentation">
                                        <h3 class="slds-listbox__option-header" role="presentation">Recent Components</h3>
                                    </div>
                                </li>
                                <li role="presentation" class="slds-listbox__item">
                                    <div id="option0" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_center" role="presentation">
                                        <div class="slds-align_absolute-center">
                                            <div role="status" class="slds-spinner slds-spinner_x-small slds-spinner_inline" style="z-index: 0;">
                                                <span class="slds-assistive-text">Loading</span>
                                                <div class="slds-spinner__dot-a"></div>
                                                <div class="slds-spinner__dot-b"></div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>



                        <div v-show="selectedOption == 'Components' && searchedComponents != null" id="search-listbox-primary-search" class="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid" role="listbox">
                            <ul class="slds-listbox slds-listbox_vertical" role="group" aria-label="Recent Items">
                                <li role="presentation" class="slds-listbox__item" v-if="searchedComponents != null && searchedComponents.length == 0">
                                    <div id="option0" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_center" role="presentation">
                                        <h3 class="slds-listbox__option-header" role="presentation">No Components Found</h3>
                                    </div>
                                </li>
                                <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: cmp.sfid }}" v-for="(cmp, index) in searchedComponents" :key="index" v-show="index < 5">
                                    <li role="presentation" class="slds-listbox__item">
                                        <div id="option99" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-media__figure slds-listbox__option-icon">
                                                <span class="slds-icon_container slds-icon-custom-custom43">
                                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/custom-sprite/svg/symbols.svg#custom43"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ cmp.name }}</span>
                                                <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">{{ cmp.author__c }} • {{ getDateAsString(cmp.createddate) }}</span>
                                            </span>
                                        </div>
                                    </li>
                                </router-link>
                            </ul>
                        </div>



                        <div v-show="selectedOption == 'All' && allSearchResults != null && searchValue != ''" id="search-listbox-primary-search" class="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid" role="listbox">

                            <ul class="slds-listbox slds-listbox_vertical" role="group" aria-label="Recent Items" v-if="allSearchResults != null && allSearchResults.length == 0 && searchValue != ''">
                                <li role="presentation" class="slds-listbox__item">
                                    <div id="option0" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_center" role="presentation">
                                        <h3 class="slds-listbox__option-header" role="presentation">No Search Results</h3>
                                    </div>
                                </li>
                            </ul>

                            <ul class="slds-listbox slds-listbox_vertical" role="group" aria-label="Recent Items" v-if="allSearchResults != null && allSearchResults.length > 0 && searchValue != ''">
                                <!-- <li role="presentation" class="slds-listbox__item" v-if="allSearchResults != null && allSearchResults.length == 0 && searchValue != ''">
                                    <div id="option0" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_center" role="presentation">
                                        <h3 class="slds-listbox__option-header" role="presentation">No Search Results</h3>
                                    </div>
                                </li> -->
                                <li role="presentation" class="slds-listbox__item" v-for="(obj, index) in allSearchResults" :key="index" v-show="index < 5">

                                    <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: obj.sfid }}" v-if="obj.appexchange_url__c != undefined">
                                        <div id="option99" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-media__figure slds-listbox__option-icon">
                                                <span class="slds-icon_container slds-icon-custom-custom43">
                                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/custom-sprite/svg/symbols.svg#custom43"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ obj.name }}</span>
                                                <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">{{ obj.author__c }} • {{ getDateAsString(obj.createddate) }}</span>
                                            </span>
                                        </div>
                                    </router-link>
                                    
                                    <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: obj.sfid }}" v-if="obj.federationidentifier != undefined">
                                        <div id="option99" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-media__figure slds-listbox__option-icon">
                                                <span class="slds-icon_container slds-icon-standard-avatar">
                                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#avatar"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ obj.name }}</span>
                                                <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">{{ obj.email }}</span>
                                            </span>
                                        </div>
                                    </router-link>

                                    <router-link :to="{ name: 'BundleDetail', params: { sfid: obj.sfid }}" v-if="obj.is_featured__c != undefined">
                                        <div id="option2" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-media__figure slds-listbox__option-icon">
                                                <span class="slds-icon_container slds-icon-standard-all">
                                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#all"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ obj.name }}</span>
                                                <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">Created {{ getDateAsString(obj.createddate) }}</span>
                                            </span>
                                        </div>
                                    </router-link>

                                </li>
                            </ul>
                        </div>



                        <div v-show="selectedOption == 'Users' && searchedUsers != null" id="search-listbox-primary-search" class="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid" role="listbox">
                            <ul class="slds-listbox slds-listbox_vertical" role="group" aria-label="Recent Items">
                                <li role="presentation" class="slds-listbox__item" v-if="searchedUsers != null && searchedUsers.length == 0">
                                    <div id="option0" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_center" role="presentation">
                                        <h3 class="slds-listbox__option-header" role="presentation">No Users Found</h3>
                                    </div>
                                </li>
                                <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: usr.sfid }}" v-for="(usr, index) in searchedUsers" :key="index" v-show="index < 5">
                                    <li role="presentation" class="slds-listbox__item">
                                        <div id="option99" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-media__figure slds-listbox__option-icon">
                                                <span class="slds-icon_container slds-icon-standard-avatar">
                                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#avatar"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ usr.name }}</span>
                                                <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">{{ usr.email }}</span>
                                            </span>
                                        </div>
                                    </li>
                                </router-link>
                            </ul>
                        </div>


                        <div v-show="selectedOption == 'Bundles' && searchedBundles != null" id="search-listbox-primary-search" class="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid" role="listbox">
                            <ul class="slds-listbox slds-listbox_vertical" role="group" aria-label="Recent Items">
                                <li role="presentation" class="slds-listbox__item" v-if="searchedBundles != null && searchedBundles.length == 0">
                                    <div id="option0" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_center" role="presentation">
                                        <h3 class="slds-listbox__option-header" role="presentation">No Bundles Found</h3>
                                    </div>
                                </li>
                                <router-link :to="{ name: 'BundleDetail', params: { sfid: bundle.sfid }}" v-for="(bundle, index) in searchedBundles" :key="index" v-show="index < 5">
                                    <li role="presentation" class="slds-listbox__item">
                                        <div id="option2" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                                            <span class="slds-media__figure slds-listbox__option-icon">
                                                <span class="slds-icon_container slds-icon-standard-all">
                                                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#all"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ bundle.name }}</span>
                                                <span class="slds-listbox__option-meta slds-listbox__option-meta_entity">Created {{ getDateAsString(bundle.createddate) }}</span>
                                            </span>
                                        </div>
                                    </li>
                                </router-link>
                            </ul>
                        </div>




                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- {% endmacro %} -->

</template>

<script>
    import { setTimeout } from 'timers';
    import UserLink from './UserLink.vue'
    import { ApiEndpoints } from "./../../mixins/ApiEndpoints";

    export default {
        name: 'HeaderSearch',
        mixins: [ ApiEndpoints ],
        data() {
            return {
                searchValue: '',

                options: ['All', 'Users', 'Bundles', 'Components'],
                selectedOption: '',

                dropdownInFocus: false,
                searchInFocus: false,

                allSearchResults: null,

                // isLoading: false
            }
        },
        computed: {
            // ...mapGetters(["recentlyViewedObjects", "allSearchedRecords", "searchedComponents", "searchedUsers", "searchedBundles", "searchObj"]),
            searchObj() { return this.$store.getters.getSearchObj; },
            searchedComponents() { return this.$store.getters.getSearchedComponents; },
            searchedUsers() { return this.$store.getters.getSearchedUsers; },
            searchedBundles() { return this.$store.getters.getSearchedBundles; },
            recentlyViewedObjects() { return this.$store.getters.getRecentlyViewedObjects; },
            accessToken() { return this.$store.getters.getAccessToken; },
        },
        props: {
            device: String
        },
        components: {
            userLink: UserLink
        },
        methods: {
            dropdownFocused() {
                this.dropdownInFocus = true;
                this.searchInFocus = false;
            },
            dropdownFocusedOut() {
                var self = this;
                setTimeout(function() { self.dropdownInFocus = false; }, 200);
            },
            searchFocused() {
                this.searchInFocus = true;
                this.dropdownInFocus = false;
            },
            searchFocusedOut() {
                var self = this;
                setTimeout(function() { self.searchInFocus = false; }, 200);
            },
            setSelectedOption(input) {
                this.selectedOption = input;
            },
            enterKeySearch() {
                this.dropdownInFocus = false;
                this.searchInFocus = false;
                this.$router.push({ name: 'SearchResults' });
            },
            clearSearch() {
                this.searchValue = '';
            },
            getDateAsString(input) {
                var monthsArray = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
                var dateObj = new Date(input);
                return monthsArray[dateObj.getUTCMonth()] + ' ' + dateObj.getUTCDate() + this.getNthValueForDateString(dateObj.getUTCDate()) + ', ' + dateObj.getUTCFullYear();
            },
            getNthValueForDateString(d) {
                if (d > 3 && d < 21) return 'th';
                switch (d % 10) {
                    case 1:  return "st";
                    case 2:  return "nd";
                    case 3:  return "rd";
                    default: return "th";
                }
            },
            setAllSearchResults() {
                console.log('setAllSearchResults');
                this.allSearchResults = null;
                var theMasterArray = [];
                var allResults = [];

                console.log('this.searchedComponents: ', this.searchedComponents);
                console.log('this.searchedUsers: ', this.searchedUsers);
                console.log('this.searchedBundles: ', this.searchedBundles);
                (this.searchedComponents != null) ? theMasterArray.push(this.searchedComponents) : theMasterArray.push([]);
                (this.searchedUsers != null) ? theMasterArray.push(this.searchedUsers) : theMasterArray.push([]);
                (this.searchedBundles != null) ? theMasterArray.push(this.searchedBundles) : theMasterArray.push([]);
                console.log('theMasterArray: ', theMasterArray);
                var loopLength = theMasterArray[(theMasterArray.map(a=>a.length).indexOf(Math.max(...theMasterArray.map(a=>a.length))))].length;
                loopLength = (loopLength > 5) ? 5 : loopLength;
                console.log('theMasterArray: ', theMasterArray);

                for(var i = 0; i < loopLength; i++) {
                    if(this.searchedComponents != null && this.searchedComponents[i] != undefined && this.searchedComponents[i] != null) {
                        allResults.push(this.searchedComponents[i]);
                    }
                    if(this.searchedUsers != null && this.searchedUsers[i] != undefined && this.searchedUsers[i] != null) {
                        allResults.push(this.searchedUsers[i]);
                    }
                    if(this.searchedBundles != null && this.searchedBundles[i] != undefined && this.searchedBundles[i] != null) {
                        allResults.push(this.searchedBundles[i]);
                    }
                }
                console.log('allResults: ', allResults);
                // this.allSearchResults = (allResults.length > 0) ?  allResults : null;
                this.allSearchResults = allResults;
                // this.isLoading = false;
            },
            resetSearchSettings() {
                this.searchObj.componentsSearchLimit = 5;
                this.searchObj.bundlesSearchLimit = 5;
                this.$store.commit('setSearchObj' , this.searchObj);

                this.$store.commit('setSearchValues' , {
                    'sortBy': 'Trending',
                    'clouds': '',
                    'features': '',
                    'industries': '',
                    'stars': null
                });
            },
            doSearch() {
                if(this.$route.path == '/search') {
                    // this.resetSearchSettings();
                    this.searchObj.componentsSearchLimit = 100;
                    this.searchObj.bundlesSearchLimit = 100;
                    this.$store.commit('setSearchObj' , this.searchObj);
                } else {
                    this.searchObj.componentsSearchLimit = 5;
                    this.searchObj.bundlesSearchLimit = 5;
                    this.$store.commit('setSearchObj' , this.searchObj);
                }

                if(this.accessToken != null) {
                    this.qSearchDemoComponents();
                    this.qSearchUsers();
                    this.qSearchBundles();
                }
            }
        },
        created() {
            this.selectedOption = 'All';
            // this.getRecentlyViewed();
        },
        watch: {
            searchValue(newVal, oldVal) {
                // this.isLoading = true;
                this.allSearchResults = null
                this.searchObj.searchTerm = newVal;
                this.$store.commit('setSearchObj' , this.searchObj);

                // coshea testing... 
                this.$store.commit('setSearchedComponents' , null);
                this.$store.commit('setSearchedUsers' , null);
                this.$store.commit('setSearchedBundles' , null);

                var tmpStr = newVal;
                setTimeout(() => {
                    if(tmpStr == this.searchObj.searchTerm) {
                        this.doSearch();
                    }
                }, 500);
            },
            selectedOption(newVal, oldVal) {
                if(this.searchValue != '') {
                    this.dropdownInFocus = true;
                    this.searchInFocus = true;
                    let el = document.getElementById('combobox_search-' + this.device);
                    console.log('el :: ' , el);
                    el.focus();
                }
                this.searchObj.searchCategory = newVal;
                this.$store.commit('setSearchObj' , this.searchObj);
                this.doSearch();
            },
            searchedComponents() {
                this.setAllSearchResults();
            },
            searchedUsers() {
                this.setAllSearchResults();
            },
            searchedBundles() {
                this.setAllSearchResults();
            },

            accessToken(newVal, oldVal) {
                if(newVal != null) {
                    this.getRecentlyViewed();
                }
            }

        }
    }
</script>
