<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->

    <div class="slds-size_1-of-1 slds-container_x-large slds-grid slds-wrap slds-gutters_direct-medium">
        <!-- {% extends "page-template.html" %}
        {% block content %} -->

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
            <!-- {% include "_back-button.html" %} -->
            <back-button />
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium" v-if="thisUser != null">
            <!-- {% import "macros/_list-view-titles.html" as list_view_title with context %}
            {{ list_view_title.person_title(name='Arthur Alvaro', initials='AA', size=owned.length, creator_since='2016') }} -->

            <list-view-titles :type="'person'"
                :name="thisUser.name"
                :initials="thisUser.initials"
                :size="demoComponents.length" />
                <!-- :creatorSince="getDateAsString()" -->

        </section>

        <section class="slds-wrap slds-grid slds-col slds-size_1-of-1 slds-m-bottom_xx-large">
            <div class = "slds-col slds-size_1-of-1 slds-m-bottom_medium">
                <!-- {% include "_list-filters.html" %} -->
                <list-filters :values="listFilterValues"
                    :showSortBy="false" 
                    v-on:listFilterChange="filtersChanged"/>
            </div>


            <div class="slds-col slds-size_1-of-1">
                <!-- {% import "macros/_demo-comp-list.html" as dc_listing with context %}
                {{ dc_listing.list(owned) }} -->
                <demo-cmp-list :demoComponents="displayedComponents"
                    :paginationSettings="paginationSettings"
                    v-on:paginationSettingsChanged="paginationSettingsChanged" />
            </div>

        </section>

        <!-- {% include "_demo-cmp-share-modal.html" %}
        {% include "_install-destination-modal.html" %}
        {% include "_install-prompt.html" %}
        {% include "_install-success-modal.html" %}
        {% endblock %} -->

    </div>

</template>

<script>
    import axios from "axios";
    import BackButton from "./BackButton.vue";
    import ListViewTitles from "./macros/ListViewTitles.vue";
    import DemoCmpList from "./macros/DemoCmpList.vue";
    import ListFilters from "./ListFilters.vue";

    export default {
        name: 'CreatedByOthersDetail',
        data() {
            return {
                demoComponents: null,
                filteredComponents: null,
                displayedComponents: null,
                listFilterValues: {
                    'sortBy': 'Trending',
                    'clouds': '',
                    'features': '',
                    'industries': '',
                    'stars': null
                },
                paginationSettings: {
                    "pagesSizeOptions": [8, 16, 32, 64],
                    "currentPageSizeOption": 8,
                    'totalDemoComponentCount': 1,
                    'currentPage': 1,
                    "totalPages": 1
                },
                thisUser: null
            }
        },
        props: {
        
        },
        components: {
            backButton: BackButton,
            listViewTitles: ListViewTitles,
            demoCmpList: DemoCmpList,
            listFilters: ListFilters
        },
        methods: {
            checkIsCreatedByMe() {
                // try {
                //     if(this.$route.params.sfid == this.$store.getters.sessionDetails.user.sfid) {
                //         this.$router.push({ name: 'CreatedByYouDetail' });
                //     }
                // } catch(ex) {
                //     console.log('ERROR WITH NAVIGATION TO CREATED-BY-YOU-DETAIL :: ' + ex.message);
                // }
            },
            getDemoComponentsForUser() {
                var authToken = this.$store.getters.getAccessToken;
                var userId = this.$route.params.sfid;
                axios.post("/v1/getCreatedByOthersComponents", JSON.stringify({ token: authToken, userId: userId })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log("getCreatedByOthersComponents RETURNED :: ", res.data.data.dc_created_by);
                        this.demoComponents = JSON.parse(JSON.stringify(res.data.data.dc_created_by));
                        this.filteredComponents = JSON.parse(JSON.stringify(res.data.data.dc_created_by));
                        this.setThePaginationSettings();
                        this.setThisUser();
                    } else {
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            setThePaginationSettings() {
                this.paginationSettings.totalPages = Math.floor(this.filteredComponents.length / this.paginationSettings.currentPageSizeOption);
                if((this.filteredComponents.length % this.paginationSettings.currentPageSizeOption) > 0) {
                    this.paginationSettings.totalPages++;
                }
                this.paginationSettings.totalDemoComponentCount = this.filteredComponents.length;
                this.setTheFilteredComponentsByPagination();
            },
            setTheFilteredComponentsByPagination() {
                var firstIndex = ((this.paginationSettings.currentPage - 1) * this.paginationSettings.currentPageSizeOption);
                var secondIndex = this.paginationSettings.currentPage * this.paginationSettings.currentPageSizeOption;
                if(secondIndex > this.paginationSettings.totalDemoComponentCount) {
                    secondIndex = this.paginationSettings.totalDemoComponentCount;
                }

                var tmpCmps = JSON.parse(JSON.stringify(this.filteredComponents));
                this.displayedComponents = tmpCmps.slice(firstIndex, secondIndex);
            },
            paginationSettingsChanged(input) {
                this.paginationSettings = input;
                this.paginationSettings = input;
                this.setThePaginationSettings();
            },
            filtersChanged(input) {
                this.listFilterValues = input;
                this.paginationSettings.currentPage = 1;
                this.filteredComponents = this.filterComponents();
                this.setThePaginationSettings();
            },
            filterComponents() {
                var retArr = [];
                var cloudsArr = (this.listFilterValues.clouds != null && this.listFilterValues.clouds != '') ? this.listFilterValues.clouds.split(';') : [];
                var featuresArr = (this.listFilterValues.features != null && this.listFilterValues.features != '') ? this.listFilterValues.features.split(';') : [];
                var industriesArr = (this.listFilterValues.industries != null && this.listFilterValues.industries != '') ? this.listFilterValues.industries.split(';') : [];

                for(var i = 0; i < this.demoComponents.length; i++) {
                    var cloudsCmp = (this.demoComponents[i].cloud__c != null) ? this.demoComponents[i].cloud__c.split(';') : [];
                    var featuresCmp = (this.demoComponents[i].feature__c != null) ? this.demoComponents[i].feature__c.split(';') : [];
                    var industriesCmp = (this.demoComponents[i].industry__c != null) ? this.demoComponents[i].industry__c.split(';') : [];

                    var hasClouds = (cloudsArr.length > 0) ? this.compareArrays(cloudsCmp, cloudsArr) : true;
                    var hasFeatures = (featuresCmp.length > 0) ? this.compareArrays(featuresCmp, featuresArr) : true;
                    var hasIndustries = (industriesArr.length > 0) ? this.compareArrays(industriesCmp, industriesArr) : true;
                    var hasStarRating = (this.listFilterValues.stars != null) ? (this.demoComponents[i].rating_average__c != null && this.demoComponents[i].rating_average__c >= this.listFilterValues.stars) : true;

                    if(hasClouds && hasFeatures && hasIndustries && hasStarRating) {
                        retArr.push(this.demoComponents[i]);
                    }
                }
                return retArr;
            },
            compareArrays(arr1, arr2) {
                return arr2.every(v => arr1.includes(v));
            },
            setThisUser() {
                if(this.demoComponents.length > 0) {
                    var theCmp = this.demoComponents[0];
                    this.thisUser = {
                        name: theCmp.author__c,
                        initials: this.getUserInitials(theCmp.author__c)
                    };
                } else {
                    // No demo components, maybe do something here?
                    this.thisUser = {
                        name: '',
                        initials: ''
                    };
                }
            },
            getUserInitials(name) {
                var splitName = name.split(' ');
                if(splitName.length >= 2) {
                    var firstInitial = splitName[0].charAt(0);
                    var secondInitial = splitName[1].charAt(0);
                    return firstInitial + secondInitial;
                } else {
                    return splitName[0].charAt(0);
                }
            },
            getDateAsString(input) {
                var monthsArray = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
                var dateObj = new Date(input);
                return monthsArray[dateObj.getUTCMonth()] + ' ' + dateObj.getUTCDate() + this.getNthValueForDateString(dateObj.getUTCDate()) + ', ' + dateObj.getUTCFullYear();
            },
            getNthValueForDateString(d) {
                if (d > 3 && d < 21) return 'th';
                switch (d % 10) {
                    case 1:  return "st";
                    case 2:  return "nd";
                    case 3:  return "rd";
                    default: return "th";
                }
            },
        },
        created() {
            this.checkIsCreatedByMe();
            this.getDemoComponentsForUser();
        },
        watch: { 
            $route(to, from) {  
                if(to !== from) { 
                    this.getDemoComponentsForUser();
                } 
            }
        }
    }
</script>

