<template>
    <footer class="slds-grid slds-wrap slds-grid_align-center slds-p-top_large slds-p-horizontal_large slds-p-bottom_medium slds-color__background_gray-3" v-on:click="printTheStore()">
        <div class="slds-col slds-shrink slds-m-bottom_small">
            <div class="slds-media slds-media_center slds-media_small">
                <div class="slds-media__figure slds-p-right_xx-small slds-border_right">
                    <svg aria-hidden="true" class="slds-icon" title="Q">
                        <use xlink:href="/assets/images/q-mini_logo.svg#Q"></use>
                    </svg>
                </div>
                <div class="slds-media__body">
                    <p><strong>Powered by Q</strong></p>
                    <p class="slds-text-title">Q&nbsp;Application&nbsp;Engineers, Q&nbsp;Creative, Q&nbsp;Labs</p>
                </div>
            </div>
        </div>
        <div class="slds-col slds-size_1-of-1"></div>
        <div class="slds-col slds-shrink" v-if="envVars != null">
            <ul class="slds-list_horizontal slds-has-dividers_left slds-has-block-links slds-text-align_center">
                <li class="slds-item">
                    <a :href="envVars.qbranchChatterURL" target="_blank">Q Branch Chatter Group</a>
                </li>
                <li class="slds-item slds-show_small">
                    <a :href="envVars.submitIdeasURL" target="_blank">Submit your Ideas</a>
                </li>
            </ul>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'PageFooter',
        props: {
        
        },
        components: {

        },
        computed: {
            envVars() { return this.$store.getters.getEnvVariables; },
        },
        methods: {
            printTheStore() {
                this.$store.dispatch('printStore');
            }
        },
        created() {

        }
    }
</script>

