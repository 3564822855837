<template>

    <div>
        <h3 class="slds-text-heading_small slds-m-bottom_small title-blue_underline">{{ bundle.name }}</h3>
        <p>{{ bundle.summary__c }}</p>
        <div class="slds-text-color_weak slds-text-body_small slds-m-top_medium">
            <span class="bundle_items">{{ bundle.bundle_item_count }}</span> items
        </div>
    </div>

</template>

<script>
    export default {
        name: 'BundleCard',
        props: {
            bundle: Object
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>

