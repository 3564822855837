<template>
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    
    <div class="slds-size_1-of-1 slds-container_x-large slds-grid slds-wrap slds-gutters_direct-medium">

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
            <back-button />
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium" v-if="bundles != null">
            <list-view-titles :type="'generic'" 
                :label="'Bundles'" 
                :size="bundles.length"
                :iconLib="'standard'" 
                :iconId="'all'"/>
        </section>


        <section class="slds-wrap slds-grid slds-col slds-size_1-of-1 slds-m-bottom_xx-large" v-if="bundles != null">
            <div class = "slds-col slds-size_1-of-1">
                <bundle-list :bundles="bundles" :twoThirds="false" />
            </div>
        </section>

        <section class="slds-wrap slds-grid slds-col slds-size_1-of-1 slds-m-bottom_xx-large" v-if="bundles == null">
            <div class = "slds-col slds-size_1-of-1">
                <!-- <bundle-list :bundles="bundles" /> -->
                <spinner :size="'small'" />
            </div>
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_x-large slds-text-align_center" v-if="envVars != null">
            <h3 class="slds-text-heading_small">Have new ideas for featured bundles?</h3>
            <p> Submit your thoughts to <a :href="envVars.innovationBoxBundles" target="_blank">innovation BOX</a></p>
        </section>

    </div>

</template>

<script>
    import axios from "axios";
    import BackButton from "./BackButton.vue";
    import ListViewTitles from "./macros/ListViewTitles.vue";
    import Spinner from "./macros/Spinner.vue";
    import BundleList from "./macros/BundleList.vue";

    export default {
        name: 'Bundles',
        data() {
            return {
                bundles: null
            }
        },
        props: {
        
        },
        components: {
            listViewTitles: ListViewTitles,
            backButton: BackButton,
            bundleList: BundleList,
            spinner: Spinner
        },
        methods: {
            getAllBundles() {
                axios.post("/v1/getBundles", JSON.stringify({ token: this.accessToken, featured: false })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log("getAllBundles RETURNED :: ", res.data.data.dc_bundles);
                        this.bundles = res.data.data.dc_bundles;
                    } else {
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        },
        created() {
            this.getAllBundles();
        },
        computed: {
            accessToken() { return this.$store.getters.getAccessToken; },
            envVars() { return this.$store.getters.getEnvVariables; },
        }
    }
</script>

