<template>
    <div>
        <section id="install-component_prompt" role="alertdialog" tabindex="-1" aria-labelledby="install-component_prompt-heading" aria-describedby="install-component_prompt-content" class="slds-modal slds-modal_prompt slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header slds-theme_inverse slds-theme_alert-texture">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 class="slds-text-heading_medium" id="install-component_prompt-heading">Install in Progress</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium slds-is-relative" id="install-component_prompt-content">
                    <div class="slds-text-align_center slds-m-bottom_x-large">
                        <p class="slds-text-heading_small slds-m-bottom_xxx-small">Installing Demo Component {{ currentlyInstallingNumber }} of {{ componentsToInstall.length }}:</p>
                        <!-- <p><em>{{ demo_components[0].Name }}</em></p> -->
                    </div>
                    <div id="install-component_progress-bar">
                        <div class="slds-grid slds-grid_align-spread slds-p-bottom_x-small">
                            <span>Install progress</span>
                            <span aria-hidden="true">
                                <strong><span class="progress_value">{{ currentPercent }}</span>% Complete</strong>
                            </span>
                        </div>
                        <div class="slds-progress-bar" aria-valuemin="0" :aria-valuemax="totalInt" :aria-valuenow="currentInt" aria-labelledby="install-component_progress-bar" role="progressbar">
                            <span class="slds-progress-bar__value" :style="'width:' + currentPercent + '%'">
                                <span class="slds-assistive-text">Progress: <span class="progress_value">{{ currentPercent }}</span>%</span>
                            </span>
                        </div>
                    </div>
                    <div class="slds-text-align_center slds-m-top_medium">
                        <p class="slds-text-body_small slds-text-color_weak">Note the install will continue even if you leave this page.</p>
                    </div>
                </div>
                <footer class="slds-modal__footer slds-theme_default">
                    <button class="slds-button slds-button_neutral close-modal_button" @click="abort">Abort</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    import { ApiEndpoints } from "../mixins/ApiEndpoints";

    export default {
        name: 'InstallPrompt',
        mixins: [ ApiEndpoints ],
        data() {
            return {
                demoComponentStatusObj: null,
                polledCount: 0,
                intervalObj: null
            }
        },
        props: {
        
        },
        components: {

        },
        computed: {
            componentsToInstall() { return this.$store.getters.getComponentsToInstall; },
            selectedOrgAuthDetails() { return this.$store.getters.getSelectedOrgAuthDetails; },
            installResults() { return this.$store.getters.getInstallResults; },
            currentlyInstallingNumber() {
                var completedResults = this.installResults.filter((x) => { return (x.deployJobStatus != null && x.deployJobStatus.complete); });
                return (completedResults.length == this.installResults.length) ? completedResults.length : completedResults.length + 1;
            },
            currentInt() {
                if(this.installResults != null) {
                    var retVal = 0;
                    for(var i = 0; i < this.installResults.length; i++) {
                        if(this.installResults[i].deployJobStatus != null) {
                            retVal = retVal + this.installResults[i].deployJobStatus.commandResults.length;
                        }
                    }
                    return retVal;
                } else {
                    return 0;
                }
            },
            totalInt() {
                return (this.componentsToInstall.length * 4);
            },
            currentPercent() {
                if(this.totalInt > 0) {
                    var perc = ((100 / this.totalInt) * this.currentInt);
                    return Math.floor(perc);
                } else {
                    return 0;
                }
            },

        },
        methods: {
            abort() {
                clearInterval(this.intervalObj);
                this.$emit('abort');
            },
            pollDeploymentStatus() {
                console.log('pollDeploymentStatus');
                var self = this;
                this.intervalObj = setInterval(function() {
                    self.checkJobStatus();
                }, 5000);
            },
            checkJobStatus() {
                //console.log('checkJobStatus');
                this.deployStatus();
                var completedResults = this.installResults.filter((x) => { return (x.deployJobStatus != null && x.deployJobStatus.complete); });
                if(completedResults.length == this.installResults.length) {
                    clearInterval(this.intervalObj);
                    this.deployComplete();
                }
            },
            deployComplete() {
                var hasErrors = false;
                for(var i = 0; i < this.installResults.length; i++) {
                    if(this.installResults[i].deployJobStatus.errors.length > 0) {
                        hasErrors = true;
                    }
                }
                if(hasErrors) {
                    this.$emit('deploy-error');
                } else {
                    this.$emit('deploy-success');
                }
            },
            setInitialInstallResults() {
                var installResults = [];
                for(var i = 0; i < this.componentsToInstall.length; i++) {
                    installResults.push({
                        demoComponent: this.componentsToInstall[i],
                        deployJobStatus: null,
                        deployId: null
                    });
                }
                this.$store.commit('setInstallResults', installResults);
            }
        },
        created() {
            console.log('componentsToInstall :: ' , this.componentsToInstall);
            console.log('selectedOrgAuthDetails :: ' , this.selectedOrgAuthDetails);

            this.setInitialInstallResults();
            this.deploy();
            this.pollDeploymentStatus();
        },
    }
</script>

