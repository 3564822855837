<template>
  <div id="app">

    <!-- <dashboard /> -->
    <!-- <confetti-celebration /> -->

    <div class="slds-grid slds-grid_frame slds-wrap slds-grid_vertical slds-grid_vertical-stretch">
        <!-- We want to hide the page header on 'new/edit' route as it has it's own header. -->
        <page-header v-if="local_sessiondetail != null  && local_sessiondetail.error == false && this.$route.path != '/new' && this.$route.path != '/edit' && this.$route.path != '/user-not-found'" />

        <div id="content-wrapper" class="slds-col slds-grid slds-wrap slds-grid_align-center slds-p-vertical_large">
            <router-view v-if="local_sessiondetail != null  && local_sessiondetail.error == false && hasLoadedToken"/>
        </div>

        <page-footer v-if="local_sessiondetail != null && local_sessiondetail.error == false"/>

        <toasts />

        <no-access-error v-if="local_sessiondetail != null && local_sessiondetail.error == true"></no-access-error>

        <help-bubble v-if="local_sessiondetail != null && local_sessiondetail.error == false" />

        <!-- v-if="localCurrentModal == 'installDestination'" -->
        <install-destination-modal v-if="localCurrentModal == 'installDestination'"
            v-on:cancelClicked="closeModal"
            v-on:installClicked="localCurrentModal = 'warningModal'" />

        <select-org-warning-modal v-if="localCurrentModal == 'warningModal'"
            v-on:cancelClicked="closeModal" 
            v-on:installAnyway="localCurrentModal = 'installPrompt'" />

        <install-prompt v-if="localCurrentModal == 'installPrompt'"
            v-on:deploy-success="localCurrentModal = 'installSuccess'" 
            v-on:deploy-error="localCurrentModal = 'installError'" 
            v-on:abort="closeModal" />

        <install-report-modal v-if="localCurrentModal == 'installReport'"
            v-on:retry-install="retryInstall"
            v-on:close-modal="closeModal" />

        <install-success-modal v-if="localCurrentModal == 'installSuccess'" 
            v-on:close-modal="closeModal"
            v-on:go-to-home="goToHome" />
        
        <install-error-modal v-if="localCurrentModal == 'installError'"
            v-on:open-report="localCurrentModal = 'installReport'"
            v-on:retry-install="retryInstall"
            v-on:close-modal="closeModal" />
    </div>
    
  </div>

</template>

<script>
    import Dashboard from './components/Dashboard.vue'
    import CancelModal from './components/CancelModal.vue'
    import ConfettiCelebration from './components/ConfettiCelebration.vue'
    import PageHeader from './components/PageHeader.vue'
    import PageFooter from './components/PageFooter.vue'
    import HelpBubble from './components/HelpBubble.vue'
    import Toasts from './components/macros/Toasts.vue'
    import axios from "axios";
    import { ApiEndpoints } from "./mixins/ApiEndpoints";
    import { GET_SESSION_DETAILS } from './store/store.js'
    import { mapGetters } from "vuex";
    import InstallDestinationModal from './components/InstallDestinationModal.vue'
    import InstallPrompt from './components/InstallPrompt.vue'
    import SelectOrgWarningModal from './components/macros/SelectOrgWarningModal.vue'
    import InstallReportModal from './components/InstallReportModal.vue'
    import InstallErrorModal from './components/InstallErrorModal.vue'
    import InstallSuccessModal from './components/InstallSuccessModal.vue'
    import NoAccessError from './components/NoAccessError.vue';
    
    export default {
        name: 'app',
        mixins: [ ApiEndpoints ],
        components: {
            dashboard: Dashboard,
            cancelModal: CancelModal,
            confettiCelebration: ConfettiCelebration,
            pageHeader: PageHeader,
            pageFooter: PageFooter,
            toasts: Toasts,
            installDestinationModal: InstallDestinationModal,
            selectOrgWarningModal: SelectOrgWarningModal,
            installPrompt: InstallPrompt,
            installReportModal: InstallReportModal,
            installSuccessModal: InstallSuccessModal,
            installErrorModal: InstallErrorModal,
            helpBubble: HelpBubble,
            NoAccessError
        },
        computed: {
            ...mapGetters(["sessionDetails", "accessToken", "qSearchAuthToken"]),

            componentsToInstall() { return this.$store.getters.getComponentsToInstall; },
        },
        data() {
            return {
                local_sessiondetail : null,
                localCurrentModal: '', // installDestination / warningModal / installPrompt / installSuccess / installError /installReport
                hasLoadedToken: false
            }
        },
        mounted() {

            // TESTING DEV ONLY
            // this.$store.state.sessionDetails = {
            //     org: {
            //         instanceurl: "https://aloha.my.salesforce.com"
            //     },
            //     user: {
            //         email: "coshea@salesforce.com",
            //         displayname: "Colm O'Shea"
            //     }
            // }

            // this.$store.state.sessionDetails = {
            //     org: null,
            //     user: null
            // }


            var sessionDetails = this.$store.state.sessionDetails;
            if (sessionDetails != null) {
                console.log("ORG :: " , sessionDetails.org);
                console.log("USER :: " , sessionDetails.user);
                this.local_sessiondetail = sessionDetails;
            } else {
                this.$store.dispatch(GET_SESSION_DETAILS,{router: this.$router}).then(() => (
                    this.local_sessiondetail = this.sessionDetails
                ));
            }

        },
        methods: {
            doInit() {
                try {
                    if(this.accessToken == null) {
                        console.log('GET TOKEN FOR :: ' + this.sessionDetails.user.email);
                        axios.post("/v1/getAccessTokenForBackend", { userEmail: this.sessionDetails.user.email }).then(res => {
                            if (!res.data.error) {
                                if(res.data.data != undefined && res.data.data != null) {
                                    this.handleAuthSuccess(res.data.data);
                                } else {
                                    console.log('ISSUE RECEIVING ACCESS TOKEN');
                                    this.handleAuthErrors();
                                }
                            } else {
                                this.handleAuthErrors();
                                console.log('Error :: getAccessToken :: ' + res.data.data.error);
                            }
                        }).catch(err => {
                            this.handleAuthErrors();
                            console.log('Exception :: getAccessToken :: ' + err);
                        });
                    } 
                } catch(ex) {
                    console.log(ex.message);
                    this.handleAuthErrors();
                }

            },
            handleAuthSuccess(authItem) {
                this.$store.commit('setAccessToken', authItem.auth_token);
                console.log('Access Token :: ' + authItem.auth_token);

                this.sessionDetails.user.sfid = authItem.logged_in_user_sfid;
                this.$store.commit('setSessionDetails', this.sessionDetails);

                this.hasLoadedToken = true;

                this.getENVs();
                this.qMessengerInit();
            },
            handleAuthErrors() {
                this.hasLoadedToken = true;
                this.$router.push({ name: 'UserNotFound' });
            },
            closeModal() {
                this.localCurrentModal = '';
                this.$store.commit('setComponentsToInstall' , null);
                this.$store.commit('setSelectedOrgAuthDetails' , null);
                this.$store.commit('setInstallResults' , null);
                this.$store.commit('setSelectOrgSpinner' , false);
            },

            qMessengerInit() {
                axios.post("/v1/q_messenger", { userEmail: this.sessionDetails.user.email }).then(res => {
                    if(!res.data.error) {
                        qMessenger.init({
                            messages: res.data.messages,
                            symbolsURL: 'https://sfdc-q-static.s3-us-west-2.amazonaws.com/q-messenger/slds/assets/icons/utility-sprite/svg/symbols.svg', // required     
                            voteURL: '/v1/q_messenger_vote'
                        });
                        qMessenger.displayMessages();
                    } else {
                        console.log('Error occured while getting messages for Q-Messenger: ' + res.data.error);
                    }
                }).catch(err => {
                    console.log('Exception occured while getting messages for Q-Messenger: ' + err);
                });
            },
            goToHome() {
                this.closeModal();
                try {
                    this.$router.push({ name: 'Home' });
                } catch(ex) {
                    // must already be home
                }
            },
            retryInstall() {
                var installResults = this.$store.getters.getInstallResults;
                var failedComponents = installResults.filter((x) => {
                    return x.deployJobStatus.errors.length > 0;
                });
                var retryComponents = [];
                for(var i = 0; i < failedComponents.length; i++) {
                    retryComponents.push(failedComponents[i].demoComponent);
                }
                this.$store.commit('setComponentsToInstall' , retryComponents);
                this.$store.commit('setInstallResults' , null);
                this.localCurrentModal = 'installPrompt';
            },
        },
        created() {

        },
        watch: {
            componentsToInstall(newVal, oldVal) {
                if(oldVal == null && newVal != null) {
                    this.localCurrentModal = 'installDestination';
                }
            },
            sessionDetails() {
                this.local_sessiondetail = this.sessionDetails;
                if(this.sessionDetails != null) {
                    this.doInit();
                }
            }
        }
        
    }
</script>