<template>

    <section class="slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-order_2 slds-medium-order_3 slds-m-bottom_x-large">

        <cards-preview :demoComponents="favoriteComponents" 
                :contentType="'favorites'"
                :type="'preview'"
                :iconClass="'custom'"
                :iconId="'custom1'" 
                :label="'Favorites'"
                :emptyStateBlurb="'Use the heart icon to favorite the demo components you like.'"
                :pageLink="'FavoritesDetail'" />

        <cards-preview :demoComponents="installedComponents" 
                :contentType="'installed'"
                :type="'preview'"
                :iconClass="'standard'"
                :iconId="'recent'" 
                :label="'Installed By You'"
                :emptyStateBlurb="'Install the demo components useful for you.'"
                :pageLink="'InstalledByYouDetail'" />
        
        <cards-preview :demoComponents="ownedComponents" 
                :contentType="'owned'"
                :type="'preview'"
                :iconClass="'custom'"
                :iconId="'custom44'" 
                :label="'Created by You'"
                :emptyStateBlurb="'Help build stellar demos by creating demo components.'"
                :pageLink="'CreatedByYouDetail'" />

    </section>
    
</template>

<script>
    import CardsPreview from './macros/CardsPreview.vue'

    export default {
        name: 'HomeSideSection',
        props: {
            favoriteComponents: Array,
            installedComponents: Array,
            ownedComponents: Array
        },
        components: {
            cardsPreview: CardsPreview
        },
        methods: {
            
        },
        created() {

        }
    }
</script>

