<template>

    <div>
        <section id="modal_rating" role="dialog" tabindex="-1" aria-labelledby="modal-heading_rating" aria-modal="true" aria-describedby="modal-content_rating" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="$emit('closeModal')">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="modal-heading_rating" class="slds-modal__title slds-hyphenate">Thank you for rating this Demo Component!</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="modal-content_rating">
                    <div class="slds-text-align_center slds-m-vertical_large">
                        <!-- {{ rating.rating_stars_input('rating_preview', 3, 'medium') }} -->
                        <rating-stars :isStatic="false" :size="'medium'"
                            :demoComponent="demoComponent"
                            :ratingValue="currentRating"
                            :tmpRatingValue="tmpRatingValue"
                            v-on:starClicked="starClicked" 
                            v-on:starMouseOver="setTempRatingValue"
                            v-on:starMouseOff="resetTmpRatingValue" />
                    </div>
                    <div class="slds-form-element">
                        <div class="slds-form-element__control">
                            <textarea :ref="'rating-textarea'" id="textarea_feedback-body" class="slds-textarea" placeholder="How can we improve?" rows="4" v-model="feedbackText"></textarea>
                        </div>
                    </div>
                </div>
                <footer class="slds-modal__footer">
                    <button class="slds-button slds-button_neutral close-modal_button" @click="$emit('closeModal')">Cancel</button>
                    <button class="slds-button slds-button_brand close-modal_button" @click="submit()">Submit</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
        <!-- {% endmacro %} -->
    </div>

</template>

<script>
    import RatingStars from './RatingStars.vue'

    export default {
        name: 'RatingModal',
        data() {
            return {
                currentRating: 0,
                tmpRatingValue: 0,
                feedbackText: ''
            }
        },
        props: {
            demoComponent: Object,
            ratingValue: Number,
            ratingComment: String
        },
        components: {
            ratingStars: RatingStars
        },
        methods: {
            starClicked(val) { this.tmpRatingValue = val; },
            setTempRatingValue(val) { this.tmpRatingValue = val; },
            resetTmpRatingValue() { this.tmpRatingValue = this.ratingValue; },
            submit() { this.$emit('submit', this.tmpRatingValue, this.feedbackText); }
        },
        created() {
            this.currentRating = this.ratingValue;
            this.tmpRatingValue = this.ratingValue;

            if(this.ratingComment) {
                this.feedbackText = this.ratingComment;
            }

            setTimeout(() => {
                document.getElementById('textarea_feedback-body').focus();
            }, 200);
        }
    }
</script>

