<template>
    



        <!-- {% macro load_more_button() %}
        onclick: disabled="disabled" -->
        <button class="slds-button slds-button_neutral slds-button_stretch load-more-results_button" @click="$emit('load-more')">
            Load More Results

            <!-- onclick: remove ".slds-hide" -->
            <div class="slds-spinner_container slds-hide">
                <div role="status" class="slds-spinner slds-spinner_x-small">
                    <span class="slds-assistive-text">Loading</span>
                    <div class="slds-spinner__dot-a"></div>
                    <div class="slds-spinner__dot-b"></div>
                </div>
            </div>
        </button>
        <!-- {% endmacro %} -->

</template>

<script>
    export default {
        name: 'LoadMoreButton',
        data: function () {
            return {
                
            }
        },
        props: {
            
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>

