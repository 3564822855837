<template>
    <div>
        <section id="install-destination_modal" role="dialog" tabindex="-1" aria-labelledby="install-destination_modal-heading" aria-modal="true" aria-describedby="install-destination_modal-content" class="slds-modal slds-fade-in-open">
            <div class="slds-modal__container">
                <header class="slds-modal__header">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="cancelClicked">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 id="install-destination_modal-heading" class="slds-text-heading_medium slds-hyphenate">Where to?</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="install-destination_modal-content" style="overflow: initial;">
                    <!-- {% import "./macros/_select-org-forms.html" as select_org_forms with context %}
                    {{ select_org_forms.destination_form() }} -->

                    <!-- <select-org-input-switcher /> -->
                    <select-org-destination-form />
                </div>
                <footer class="slds-modal__footer">
                    <button class="slds-button slds-button_neutral close-modal_button" @click="cancelClicked">Cancel</button>
                    <button class="slds-button slds-button_brand install-to_button" @click="installClicked" :disabled="selectedOrgAuthDetails == null">Install</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    import SelectOrgDestinationForm from './macros/SelectOrgDestinationForm.vue'

    export default {
        name: 'InstallDestinationModal',
        props: {
        
        },
        components: {
            selectOrgDestinationForm: SelectOrgDestinationForm
        },
        computed: {
            selectedOrgAuthDetails() { return this.$store.getters.getSelectedOrgAuthDetails },
        },
        methods: {
            installClicked() {
                this.$emit('installClicked');
            },
            cancelClicked() {
                this.$emit('cancelClicked');
            }
        },
        created() {

        }
    }
</script>

