<template>
    <router-link :to="{ name: whereTo, params: { sfid: userSFID }}" :class="cmpClass" :style="cmpStyle">
        {{ userName }}
    </router-link>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: 'UserLink',
        props: {
            userName: String,
            userSFID: String,
            cmpClass: { type: String, default: '' },
            cmpStyle: { type: String, default: '' },
        },
        computed: {
            ...mapGetters(["sessionDetails"]),
            whereTo() {
                return (this.userSFID == this.sessionDetails.user.sfid) ? 'CreatedByYouDetail' : 'CreatedByOthersDetail';
            }
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>

