<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    <div>

        <!--
          IMPORTANT NOTE: Due to browsers inconsistencies, clip-path cannot be referenced from an external file.
          Therefore all SVGs in this module have to be clipped with an inline SVG definition
          that should be defined once in the HTML body (page-template.html).
          Make sure you copy the SVG structure there and have it only once in your final app
          Further Reference: https://bugs.chromium.org/p/chromium/issues/detail?id=109212
        -->

        <!-- {% macro rating_stars_input(cmpntId, rating_value=0, size='small') %} -->

        <!-- <span :id="demoComponent.id" class="star-rating_input" :data_value="ratingValue" v-if="!isStatic" v-on:mouseout="starMouseOff">
            <span class="rating-unit" :data-value="i" v-for="i in 5" :key="i">
                <input type="radio" :name="'rating_' + demoComponent.id" :value="i" :id="'rating_' + demoComponent.id + '_' + i" v-on:click="starClicked(i)">
                <label :for="'rating_' + demoComponent.id + '_' + i" :data-value="i">
                    <span class="slds-icon_container" :title="i + 'star(s)'" v-on:mouseover="starMouseOver(i)">
                        <svg :class="tmpRatingValue >= i ? 'slds-icon slds-icon-text-default star-checked slds-icon_' + size : 'slds-icon slds-icon-text-default star-not-checked slds-icon_' + size" aria-hidden="true" viewBox="0 0 24 24">
                            <use xlink:href="/assets/images/custom-icons-sprite.svg#starShape" clip-path="url(#starClip)" />
                        </svg>
                      <span class="slds-assistive-text">{{ i }} &nbsp; star(s)</span>
                    </span>
                </label>
            </span>
        </span> -->

        <span class="star-rating_input" :data_value="ratingValue" v-if="!isStatic && demoComponent != null" v-on:mouseout="starMouseOff">
            <span class="rating-unit" :data-value="i" v-for="i in 5" :key="i">
                <input type="radio" :name="'rating'" :value="i" :id="'rating_' + i + demoComponent.sfid" v-on:click="starClicked(i)">
                <label :for="'rating_' + i + demoComponent.sfid" :data-value="i + demoComponent.sfid">
                    <span class="slds-icon_container" :title="i + 'star(s)'" v-on:mouseover="starMouseOver(i)">
                        <svg :class="tmpRatingValue >= i ? 'slds-icon slds-icon-text-default star-checked slds-icon_' + size : 'slds-icon slds-icon-text-default star-not-checked slds-icon_' + size" aria-hidden="true" viewBox="0 0 24 24">
                            <use xlink:href="/assets/images/custom-icons-sprite.svg#starShape" clip-path="url(#starClip)" />
                        </svg>
                      <span class="slds-assistive-text">{{ i }} &nbsp; star(s)</span>
                    </span>
                </label>
            </span>
        </span>

        <!-- {% endmacro %} -->

        <!-- {% macro rating_stars_static(cmpntId, rating_value=0, size='x-small', grayed=false) %} -->
        <span :id="demoComponent.id" :class="grayed ? 'star-rating_static size_' + size : 'star-rating_static grayed size_' + size" v-if="isStatic && staticRatingValue == null">
            <span class="rating-unit" :data-value="i" v-for="i in 5" :key="i">
                <span class="star empty-star slds-icon_container" :title="i + ' star(s)'">
                    <svg :class="'slds-icon slds-icon-text-default slds-icon_' + size" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <use xlink:href="/assets/images/custom-icons-sprite.svg#starShape" clip-path="url(#starClip)" />
                    </svg>
                    <span class="slds-assistive-text">{{ i }} star(s)</span>
                </span>
                <span class="star filled-star slds-icon_container" :style="i-1 == roundedRatingValue ? ('width: ' + ((demoComponent.rating_average__c - roundedRatingValue) * 100) + '%') : ''" :title="i + ' star(s)'" v-show="(i-1 <= roundedRatingValue) && (demoComponent.rating_average__c != null)">
                    <svg :class="'slds-icon slds-icon-text-default slds-icon_' + size" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <use xlink:href="/assets/images/custom-icons-sprite.svg#starShape" clip-path="url(#starClip)" />
                    </svg>
                    <span class="slds-assistive-text">{{ i }} star(s)</span>
                </span>
            </span>
        </span>
        <!-- {% endmacro %} -->


        <span :class="grayed ? 'star-rating_static gray-stars size_' + size : 'star-rating_static size_' + size" :data-value="staticRatingValue" v-if="isStatic && staticRatingValue != null">
            <span class="rating-unit" :data-value="i" v-for="i in 5" :key="i">
                <span class="star empty-star slds-icon_container" :title="i + ' star(s)'">
                    <svg :class="'slds-icon slds-icon-text-default slds-icon_' + size" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <use xlink:href="/assets/images/custom-icons-sprite.svg#starShape" clip-path="url(#starClip)" />
                    </svg>
                    <span class="slds-assistive-text">{{ i }} star(s)</span>
                </span>
                <span class="star filled-star slds-icon_container" :title="i + ' star(s)'" v-show="(i <= staticRatingValue)">
                    <svg :class="'slds-icon slds-icon-text-default slds-icon_' + size" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <use xlink:href="/assets/images/custom-icons-sprite.svg#starShape" clip-path="url(#starClip)" />
                    </svg>
                    <span class="slds-assistive-text">{{ i }} star(s)</span>
                </span>
            </span>
        </span>

    </div>

</template>

<script>
    export default {
        name: 'RatingStars',
        data() {
            return {

            }
        },
        props: {
            demoComponent: Object,
            isStatic: Boolean,
            size: String,
            grayed: Boolean,
            staticRatingValue: Number,
            ratingValue: Number,
            tmpRatingValue: Number
        },
        computed: {
            roundedRatingValue() {
                if(this.demoComponent != null && this.demoComponent.rating_average__c != null) {
                    return Math.floor(this.demoComponent.rating_average__c);
                } else {
                    return 0;
                }
            }
        },
        components: {

        },
        methods: {
            starClicked(val) { this.$emit('starClicked', val); },
            // starClicked(val, cmp) { this.$emit('starClicked', val, cmp); },
            starMouseOver(val) { this.$emit('starMouseOver', val); },
            starMouseOff() { this.$emit('starMouseOff'); }
        },
        created() {
            
        }
    }
</script>

<style scoped>
    .star-checked {
        fill: #f9ca06 !important;
    }
    .star-not-checked {
        fill: #c9c7c5 !important;
        stroke: none !important;
    }
</style>

