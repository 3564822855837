<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    <div>

        <!-- {% import "macros/_inline-actions.html" as inline_actions with context %}
        {% import "macros/_rating-stars.html" as rating with context %} -->

        <!-- {% macro demo_cmp_item_img(demo_cmp) %}
            {% if demo_cmp.Screen_Shot_Id__c == "" %}
                <img src="./assets/placeholder.png" alt="{{ demo_cmp.Name }}" />
            {% else %}
                <img src="./assets/demo-components-images/{{ demo_cmp.Screen_Shot_Id__c }}.{{ demo_cmp.screenshot_type }}" alt="{{ demo_cmp.Name }}" />
            {% endif %}
        {% endmacro %} -->

        <!-- {% macro demo_cmp_list_item(demo_cmp, hidden=false, is_recommended=false) %} -->

        <!-- <div class="slds-tile slds-media slds-m-bottom_x-large demoComponentTile {{ 'slds-hide' if hidden }}" data-id="{{ demo_cmp.Id }}"> -->
        <div class="slds-tile slds-media slds-m-bottom_x-large demoComponentTile" :data-id="demoComponent.id" v-if="type == 'list'">
            <div class="slds-media__figure">
                <figure class="slds-image slds-image_card slds-theme_shade">
                    <!-- <a href="./demo-cmp-detail.html" class="slds-image__crop slds-image__crop_1-by-1">
                        <img v-if="demoComponent.image_url__c != null" :src="demoComponent.image_url__c" />
                        <img v-if="demoComponent.image_url__c == null" src="/assets/images/placeholder.png" />
                    </a> -->
                    <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: demoComponent.sfid }}" class="slds-image__crop slds-image__crop_1-by-1">
                        <img v-if="demoComponent.image_url__c != null" :src="demoComponent.image_url__c" />
                        <img v-if="demoComponent.image_url__c == null" src="/assets/images/placeholder.png" />
                    </router-link>
                </figure>
            </div>
            <div class="slds-media__body">
                <div class="slds-media slds-media_small slds-media_responsive slds-m-bottom_xx-small">
                    <div class="slds-media__body slds-m-bottom_xx-small">
                        <!-- href="./demo-cmp-detail.html" -->
                        <!-- <h3 class="slds-text-heading_small slds-m-bottom_xx-small"><a class="" v-on:click="goToDemoComponentDetail(demoComponent.sfid)">{{ demoComponent.name }}</a></h3> -->
                        <h3 class="slds-text-heading_small slds-m-bottom_xx-small">
                            <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: demoComponent.sfid }}">
                                {{ demoComponent.name }}
                            </router-link>
                        </h3>
                        <ul class="slds-list_horizontal slds-has-dividers_left slds-text-body_small">

                            <li class="slds-item slds-truncate">
                                by &nbsp; 
                                <!-- <a href="created-by-others-detail.html" class="author" style="padding-left: 3px;">{{ demoComponent.author__c }}</a> -->

                                <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: demoComponent.ownerid }}" style="padding-left: 3px;">
                                    {{ demoComponent.author__c }}
                                </router-link> -->
                                <user-link :userName="demoComponent.author__c" :userSFID="demoComponent.ownerid" :cmpStyle="'padding-left: 3px;'" />

                                <!-- <user-link :userName="demoComponent.author__c"
                                    :userEmail="demoComponent.author__c"
                                    :userSFID="demoComponent.ownerid"
                                    :addPadding="true" /> -->
                            </li>

                            <li class="slds-item slds-show_medium">{{ componentLastModifiedDate }}</li>
                            <li class="slds-item slds-show_medium">{{ componentLastUsedDate }}</li>
                            
                            <li class="slds-item q-reviewed" v-show="demoComponent.q_approved__c">
                                <div class="slds-is-relative q-approved_icon">
                                    <!-- <a class="q-approved_icon" href="javascript:void(0)" aria-describedby="Reviewed by Q Branch"> -->
                                    <a href="javascript:void(0)" aria-describedby="Reviewed by Q Branch">
                                        <span class="slds-icon_container" title="Q Branch reviewed and approves this Component">
                                            <svg class="slds-icon slds-icon_xx-small slds-align-bottom " aria-hidden="true">
                                                <use xlink:href="/assets/images/q-logo.svg#q-logo_mini"></use>
                                            </svg>
                                            <span class="slds-assistive-text">Reviewed by Q Branch</span>
                                        </span>
                                    </a>
                                    <!-- <div class="q-approved_popover slds-popover slds-popover_tooltip slds-nubbin_bottom slds-rise-from-ground slds-fall-into-ground" role="tooltip" id="q-approved_icon_tooltip" style="position:absolute;top:-68px;left:-102px;width:220px;"> -->
                                    <div class="q-approved_popover slds-popover slds-popover_tooltip slds-nubbin_bottom" role="tooltip" id="q-approved_icon_tooltip" style="position:absolute;top:-68px;left:-102px;width:220px;display:none;">
                                        <div class="slds-popover__body">This component is up to date and has been reviewed by Q Branch.</div>
                                    </div>
                                </div>
                            </li>
                            <li class="slds-item slds-show_medium" v-show="demoComponent.draft__c"><span class="slds-badge">Inactive</span></li>
                        </ul>
                    </div>
                    <div class="slds-media__figure_reverse">
                      <div class="slds-grid">
                        <!-- <div class="slds-col slds-align-middle {{ 'slds-hide' if demo_cmp.is_under_review==false }}"> -->
                        <div class="slds-col slds-align-middle" v-show="demoComponent.under_review__c">
                            <span class="slds-badge">Under Review</span>
                        </div>
                        <!-- <div class="slds-col slds-p-left_xx-small slds-is-relative {{ 'slds-hide' if not is_recommended }}"> -->
                        <div class="slds-col slds-p-left_xx-small slds-is-relative slds-hide">
                            <button class="not-relevant_button slds-button slds-button_icon slds-button_icon-small" title="Not Relevant">
                                <svg class="slds-button__icon" aria-hidden="true">
                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                </svg>
                                <span class="slds-assistive-text">Not Relevant</span>
                            </button>
                            <div class="slds-popover slds-popover_tooltip slds-nubbin_bottom slds-rise-from-ground slds-fall-into-ground slds-is-absolute" role="tooltip" style="top: -48px; left: -31px;">
                                <div class="slds-popover__body">Not&nbsp;Relevant</div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="slds-tile__detail">
                    <!-- <p class="slds-text-body_regular slds-hyphenate slds-m-bottom_x-small"><a href="./demo-cmp-detail.html" class="slds-text-link_reset clamp-lines_body">{{ componentSummary }}</a></p> -->

                    <!-- <p class="slds-text-body_regular slds-hyphenate slds-m-bottom_x-small">
                        <a href="./demo-cmp-detail.html" class="slds-text-link_reset clamp-lines_body" v-html="componentSummary">
                        </a>
                    </p> -->

                    <p class="slds-text-body_regular slds-hyphenate slds-m-bottom_x-small">
                        <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: demoComponent.sfid }}" class="slds-text-link_reset clamp-lines_body" v-html="componentSummary">
                            <!-- {{ demoComponent.name }} -->
                        </router-link>
                    </p>

                    <ul class="slds-list_horizontal slds-grid_vertical-align-center">
                        <li class="slds-m-right_large">
                            <span class="rating_inline-action nowrap">
                                <rating-stars :demoComponent="demoComponent" :isStatic="true" :size="'x-small'" />
                                <!-- {{ rating.rating_stars_static("demo_cmp_list_" + demo_cmp.Id, demo_cmp.rating, 'x-small') }} -->
                                <span class="slds-text-body_small slds-text-color_weak slds-p-left_xx-small">{{ demoComponent.review_count__c }}</span>
                            </span>
                        </li>
                        <li class="slds-m-right_large">
                            <!-- {{ inline_actions.favorite(demo_cmp.is_favorited, demo_cmp.favorited_count) }} -->
                            <inline-actions :demoComponent="demoComponent" :type="'favorite'" :isFavorite="isFavorite" v-on:favoriteClicked="favoriteClicked"/>
                        </li>
                        <li class="slds-show_medium">
                            <!-- {{ inline_actions.install(demo_cmp.is_installed, demo_cmp.installed_count) }} -->
                            <inline-actions :demoComponent="demoComponent" :type="'install'" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- {% endmacro %} -->



        <!-- {% macro demo_cmp_card_item(demo_cmp, content_type='favorite') %} -->
        <!-- <div class="slds-tile slds-media slds-media_center" :data-id="demoComponent.id" v-if="type == 'card'">
            <div class="slds-media__figure">
                <figure class="slds-image slds-image_card slds-avatar_large slds-theme_shade">
                    <a href="./demo-cmp-detail.html" class="slds-image__crop slds-image__crop_1-by-1">
                        <img v-if="demoComponent.image_url__c != null" :src="demoComponent.image_url__c" />
                        <img v-if="demoComponent.image_url__c == null" src="/assets/images/placeholder.png" />
                    </a>
                </figure>
            </div>
            <div class="slds-media__body">
                <h3 class="">
                    <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: demoComponent.sfid }}">
                        {{ demoComponent.name }}
                    </router-link>
                </h3>
                <div class="slds-tile__detail slds-text-body_small">
                    <p>{{ componentLastUsedDate }}</p>
                </div>
            </div>
        </div> -->
        <!-- {% endmacro %} -->


        <!-- {% macro rating_card(demo_cmp) %} -->
        <div class="ratingCard slds-box slds-is-relative" :data-id="demoComponent.id" v-if="type == 'rating'">
            <!-- <button class="slds-button slds-button_icon slds-m-around_xxx-small slds-is-absolute" title="Close" style="top: 0; right : 0;" @click="$emit('close-card', ratingCardIndex);">
                <svg class="slds-button__icon" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                </svg>
                <span class="slds-assistive-text">Close</span>
            </button> -->
            <div class="slds-media slds-media_center">
                <div class="slds-media__figure">
                    <figure class="slds-image slds-image_card slds-theme_shade">
                        <!-- <a href="./demo-cmp-detail.html" class="slds-image__crop slds-image__crop_1-by-1">
                            {{ demo_cmp_item_img(demo_cmp) }}
                        </a> -->

                        <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: demoComponent.sfid }}" class="slds-image__crop slds-image__crop_1-by-1">
                            <img v-if="demoComponent.image_url__c != null" :src="demoComponent.image_url__c" />
                            <img v-if="demoComponent.image_url__c == null" src="/assets/images/placeholder.png" />
                        </router-link>
                    </figure>
                </div>
                <div class="slds-media__body">
                    <!-- <a href="./demo-cmp-detail.html" class="slds-show slds-truncate slds-m-bottom_xx-small">{{ demo_cmp.Name }}</a> -->
                    <!-- {{ rating.rating_stars_input("feedback_input_" + demo_cmp.Id, 0, "small") }} -->

                    <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: demoComponent.sfid }}" class="slds-show slds-truncate slds-m-bottom_xx-small">
                        {{ demoComponent.name }}
                    </router-link>
                    <rating-stars :isStatic="false" :size="'small'"
                        :demoComponent="demoComponent"
                        :ratingValue="currentRating"
                        :tmpRatingValue="tmpRatingValue"
                        v-on:starClicked="openRatingModal" 
                        v-on:starMouseOver="setTempRatingValue"
                        v-on:starMouseOff="resetTmpRatingValue" />
                </div>
            </div>
        </div>
        <!-- {% endmacro %} -->

    </div>

</template>

<script>
    import axios from "axios";
    import RatingStars from './RatingStars.vue'
    import InlineActions from './InlineActions.vue'
    import UserLink from './UserLink.vue'
    import { ApiEndpoints } from "../../mixins/ApiEndpoints";

    export default {
        name: 'DemoCmpListItem',
        mixins: [ ApiEndpoints ],
        data() {
            return {
                currentRating: 0,
                tmpRatingValue: 0,
            }
        },
        props: {
            demoComponent: Object,
            type: String, // list / card / rating,
            isFavorite: Boolean,

            ratingCardIndex: Number // used by parent to identify
        },
        components: {
            ratingStars: RatingStars,
            inlineActions: InlineActions,
            userLink: UserLink
        },
        computed: {
            accessToken() { return this.$store.getters.getAccessToken; },
            componentSummary() {
                var showdown = require('showdown');
                var converter = new showdown.Converter();
                return converter.makeHtml(this.demoComponent.description__c);
            },
            componentLastModifiedDate() {
                var retVal;
                var updatedTime;

                if((this.demoComponent.listing_last_modified_date__c == null) || (this.demoComponent.listing_last_modified_date__c == this.demoComponent.createddate)) {
                    updatedTime = new Date(this.demoComponent.createddate);
                    retVal = 'Created';
                } else {
                    updatedTime = new Date(this.demoComponent.listing_last_modified_date__c);
                    retVal = 'Edited';
                }
                
                var todaysDate = new Date();
                var seconds = (todaysDate.getTime() - updatedTime.getTime()) / 1000;

                if(seconds > 31557600) {
                    var x = Math.floor(seconds / 31557600).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' years ago' : retVal + ' ' + x + ' year ago';
                } else if(seconds > 2629800) {
                    var x = Math.floor(seconds / 2629800).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' months ago' : retVal + ' ' + x + ' month ago';
                } else if(seconds > 604800) {
                    var x = Math.floor(seconds / 604800).toString();
                    if(x == 0) { x = 1; }
                    retVal = x >= 2 ? retVal + ' ' + x + ' weeks ago' : retVal + ' ' + x + ' week ago';
                } else if(seconds > 87660) {
                    var x = Math.floor(seconds / 87660).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' days ago' : retVal + ' ' + x + ' day ago';
                } else if(seconds > 3600) {
                    var x = Math.floor(seconds / 3600).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' hours ago' : retVal + ' ' + x + ' hour ago';
                } else if(seconds > 60) {
                    var x = Math.floor(seconds / 60).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' minutes ago' : retVal + ' ' + x + ' minute ago';
                } else {
                    var x = Math.floor(seconds).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' seconds ago' : retVal + ' ' + x + ' second ago';
                }
                return retVal;
            },
            componentLastUsedDate() {
                if(this.demoComponent.most_recent_usage_date__c == null) {
                    return 'Never Used'
                } else {
                    var retVal = 'Last used';
                    var todaysDate = new Date();
                    var lastUsedTime = new Date(this.demoComponent.most_recent_usage_date__c);
                    var seconds = (todaysDate.getTime() - lastUsedTime.getTime()) / 1000;

                    if(seconds > 31557600) {
                        var x = Math.floor(seconds / 31557600).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' years ago' : retVal + ' ' + x + ' year ago';
                    } else if(seconds > 2629800) {
                        var x = Math.floor(seconds / 2629800).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' months ago' : retVal + ' ' + x + ' month ago';
                    } else if(seconds > 604800) {
                        var x = Math.floor(seconds / 2629800).toString();
                        if(x == 0) { x = 1; }
                        retVal = x >= 2 ? retVal + ' ' + x + ' weeks ago' : retVal + ' ' + x + ' week ago';
                    } else if(seconds > 87660) {
                        var x = Math.floor(seconds / 87660).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' days ago' : retVal + ' ' + x + ' day ago';
                    } else if(seconds > 3600) {
                        var x = Math.floor(seconds / 3600).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' hours ago' : retVal + ' ' + x + ' hour ago';
                    } else if(seconds > 60) {
                        var x = Math.floor(seconds / 60).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' minutes ago' : retVal + ' ' + x + ' minute ago';
                    } else {
                        var x = Math.floor(seconds).toString();
                        retVal = x >= 2 ? retVal + ' ' + x + ' seconds ago' : retVal + ' ' + x + ' second ago';
                    }
                    return retVal;
                }
            }
        },
        methods: {
            goToDemoComponentDetail(sfid) { this.$router.push({ name: 'DemoCmpDetail', params: { sfid: sfid } }); },
            openRatingModal(val) {
                this.currentRating = val;
                this.$emit('openRatingModal', val, this.demoComponent);
            },
            setTempRatingValue(val) { this.tmpRatingValue = val },
            resetTmpRatingValue() { this.tmpRatingValue = 0; },
            favoriteClicked() {
                var self = this;
                this.isFavorite = !this.isFavorite;

                if(this.isFavorite) {
                    this.demoComponent.number_of_favorites__c = this.demoComponent.number_of_favorites__c + 1;
                } else {
                    this.demoComponent.number_of_favorites__c = ((this.demoComponent.number_of_favorites__c - 1) > 0) ? this.demoComponent.number_of_favorites__c - 1 : 0;
                }

                axios.post("/v1/favoriteComponent", JSON.stringify({ token: this.accessToken, componentId: this.demoComponent.sfid, favorite: this.isFavorite })).then((res) => {
                    if (!res.data.error) {
                        // self.getDemoComponents();
                        self.getFavoriteComponents();
                    } else {
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        },
    }
</script>

<style scoped>
    .q-approved_icon:hover #q-approved_icon_tooltip {
        display: block !important;
    }
</style>


