<template>

    <div>

        <!-- Demo Org -->
        <div v-if="isWithinOrg">
            <div class="slds-size_1-of-1 slds-container_small slds-grid slds-wrap slds-gutters_direct-medium">
                <div class="slds-align_absolute-center slds-m-bottom_x-large">
                    <img src="/assets/images/demo_components_logo-main@2x.png" />
                </div>
                <!-- <button class="slds-button slds-button_brand slds-align_absolute-center" @click="createNewUser" :disabled="isLoading">Create Profile</button> -->
                <a href="https://q-passport.sfdc.sh/auth/alohaauth?app=demo-components-aloha-staging" target="_blank" style="width: 100%;">
                    <button class="slds-button slds-button_brand slds-button_stretch">
                        <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#new_window"></use>
                        </svg>
                        Authenticate with Aloha
                    </button>
                </a>
            </div>
        </div>


        <!-- Demo Org -->
        <div v-if="newAlohaUser">
            <div class="slds-size_1-of-1 slds-container_medium slds-grid slds-wrap slds-gutters_direct-medium">
                <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
                    <div class="slds-text-heading_large">
                        <b>Hello Trailblazer!</b>
                    </div>
                </section>
                <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium slds-text-heading_small">
                    It looks like this is your first time here. Please review the following info to get started.
                </section>
                <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium slds-text-heading_small">
                    <b>Name:</b> {{ sessionDetails.user.displayname }} <br/>
                    <b>Email:</b> {{ sessionDetails.user.email }} <br/>
                </section>
                <section class="slds-col slds-size_1-of-1 slds-m-bottom_x-large slds-align_absolute-center">
                    <button class="slds-button slds-button_brand" @click="createNewUser">Yes, it's me!</button>
                    <button class="slds-button slds-button_neutral" @click="showContactUsPage = true">I'm not quite sure about this</button>
                </section>
            </div>
        </div>


        <!-- Contact Us -->
        <div v-if="showContactUsPage || noUserFound">
            <div class="slds-size_1-of-1 slds-container_medium slds-grid slds-wrap slds-gutters_direct-medium">
                <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
                    <div class="slds-text-heading_large">
                        <b>Hello Trailblazer!</b>
                    </div>
                </section>
                <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium slds-text-heading_small">
                    This is awkward, but we are having trouble finding your user profile. <br/>
                    Please reach out to <a href="javascript:void(0);">demo-components-support@salesforce.com</a> to get this fixed.
                </section>
            </div>
        </div>


        <!-- Spinner -->
        <div class="slds-spinner_container" v-if="isLoading">
            <div role="status" class="slds-spinner slds-spinner_brand slds-spinner_large">
                <span class="slds-assistive-text">Loading</span>
                <div class="slds-spinner__dot-a"></div>
                <div class="slds-spinner__dot-b"></div>
            </div>
            <div class="slds-align_absolute-center slds-p-top_xx-large slds-m-top_x-large slds-grid slds-grid_vertical" style="height: 100%">
                <span class="slds-text-heading_large slds-m-bottom_small slds-m-top_xx-large">Creating Your User</span>
                <span class="slds-text-heading_small">{{ loadingInfo }}</span>
            </div>
        </div>


    </div>

</template>

<script>
    import axios from "axios";

    export default {
        name: 'UserNotFound',
        data() {
            return {
                isLoading: false,
                loadingInfo: 'Authorizing',
                loadingInfoCurrentPos: 0,
                loadingInfoOptions: [
                    'Creating Profile now',
                    'This may take up to a minute to complete',
                    'Nearly there, just cleaning up a few things',
                    'Nearly there',
                    'Any second now'
                ],
                intervalObj: null,
                userCreatedSuccess: false,

                trailing: '.',
                trailingIntervalObj: null,

                showContactUsPage: false,
            }
        },
        props: {
        
        },
        computed: {
            sessionDetails() { return this.$store.getters.sessionDetails },
            newAlohaUser() {
                return (this.sessionDetails != null && 
                    this.sessionDetails.org != undefined && 
                    this.sessionDetails.org != null && 
                    this.sessionDetails.org.instanceurl != null && 
                    this.sessionDetails.org.instanceurl == 'https://aloha.my.salesforce.com' && 
                    this.sessionDetails.user != null &&
                    this.showContactUsPage == false
                );
            },
            isWithinOrg() {
                return (
                    this.sessionDetails != null &&
                    this.sessionDetails.org != null && 
                    this.sessionDetails.org.instanceurl != null && 
                    this.sessionDetails.org.instanceurl != 'https://aloha.my.salesforce.com' &&
                    this.showContactUsPage == false
                );
            },
            noUserFound() {
                return (
                    this.sessionDetails == null ||
                    this.sessionDetails.org == undefined ||
                    this.sessionDetails.user == undefined ||
                    this.sessionDetails.org == null ||
                    this.sessionDetails.user == null
                );
            }
        },
        components: {

        },
        methods: {
            createNewUser() {
                try {
                    this.startLoading();
                    var firstName = this.sessionDetails.user.displayname.split(' ')[0];
                    var lastName = this.sessionDetails.user.displayname.split(' ')[1];
                    var payload = {
                        userEmail: this.sessionDetails.user.email,
                        firstName: firstName,
                        lastName: lastName,
                    };
                    axios.post("/v1/getAccessTokenForBackendNewUser", payload).then(res => {
                        if (!res.data.error) {
                            if(res.data.data != undefined && res.data.data != null) {
                                console.log('createNewUser RETURNED :: ' , res.data.data);
                                this.userCreatedSuccess = true;
                            } else {
                                console.log('ISSUE RECEIVING ACCESS TOKEN :: ' , res.data.data.error);
                            }
                        } else {
                            console.log('Error :: getAccessTokenForBackendNewUser :: ' + res.data.data.error);
                        }
                    }).catch(err => {
                        console.log('Exception :: getAccessTokenForBackendNewUser :: ' + err);
                    });
                } catch(ex) {
                    console.log('EXCEPTION :: ' , ex.message);
                }
            },
            startLoading() {
                this.isLoading = true;
                var self = this;
                this.intervalObj = setInterval(() => {
                    if(self.loadingInfoCurrentPos < 5) {
                        self.loadingInfo = this.loadingInfoOptions[self.loadingInfoCurrentPos];
                    }
                    if(self.loadingInfoCurrentPos == 5) {
                        self.timeoutComplete();
                    }
                    self.loadingInfoCurrentPos = self.loadingInfoCurrentPos + 1;
                }, 10000 );
            },
            startTrailing() {
                var self = this;
                this.trailingIntervalObj = setInterval(() => {
                    self.trailing = self.trailing + '.';
                    if(self.trailing == '....') {
                        self.trailing = '.';
                    }
                }, 1000);
            },
            timeoutComplete() {
                console.log('Going to: ' + window.location.protocol + '//' + window.location.hostname);
                window.location.assign(window.location.protocol + '//' + window.location.hostname);
            },
            handleCreateNewUserError() {
                clearInterval(this.intervalObj);
            },
        },
    }
</script>

