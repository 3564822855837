<template>
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->

    <div class="slds-size_1-of-1 slds-container_x-large slds-grid slds-wrap slds-gutters_direct-medium">
        <!-- {% extends "page-template.html" %}
        {% block content %} -->

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
            <!-- {% include "_back-button.html" %} -->
            <back-button />
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium" v-if="favoriteComponents != null">
            <!-- {% import "macros/_list-view-titles.html" as list_view_title with context %}
            {{ list_view_title.generic_title('Favorites', favorites.length, 'custom', 'custom1') }} -->
            <list-view-titles :type="'generic'" 
                                :label="'Favorites'" 
                                :size="initialFavouriteCount"
                                :iconLib="'custom'" 
                                :iconId="'custom1'"/>
        </section>

        <section class="slds-wrap slds-grid slds-col slds-size_1-of-1 slds-m-bottom_xx-large" v-if="favoriteComponents != null && favoriteComponents.length > 0">
            <div class = "slds-col slds-size_1-of-1 slds-m-bottom_medium">
                <!-- {% include "_list-filters.html" %} -->
                <list-filters :values="listFilterValues"
                    :showSortBy="false" 
                    v-on:listFilterChange="filtersChanged"/>
            </div>


            <div class="slds-col slds-size_1-of-1">
                <!-- {% import "macros/_demo-comp-list.html" as dc_listing with context %}
                {{ dc_listing.list(favorites) }} -->

                <demo-cmp-list :demoComponents="displayedComponents"
                    :paginationSettings="paginationSettings"
                    v-on:paginationSettingsChanged="paginationSettingsChanged" />

            </div>

        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_x-large" v-if="favoriteComponents != null && favoriteComponents.length == 0">
            <div class="slds-illustration slds-illustration_large">
                <!-- {{ illustrations.open_chest() }} -->
                <illustrations :image="'open_chest'" />
                <div class="slds-text-longform">
                    <h3 class="slds-text-heading_medium">No favorites yet?</h3>
                    <p class="slds-text-body_regular">
                        You can favorite the Demo Components you like to access them easily next time.<br />
                        Click on
                        <span class="slds-show_inline-block">
                            <span class="favorite_inline-action nowrap noselect">
                                <span class="slds-icon_container slds-m-horizontal_xx-small" title="Favorite">
                                    <svg class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true" viewBox="0 0 24 24">
                                        <use xlink:href="/assets/images/custom-icons-sprite.svg#favoriteShape" clip-path="url(#favoriteClip)" />
                                    </svg>
                                    <span class="slds-assistive-text">Favorite icon</span>
                                </span>
                            </span>
                        </span>
                        to favorite demo components.
                    </p>
                </div>
            </div>
        </section>

        <section class="slds-col slds-size_1-of-1">
            <!-- {% import "macros/_carousels.html" as carousels with context %}
            {#
            {{ carousels.generic_carousel('recommendations', recommendations, 'Recommended for you', true, true, false) }}
            #}
            {{ carousels.generic_carousel('favorited', [2,0,4,5,9,3,1,10,11,17,13,20,7,14,8], 'Most Favorited', false, false, false) }} -->
            <carousels :type="'generic'" 
                        :title="'Most Favorited'"
                        :demoComponents="mostFavoritedComponents"
                        :einstein="false"
                        :hasViewAll="false"
                        :fullPage="true"
                        v-on:favorite-clicked="favoritedClicked" />
        </section>

        <!-- {% include "_demo-cmp-share-modal.html" %}
        {% include "_install-destination-modal.html" %}
        {% include "_install-prompt.html" %}
        {% include "_install-success-modal.html" %}
        {% endblock %} -->

    </div>
</template>

<script>
    import Illustrations from "./macros/Illustrations.vue";
    import BackButton from "./BackButton.vue";
    import ListFilters from "./ListFilters.vue";
    import ListViewTitles from "./macros/ListViewTitles.vue";
    import axios from "axios";
    import { ApiEndpoints } from "../mixins/ApiEndpoints";
    import DemoCmpList from './macros/DemoCmpList.vue'
    import Carousels from './macros/Carousels.vue'

    export default {
        name: 'FavoritesDetail',
        mixins: [ ApiEndpoints ],
        data() {
            return {
                demoComponents: null,
                filteredComponents: null,
                displayedComponents: null,
                listFilterValues: {
                    'sortBy': 'Trending',
                    'clouds': '',
                    'features': '',
                    'industries': '',
                    'stars': null
                },
                paginationSettings: {
                    "pagesSizeOptions": [8, 16, 32, 64],
                    "currentPageSizeOption": 8,
                    'totalDemoComponentCount': 1,
                    'currentPage': 1,
                    "totalPages": 1
                },
                initialFavouriteCount: 0
            }
        },
        props: {
        
        },
        computed: {
            accessToken() { return this.$store.getters.getAccessToken; },
            favoriteComponents() { return this.$store.getters.getFavoriteComponents; },
            mostFavoritedComponents() { return this.$store.getters.getMostFavoritedComponents; },
        },
        components: {
            illustrations: Illustrations,
            backButton: BackButton,
            listViewTitles: ListViewTitles,
            listFilters: ListFilters,
            demoCmpList: DemoCmpList,
            carousels: Carousels
        },
        methods: {
            doInit() {
                this.getMostFavoritedComponents();
                if(this.favoriteComponents == null) {
                    this.getFavoriteComponents();
                } else {
                    this.demoComponents = JSON.parse(JSON.stringify(this.favoriteComponents));
                    this.filteredComponents = JSON.parse(JSON.stringify(this.favoriteComponents));
                    this.displayedComponents = JSON.parse(JSON.stringify(this.favoriteComponents));
                    this.initialFavouriteCount = this.demoComponents.length;
                    this.setThePaginationSettings();
                }
            },
            setThePaginationSettings() {
                this.paginationSettings.totalPages = Math.floor(this.filteredComponents.length / this.paginationSettings.currentPageSizeOption);
                if((this.filteredComponents.length % this.paginationSettings.currentPageSizeOption) > 0) {
                    this.paginationSettings.totalPages++;
                }
                this.paginationSettings.totalDemoComponentCount = this.filteredComponents.length;
                this.setTheFilteredComponentsByPagination();
            },
            setTheFilteredComponentsByPagination() {
                var firstIndex = ((this.paginationSettings.currentPage - 1) * this.paginationSettings.currentPageSizeOption);
                var secondIndex = this.paginationSettings.currentPage * this.paginationSettings.currentPageSizeOption;
                if(secondIndex > this.paginationSettings.totalDemoComponentCount) {
                    secondIndex = this.paginationSettings.totalDemoComponentCount;
                }

                var tmpCmps = JSON.parse(JSON.stringify(this.filteredComponents));
                this.displayedComponents = tmpCmps.slice(firstIndex, secondIndex);
            },
            paginationSettingsChanged(input) {
                this.paginationSettings = input;
                this.paginationSettings = input;
                this.setThePaginationSettings();
            },
            filtersChanged(input) {
                this.listFilterValues = input;
                this.paginationSettings.currentPage = 1;
                this.filteredComponents = this.filterComponents();
                this.setThePaginationSettings();
            },
            filterComponents() {
                var retArr = [];
                var cloudsArr = (this.listFilterValues.clouds != null && this.listFilterValues.clouds != '') ? this.listFilterValues.clouds.split(';') : [];
                var featuresArr = (this.listFilterValues.features != null && this.listFilterValues.features != '') ? this.listFilterValues.features.split(';') : [];
                var industriesArr = (this.listFilterValues.industries != null && this.listFilterValues.industries != '') ? this.listFilterValues.industries.split(';') : [];

                for(var i = 0; i < this.demoComponents.length; i++) {
                    var cloudsCmp = (this.demoComponents[i].cloud__c != null) ? this.demoComponents[i].cloud__c.split(';') : [];
                    var featuresCmp = (this.demoComponents[i].feature__c != null) ? this.demoComponents[i].feature__c.split(';') : [];
                    var industriesCmp = (this.demoComponents[i].industry__c != null) ? this.demoComponents[i].industry__c.split(';') : [];

                    var hasClouds = (cloudsArr.length > 0) ? this.compareArrays(cloudsCmp, cloudsArr) : true;
                    var hasFeatures = (featuresCmp.length > 0) ? this.compareArrays(featuresCmp, featuresArr) : true;
                    var hasIndustries = (industriesArr.length > 0) ? this.compareArrays(industriesCmp, industriesArr) : true;
                    var hasStarRating = (this.listFilterValues.stars != null) ? (this.demoComponents[i].rating_average__c != null && this.demoComponents[i].rating_average__c >= this.listFilterValues.stars) : true;

                    if(hasClouds && hasFeatures && hasIndustries && hasStarRating) {
                        retArr.push(this.demoComponents[i]);
                    }
                }
                return retArr;
            },
            compareArrays(arr1, arr2) {
                return arr2.every(v => arr1.includes(v));
            },
            favoritedClicked(demoCmp) {
                console.log('favoritedClicked 2 :: ' , demoCmp);

                var mostFavorited = JSON.parse(JSON.stringify(this.mostFavoritedComponents));
                for(var i = 0; i < mostFavorited.length; i++) {
                    if(mostFavorited[i].sfid == demoCmp.sfid) {
                        mostFavorited[i].is_favourite = !mostFavorited[i].is_favourite;
                        if(mostFavorited[i].is_favourite) {
                            mostFavorited[i].number_of_favorites__c = mostFavorited[i].number_of_favorites__c + 1;
                        } else {
                            mostFavorited[i].number_of_favorites__c = ((mostFavorited[i].number_of_favorites__c - 1) > 0) ? mostFavorited[i].number_of_favorites__c - 1 : 0;
                        }
                    }
                }
                this.$store.commit('setMostFavoritedComponents', mostFavorited);


                // THIS IS NOT COMPLETE!!
                var self = this;
                axios.post("/v1/favoriteComponent", JSON.stringify({ token: this.accessToken, componentId: demoCmp.sfid, favorite: !demoCmp.is_favourite })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        
                    } else {
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    console.log(err);
                });



            }
        },
        created() {
            this.doInit();
        },
        watch: {
            favoriteComponents(newVal, oldVal) {
                if(oldVal == null && newVal != null) {
                    this.demoComponents = JSON.parse(JSON.stringify(newVal));
                    this.filteredComponents = JSON.parse(JSON.stringify(newVal));
                    this.displayedComponents = JSON.parse(JSON.stringify(newVal));
                    this.initialFavouriteCount = this.demoComponents.length;
                    this.setThePaginationSettings();
                }
            }
        }
    }
</script>

