<template>
    
    <!-- {% macro single_line(theme='success', id, body) %} -->
    <div class="slds-notify_container slds-is-fixed" v-show="showToast">
        <div :class="'slds-notify slds-notify_toast slds-theme_' + toastState" role="status">
            <span class="slds-assistive-text">{{ toastState }}</span>
            <span :class="'slds-icon_container slds-icon-utility-' + toastState + ' slds-m-right_small slds-no-flex slds-align-top'" :title="toastMessage">
                <svg class="slds-icon slds-icon_small" aria-hidden="true">
                    <use :xlink:href="'/assets/icons/utility-sprite/svg/symbols.svg#' + toastState"></use>
                </svg>
            </span>
            <div class="slds-notify__content">
                <h2 class="slds-text-heading_small ">{{ toastMessages[toastMessage] }}</h2>
            </div>
            <div class="slds-notify__close">
                <button class="slds-button slds-button_icon slds-button_icon-inverse" title="Close" v-on:click="closeToast()">
                    <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                    </svg>
                    <span class="slds-assistive-text">Close</span>
                </button>
            </div>
        </div>
    </div>
    <!-- {% endmacro %} -->

</template>

<script>
    import { setTimeout, setInterval, clearInterval, clearTimeout } from 'timers';
    import toast_messages from '../../json/toast_messages.json';

    export default {
        name: 'Toasts',
        data: function() {
            return {
                countdown: 0,
                showToast: false,
                toastState: '', // info, success, error, warning
                toastMessage: '',
                toastLengthInSeconds: 0
            };
        },
        methods: {
            showTheToast() {
                this.countdown = this.toastLengthInSeconds;
                if(this.showToast == false) {
                    this.showToast = true;
                    var self = this;
                    var selfInterval = setInterval(function() {
                        self.countdown = self.countdown - 1;
                        if(self.countdown < 0) {
                            clearInterval(selfInterval);
                            self.showToast = false;
                        }
                    }, 1000);
                }
            },
            closeToast() {
                this.showToast = false;
                this.countdown = 0;
            },
            undoData() {
                this.closeToast();
            }
        },
        computed: {
            toastMessages() { return toast_messages; },
            toastObject() {
                return this.$store.getters.getAllPurposeToastObject;
            }
        },
        watch: {
            toastObject: function(newVal, oldVal) {
                if(newVal != null) {
                    this.toastState = newVal['toastState'];
                    this.toastMessage = newVal['toastMessage'];
                    this.toastLengthInSeconds = newVal['toastLengthInSeconds'];
                    this.showTheToast();
                }
                
            }
        }
    }
</script>

