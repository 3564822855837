<template>
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    
    <div v-show="!hideComponent">
        <!-- {% import "macros/_demo-cmp-list-item.html" as dc_list_item with context %} -->

        <h2 class="slds-text-heading_small slds-m-bottom_x-small">Please Rate the Demo Components You Installed Recently</h2>

        <!-- <div class="slds-grid slds-wrap slds-gutters_direct-x-small"> -->
        <transition-group name="list" tag="div" class="slds-grid slds-wrap slds-gutters_direct-x-small">
            <!-- {% for index in [18, 14, 8] %} -->
            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-m-bottom_small" v-for="(obj,i) in tmpComponents" :key="obj">
                <!-- {{ dc_list_item.rating_card(demo_components[index]) }} -->
                <demo-cmp-list-item :demoComponent="obj" 
                    :type="'rating'"
                    :ratingCardIndex="i" 
                    v-on:openRatingModal="openRatingModalHandler" 
                    v-on:close-card="closeCard"/>
            </div>
            <!-- {% endfor %} -->
        </transition-group>
        <!-- </div> -->

        <div v-if="tmpComponents == null" class="slds-grid slds-wrap slds-gutters_direct-x-small">
            <spinner :size="'small'"/>
        </div>

        <rating-modal v-if="showModal" :demoComponent="currentDemoComponent" :ratingValue="currentRatingValue" v-on:closeModal="closeModal" v-on:submit="submitRating"/>
    </div>
</template>

<script>
    import axios from "axios";
    import DemoCmpListItem from './macros/DemoCmpListItem.vue'
    import RatingModal from './macros/RatingModal.vue'
    import Spinner from './macros/Spinner.vue'

    export default {
        name: 'RatingCards',
        data() {
            return {
                showModal: false,
                currentDemoComponent: null,
                currentRatingValue: 0,
                hideComponent: false,

                tmpComponents: null
            }
        },
        components: {
            demoCmpListItem: DemoCmpListItem,
            ratingModal: RatingModal,
            spinner: Spinner
        },
        computed: {
            accessToken() { return this.$store.getters.getAccessToken; },
            recentlyUsedComponents() { return this.$store.getters.getRecentlyUsedComponents; }
        },
        methods: {
            openRatingModalHandler(starValue, cmp) {
                this.currentRatingValue = starValue;
                this.currentDemoComponent = cmp;
                this.showModal = true;
            },
            closeModal() {
                this.showModal = false;
            },
            closeCard(indexVal) {
                this.tmpComponents.splice(indexVal, 1);
                if(this.tmpComponents.length == 0) {
                    this.hideComponent = true;
                }
            },
            submitRating(ratingValue, feedbackText) {
                this.closeModal();
                var removeIndex = this.getArrayIndexBySFID(this.currentDemoComponent, this.tmpComponents);
                this.closeCard(removeIndex);
                this.setFeedback(ratingValue, feedbackText);
            },
            setFeedback(starRating, feedbackText) {
                var self = this;
                axios.post("/v1/postFeedback", JSON.stringify({ token: this.accessToken, componentId: this.currentDemoComponent.sfid, rating: starRating, comment: feedbackText })).then((res) => {
                    if (!res.data.error) {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'success', toastMessage: 'FEEDBACK_SUCCESS', toastLengthInSeconds: 5 });
                        self.currentDemoComponent = null;
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'FEEDBACK_ERROR', toastLengthInSeconds: 10 });
                        self.currentDemoComponent = null;
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'FEEDBACK_ERROR', toastLengthInSeconds: 10 });
                    console.log(err);
                });
            },
            getArrayIndexBySFID(demoCmp, demoCmpArray) {
                for(var i = 0; i < demoCmpArray.length; i++) {
                    if(demoCmpArray[i]['sfid'] === demoCmp.sfid) {
                        return i;
                    }
                }
                return -1;
            }
        },
        created() {
            if(this.recentlyUsedComponents != null) {
                var retVal = [];
                for(var i = 0; i < this.recentlyUsedComponents.length; i++) {
                    retVal.push(this.recentlyUsedComponents[i]);
                }
                this.tmpComponents = retVal.slice(0, 3);
            }
        },
        watch: {
            recentlyUsedComponents(newVal, oldVal) {
                var retVal = [];
                for(var i = 0; i < newVal.length; i++) {
                    retVal.push(newVal[i]);
                }
                this.tmpComponents = retVal.slice(0, 3);
            }
        }
    }
</script>


<style scoped>
    .list-enter-active, .list-leave-active {
        transition: all 0.5s;
    }
    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(50px);
        /* transform: translateX(-100px); */
    }
</style>
