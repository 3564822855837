<template>

    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    <div v-show="!hideComponent">
        <div id="not-relevant_popover" class="slds-popover slds-popover_tooltip slds-nubbin_bottom slds-is-absolute slds-rise-from-ground" role="tooltip" v-bind:style="{ display: tooltipDisplay, top: tooltipTop, left: tooltipLeft }">
            <div class="slds-popover__body">Not Relevant</div>
        </div>

        <div class="slds-grid slds-grid_vertical-align-center slds-gutters_direct-xx-small slds-m-bottom_x-small">
            <h2 class="slds-col slds-text-heading_small">{{ title }}</h2>
            <span class="slds-media slds-media_center slds-media_small slds-m-left_x-small slds-show_medium" v-if="einstein">
                <div class="slds-media__figure">
                    <span class="slds-icon_container" title="Powered by Einstein">
                        <img alt="Einstein icon" class="slds-icon slds-icon_small" src="/assets/images/einstein.svg" title="Powered by Einstein" />
                    </span>
                </div>
                <div class="slds-media__body">
                    <span class="slds-col slds-text-body_small slds-text-color_weak">Powered by Einstein</span>
                </div>
            </span>
            <!-- <a class="slds-col slds-col_bump-left" href="./recommended-for-you.html" v-if="hasViewAll">View All</a> -->
            <router-link to="/recommended" class="slds-col slds-col_bump-left" v-if="hasViewAll">
                View All
            </router-link>
        </div>

        <div class="slds-grid slds-grid_vertical-align-center slds-gutters_direct-xx-small slds-m-bottom_x-small" v-if="tmpDemoComponents == null">
            <spinner :size="'small'" />
        </div>

        <div :id="title + '_carousel'" class="slds-carousel">
            <div class="slds-carousel__stage">
                <transition-group name="list" tag="div" class="slds-carousel__panels slds-is-relative slds-grid slds-gutters_direct-x-small" v-bind:style="{ transform: activePanelTransform }">
                    <div :id="title + '-id-' + i" :class="fullPage ? 'slds-col slds-carousel__panel slds-size_1-of-1 slds-medium-size_1-of-5 list-item' : 'slds-col slds-carousel__panel slds-size_1-of-1 slds-medium-size_1-of-3 list-item'" role="tabpanel" aria-hidden="false" v-for="(obj, i) in tmpDemoComponents" v-bind:key="obj">
                        <div class="slds-carousel__panel-action slds-scrollable_none slds-container_fluid slds-is-relative">
                            <div class="not-relevant_action slds-is-absolute slds-container_fluid slds-grid slds-grid_align-end slds-p-bottom_medium slds-show_medium" v-if="einstein">
                                <!-- v-on:mouseover="showTooltip" v-on:mouseout="hideTooltip" -->
                                <button class="slds-col slds-button slds-button_icon slds-m-around_xxx-small" title="Not Relevant" v-on:mouseover="showTooltip" v-on:mouseout="hideTooltip" @click="notRelevantClicked(i)">
                                    <svg class="slds-button__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                    </svg>
                                    <span class="slds-assistive-text">Not Relevant</span>
                                </button>
                            </div>
                            <div class="panel_image slds-border_bottom">
                                <figure class="slds-image slds-theme_shade">
                                    <span class="slds-image__crop slds-image__crop_16-by-9">
                                        <img v-if="obj.image_url__c == null || obj.image_url__c == ''" src="/assets/images/placeholder.png" :alt="obj.name" />
                                        <img v-if="obj.image_url__c != null && obj.image_url__c != ''" :src="obj.image_url__c" :alt="obj.name" />
                                    </span>
                                </figure>
                            </div>
                            <div class="panel_detail slds-m-horizontal_small slds-m-top_small">
                                <!-- <a href="./demo-cmp-detail.html" class="" tabindex="0">
                                    <h3 class="slds-text-heading_small slds-m-bottom_xx-small clamp-lines_title">{{ obj.name }}</h3>
                                </a> -->
                                <router-link :to="{ name: 'DemoCmpDetail', params: { sfid: obj.sfid }}">
                                    <h3 class="slds-text-heading_small slds-m-bottom_xx-small clamp-lines_title">{{ obj.name }}</h3>
                                </router-link>
                                <ul class="slds-list_horizontal slds-has-dividers_left slds-text-body_small slds-m-bottom_xx-small">
                                    <!-- <li class="slds-item slds-has-flexi-truncate">by &nbsp; <a href="javascript:void(0);" class="author slds-truncate" :title="obj.author__c" style="padding-left: 3px;"> {{ obj.author__c }}</a></li> -->

                                    <li class="slds-item slds-has-flexi-truncate">by &nbsp;
                                        <!-- <router-link :to="{ name: 'CreatedByOthersDetail', params: { sfid: obj.ownerid }}" class="author slds-truncate" style="padding-left: 3px;">
                                            {{ obj.author__c }}
                                        </router-link> -->
                                        <user-link :userName="obj.author__c" :userSFID="obj.ownerid" :cmpClass="'author slds-truncate'" :cmpStyle="'padding-left: 3px;'" />
                                    </li>
                                </ul>
                                <ul class="slds-list_horizontal slds-grid_vertical-align-center slds-wrap">
                                    <li class="slds-m-right_large nowrap slds-m-bottom_x-small">
                                        <span class="rating_inline-action nowrap">
                                            <rating-stars :demoComponent="obj" :isStatic="true" :size="'x-small'" />
                                            <span class="slds-text-body_small slds-text-color_weak slds-p-left_x-small">{{ obj.review_count__c }}</span>
                                        </span>
                                    </li>
                                    <li class="slds-m-bottom_x-small">
                                        <inline-actions v-if="title == 'Most Favorited'" :demoComponent="obj" :type="'favorite'" :isFavorite="obj.is_favourite" v-on:favoriteClicked="favoriteComponent(obj)" />
                                        <inline-actions v-if="title != 'Most Favorited'" :demoComponent="obj" :type="'install'" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </transition-group>

                <ul class="slds-carousel__indicators " role="tablist" v-if="tmpDemoComponents != null">
                    <li class="slds-carousel__indicator" role="presentation">
                        <a id="indicator-id-01" :class="activePanel == 0 ? 'slds-carousel__indicator-action slds-is-active' : 'slds-carousel__indicator-action'" href="javascript:void(0);" role="tab" tabindex="0" aria-selected="true" :aria-controls="title + '-id-0'" :title="title + ' Panel #1'" data-panel="0" v-on:click="indicatorClicked(0)">
                            <span class="slds-assistive-text">{{ title }} Panel #1</span>
                        </a>
                    </li>
                    <li class="slds-carousel__indicator" role="presentation">
                        <a id="indicator-id-02" :class="activePanel == 1 ? 'slds-carousel__indicator-action slds-is-active' : 'slds-carousel__indicator-action'" href="javascript:void(0);" role="tab" tabindex="-1" aria-selected="false" :aria-controls="title + '-id-1'" :title="title + ' Panel #2'" data-panel="1" v-show="!(demoComponents.length <= thresholdOne)" v-on:click="indicatorClicked(1)">
                            <span class="slds-assistive-text">{{ title }} Panel #2</span>
                        </a>
                    </li>
                    <li class="slds-carousel__indicator" role="presentation">
                        <a id="indicator-id-03" :class="activePanel == 2 ? 'slds-carousel__indicator-action slds-is-active' : 'slds-carousel__indicator-action'" href="javascript:void(0);" role="tab" tabindex="-1" aria-selected="false" :aria-controls="title + '-id-2'" :title="title + ' Panel #3'" data-panel="2" v-show="!(demoComponents.length <= thresholdTwo)" v-on:click="indicatorClicked(2)">
                            <span class="slds-assistive-text">{{ title }} Panel #3</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    import InlineActions from './InlineActions.vue'
    import RatingStars from './RatingStars.vue'
    import Spinner from './Spinner.vue'
    import UserLink from './UserLink.vue'
    import axios from "axios";

    export default {
        name: 'Carousels',
        data() {
            return {
                activePanel: 0,
                activePanelTransform: 'translateX(-0%)',

                tooltipTop: 0,
                tooltipLeft: 0,
                tooltipDisplay: 'none',

                tmpDemoComponents: null,
                hideComponent: false,
            }
        },
        props: {
            type: String, // generic / bundle
            title: String,
            demoComponents: Array,
            einstein: Boolean,
            hasViewAll: Boolean,
            fullPage: Boolean, // 5 across if true, 3 otherwise
        },
        components: {
            inlineActions: InlineActions,
            ratingStars: RatingStars,
            spinner: Spinner,
            userLink: UserLink
        },
        methods: {
            indicatorClicked(val) {
                this.activePanel = val;
                this.activePanelTransform = 'translateX(' + val * -100 + '%)';
            },
            showTooltip(event) {
                this.tooltipTop = ((event.pageY - event.offsetY) - 46) + 'px';
                this.tooltipLeft = ((event.pageX - event.offsetX) - 40) + 'px';
                this.tooltipDisplay = 'block';
            },
            hideTooltip() {
                this.tooltipDisplay = 'none';
            },
            notRelevantClicked(indexVal) {
                this.hideTooltip();
                var notRelevantComponent = this.tmpDemoComponents[indexVal];
                this.tmpDemoComponents.splice(indexVal, 1);
                if (this.tmpDemoComponents.length == 0) {
                    this.hideComponent = true;
                }
                if ((this.activePanel > 0) && ((this.activePanel * this.thresholdOne == this.tmpDemoComponents.length) || (this.activePanel * this.thresholdOne == this.tmpDemoComponents.length))) {
                    var self = this;
                    setTimeout(function () {
                        self.indicatorClicked(self.activePanel - 1);
                    }, 500);
                }
                this.callNotRelevant(notRelevantComponent)
            },
            callNotRelevant(cmp) {
                console.log('callNotRelevant :: ', cmp);
                var payload = {
                    token: this.accessToken,
                    dc_id: cmp.sfid
                };
                axios.post("/v1/notRelevant", JSON.stringify(payload)).then(res => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('SUCCESSFULLY SET COMPONENT TO NOT RELEVANT');
                    } else {
                        console.log('Error :: callNotRelevant :: ' + res.data.data.error);
                    }
                }).catch(err => {
                    console.log('Exception :: callNotRelevant :: ' + err);
                });
            },
            favoriteComponent(demoCmp) {
                console.log('favoriteComponent :: ', demoCmp);
                this.$emit('favorite-clicked', demoCmp);
            }
        },
        created() {
            if (this.demoComponents != null) {
                this.tmpDemoComponents = this.demoComponents;
            }
        },
        computed: {
            accessToken() {
                return this.$store.getters.getAccessToken;
            },
            thresholdOne() {
                return this.fullPage ? 5 : 3;
            },
            thresholdTwo() {
                return this.fullPage ? 10 : 6;
            }
        },
        watch: {
            demoComponents(newVal, oldVal) {
                this.tmpDemoComponents = newVal;

                if (newVal != null && newVal.length == 0) {
                    this.hideComponent = true;
                }
            }
        }
    }
</script>


<style scoped>
    /* .list-enter-active, .list-leave-active {
        transition: all 0.5s;
    } */
    /* .list-enter, .list-leave-to {
        opacity: 0;
        transform: translateY(50px);
    } */

    .list-leave-active {
        transition: all 0.5s;
    }

    .list-leave-to {
        opacity: 0;
        transform: translateY(50px);
    }
</style>