<template>
    <div>
        <!-- dashboard goes here -->

        <button v-on:click="doTheThing('Male')" style="margin-right: 20px;">Show me Men </button>
        <button v-on:click="doTheThing('Female')">Show me Women </button>

        <div id="dashboard" style="width: 100%; Height: 650px;"></div>

    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: 'Dashboard',
        props: {
        
        },
        methods: {
            doTheThing(gender) {
                axios.get("/v1/getAccessTokenForAnalytics").then(res => {
                    if (res.data.success) {
                        console.log('TOKEN: ' + res.data.data);

                        var url = 'https://coshea-lightning-out.lightning.force.com';
                        var appName = 'c:cosheaDashboardApp';
                        var self = this;
			            $Lightning.use(appName, function() {
                            console.log('Did the $Lightning.use stuff');
                            self.createComponent(res.data.data, gender);
                        }, url, res.data.data);
                    } else {
                        console.log('RAN INTO AN ISSUE');
                    }
                }).catch(err => {
                    console.log(err);
                    // this.showToast('error', 'Error Gettings Icons', err, 10);
                });
            },
            createComponent(token, gender) {
                console.log('createComponent called');
                var filterString = "{'datasets':{'coshea_mock_data':[{'fields':['gender'], 'filter':{'operator': 'in', 'values':['" + gender + "']}}]}}}";
                $Lightning.createComponent("c:cosheaDashboardComponent", { 
                    accessToken: token,
                    filterString: filterString
                }, "dashboard" , function(cmp, msg, err) {
                    console.log('DONE:: ' + cmp + ' : ' + msg + ' : ' + err);
                }); 
            }
        }
    }
</script>

