<template>
    <div>
        <section id="markdown-import_modal" role="alertdialog" tabindex="-1" aria-labelledby="markdown-import_modal-heading" aria-modal="true" aria-describedby="markdown-preview_modal-content" class="slds-modal slds-fade-in-open slds-modal_prompt">
            <div class="slds-modal__container">
                <header class="slds-modal__header slds-theme_warning slds-theme_alert-texture">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="$emit('closeModal')">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                    <h2 class="slds-text-heading_medium slds-hyphenate" id="markdown-import_modal-heading">Import README</h2>
                </header>
                <div class="slds-modal__content slds-p-around_medium" id="markdown-import_modal-content">
                    <p>
                        You are about to pull content from the <em>README.md</em> file of the following GitHub repo:
                        <a :href="packageUrl" target="_blank">{{packageUrl}}</a>
                        <br/><br/>
                        This will <strong>override any pending changes</strong> made to the Demo Component description.
                        <br/>
                        <em>Note: some elements may not function/render as expected. Please review imported markdown.</em>
                        <br/><br/>
                        Are you sure you want to import this content?
                    </p>
                </div>
                <footer class="slds-modal__footer slds-theme_default">
                    <button class="slds-button slds-button_neutral" @click="$emit('closeModal')">Cancel</button>
                    <button class="slds-button slds-button_brand" @click="$emit('importReadme')">Override description</button>
                </footer>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    export default {
        name: 'MarkdownImportModal',
        props: {
            packageUrl: String
        },
        components: {

        },
        methods: {
            
        },
        created() {

        }
    }
</script>

