<template>

    <div>
        <!-- {% macro open_chest() %} -->
        <svg v-if="image == 'open_chest'" class="slds-illustration__svg" viewBox="0 0 454 213" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-70.000000, -94.000000)">
                    <g>
                        <g transform="translate(124.500000, 222.000000)">
                            <g fill="#FFFFFF">
                                <path vector-effect="non-scaling-stroke"
                                    d="M18.9209988,1.95433401 L33.259296,51.443436 C33.5666778,52.5043744 32.9557995,53.613617 31.8948612,53.9209988 C31.7139843,53.9734036 31.5266126,54 31.3382972,54 L2.6617028,54 C1.5571333,54 0.661702805,53.1045695 0.661702805,52 C0.661702805,51.8116846 0.688299176,51.6243129 0.74070397,51.443436 L15.0790012,1.95433401 C15.386383,0.893395645 16.4956256,0.282517358 17.556564,0.589899164 C18.2152102,0.780726338 18.7301717,1.29568777 18.9209988,1.95433401 Z">
                                </path>
                            </g>
                            <g class="slds-illustration__stroke-secondary" stroke-linecap="round" stroke-width="3">
                                <polygon vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="17 0.323943662 34 54 -1.81721305e-12 54"></polygon>
                                <path vector-effect="non-scaling-stroke"
                                    d="M17,4.6953125 C17,43.0456294 17,62.6471919 17,63.5 C17,62.6471919 17,43.0456294 17,4.6953125 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M17,29.3239437 C22.3333333,35.7851611 25,39.1184944 25,39.3239437 C25,39.1184944 22.3333333,35.7851611 17,29.3239437 Z"
                                    stroke-linejoin="round"
                                    transform="translate(21.000000, 34.323944) scale(-1, 1) translate(-21.000000, -34.323944) ">
                                </path>
                            </g>
                        </g>
                        <g transform="translate(145.000000, 194.000000)">
                            <g transform="translate(1.000000, 0.000000)" fill="#FFFFFF">
                                <path vector-effect="non-scaling-stroke"
                                    d="M25.6478873,0 L50.879042,84.4273253 C51.1953215,85.4856452 50.5937789,86.5999782 49.535459,86.9162577 C49.3496374,86.9717906 49.1567264,87 48.9627843,87 L2.33299037,87 C1.22842087,87 0.332990367,86.1045695 0.332990367,85 C0.332990367,84.8060578 0.361199757,84.6131469 0.416732643,84.4273253 L25.6478873,0 Z">
                                </path>
                            </g>
                            <g class="slds-illustration__stroke-secondary" stroke-linecap="round" stroke-width="3">
                                <polygon vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="26.5 0 52.5 87 0.5 87"></polygon>
                                <path vector-effect="non-scaling-stroke"
                                    d="M26.5,2.58642578 C26.5,61.0261034 26.5,90.9972948 26.5,92.5 C26.5,90.9972948 26.5,61.0261034 26.5,2.58642578 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M15.6478873,42 C22.314554,49.078692 25.6478873,52.7453587 25.6478873,53 C25.6478873,52.7453587 22.314554,49.078692 15.6478873,42 Z"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M27.6478873,68 C36.9812207,57.078692 41.6478873,51.7453587 41.6478873,52 C41.6478873,51.7453587 36.9812207,57.078692 27.6478873,68 Z"
                                    stroke-linejoin="round"></path>
                            </g>
                        </g>
                        <g transform="translate(404.500000, 245.000000) scale(-1, 1) translate(-404.500000, -245.000000) translate(348.000000, 226.000000)"
                            class="slds-illustration__stroke-secondary" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="3">
                            <polyline vector-effect="non-scaling-stroke" points="0 38 47.5 0 80.5 26"></polyline>
                            <polyline vector-effect="non-scaling-stroke" points="71 17 80.5 9 113 36"></polyline>
                        </g>
                        <g transform="translate(72.000000, 262.500000)">
                            <path vector-effect="non-scaling-stroke"
                                d="M153.962142,26.4644491 C151.225735,20.0143094 144.944776,15.5029106 137.633892,15.5029106 C135.619663,15.5029106 133.683612,15.8453541 131.878328,16.4764392 C128.451481,11.1704266 122.567406,7.66985447 115.883789,7.66985447 C109.491267,7.66985447 103.830159,10.8721423 100.350851,15.7935668 C98.9589956,14.968161 97.3423157,14.4956341 95.6177606,14.4956341 C94.1083143,14.4956341 92.6815102,14.8576334 91.4157672,15.5014039 C87.9975328,6.58722215 79.5098304,0.275259875 69.5804557,0.275259875 C60.4632836,0.275259875 52.5615782,5.59684366 48.6837305,13.3681823 C46.3912034,12.266973 43.8314865,11.6515593 41.1312741,11.6515593 C32.4373504,11.6515593 25.1998844,18.0312998 23.6476214,26.4644491 L153.962142,26.4644491 Z"
                                class="slds-illustration__fill-secondary"></path>
                            <path vector-effect="non-scaling-stroke" d="M13,25 L143,25"
                                class="slds-illustration__stroke-secondary" stroke-width="3" stroke-linecap="round">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M0,25 L450,25"
                                class="slds-illustration__stroke-secondary" stroke-width="3" stroke-linecap="round">
                            </path>
                        </g>
                        <g transform="translate(344.000000, 247.000000)">
                            <g transform="translate(0.293436, 0.054545)">
                                <path vector-effect="non-scaling-stroke"
                                    d="M165.428708,41.9454545 L0.0995432562,41.9454545 C0.0336614956,41.2089487 0,40.4630069 0,39.7090909 C0,26.2132599 10.7866531,15.2727273 24.0926641,15.2727273 C27.7492016,15.2727273 31.215485,16.0989227 34.3199502,17.5772977 C39.5712028,7.14424616 50.271428,0 62.6175975,0 C76.0636257,0 87.5573893,8.47383452 92.1862485,20.441159 C93.9002755,19.5768947 95.8324059,19.0909091 97.8764479,19.0909091 C100.211783,19.0909091 102.401037,19.7252784 104.285841,20.8333889 C108.997403,14.2263569 116.663488,9.92727273 125.320028,9.92727273 C138.043441,9.92727273 148.627152,19.2146805 150.834755,31.4671412 C151.487388,31.3631046 152.156394,31.3090909 152.837838,31.3090909 C159.117096,31.3090909 164.340238,35.8953699 165.428708,41.9454545 Z"
                                    class="slds-illustration__fill-secondary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M32.7065637,40.4454545 L173.706564,40.4454545"
                                    class="slds-illustration__stroke-secondary" stroke-width="3" stroke-linecap="round">
                                </path>
                            </g>
                        </g>
                        <g transform="translate(105.000000, 189.000000)">
                            <g transform="translate(106.000000, 48.000000)" fill="#FFFFFF">
                                <polygon vector-effect="non-scaling-stroke"
                                    points="121.5 14.5 158.5 14.5 158.5 0.5 47.5 0.5 47.5 14.5 93.5 14.5 93.5 35.5 121.5 35.5 121.5 14.5">
                                </polygon>
                                <rect x="0" y="0" width="48" height="14"></rect>
                            </g>
                            <g transform="translate(106.000000, 62.000000)" class="slds-illustration__fill-secondary">
                                <polygon vector-effect="non-scaling-stroke"
                                    points="93.3109375 0.456640625 93.3109375 21.6722656 120.925 21.6722656 121.823047 1.13242188 158.5 0.456640625 158.5 54.5 48.5 54.5 48.5 0.693359375">
                                </polygon>
                                <rect x="0.5" y="0.5" width="48" height="54"></rect>
                            </g>
                            <g>
                                <rect class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round" x="154.5" y="48.5" width="110" height="68"></rect>
                                <polygon vector-effect="non-scaling-stroke" class="slds-illustration__stroke-primary"
                                    stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                    points="264.5 62.5 264.5 48.5 154.5 48.5 154.5 62.5 199.5 62.5 199.5 83.5 227.5 83.5 227.5 62.5">
                                </polygon>
                                <path vector-effect="non-scaling-stroke"
                                    d="M96.5,7.5 L96.5,7.5 C109.754834,7.5 120.5,18.245166 120.5,31.5 L120.5,41.5 L72.5,41.5 L72.5,31.5 C72.5,18.245166 83.245166,7.5 96.5,7.5 Z"
                                    class="slds-illustration__fill-secondary"
                                    transform="translate(96.500000, 24.500000) scale(-1, 1) rotate(90.000000) translate(-96.500000, -24.500000) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M105.624512,4.97216797 C94.4226888,4.50244141 85.9366862,9.85058594 80.1665039,21.0166016 C79.8051287,21.7159073 79.4687138,22.3615749 79.1572595,22.9536042 L80.1089196,17.7968272 C80.4778395,16.9245818 81.0906215,15.4885626 81.9472656,13.4887695 C86.7151693,7.81103516 89.2679036,4.97216797 89.6054688,4.97216797 C89.9430339,4.97216797 92.2714844,3.71272786 96.5908203,1.19384766 L101.309082,0.447753906 L105.624512,0.447753906 L105.624512,4.97216797 Z"
                                    fill="#FFFFFF"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M96.5,7.5 L96.5,7.5 C109.754834,7.5 120.5,18.245166 120.5,31.5 L120.5,41.5 L72.5,41.5 L72.5,31.5 C72.5,18.245166 83.245166,7.5 96.5,7.5 Z"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    transform="translate(96.500000, 24.500000) scale(-1, 1) rotate(90.000000) translate(-96.500000, -24.500000) ">
                                </path>
                                <rect class="slds-illustration__fill-secondary" x="106.5" y="0.5" width="110"
                                    height="48"></rect>
                                <rect class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round" x="106.5" y="0.5" width="110" height="48"></rect>
                                <rect class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round" x="106.5" y="62.5" width="48" height="54"></rect>
                                <rect class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round" x="106.5" y="48.5" width="48" height="14"></rect>
                                <path vector-effect="non-scaling-stroke"
                                    d="M219,66 C219,68.765 216.765,71 214,71 C211.235,71 209,68.765 209,66 C209,63.235 211.235,61 214,61 C216.765,61 219,63.235 219,66 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke" d="M214,69 L214,74"
                                    class="slds-illustration__stroke-primary" stroke-width="4" stroke-linecap="round">
                                </path>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="164" cy="72" r="3"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="164" cy="107" r="3"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="255" cy="72" r="3"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="255" cy="107" r="3"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="145" cy="72" r="3"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="145" cy="107" r="3"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="116" cy="72" r="3"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="116" cy="107" r="3"></circle>
                                <path vector-effect="non-scaling-stroke"
                                    d="M289.928751,96.2971422 L298,116.518658 L280,116.518658 L288.071249,96.2971422 C288.275982,95.784207 288.857768,95.5343604 289.370703,95.7390942 C289.625359,95.8407378 289.827108,96.0424867 289.928751,96.2971422 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M300.428751,103.813271 L305.5,116.518658 L293.5,116.518658 L298.571249,103.813271 C298.775982,103.300336 299.357768,103.050489 299.870703,103.255223 C300.125359,103.356867 300.327108,103.558616 300.428751,103.813271 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M93.4287513,96.2971422 L101.5,116.518658 L83.5,116.518658 L91.5712487,96.2971422 C91.7759825,95.784207 92.3577681,95.5343604 92.8707033,95.7390942 C93.1253588,95.8407378 93.3271077,96.0424867 93.4287513,96.2971422 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(92.500000, 106.517446) scale(-1, 1) translate(-92.500000, -106.517446) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M76.9287513,103.813271 L82,116.518658 L70,116.518658 L75.0712487,103.813271 C75.2759825,103.300336 75.8577681,103.050489 76.3707033,103.255223 C76.6253588,103.356867 76.8271077,103.558616 76.9287513,103.813271 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(76.000000, 110.275510) scale(-1, 1) translate(-76.000000, -110.275510) ">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M360,116.5 L372,116.5"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke" d="M0,116.5 L350,116.5"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </g>
                        </g>
                        <g transform="translate(160.000000, 126.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke"
                                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                        </g>
                        <g transform="translate(271.000000, 95.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke"
                                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                        </g>
                        <g transform="translate(402.000000, 164.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <g
                                transform="translate(31.713442, 25.088326) rotate(-15.000000) translate(-31.713442, -25.088326) translate(4.713442, 6.588326)">
                                <path vector-effect="non-scaling-stroke"
                                    d="M31.0360707,3.43528591 C31.0360707,3.43528591 40.5802283,0.671893051 42.6488424,10.6908663"
                                    transform="translate(36.842457, 6.888440) rotate(41.000000) translate(-36.842457, -6.888440) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M40.4282002,10.1797377 C40.4282002,10.1797377 49.9723578,7.4163448 52.0409719,17.435318"
                                    transform="translate(46.234586, 13.632892) scale(-1, 1) rotate(-41.000000) translate(-46.234586, -13.632892) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M0.730284783,29.5865514 C0.730284783,29.5865514 10.2744424,26.8231586 12.3430565,36.8421318">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M12.7302848,29.5865514 C12.7302848,29.5865514 22.2744424,26.8231586 24.3430565,36.8421318"
                                    transform="translate(18.536671, 33.039705) scale(-1, 1) translate(-18.536671, -33.039705) ">
                                </path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <!-- {% endmacro %} -->

        <!-- {% macro hammock() %} -->
        <svg v-if="image == 'hammock'" class="slds-illustration__svg" viewBox="0 0 454 182" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-73.000000, -110.000000)">
                    <g>
                        <g transform="translate(75.000000, 240.000000)">
                            <polyline vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                transform="translate(375.250000, 18.000000) scale(-1, 1) translate(-375.250000, -18.000000) "
                                points="335.5 36 380 0 415 27"></polyline>
                            <polyline vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                transform="translate(324.500000, 22.500000) scale(-1, 1) translate(-324.500000, -22.500000) "
                                points="303 18 313.5 9 346 36"></polyline>
                            <path vector-effect="non-scaling-stroke" d="M0,36.5 L450,36.5"
                                class="slds-illustration__stroke-secondary" stroke-width="3" stroke-linecap="round">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M406.04276,9.26543454 L413,32.7461193 L400.495829,23.8448556 L404.113561,9.30802252 C404.246938,8.772085 404.789525,8.44574518 405.325462,8.57912213 C405.669253,8.66468028 405.942114,8.9257539 406.04276,9.26543454 Z"
                                class="slds-illustration__fill-secondary"></path>
                            <path vector-effect="non-scaling-stroke"
                                d="M311.962964,11.4391572 L318,33 L306.770218,23.1063642 L310.037036,11.4391572 C310.185948,10.9073269 310.7378,10.5969106 311.26963,10.7458231 C311.605963,10.8399963 311.868791,11.1028246 311.962964,11.4391572 Z"
                                class="slds-illustration__fill-secondary"
                                transform="translate(312.385109, 21.626882) scale(-1, 1) translate(-312.385109, -21.626882) ">
                            </path>
                        </g>
                        <g transform="translate(83.000000, 173.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke"
                                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                        </g>
                        <g transform="translate(274.000000, 111.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke"
                                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                        </g>
                        <g transform="translate(115.000000, 191.000000)">
                            <g transform="translate(148.000000, 32.000000) scale(-1, 1) translate(-148.000000, -32.000000) translate(116.000000, 0.000000)"
                                class="slds-illustration__fill-secondary">
                                <path vector-effect="non-scaling-stroke"
                                    d="M25,53 C25,55.76 22.76125,58 20,58 C17.24,58 15,55.76 15,53 C15,50.24 17.24,48 20,48 C22.76125,48 25,50.24 25,53 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M8,58 C6.34130214,58 5,59.344354 5,61 C5,62.655646 6.34130214,64 8,64 C9.65717192,64 11,62.655646 11,61 C11,59.344354 9.65717192,58 8,58">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M43.2932416,0 C35.2856577,0 28.3128049,4.40586521 24.6382957,10.9184604 C22.0889739,9.11222859 18.9814709,8.03886643 15.6213524,8.03886643 C6.99160591,8.03886643 0,15.0367986 0,23.6690948 C0,32.302472 6.99160591,39.3004042 15.6213524,39.3004042 C15.8077805,39.3004042 15.9920023,39.2777047 16.1806367,39.2712191 C19.0013272,43.3344299 23.6918145,46 29.011083,46 C32.7848734,46 36.2475826,44.6596485 38.9469293,42.4296926 C40.3512074,42.7193815 41.8051261,42.8739543 43.2932416,42.8739543 C55.1275661,42.8739543 64,33.4569038 64,21.6185732 C64,9.77916158 55.1275661,0 43.2932416,0 Z">
                                </path>
                            </g>
                            <g transform="translate(0.000000, 18.000000)">
                                <path vector-effect="non-scaling-stroke"
                                    d="M90.5057553,53.0538038 C90.5057553,53.0538038 116.215483,58.0701972 145.433002,58.0701972 C174.650521,58.0701972 204.505755,53.0538038 204.505755,53.0538038 C204.505755,53.0538038 192.222862,60.6378663 185.905056,63.0258453 C179.58725,65.4138243 166.637272,67.3671811 166.637272,67.3671811 C166.637272,67.3671811 153.865271,70.7987363 147.505755,70.9374424 C141.14624,71.0761485 132.54218,68.1108222 132.54218,68.1108222 L106.786672,63.0258453 L90.5057553,53.0538038 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M84.8198266,43.3368404 C84.8198266,43.3368404 99.4457512,70.425365 147.915957,70.425365 C196.386164,70.425365 214.819827,43.3368404 214.819827,43.3368404"
                                    class="slds-illustration__stroke-primary" stroke-width="4" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke" d="M73,49.5 L88,49.5"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M211,49.5 L226,49.5"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M142.74548,0 L153.650377,2.22044605e-16 C154.478804,8.05656105e-16 155.150377,0.671572875 155.150377,1.5 L155.150377,2.98261094 C155.150377,3.11136765 155.100706,3.2351613 155.01171,3.32820944 L146.234757,12.5047677 L155.209827,12.5047677 C156.038254,12.5047677 156.709827,13.1763405 156.709827,14.0047677 L156.709827,14.4270108 C156.709827,15.2554379 156.038254,15.9270108 155.209827,15.9270108 L141.888626,15.9270108 C141.060199,15.9270108 140.388626,15.2554379 140.388626,14.4270108 L140.388626,12.8248256 C140.388626,12.696109 140.438265,12.5723507 140.527212,12.4793109 L149.20663,3.40051458 L142.74548,3.40051458 C141.917053,3.40051458 141.24548,2.7289417 141.24548,1.90051458 L141.24548,1.5 C141.24548,0.671572875 141.917053,1.52179594e-16 142.74548,0 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke" d="M20,81.5 L390,81.5"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M0,81.5 L12,81.5"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                            </g>
                        </g>
                        <g transform="translate(175.500000, 153.000000)">
                            <g transform="translate(0.000000, 7.000000)" class="slds-illustration__fill-secondary">
                                <path vector-effect="non-scaling-stroke"
                                    d="M19.3444016,2.17529966 L32.8957957,84.445825 C33.0753185,85.5357082 32.3373256,86.5647651 31.2474424,86.7442879 C31.1399954,86.7619863 31.0312825,86.7708798 30.9223876,86.7708798 L2.12566435,86.7708798 C1.02109485,86.7708798 0.125664347,85.8754493 0.125664347,84.7708798 C0.125664347,84.6486417 0.136870913,84.526661 0.159143349,84.4064691 L15.4044726,2.13594377 C15.6057311,1.04986421 16.6493247,0.332574998 17.7354043,0.533833473 C18.5639901,0.687376483 19.2074411,1.34381197 19.3444016,2.17529966 Z">
                                </path>
                            </g>
                            <g class="slds-illustration__stroke-primary" stroke-linecap="round" stroke-width="3">
                                <polygon vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="17 0.709590879 34 94.5 -1.82254212e-12 94.5"></polygon>
                                <path vector-effect="non-scaling-stroke"
                                    d="M17,8.09449405 C17,92.0999502 17,135.036706 17,136.904762 C17,135.036706 17,92.0999502 17,8.09449405 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M17,64.2334004 C24.3333333,71.7198767 28,75.6881307 28,76.1381623 C28,75.6881307 24.3333333,71.7198767 17,64.2334004 Z"
                                    stroke-linejoin="round"
                                    transform="translate(22.500000, 70.185781) scale(-1, 1) translate(-22.500000, -70.185781) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M8,50.2334004 C14,57.05321 17,60.6881307 17,61.1381623 C17,60.6881307 14,57.05321 8,50.2334004 Z"
                                    stroke-linejoin="round"></path>
                            </g>
                        </g>
                        <g transform="translate(307.500000, 135.000000)">
                            <g transform="translate(0.000000, 2.000000)" class="slds-illustration__fill-secondary">
                                <path vector-effect="non-scaling-stroke"
                                    d="M29.524032,0.669921875 L55.6419593,108.305728 C55.9024257,109.379148 55.2433964,110.460477 54.1699762,110.720943 C54.0155692,110.75841 53.8572476,110.777344 53.69836,110.777344 L2.40272523,110.777344 C1.29815573,110.777344 0.402725233,109.881913 0.402725233,108.777344 C0.402725233,108.601258 0.42597945,108.425943 0.471884777,108.255946 L29.524032,0.669921875 Z">
                                </path>
                            </g>
                            <g class="slds-illustration__stroke-primary" stroke-linecap="round" stroke-width="3">
                                <polygon vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="29 0 56.572466 112.5 0 112.5"></polygon>
                                <path vector-effect="non-scaling-stroke"
                                    d="M29,6.09449405 C29,103.433283 29,153.036706 29,154.904762 C29,153.036706 29,103.433283 29,6.09449405 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M29,74.2334004 C41,87.05321 47,93.6881307 47,94.1381623 C47,93.6881307 41,87.05321 29,74.2334004 Z"
                                    stroke-linejoin="round"
                                    transform="translate(38.000000, 84.185781) scale(-1, 1) translate(-38.000000, -84.185781) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M14,61.2334004 C24,72.7198767 29,78.6881307 29,79.1381623 C29,78.6881307 24,72.7198767 14,61.2334004 Z"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M29,48.2334004 C36.3333333,57.05321 40,61.6881307 40,62.1381623 C40,61.6881307 36.3333333,57.05321 29,48.2334004 Z"
                                    stroke-linejoin="round"
                                    transform="translate(34.500000, 55.185781) scale(-1, 1) translate(-34.500000, -55.185781) ">
                                </path>
                            </g>
                        </g>
                        <g transform="translate(347.500000, 166.000000)">
                            <g transform="translate(0.000000, 4.000000)" class="slds-illustration__fill-secondary">
                                <path vector-effect="non-scaling-stroke"
                                    d="M19.3444016,2.17529966 L32.8957957,84.445825 C33.0753185,85.5357082 32.3373256,86.5647651 31.2474424,86.7442879 C31.1399954,86.7619863 31.0312825,86.7708798 30.9223876,86.7708798 L2.12566435,86.7708798 C1.02109485,86.7708798 0.125664347,85.8754493 0.125664347,84.7708798 C0.125664347,84.6486417 0.136870913,84.526661 0.159143349,84.4064691 L15.4044726,2.13594377 C15.6057311,1.04986421 16.6493247,0.332574998 17.7354043,0.533833473 C18.5639901,0.687376483 19.2074411,1.34381197 19.3444016,2.17529966 Z">
                                </path>
                            </g>
                            <g class="slds-illustration__stroke-primary" stroke-linecap="round" stroke-width="3">
                                <polygon vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="17 0.709590879 34 91.5 -1.82254212e-12 91.5"></polygon>
                                <path vector-effect="non-scaling-stroke"
                                    d="M17,5.09449405 C17,83.0999502 17,123.036706 17,124.904762 C17,123.036706 17,83.0999502 17,5.09449405 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M17,61.2334004 C24.3333333,68.7198767 28,72.6881307 28,73.1381623 C28,72.6881307 24.3333333,68.7198767 17,61.2334004 Z"
                                    stroke-linejoin="round"
                                    transform="translate(22.500000, 67.185781) scale(-1, 1) translate(-22.500000, -67.185781) ">
                                </path>
                            </g>
                        </g>
                        <g transform="translate(392.000000, 184.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <g
                                transform="translate(31.713442, 25.088326) rotate(-15.000000) translate(-31.713442, -25.088326) translate(4.713442, 6.588326)">
                                <path vector-effect="non-scaling-stroke"
                                    d="M31.0360707,3.43528591 C31.0360707,3.43528591 40.5802283,0.671893051 42.6488424,10.6908663"
                                    transform="translate(36.842457, 6.888440) rotate(41.000000) translate(-36.842457, -6.888440) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M40.4282002,10.1797377 C40.4282002,10.1797377 49.9723578,7.4163448 52.0409719,17.435318"
                                    transform="translate(46.234586, 13.632892) scale(-1, 1) rotate(-41.000000) translate(-46.234586, -13.632892) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M0.730284783,29.5865514 C0.730284783,29.5865514 10.2744424,26.8231586 12.3430565,36.8421318">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M12.7302848,29.5865514 C12.7302848,29.5865514 22.2744424,26.8231586 24.3430565,36.8421318"
                                    transform="translate(18.536671, 33.039705) scale(-1, 1) translate(-18.536671, -33.039705) ">
                                </path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <!-- {% endmacro %} -->

        <!-- {% macro axe() %} -->
        <svg v-if="image == 'axe'" class="slds-illustration__svg" viewBox="0 0 470 229" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-65.000000, -89.000000)">
                    <g>
                        <g transform="translate(67.000000, 266.000000)">
                            <path vector-effect="non-scaling-stroke" d="M16,36.5 L466,36.5"
                                class="slds-illustration__stroke-secondary" stroke-width="3" stroke-linecap="round">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M0,36.5 L6,36.5"
                                class="slds-illustration__stroke-secondary" stroke-width="3" stroke-linecap="round">
                            </path>
                            <polyline vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                points="43 36 87.5 0 121.5 27"></polyline>
                            <polyline vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                points="111 17 121.5 9 153 36"></polyline>
                            <path vector-effect="non-scaling-stroke"
                                d="M50.962964,14.4391572 L57,36 L45.770218,26.1063642 L49.037036,14.4391572 C49.1859485,13.9073269 49.7377996,13.5969106 50.2696299,13.7458231 C50.6059625,13.8399963 50.8687909,14.1028246 50.962964,14.4391572 Z"
                                class="slds-illustration__fill-secondary"
                                transform="translate(51.385109, 24.626882) scale(-1, 1) translate(-51.385109, -24.626882) ">
                            </path>
                        </g>
                        <g transform="translate(451.500000, 238.500000)">
                            <g fill="#FFFFFF">
                                <path vector-effect="non-scaling-stroke"
                                    d="M18.9209988,1.95433401 L33.259296,51.443436 C33.5666778,52.5043744 32.9557995,53.613617 31.8948612,53.9209988 C31.7139843,53.9734036 31.5266126,54 31.3382972,54 L2.6617028,54 C1.5571333,54 0.661702805,53.1045695 0.661702805,52 C0.661702805,51.8116846 0.688299176,51.6243129 0.74070397,51.443436 L15.0790012,1.95433401 C15.386383,0.893395645 16.4956256,0.282517358 17.556564,0.589899164 C18.2152102,0.780726338 18.7301717,1.29568777 18.9209988,1.95433401 Z">
                                </path>
                            </g>
                            <g class="slds-illustration__stroke-secondary" stroke-linecap="round" stroke-width="3">
                                <polygon vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="17 0.323943662 34 54 -1.81721305e-12 54"></polygon>
                                <path vector-effect="non-scaling-stroke"
                                    d="M17,4.6953125 C17,43.0456294 17,62.6471919 17,63.5 C17,62.6471919 17,43.0456294 17,4.6953125 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M17,29.3239437 C22.3333333,35.7851611 25,39.1184944 25,39.3239437 C25,39.1184944 22.3333333,35.7851611 17,29.3239437 Z"
                                    stroke-linejoin="round"
                                    transform="translate(21.000000, 34.323944) scale(-1, 1) translate(-21.000000, -34.323944) ">
                                </path>
                            </g>
                        </g>
                        <g transform="translate(408.000000, 205.500000)">
                            <g transform="translate(1.000000, 0.000000)" fill="#FFFFFF">
                                <path vector-effect="non-scaling-stroke"
                                    d="M25.6478873,0 L50.879042,84.4273253 C51.1953215,85.4856452 50.5937789,86.5999782 49.535459,86.9162577 C49.3496374,86.9717906 49.1567264,87 48.9627843,87 L2.33299037,87 C1.22842087,87 0.332990367,86.1045695 0.332990367,85 C0.332990367,84.8060578 0.361199757,84.6131469 0.416732643,84.4273253 L25.6478873,0 Z">
                                </path>
                            </g>
                            <g class="slds-illustration__stroke-secondary" stroke-linecap="round" stroke-width="3">
                                <polygon vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="26.5 0 52.5 87 0.5 87"></polygon>
                                <path vector-effect="non-scaling-stroke"
                                    d="M26.5,3.58642578 C26.5,64.0261034 26.5,94.9972948 26.5,96.5 C26.5,94.9972948 26.5,64.0261034 26.5,3.58642578 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M15.6478873,42 C22.9812207,49.078692 26.6478873,52.7453587 26.6478873,53 C26.6478873,52.7453587 22.9812207,49.078692 15.6478873,42 Z"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M27.6478873,68 C36.9812207,57.078692 41.6478873,51.7453587 41.6478873,52 C41.6478873,51.7453587 36.9812207,57.078692 27.6478873,68 Z"
                                    stroke-linejoin="round"></path>
                            </g>
                        </g>
                        <g
                            transform="translate(323.317280, 164.835938) rotate(-45.000000) translate(-323.317280, -164.835938) translate(242.317280, 130.835938)">
                            <g transform="translate(5.000000, 44.000000)" fill="#FFFFFF">
                                <path vector-effect="non-scaling-stroke"
                                    d="M4.18947979,0.995611783 L0.115234375,11.9555255 C12.0957996,19.4577143 18.3338849,23.301537 18.8294904,23.4869936 C19.1669204,23.6132606 21.7612542,24.0399819 26.9701953,23.7763273 C28.5980425,23.6939326 31.6346656,23.3623612 36.0800647,22.7816131 C40.0461992,21.6828201 43.0275796,20.7161876 45.0242059,19.8817158 C47.0208321,19.0472439 50.3465588,17.377878 55.0013859,14.8736182 L47.5277368,3.21878589 L4.18947979,0.995611783 Z">
                                </path>
                            </g>
                            <g transform="translate(1.000000, 0.000000)" class="slds-illustration__fill-secondary">
                                <rect class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round" x="0.973240311" y="10.995821" width="11.5" height="12">
                                </rect>
                                <path vector-effect="non-scaling-stroke"
                                    d="M4.77755146,50.2284782 C13.286202,52.6362568 21.480957,53.7936597 29.3618164,53.7006867 C37.2426758,53.6077138 44.7765582,51.7400968 51.9634637,48.0978356 C50.0751252,44.4670451 48.7826758,41.7898449 48.0861155,40.0662351 C47.3895551,38.3426252 46.5236255,35.6790055 45.4883267,32.075376 L45.2946319,0.293204959 L12.5695367,0.148192827 L12.5726441,32.0166806 C11.5738974,36.4623212 10.6196945,39.7477667 9.71003558,41.8730172 C8.80037666,43.9982676 7.15621528,46.7834213 4.77755146,50.2284782 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M45.4341011,10.7997716 L160.934101,10.7997716 L160.934101,29.2075859 C138.796894,24.9149057 121.296894,22.8695992 108.434101,23.0716664 C103.947529,23.0716664 96.9638862,23.0716664 87.4831733,23.0716664 L45.4341011,23.0716664 L45.4341011,10.7997716 Z">
                                </path>
                            </g>
                            <g transform="translate(0.000000, 0.000000)">
                                <path vector-effect="non-scaling-stroke"
                                    d="M13.5015145,4.53816197e-13 L46.505417,4.53816197e-13 L46.505417,24.0516129 C46.0805407,29.6108858 47.016166,35.1227138 49.312293,40.5870968 C51.6084201,46.0514798 55.1733269,52.064383 60.0070135,58.6258065 C49.5057718,64.6387097 39.5045892,67.6451613 30.0034658,67.6451613 C20.5023423,67.6451613 10.5011597,64.6387097 -8.20017976e-05,58.6258065 C5.7540776,50.5948062 9.47046374,44.581903 11.1490764,40.5870968 C12.8276891,36.5922906 13.6118351,31.0804626 13.5015145,24.0516129 L13.5015145,4.53816197e-13 Z"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <rect class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round" x="1.97324031" y="10.995821" width="11.5" height="12">
                                </rect>
                                <path vector-effect="non-scaling-stroke"
                                    d="M46.4341011,10.7997716 L161.934101,10.7997716 L161.934101,29.2075859 C139.796894,24.9149057 122.296894,22.8695992 109.434101,23.0716664 C104.947529,23.0716664 97.9638862,23.0716664 88.4831733,23.0716664 L46.4341011,23.0716664 L46.4341011,10.7997716 Z"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <polygon vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    points="66 10.5225806 102 10.5225806 102.162282 23.0225806 66.0542614 22.8973803">
                                </polygon>
                            </g>
                        </g>
                        <g transform="translate(124.000000, 219.518658)">
                            <g transform="translate(117.000000, 0.000000)" fill="#FFFFFF">
                                <polygon vector-effect="non-scaling-stroke"
                                    points="0.115234375 0.823139211 0.115234375 6.95790484 131.128906 21.124897 131.128906 14.975483">
                                </polygon>
                            </g>
                            <g transform="translate(95.000000, 4.000000)" class="slds-illustration__fill-secondary">
                                <polygon vector-effect="non-scaling-stroke"
                                    points="21.9667969 0.213764211 152.662109 14.9813423 152.662109 65.9637642 160.867188 80.1434517 174.628906 92.3211861 0.63671875 92.3211861 13.7480469 80.1434517 21.9667969 61.2977486">
                                </polygon>
                            </g>
                            <g>
                                <path vector-effect="non-scaling-stroke"
                                    d="M118.5,15.4813423 L118.5,61.4813423 C117.921224,71.8381104 115.333984,79.318309 110.738281,83.921938 C106.142578,88.525567 100.896484,92.8787018 95,96.9813423 L271,96.9813423 C264.389323,92.6555667 259.341797,87.3824891 255.857422,81.1621094 C252.373047,74.9417297 250.253906,67.5264029 249.5,58.916129 L249.5,0.481342336 L118.5,15.4813423 Z"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    transform="translate(183.000000, 48.731342) scale(-1, 1) translate(-183.000000, -48.731342) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M271.928751,76.7784845 L280,97 L262,97 L270.071249,76.7784845 C270.275982,76.2655493 270.857768,76.0157027 271.370703,76.2204365 C271.625359,76.3220801 271.827108,76.5238291 271.928751,76.7784845 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M282.428751,84.2946136 L287.5,97 L275.5,97 L280.571249,84.2946136 C280.775982,83.7816784 281.357768,83.5318318 281.870703,83.7365656 C282.125359,83.8382092 282.327108,84.0399581 282.428751,84.2946136 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M90.4287513,76.7784845 L98.5,97 L80.5,97 L88.5712487,76.7784845 C88.7759825,76.2655493 89.3577681,76.0157027 89.8707033,76.2204365 C90.1253588,76.3220801 90.3271077,76.5238291 90.4287513,76.7784845 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(89.500000, 86.998788) scale(-1, 1) translate(-89.500000, -86.998788) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M76.9287513,84.2946136 L82,97 L70,97 L75.0712487,84.2946136 C75.2759825,83.7816784 75.8577681,83.5318318 76.3707033,83.7365656 C76.6253588,83.8382092 76.8271077,84.0399581 76.9287513,84.2946136 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(76.000000, 90.756853) scale(-1, 1) translate(-76.000000, -90.756853) ">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M20,96.9813423 L370,96.9813423"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke" d="M0,96.9813423 L12,96.9813423"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M226.5,14.4813423 L226.5,40.4813423 C226.854167,55.9423134 234.6875,67.6089801 250,75.4813423"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M212.5,12.4813423 L212.5,51.4813423 C211.329427,75.4461105 226.830078,87.4461105 259.001953,87.4813423"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke" d="M190.5,9.48134234 L190.5,96.4813423"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke" d="M160.5,5.48134234 L160.5,96.4813423"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M148.5,4.48134234 L148.5,60.4813423 C148.166667,84.3362203 131.634766,96.2636593 98.9042969,96.2636593"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M131.5,2.48134234 L131.5,54.2203125 C132.691406,76.2363071 124.326172,87.2443044 106.404297,87.2443044"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </g>
                        </g>
                        <g transform="translate(160.000000, 107.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke"
                                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                        </g>
                        <g transform="translate(319.000000, 147.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke"
                                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                        </g>
                        <g transform="translate(179.000000, 216.518658)">
                            <g transform="translate(6.000000, 2.000000)" fill="#FFFFFF">
                                <path vector-effect="non-scaling-stroke"
                                    d="M20.8180195,40.0662428 L20.8180195,3.98882348 C14.8180195,9.37296043 11.8180195,15.3858637 11.8180195,22.0275332 C11.8180195,28.6692027 14.8180195,34.6821059 20.8180195,40.0662428 Z"
                                    transform="translate(16.318019, 22.027533) scale(-1, -1) rotate(-45.000000) translate(-16.318019, -22.027533) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M38.0732996,21.3284985 L38.0732996,-1.21988855 C34.3232996,2.14519704 32.4482996,5.90326156 32.4482996,10.054305 C32.4482996,14.2053484 34.3232996,17.963413 38.0732996,21.3284985 Z"
                                    transform="translate(35.260800, 10.054305) scale(1, -1) rotate(-45.000000) translate(-35.260800, -10.054305) ">
                                </path>
                            </g>
                            <g transform="translate(0.000000, 6.000000)" class="slds-illustration__fill-secondary">
                                <path vector-effect="non-scaling-stroke"
                                    d="M20.8180195,42.0662428 L20.8180195,5.98882348 C14.8180195,11.3729604 11.8180195,17.3858637 11.8180195,24.0275332 C11.8180195,30.6692027 14.8180195,36.6821059 20.8180195,42.0662428 Z"
                                    transform="translate(16.318019, 24.027533) rotate(-45.000000) translate(-16.318019, -24.027533) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M47.9262378,21.3284985 L47.9262378,-1.21988855 C44.1762378,2.14519704 42.3012378,5.90326156 42.3012378,10.054305 C42.3012378,14.2053484 44.1762378,17.963413 47.9262378,21.3284985 Z"
                                    transform="translate(45.113738, 10.054305) scale(-1, 1) rotate(-45.000000) translate(-45.113738, -10.054305) ">
                                </path>
                            </g>
                            <g class="slds-illustration__stroke-primary" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3">
                                <path vector-effect="non-scaling-stroke"
                                    d="M60,64.4813423 C42.5559896,58.4405997 33.7226563,49.4405997 33.5,37.4813423 L33.5,0.481342336">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M19.5,44.8774194 C25.5,39.4932824 28.5,33.4803792 28.5,26.8387097 C28.5,20.1970402 25.5,14.1841369 19.5,8.8 C13.5,14.1841369 10.5,20.1970402 10.5,26.8387097 C10.5,33.4803792 13.5,39.4932824 19.5,44.8774194 Z"
                                    transform="translate(19.500000, 26.838710) rotate(-45.000000) translate(-19.500000, -26.838710) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M43.125,25.3354839 C46.875,21.9703983 48.75,18.2123338 48.75,14.0612903 C48.75,9.91024688 46.875,6.15218236 43.125,2.78709677 C39.375,6.15218236 37.5,9.91024688 37.5,14.0612903 C37.5,18.2123338 39.375,21.9703983 43.125,25.3354839 Z"
                                    transform="translate(43.125000, 14.061290) scale(-1, 1) rotate(-45.000000) translate(-43.125000, -14.061290) ">
                                </path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <!-- {% endmacro %} -->

        <!-- {% macro desert() %} -->
        <svg v-if="image == 'desert'" class="slds-illustration__svg" viewBox="0 0 468 194" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-67.000000, -112.000000)">
                    <g>
                        <g transform="translate(245.000000, 200.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke"
                                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                        </g>
                        <g transform="translate(135.000000, 152.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke"
                                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                        </g>
                        <g transform="translate(69.000000, 256.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke" d="M14,36.5 L464,36.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,36.5 L6,36.5"></path>
                            <polyline vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                points="234.5 36 279.5 0 313.5 26"></polyline>
                            <path vector-effect="non-scaling-stroke" d="M279.5,1 L279.5,35" stroke-linejoin="round">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M313.5,11 C313.5,20.7437888 313.5,25.7437888 313.5,26 C313.5,25.7437888 313.5,20.7437888 313.5,11 Z"
                                stroke-linejoin="round"></path>
                            <polyline vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                points="303.5 17 313.5 9 347.5 36"></polyline>
                        </g>
                        <g transform="translate(113.000000, 178.000000)">
                            <g transform="translate(30.000000, 8.000000)" class="slds-illustration__fill-secondary">
                                <path vector-effect="non-scaling-stroke"
                                    d="M29.5,60.5 L29.5,12 C29.5,5.372583 34.872583,0 41.5,0 C48.127417,0 53.5,5.372583 53.5,12 L53.5,40.5 L70.5,40.5 L70.5,27 C70.5,23.1340068 73.6340068,20 77.5,20 C81.3659932,20 84.5,23.1340068 84.5,27 L84.5,48.5 C84.5,51.8137085 81.8137085,54.5 78.5,54.5 L53.5,54.5 L53.5,118.5 L29.5,118.5 L29.5,74.5 L6.5,74.5 C3.1862915,74.5 0.5,71.8137085 0.5,68.5 L0.5,39 C0.5,35.1340068 3.63400675,32 7.5,32 C11.3659932,32 14.5,35.1340068 14.5,39 L14.5,60.5 L29.5,60.5 Z">
                                </path>
                            </g>
                            <g transform="translate(59.000000, 7.000000)" fill="#FFFFFF">
                                <path vector-effect="non-scaling-stroke"
                                    d="M2,11 C3.65332031,8.49145508 5.65181478,6.77364095 7.9954834,5.84655762 C11.5109863,4.45593262 15.2684326,4.95605469 17.8156738,6.2824707 C20.362915,7.60888672 22.3626709,9.64978027 23.2602539,11.81604 C23.8586426,13.2602132 23.8586426,11.7547201 23.2602539,7.29956055 L19.612793,3.56494141 L13.7923584,0.564331055 L8.765625,1.42663574 L4.67321777,3.19787598 C2.69893392,5.13902708 1.69559733,6.16722532 1.66320801,6.2824707 C1.61462402,6.45533878 0.856079102,9.49145508 0.813964844,9.66003418 C0.785888672,9.77242025 1.18123372,10.2190755 2,11 Z">
                                </path>
                            </g>
                            <g class="slds-illustration__stroke-primary" stroke-width="3">
                                <path vector-effect="non-scaling-stroke" d="M0,126.5 L356,126.5" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M362.5,126.5 L372.013149,126.5"
                                    stroke-linecap="round"></path>
                                <path vector-effect="non-scaling-stroke" d="M107.5,20 L107.5,28" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M113,30.6568542 L118.656854,25"
                                    stroke-linecap="round"></path>
                                <path vector-effect="non-scaling-stroke" d="M37.5,32 L37.5,40" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M44,42.6568542 L49.6568542,37"
                                    stroke-linecap="round"></path>
                                <path vector-effect="non-scaling-stroke" d="M25,42.6568542 L30.6568542,37"
                                    stroke-linecap="round"
                                    transform="translate(27.828427, 39.828427) scale(-1, 1) translate(-27.828427, -39.828427) ">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M71.5,0 L71.5,8" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M79,10.6568542 L84.6568542,5"
                                    stroke-linecap="round"></path>
                                <path vector-effect="non-scaling-stroke" d="M58,10.6568542 L63.6568542,5"
                                    stroke-linecap="round"
                                    transform="translate(60.828427, 7.828427) scale(-1, 1) translate(-60.828427, -7.828427) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M59.5,68.5 L59.5,20 C59.5,13.372583 64.872583,8 71.5,8 C78.127417,8 83.5,13.372583 83.5,20 L83.5,48.5 L100.5,48.5 L100.5,35 C100.5,31.1340068 103.634007,28 107.5,28 C111.365993,28 114.5,31.1340068 114.5,35 L114.5,56.5 C114.5,59.8137085 111.813708,62.5 108.5,62.5 L83.5,62.5 L83.5,126.5 L59.5,126.5 L59.5,82.5 L36.5,82.5 C33.1862915,82.5 30.5,79.8137085 30.5,76.5 L30.5,47 C30.5,43.1340068 33.6340068,40 37.5,40 C41.3659932,40 44.5,43.1340068 44.5,47 L44.5,68.5 L59.5,68.5 Z">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M77,88.5 L92.5,88.5 L92.5,81 C92.5,77.1340068 95.6340068,74 99.5,74 L99.5,74 C103.365993,74 106.5,77.1340068 106.5,81 L106.5,96.5 C106.5,99.8137085 103.813708,102.5 100.5,102.5 L77,102.5"
                                    class="slds-illustration__fill-secondary" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </g>
                        </g>
                        <g transform="translate(429.000000, 242.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <g>
                                <path vector-effect="non-scaling-stroke" d="M12.5,4 L12.5,50"></path>
                                <polyline vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="11 18.5 0.5 18.5 0.5 0"></polyline>
                                <polyline vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="14 25.5 24.5 25.5 24.5 8"></polyline>
                                <polyline vector-effect="non-scaling-stroke" stroke-linejoin="round"
                                    points="2.5 31 2.5 38.5 11 38.5"></polyline>
                            </g>
                        </g>
                        <g transform="translate(327.000000, 95.000000)">
                            <g>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-secondary"
                                    cx="64" cy="64" r="23"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                    stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-dasharray="135,1,1,18"
                                    transform="translate(64.000000, 64.000000) rotate(230.000000) translate(-64.000000, -64.000000) "
                                    cx="64" cy="64" r="45"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                    stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-dasharray="107,10"
                                    transform="translate(64.000000, 64.000000) rotate(150.000000) translate(-64.000000, -64.000000) "
                                    cx="64" cy="64" r="33"></circle>
                            </g>
                            <g transform="translate(41.000000, 41.000000)" class="slds-illustration__stroke-primary"
                                stroke-width="3">
                                <circle vector-effect="non-scaling-stroke" cx="23" cy="23" r="23"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <!-- {% endmacro %} -->

        <!-- {% macro fishing() %} -->
        <svg v-if="image == 'fishing'" class="slds-illustration__svg" viewBox="0 0 466 297" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-69.000000, -68.000000)">
                    <g>
                        <g
                            transform="translate(455.000000, 172.000000) scale(-1, 1) translate(-455.000000, -172.000000) translate(377.000000, 153.000000)">
                            <polyline vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                points="26 36 72.5 0 105.5 27"></polyline>
                            <polyline vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                points="96 17 105.5 9 138 36"></polyline>
                            <path vector-effect="non-scaling-stroke" d="M0,36.5 L156,36.5"
                                class="slds-illustration__stroke-secondary" stroke-width="3" stroke-linecap="round">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M34.202166,22.8278095 L37.207834,33.1133915 L30.8916038,28.4131636 L32.2719085,22.8668 C32.4052855,22.3308624 32.9478721,22.0045226 33.4838096,22.1378996 C33.8288998,22.223781 34.102419,22.486469 34.202166,22.8278095 Z"
                                class="slds-illustration__fill-secondary"
                                transform="translate(34.049719, 27.610728) scale(-1, 1) translate(-34.049719, -27.610728) ">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M28.9755366,18.8278095 L34.0379951,36.1518555 L29.5001232,33.2627202 L24.2782891,29.9851658 L27.0452792,18.8668 C27.1786561,18.3308624 27.7212427,18.0045226 28.2571802,18.1378996 C28.6022704,18.223781 28.8757896,18.486469 28.9755366,18.8278095 Z"
                                class="slds-illustration__fill-secondary"
                                transform="translate(29.158142, 27.129960) scale(-1, 1) translate(-29.158142, -27.129960) ">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M134.935531,21.7547479 L139.204202,37 L130.811328,29.6057303 L133.009603,21.7547479 C133.158515,21.2229175 133.710367,20.9125013 134.242197,21.0614138 C134.57853,21.1555869 134.841358,21.4184152 134.935531,21.7547479 Z"
                                class="slds-illustration__fill-secondary"></path>
                        </g>
                        <g transform="translate(71.000000, 128.000000)">
                            <g>
                                <path vector-effect="non-scaling-stroke" d="M237,61.5 L249,61.5"
                                    class="slds-illustration__stroke-secondary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M0,61.5 L230,61.5"
                                    class="slds-illustration__stroke-secondary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M34.9102011,32.0436939 L38.3925781,48.4296875 L26.453125,58.6445313 L32.9606296,32.0141932 C33.0917307,31.4776944 33.6329274,31.1490548 34.1694262,31.2801559 C34.5424771,31.3713162 34.8303699,31.6680556 34.9102011,32.0436939 Z"
                                    class="slds-illustration__fill-secondary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M191.962964,27.4391572 L198,49 L186.770218,39.1063642 L190.037036,27.4391572 C190.185948,26.9073269 190.7378,26.5969106 191.26963,26.7458231 C191.605963,26.8399963 191.868791,27.1028246 191.962964,27.4391572 Z"
                                    class="slds-illustration__fill-secondary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M39.9662349,37.3583594 L42.6016149,47.2410342 L40.7070312,48.7349783 L35,48.7349783 L38.0337651,37.3583594 C38.1760682,36.8247225 38.7240258,36.5074839 39.2576627,36.6497871 C39.6036917,36.7420615 39.8739605,37.0123303 39.9662349,37.3583594 Z"
                                    class="slds-illustration__fill-secondary"></path>
                                <polyline vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                    stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                    points="25 61 99.5 0 157.5 45"></polyline>
                                <polyline vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                    stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                                    points="140 30.9433962 157.5 16 212 60"></polyline>
                            </g>
                        </g>
                        <g transform="translate(145.000000, 69.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <g>
                                <path vector-effect="non-scaling-stroke"
                                    d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                                <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                            </g>
                        </g>
                        <g transform="translate(297.000000, 109.000000)" class="slds-illustration__stroke-secondary"
                            stroke-linecap="round" stroke-width="3">
                            <path vector-effect="non-scaling-stroke"
                                d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13">
                            </path>
                            <path vector-effect="non-scaling-stroke"
                                d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5">
                            </path>
                            <path vector-effect="non-scaling-stroke" d="M18,27.5 L83.0004985,27.5"></path>
                            <path vector-effect="non-scaling-stroke" d="M0,27.5 L8,27.5"></path>
                        </g>
                        <g transform="translate(113.000000, 75.000000)">
                            <g transform="translate(217.000000, 0.000000)">
                                <path vector-effect="non-scaling-stroke" d="M20.5,162.789062 L20.5,174.839062"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M20.5,150.789062 L20.5,153.839063"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke" d="M20.5,0.772022494 L20.5,111.841169"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__stroke-primary"
                                    stroke-width="3" cx="20.5" cy="181" r="6"></circle>
                                <path vector-effect="non-scaling-stroke"
                                    d="M20.5,188 C20.5,200 20.5,209.666667 20.5,217 C20.5,228 0.5,228 0.5,217 C0.5,209.666667 0.5,205.282924 0.5,203.848771 L7,211"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M20.5,128 C27.127417,128 32.5,125.627417 32.5,123 C32.5,116.372583 27.127417,111 20.5,111 C13.872583,111 8.5,116.372583 8.5,123 C8.5,126.627417 13.872583,128 20.5,128 Z"
                                    class="slds-illustration__fill-primary"></path>
                            </g>
                            <g transform="translate(0.000000, 134.000000)" class="slds-illustration__stroke-secondary"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
                                <path vector-effect="non-scaling-stroke"
                                    d="M66.5016961,5.72253485 C59.2581641,7.22907384 54.7616802,0 54.7616802,0 L54.7530053,0 C54.7530053,0 51.3235326,5.94519033 44.2795231,5.94519033 C37.5304598,5.94519033 33.8262823,0 33.8262823,0 L33.8147158,0 C33.8147158,0 30.3910264,5.94519033 23.3470169,5.94519033 C16.5950619,5.94519033 12.8937761,0 12.8937761,0 L12.8822096,0 C12.8822096,0 7.08738399,7.34763065 0,5.84109166">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M129.501696,5.72253485 C122.258164,7.22907384 117.76168,0 117.76168,0 L117.753005,0 C117.753005,0 114.323533,5.94519033 107.279523,5.94519033 C100.53046,5.94519033 96.8262823,0 96.8262823,0 L96.8147158,0 C96.8147158,0 93.3910264,5.94519033 86.3470169,5.94519033 C79.5950619,5.94519033 75.8937761,0 75.8937761,0 L75.8822096,0 C75.8822096,0 70.087384,7.34763065 63,5.84109166">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M192.501696,5.72253485 C185.258164,7.22907384 180.76168,0 180.76168,0 L180.753005,0 C180.753005,0 177.323533,5.94519033 170.279523,5.94519033 C163.53046,5.94519033 159.826282,0 159.826282,0 L159.814716,0 C159.814716,0 156.391026,5.94519033 149.347017,5.94519033 C142.595062,5.94519033 138.893776,0 138.893776,0 L138.88221,0 C138.88221,0 133.087384,7.34763065 126,5.84109166">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M259.501696,5.72253485 C252.258164,7.22907384 247.76168,0 247.76168,0 L247.753005,0 C247.753005,0 244.323533,5.94519033 237.279523,5.94519033 C230.53046,5.94519033 226.826282,0 226.826282,0 L226.814716,0 C226.814716,0 223.391026,5.94519033 216.347017,5.94519033 C209.595062,5.94519033 205.893776,0 205.893776,0 L205.88221,0 C205.88221,0 200.087384,7.34763065 193,5.84109166">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M322.501696,5.72253485 C315.258164,7.22907384 310.76168,0 310.76168,0 L310.753005,0 C310.753005,0 307.323533,5.94519033 300.279523,5.94519033 C293.53046,5.94519033 289.826282,0 289.826282,0 L289.814716,0 C289.814716,0 286.391026,5.94519033 279.347017,5.94519033 C272.595062,5.94519033 268.893776,0 268.893776,0 L268.88221,0 C268.88221,0 263.087384,7.34763065 256,5.84109166">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M209.501696,25.7225349 C202.258164,27.2290738 197.76168,20 197.76168,20 L197.753005,20 C197.753005,20 194.323533,25.9451903 187.279523,25.9451903 C180.53046,25.9451903 176.826282,20 176.826282,20 L176.814716,20 C176.814716,20 173.391026,25.9451903 166.347017,25.9451903 C159.595062,25.9451903 155.893776,20 155.893776,20 L155.88221,20 C155.88221,20 150.087384,27.3476307 143,25.8410917">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M405.501696,5.72253485 C398.258164,7.22907384 393.76168,0 393.76168,0 L393.753005,0 C393.753005,0 390.323533,5.94519033 383.279523,5.94519033 C376.53046,5.94519033 372.826282,0 372.826282,0 L372.814716,0 C372.814716,0 369.391026,5.94519033 362.347017,5.94519033 C355.595062,5.94519033 351.893776,0 351.893776,0 L351.88221,0 C351.88221,0 346.087384,7.34763065 339,5.84109166">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M335.501696,25.7225349 C328.258164,27.2290738 323.76168,20 323.76168,20 L323.753005,20 C323.753005,20 320.323533,25.9451903 313.279523,25.9451903 C306.53046,25.9451903 302.826282,20 302.826282,20 L302.814716,20 C302.814716,20 299.391026,25.9451903 292.347017,25.9451903 C285.595062,25.9451903 281.893776,20 281.893776,20 L281.88221,20 C281.88221,20 276.087384,27.3476307 269,25.8410917">
                                </path>
                            </g>
                        </g>
                        <g transform="translate(373.000000, 263.000000)">
                            <g transform="translate(1.000000, 46.000000)" fill="#FFFFFF">
                                <polygon vector-effect="non-scaling-stroke"
                                    points="0.950683594 0.737792969 111.447754 0.737792969 118.248047 5 0.272460938 5 0.272460938 2.88110352">
                                </polygon>
                            </g>
                            <g>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                    stroke-width="3" cx="9" cy="28" r="5"></circle>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__stroke-secondary"
                                    stroke-width="3" cx="30" cy="9" r="9"></circle>
                                <polygon vector-effect="non-scaling-stroke" class="slds-illustration__fill-secondary"
                                    points="0.562988281 51 119.302246 51 123.863281 55 126.056641 62.4726562 114.675781 75.40625 22.7675781 75.40625 13.2597656 70.8320312 7.12890625 65.2988281">
                                </polygon>
                            </g>
                            <g transform="translate(0.000000, 25.000000)">
                                <path vector-effect="non-scaling-stroke"
                                    d="M3.63585436,21.5 L109.778306,21.5 C114.1037,21.5 118.186223,23.4993351 120.838196,26.9163632 L127.5,35.5 L120.800658,45.365147 C118.194742,49.2025017 113.857343,51.5 109.218799,51.5 L31.3324501,51.5 C22.8638438,50.9724099 16.3582376,48.8029983 11.8156315,44.9917652 C7.80246311,41.6247288 4.13067554,35.1206458 0.800268738,25.479516 L0.800270003,25.4795155 C0.259297765,23.9134656 1.09028748,22.2053865 2.65633747,21.6644143 C2.97143994,21.5555661 3.30248145,21.5 3.63585436,21.5 Z"
                                    class="slds-illustration__stroke-primary" stroke-width="3"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M142.258276,22.7729944 C138.748717,19.2634357 134.125785,17.4119596 128.242641,17.2426407 C128.41196,23.1257854 130.263436,27.7487171 133.772994,31.2582757 C137.282553,34.7678344 141.905485,36.6193105 147.788629,36.7886294 C147.619311,30.9054847 145.767834,26.282553 142.258276,22.7729944 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(138.015635, 27.015635) scale(-1, 1) translate(-138.015635, -27.015635) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M142.258276,40.7729944 C138.748717,37.2634357 134.125785,35.4119596 128.242641,35.2426407 C128.41196,41.1257854 130.263436,45.7487171 133.772994,49.2582757 C137.282553,52.7678344 141.905485,54.6193105 147.788629,54.7886294 C147.619311,48.9054847 145.767834,44.282553 142.258276,40.7729944 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(138.015635, 45.015635) scale(-1, -1) translate(-138.015635, -45.015635) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M81,-0.442349499 L81,21 C81,22.1045695 80.1045695,23 79,23 L61.5679702,23 C60.4634007,23 59.5679702,22.1045695 59.5679702,21 C59.5679702,20.49722 59.7573376,20.0128843 60.0983633,19.6434398 L79.2651966,-1.1206296 C79.6398003,-1.52645034 80.2724594,-1.5517567 80.6782801,-1.17715294 C80.8833623,-0.987846292 81,-0.721447545 81,-0.442349499 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M50,49.3234743 L50,62 C50,63.1045695 49.1045695,64 48,64 L38.0175677,64 C36.9129982,64 36.0175677,63.1045695 36.0175677,62 C36.0175677,61.4777575 36.2218416,60.9762301 36.5867303,60.602608 L48.2845813,48.6247784 C48.6704604,48.2296635 49.3035811,48.2221765 49.698696,48.6080556 C49.8913701,48.796226 50,49.0541578 50,49.3234743 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(43.000000, 57.000000) scale(1, -1) translate(-43.000000, -57.000000) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M100,49.3234743 L100,62 C100,63.1045695 99.1045695,64 98,64 L88.0175677,64 C86.9129982,64 86.0175677,63.1045695 86.0175677,62 C86.0175677,61.4777575 86.2218416,60.9762301 86.5867303,60.602608 L98.2845813,48.6247784 C98.6704604,48.2296635 99.3035811,48.2221765 99.698696,48.6080556 C99.8913701,48.796226 100,49.0541578 100,49.3234743 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(93.000000, 57.000000) scale(1, -1) translate(-93.000000, -57.000000) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M35,21.1948242 C37.6666667,25.7316081 39,30.6666667 39,36 C39,41.3333333 37.6666667,46.6666667 35,52"
                                    class="slds-illustration__stroke-primary" stroke-width="3"></path>
                                <path vector-effect="non-scaling-stroke" d="M39,35.5 L126,35.5"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="20" cy="31" r="4"></circle>
                            </g>
                        </g>
                        <g
                            transform="translate(244.500000, 274.000000) scale(-1, 1) translate(-244.500000, -274.000000) translate(190.000000, 251.000000)">
                            <g transform="translate(0.000000, 14.000000)" fill="#FFFFFF">
                                <polygon vector-effect="non-scaling-stroke"
                                    points="91.2460938 8.703125 83.0820312 0.9765625 0.1171875 0.9765625 3.26757813 11.6367188">
                                </polygon>
                            </g>
                            <g transform="translate(1.000000, 19.000000)" class="slds-illustration__fill-secondary">
                                <path vector-effect="non-scaling-stroke"
                                    d="M91.2900009,4 C89.5899629,2.01216046 88.5351253,1.01216046 88.1254883,1 C87.7158512,0.98783954 58.7033187,0.98783954 1.08789063,1 L0.375825695,4 L5.47167969,13.8271484 L20.2802734,18.5742188 C45.4534802,18.6571158 64.3715303,18.8351757 77.0344238,19.1083984 L83.2062537,18.4651753 L88.984375,13.8271484 L91.2900009,4 Z">
                                </path>
                            </g>
                            <g>
                                <path vector-effect="non-scaling-stroke"
                                    d="M0.0988502724,15.5 L79.1988702,15.5 C83.8798149,15.5 88.2510535,17.8394159 90.8475744,21.7341973 L94.0247762,26.5 L90.8475744,31.2658027 C88.2510535,35.1605841 83.8798149,37.5 79.1988702,37.5 L20.4692206,37.5 C14.9277254,37.0242117 10.6576403,35.3888654 7.65896553,32.5939611 C4.66029072,29.7990569 2.14025231,24.1010698 0.0988502724,15.5 Z"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M104.678431,16.8215691 C102.1744,14.317538 98.8265543,13.0459654 94.527077,13.027077 C94.5459654,17.3265543 95.817538,20.6743997 98.3215691,23.1784309 C100.8256,25.682462 104.173446,26.9540346 108.472923,26.972923 C108.454035,22.6734457 107.182462,19.3256003 104.678431,16.8215691 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(101.500000, 20.000000) scale(-1, 1) translate(-101.500000, -20.000000) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M104.678431,29.8215691 C102.1744,27.317538 98.8265543,26.0459654 94.527077,26.027077 C94.5459654,30.3265543 95.817538,33.6743997 98.3215691,36.1784309 C100.8256,38.682462 104.173446,39.9540346 108.472923,39.972923 C108.454035,35.6734457 107.182462,32.3256003 104.678431,29.8215691 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(101.500000, 33.000000) scale(-1, -1) translate(-101.500000, -33.000000) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M59,0.234153768 L59,17 L41.0828917,17 L57.2668751,-0.445940303 C57.6424807,-0.850833981 58.2752004,-0.874576673 58.6800941,-0.498971089 C58.8840774,-0.309742913 59,-0.0440844709 59,0.234153768 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M37,35.544829 L37,43.326584 C37,44.4311535 36.1045695,45.326584 35,45.326584 L29.7979244,45.326584 C28.6933549,45.326584 27.7979244,44.4311535 27.7979244,43.326584 C27.7979244,42.8043415 28.0021983,42.3028141 28.367087,41.929192 L35.2845813,34.846133 C35.6704604,34.4510182 36.3035811,34.4435312 36.698696,34.8294103 C36.8913701,35.0175807 37,35.2755125 37,35.544829 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(32.398962, 40.663292) scale(1, -1) translate(-32.398962, -40.663292) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M75,35.544829 L75,43.326584 C75,44.4311535 74.1045695,45.326584 73,45.326584 L67.7979244,45.326584 C66.6933549,45.326584 65.7979244,44.4311535 65.7979244,43.326584 C65.7979244,42.8043415 66.0021983,42.3028141 66.367087,41.929192 L73.2845813,34.846133 C73.6704604,34.4510182 74.3035811,34.4435312 74.698696,34.8294103 C74.8913701,35.0175807 75,35.2755125 75,35.544829 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(70.398962, 40.663292) scale(1, -1) translate(-70.398962, -40.663292) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M24,15 C26,18.8333333 27,22.6666667 27,26.5 C27,30.3333333 26,34.1666667 24,38"
                                    class="slds-illustration__stroke-primary" stroke-width="3"></path>
                                <path vector-effect="non-scaling-stroke" d="M29,26.5 L92,26.5"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="15.5" cy="22.5" r="2.5"></circle>
                            </g>
                        </g>
                        <g
                            transform="translate(174.500000, 341.000000) scale(-1, 1) translate(-174.500000, -341.000000) translate(120.000000, 318.000000)">
                            <g transform="translate(0.000000, 14.000000)" fill="#FFFFFF">
                                <polygon vector-effect="non-scaling-stroke"
                                    points="91.2460938 8.703125 83.0820312 0.9765625 0.1171875 0.9765625 3.26757813 11.6367188">
                                </polygon>
                            </g>
                            <g transform="translate(1.000000, 19.000000)" class="slds-illustration__fill-secondary">
                                <path vector-effect="non-scaling-stroke"
                                    d="M91.2900009,4 C89.5899629,2.01216046 88.5351253,1.01216046 88.1254883,1 C87.7158512,0.98783954 58.7033187,0.98783954 1.08789063,1 L0.375825695,4 L5.47167969,13.8271484 L20.2802734,18.5742188 C45.4534802,18.6571158 64.3715303,18.8351757 77.0344238,19.1083984 L83.2062537,18.4651753 L88.984375,13.8271484 L91.2900009,4 Z">
                                </path>
                            </g>
                            <g>
                                <path vector-effect="non-scaling-stroke"
                                    d="M0.0988502724,15.5 L79.1988702,15.5 C83.8798149,15.5 88.2510535,17.8394159 90.8475744,21.7341973 L94.0247762,26.5 L90.8475744,31.2658027 C88.2510535,35.1605841 83.8798149,37.5 79.1988702,37.5 L20.4692206,37.5 C14.9277254,37.0242117 10.6576403,35.3888654 7.65896553,32.5939611 C4.66029072,29.7990569 2.14025231,24.1010698 0.0988502724,15.5 Z"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M104.678431,16.8215691 C102.1744,14.317538 98.8265543,13.0459654 94.527077,13.027077 C94.5459654,17.3265543 95.817538,20.6743997 98.3215691,23.1784309 C100.8256,25.682462 104.173446,26.9540346 108.472923,26.972923 C108.454035,22.6734457 107.182462,19.3256003 104.678431,16.8215691 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(101.500000, 20.000000) scale(-1, 1) translate(-101.500000, -20.000000) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M104.678431,29.8215691 C102.1744,27.317538 98.8265543,26.0459654 94.527077,26.027077 C94.5459654,30.3265543 95.817538,33.6743997 98.3215691,36.1784309 C100.8256,38.682462 104.173446,39.9540346 108.472923,39.972923 C108.454035,35.6734457 107.182462,32.3256003 104.678431,29.8215691 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(101.500000, 33.000000) scale(-1, -1) translate(-101.500000, -33.000000) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M59,0.234153768 L59,17 L41.0828917,17 L57.2668751,-0.445940303 C57.6424807,-0.850833981 58.2752004,-0.874576673 58.6800941,-0.498971089 C58.8840774,-0.309742913 59,-0.0440844709 59,0.234153768 Z"
                                    class="slds-illustration__fill-primary"></path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M37,35.544829 L37,43.326584 C37,44.4311535 36.1045695,45.326584 35,45.326584 L29.7979244,45.326584 C28.6933549,45.326584 27.7979244,44.4311535 27.7979244,43.326584 C27.7979244,42.8043415 28.0021983,42.3028141 28.367087,41.929192 L35.2845813,34.846133 C35.6704604,34.4510182 36.3035811,34.4435312 36.698696,34.8294103 C36.8913701,35.0175807 37,35.2755125 37,35.544829 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(32.398962, 40.663292) scale(1, -1) translate(-32.398962, -40.663292) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M75,35.544829 L75,43.326584 C75,44.4311535 74.1045695,45.326584 73,45.326584 L67.7979244,45.326584 C66.6933549,45.326584 65.7979244,44.4311535 65.7979244,43.326584 C65.7979244,42.8043415 66.0021983,42.3028141 66.367087,41.929192 L73.2845813,34.846133 C73.6704604,34.4510182 74.3035811,34.4435312 74.698696,34.8294103 C74.8913701,35.0175807 75,35.2755125 75,35.544829 Z"
                                    class="slds-illustration__fill-primary"
                                    transform="translate(70.398962, 40.663292) scale(1, -1) translate(-70.398962, -40.663292) ">
                                </path>
                                <path vector-effect="non-scaling-stroke"
                                    d="M24,15 C26,18.8333333 27,22.6666667 27,26.5 C27,30.3333333 26,34.1666667 24,38"
                                    class="slds-illustration__stroke-primary" stroke-width="3"></path>
                                <path vector-effect="non-scaling-stroke" d="M29,26.5 L92,26.5"
                                    class="slds-illustration__stroke-primary" stroke-width="3" stroke-linecap="round">
                                </path>
                                <circle vector-effect="non-scaling-stroke" class="slds-illustration__fill-primary"
                                    cx="15.5" cy="22.5" r="2.5"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <!-- {% endmacro %} -->

    </div>

</template>

<script>
    export default {
        name: 'Illustrations',
        props: {
            image: String
        },
        components: {

        },
        methods: {

        },
        created() {

        }
    }
</script>