<template>
    <div id="help_widget" class="slds-is-fixed slds-grid slds-wrap slds-grid_align-end">
        <!-- slds-hide -->
        <section id="help_dialog" aria-describedby="help_dialog-body" aria-labelledby="help_dialog-heading" class="slds-col slds-size_1-of-1 slds-popover slds-nubbin_bottom-right slds-m-bottom_large" role="dialog" v-show="isShowing">
            <button class="slds-button slds-button_icon slds-button_icon-small slds-float_right slds-popover__close" title="Close dialog" @click="toggleShow">
                <svg class="slds-button__icon" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                </svg>
                <span class="slds-assistive-text">Close dialog</span>
            </button>
            <header class="slds-popover__header">
                <h2 class="slds-text-heading_medium" id="help_dialog-heading">FAQ</h2>
            </header>
            <div class="slds-popover__body slds-p-vertical_medium" id="help_dialog-body">
                <!-- {% for item in faq %} -->
                <a :href="item.link" target="_blank" class="slds-text-link_reset slds-media slds-media_center slds-m-bottom_medium" v-for="(item, index) in items" :key="index">
                    <div class="slds-media__body">
                        <p class="slds-text-heading_small">{{ item.label }}</p>
                    </div>
                    <div class="slds-media__figure slds-media__figure_reverse">
                        <span class="slds-icon_container slds-icon-utility-chevronright" title="Go to FAQ">
                            <svg class="slds-icon slds-icon-text-light slds-icon_x-small" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevronright"></use>
                            </svg>
                            <span class="slds-assistive-text">Go to FAQ</span>
                        </span>
                    </div>
                </a>
                <!-- {% endfor %} -->
            </div>

            <footer class="slds-popover__footer">
                <a :href="faqLink" target="_blank" class="slds-text-link_reset slds-media slds-media_center">
                    <div class="slds-media__body">
                        <p class="slds-text-color_weak">For other questions or feedback, read this <span class="slds-text-link">FAQ document</span>.</p>
                    </div>
                    <div class="slds-media__figure slds-media__figure_reverse">
                        <span class="slds-icon_container slds-icon-utility-chevronright" title="Go to FAQ">
                            <svg class="slds-icon slds-icon-text-light slds-icon_x-small" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevronright"></use>
                            </svg>
                            <span class="slds-assistive-text">Go to FAQ</span>
                        </span>
                    </div>
                </a>
            </footer>
        </section>
        <button id="help_bubble" class="slds-col slds-button slds-button_brand slds-p-horizontal_large slds-p-vertical_medium slds-text-heading_medium" @click="toggleShow">
            <svg class="slds-button__icon slds-button__icon_left slds-icon_small" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#question_mark"></use>
            </svg>Help
        </button>
    </div>
</template>

<script>
    import helpBubbleItems from '../json/help_bubble_items.json';

    export default {
        name: 'HelpBubble',
        data() {
            return {
                isShowing: false
            }
        },
        props: {
        
        },
        components: {

        },
        computed: {
            items() { return helpBubbleItems.items; },
            faqLink() { return helpBubbleItems.faq_document_link; },
        },
        methods: {
            toggleShow() {
                this.isShowing = !this.isShowing;
            }
        },
        created() {

        }
    }
</script>

