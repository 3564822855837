<template>
    <div>
        <section role="dialog" tabindex="-1" class="slds-modal slds-fade-in-open" id="install-success_modal" aria-labelledby="install-success_modal-label" aria-describedby="install-success_modal-content" aria-modal="true">
            <div class="slds-modal__container">
                <header class="slds-modal__header slds-modal__header_empty">
                    <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" @click="closeModal">
                        <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                        </svg>
                        <span class="slds-assistive-text">Close</span>
                    </button>
                </header>
                <div class="slds-modal__content slds-grid slds-grid_vertical" id="install-success_modal-content">
                    <div class="slds-col modal_illustration">
                        <img src="/assets/images/install_banner@2x.png" alt="Success Banner" />
                    </div>
                    <div class="slds-col slds-p-around_medium">
                        <div class="slds-welcome-mat__info-content slds-p-horizontal_small">
                            <h2 class="slds-welcome-mat__info-title" id="install-success_modal-label">Success!</h2>
                            <div class="slds-welcome-mat__info-description">
                                <div class="slds-text-longform">
                                    <h3 class="slds-text-heading_small" v-show="installResults.length > 1">{{ installResults.length }} new Demo Components are now installed on your Org.</h3>
                                    <h3 class="slds-text-heading_small" v-show="installResults.length == 1"><em>{{ installResults[0].demoComponent.name }}</em> is now installed on your org.</h3>
                                    <!-- <p class="slds-text-body_regular slds-text-color_weak slds-show_medium">Share the love and inspire your peers by posting this <a id="share_cta" href="javascript:void(0)">shareable URL</a> on Chatter.</p> -->
                                </div>
                            </div>
                            <div class="slds-welcome-mat__info-actions slds-m-bottom_medium">
                                <button class="slds-button slds-button_neutral close-modal_button" @click="closeModal">Stay Here</button>
                                <a class="slds-button slds-button_brand modal-submit-button" @click="goToHome">Go to the Home Page</a>
                            </div>
                        </div>
                  </div>
                </div>
            </div>
        </section>
        <div class="slds-backdrop slds-backdrop_open"></div>
    </div>
</template>

<script>
    export default {
        name: 'InstallSuccessModal',
        props: {
        
        },
        components: {

        },
        computed: {
            installResults() { return this.$store.getters.getInstallResults; },
        },
        methods: {
            closeModal() {
                this.$emit('close-modal');
            },
            goToHome() {
                this.$emit('go-to-home');
            }
        },
        created() {

        }
    }
</script>

