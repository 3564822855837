<template>
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->
    
    <div class="slds-size_1-of-1 slds-container_x-large slds-grid slds-wrap slds-gutters_direct-medium">
        <!-- {% extends "page-template.html" %}
        {% block content %}

        {% set bundle = bundles[0] %}

        {% import "macros/_bundles.html" as bundle_macro with context %} -->

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
            <!-- {% include "_back-button.html" %} -->
            <back-button />
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium" v-if="bundle != null">
            <div class="slds-grid slds-gutters_direct-x-small slds-grid_vertical-align-end slds-m-bottom_medium">
                <h1 class="slds-col slds-text-heading_large">{{ bundle.name }}</h1>
                <span v-if="demoComponents != null" class="slds-col slds-text-body_small slds-text-color_weak slds-m-bottom_xx-small">{{ demoComponents.length }} &nbsp;<span class="is_plural">item</span></span>
            </div>
            <div class="slds-m-bottom_small">
                <div class="slds-media slds-media_center">
                    <div class="slds-media__figure">
                        <span class="slds-icon_container slds-icon-utility-clock" title="Timestamp">
                            <svg class="slds-icon slds-icon-text-default slds-icon_xx-small" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#clock"></use>
                            </svg>
                            <span class="slds-assistive-text">Timestamp</span>
                        </span>
                    </div>
                    <div class="slds-media__body">
                        <span class="slds-text-body_small slds-text-color_weak">{{ getLastEditedString(bundle.lastmodifieddate) }}</span>
                    </div>
                </div>
            </div>
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_large" v-if="bundle != null">
            <div class="slds-box slds-box_small slds-theme_shade">
                <h3 class="slds-text-heading_small slds-m-bottom_small">Description</h3>
                <div class="slds-text-longform is-markdown slds-m-bottom_large">
                    {{ bundle.description__c }}
                </div>
                <div id="bundleCategories" class="">
                    <!-- <span class="slds-badge slds-badge_lightest slds-m-left_none slds-m-right_xx-small slds-m-bottom_xx-small slds-show_inline-block" v-for="(cloud, index) in bundle.clouds__c.split(';')" :key="index">{{ cloud }}</span>
                    <span class="slds-badge slds-badge_lightest slds-m-left_none slds-m-right_xx-small slds-m-bottom_xx-small slds-show_inline-block" v-for="(industry, index) in bundleIndustries" :key="index">{{ industry }}</span>
                    <span class="slds-badge slds-badge_lightest slds-m-left_none slds-m-right_xx-small slds-m-bottom_xx-small slds-show_inline-block" v-for="(feature, index) in bundleFeatures" :key="index">{{ feature }}</span> -->

                    <span class="slds-badge slds-badge_lightest slds-m-left_none slds-m-right_xx-small slds-m-bottom_xx-small slds-show_inline-block" v-for="(item, index) in pills" :key="index">{{ item }}</span>
                   
                </div>
            </div>
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_large slds-text-align_center" v-if="bundle != null">
            <button id="install-bundle_button" class="slds-button slds-button_brand slds-button_dual-stateful" aria-live="assertive" :disabled="(demoComponents == null) || (demoComponents != null && demoComponents.length == 0)" @click="installAll"><!-- slds-is-pressed -->
                <span class="slds-text-not-pressed">
                    <svg class="slds-button__icon slds-button__icon_small slds-button__icon_left" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#download"></use>
                    </svg>Install All
                </span>
                <!-- <span class="slds-text-pressed">
                    <svg class="slds-button__icon slds-button__icon_small slds-button__icon_left" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                    </svg>Installed
                </span> -->
            </button>
        </section>

        <section class="slds-wrap slds-grid slds-col slds-size_1-of-1 slds-m-bottom_xx-large">
            <div class="slds-col slds-size_1-of-1">
                <!-- {% import "macros/_demo-comp-list.html" as dc_listing with context %}
                {{ dc_listing.list(bundle.demo_components) }} -->
                <demo-cmp-list :demoComponents="demoComponents" />
            </div>
        </section>

        <section class="slds-col slds-size_1-of-1 slds-m-bottom_x-large slds-text-align_center" v-if="envVars != null">
            <!-- {{ bundle_macro.bundle_ideas() }} -->
            <h3 class="slds-text-heading_small">Have new ideas for featured bundles?</h3>
            <p> Submit your thoughts to <a :href="envVars.innovationBoxBundles">innovation BOX</a></p>
        </section>



    <!-- {% include "_install-prompt.html" %}
    {% include "_install-error-modal.html" %}
    {% include "_install-report-modal.html" %}
    {% endblock %} -->


    </div>
</template>

<script>
    import axios from "axios";
    import BackButton from "./BackButton.vue";
    import DemoCmpList from "./macros/DemoCmpList.vue";

    export default {
        name: 'BundleDetail',
        data() {
            return {
                bundle: null,
                demoComponents: null
            }
        },
        props: {
        
        },
        components: {
            backButton: BackButton,
            demoCmpList: DemoCmpList
        },
        computed: {
            // This shouldn't be a computed property, rather use the commented data one above
            // bundle() { return this.$store.getters.getFeaturedBundles[0]; },
            
            accessToken() { return this.$store.getters.getAccessToken; },
            bundleClouds() { return this.bundle == null ? [] : this.bundle.clouds__c.split(';'); },
            bundleIndustries() { return this.bundle == null ? [] : this.bundle.industries__c.split(';'); },
            bundleFeatures() { return this.bundle == null ? [] : this.bundle.features__c.split(';'); },
            envVars() { return this.$store.getters.getEnvVariables; },

            pills() {
                if(this.bundle == null) {
                    return [];
                } else {
                    var retVal = [];
                    if(this.bundle.clouds__c != null && this.bundle.clouds__c != '') {
                        retVal = retVal.concat(this.bundle.clouds__c.split(';'));
                    }
                    if(this.bundle.features__c != null && this.bundle.features__c != '') {
                        retVal = retVal.concat(this.bundle.features__c.split(';'));
                    }
                    if(this.bundle.industries__c != null && this.bundle.industries__c != '') {
                        retVal = retVal.concat(this.bundle.industries__c.split(';'));
                    }
                    return retVal;
                }
            }
        },
        created() {
            this.getBundleDetails();
            this.getDemoComponentsForBundle();
        },
        methods: {
            getLastEditedString(input) {
                var retVal = 'Last edited';
                var todaysDate = new Date();
                var lastUsedTime = new Date(input);
                var seconds = (todaysDate.getTime() - lastUsedTime.getTime()) / 1000;

                if(seconds > 31557600) {
                    var x = Math.floor(seconds / 31557600).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' years ago' : retVal + ' ' + x + ' year ago';
                } else if(seconds > 2629800) {
                    var x = Math.floor(seconds / 2629800).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' months ago' : retVal + ' ' + x + ' month ago';
                } else if(seconds > 604800) {
                    var x = Math.floor(seconds / 604800).toString();
                    if(x == 0) { x = 1; }
                    retVal = x >= 2 ? retVal + ' ' + x + ' weeks ago' : retVal + ' ' + x + ' week ago';
                } else if(seconds > 87660) {
                    var x = Math.floor(seconds / 87660).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' days ago' : retVal + ' ' + x + ' day ago';
                } else if(seconds > 3600) {
                    var x = Math.floor(seconds / 3600).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' hours ago' : retVal + ' ' + x + ' hour ago';
                } else if(seconds > 60) {
                    var x = Math.floor(seconds / 60).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' minutes ago' : retVal + ' ' + x + ' minute ago';
                } else {
                    var x = Math.floor(seconds).toString();
                    retVal = x >= 2 ? retVal + ' ' + x + ' seconds ago' : retVal + ' ' + x + ' second ago';
                }
                return retVal;
            },
            getBundleDetails() {
                var bundleId = this.$route.params.sfid;
                axios.post("/v1/getBundle", JSON.stringify({ token: this.accessToken, bundleId: bundleId })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('getBundleDetails :: ' , res.data.data.dc_bundle);
                        this.bundle = res.data.data.dc_bundle[0];
                    } else {
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            getDemoComponentsForBundle() {
                var self = this;
                var bundleId = this.$route.params.sfid;
                axios.post("/v1/getDemoComponentsForBundle", JSON.stringify({ token: this.accessToken, bundleId: bundleId })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        console.log('getDemoComponentsForBundle :: ' , res.data.data.dcs_bundle_items);
                        self.demoComponents = res.data.data.dcs_bundle_items;
                    } else {
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            installAll() {
                var tmpArray = [];
                // tmpArray.push(this.demoComponent);
                for(var i = 0; i < this.demoComponents.length; i++) {
                    tmpArray.push(this.demoComponents[i]);
                }
                this.$store.commit('setComponentsToInstall' , tmpArray);
            }
        },
        watch: { 
            $route(to, from) {  
                if(to !== from) { 
                    this.getBundleDetails();
                    this.getDemoComponentsForBundle();
                } 
            }
        }
    }
</script>

