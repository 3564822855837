<template>
    <!-- href="javascript:window.history.back();" -->
    <a class="slds-button" v-on:click="goBack">
        <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevronleft"></use>
        </svg>
        Back
    </a>
</template>

<script>
    export default {
        name: 'BackButton',
        props: {
        
        },
        components: {

        },
        methods: {
            goBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
            }
        },
        created() {

        }
    }
</script>

