<template>

    <article :id="content_type + '_card'" class="slds-card my-records-summary_card" v-if="type == 'preview'">
        <div class="slds-card__header slds-grid">
            <header class="slds-media slds-media_center slds-has-flexi-truncate">
                <div class="slds-media__figure">
                    <span :class="'slds-icon_container slds-icon-' + iconClass + '-' + iconId" :title="label">
                        <svg class="slds-icon slds-icon_small" aria-hidden="true">
                            <use :xlink:href="'/assets/icons/' + iconClass + '-sprite/svg/symbols.svg#' + iconId"></use>
                        </svg>
                        <span class="slds-assistive-text">{{ label }}</span>
                    </span>
                </div>
                <div class="slds-media__body">
                    <h2 class="slds-card__header-title">
                        <!-- <a href="www.google.com" class="slds-card__header-link slds-truncate" :title="label">
                            <span>{{ label }} <span class="list-length" v-if="demoComponents != null">({{ demoComponents.length }})</span></span>
                        </a> -->
                        <router-link :to="{ name: pageLink}" class="slds-card__header-link slds-truncate">
                            <span>{{ label }} <span class="list-length" v-if="demoComponents != null">({{ demoComponents.length }})</span></span>
                        </router-link>
                    </h2>
                </div>
            </header>
        </div>
        <div class="slds-card__body slds-card__body_inner slds-show_medium">
            <div class="empty-state" v-if="demoComponents != null && demoComponents.length == 0">
                <div class="slds-illustration slds-illustration_small slds-p-vertical_medium">
                    <illustrations :image="'open_chest'" v-if="contentType == 'favorites'" />
                    <illustrations :image="'hammock'" v-if="contentType == 'installed'" />
                    <illustrations :image="'axe'" v-if="contentType == 'owned'" />
                    <div class="slds-text-longform">
                        <p class="slds-text-body_regular">{{ emptyStateBlurb }}</p>
                        <router-link :to="{ name: 'DemoCmpNew'}">
                            <button class="slds-button slds-button_brand nowrap new_button" v-if="contentType == 'owned'">
                                <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#add"></use>
                                </svg>New
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="listing" :data-items="demoComponents.length" v-if="demoComponents != null && demoComponents.length > 0 && demoComponents.length < 3">
                <!-- <demo-cmp-list-item :demoComponent="obj" v-for="(obj, i) in demoComponents" :key="i" :type="'card'" /> -->
                <demo-cmp-list-item-for-card :demoComponent="obj" v-for="(obj, i) in demoComponents" :key="i" />
            </div>
            <div class="listing" :data-items="demoComponents.length" v-if="demoComponents != null && demoComponents.length > 0 && demoComponents.length >= 3">
                <!-- <demo-cmp-list-item :demoComponent="demoComponents[i-1]" v-for="i in 3" :key="i" :type="'card'" /> -->
                <demo-cmp-list-item-for-card :demoComponent="demoComponents[i-1]" v-for="i in 3" :key="i" />
            </div>
            <div class="null-state" v-if="demoComponents == null">
                <spinner :size="'small'"/>
            </div>
        </div>
        <footer class="slds-card__footer" v-if="demoComponents != null && demoComponents.length > 0">
            <!-- <a class="slds-card__footer-action" href="www.google.com">View All
                <span class="slds-assistive-text">{{ contentType }}</span>
            </a> -->
            <router-link :to="{ name: pageLink}" class="slds-card__footer-action">View All
                <span class="slds-assistive-text">{{ contentType }}</span>
            </router-link>
        </footer>
    </article>

</template>

<script>
    import Illustrations from './Illustrations.vue'
    import DemoCmpListItem from './DemoCmpListItem.vue'
    import DemoCmpListItemForCard from './DemoCmpListItemForCard.vue'
    import Spinner from './Spinner.vue'

    export default {
        name: 'CardsPreview',
        props: {
            demoComponents: Array,
            contentType: String, // favorites / installed / owned
            type: String, // preview / feedback / discussion,
            iconClass: String,
            iconId: String,
            label: String,
            emptyStateBlurb: String,
            pageLink: String // This should be a router link
        },
        components: {
            illustrations: Illustrations,
            demoCmpListItem: DemoCmpListItem,
            demoCmpListItemForCard: DemoCmpListItemForCard,
            spinner: Spinner
        },
        methods: {
            
        },
        created() {

        }
    }
</script>

