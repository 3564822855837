<template>

    <!-- {% macro visual_picker() %} -->
    <div class="slds-form-element__control slds-container_fluid slds-grid">
        <div class="slds-col slds-size_1-of-2 slds-p-right_x-small" v-on:click="setSource('Stockpile')">
            <div class="slds-visual-picker slds-container_fluid">
                <input type="radio" id="visual-picker_stockpile" value="visual-picker_stockpile" name="visual-picker_org" checked="checked"/>
                <label for="visual-picker_stockpile">
                    <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
                        <span>
                            <span class="slds-text-title">From</span>
                            <span class="slds-text-heading_small">Stockpile</span>
                        </span>
                    </span>
                    <span class="slds-icon_container slds-visual-picker__text-check">
                        <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                        </svg>
                    </span>
                </label>
            </div>
        </div>
        <div class="slds-col slds-size_1-of-2 slds-p-left_x-small" v-on:click="setSource('Authenticate')">
            <div class="slds-visual-picker slds-container_fluid">
                <input type="radio" id="visual-picker_auth" value="visual-picker_auth" name="visual-picker_org" />
                <label for="visual-picker_auth">
                    <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
                        <span>
                            <span class="slds-text-title">Authenticate with</span>
                            <span class="slds-text-heading_small">Username/Password</span>
                        </span>
                    </span>
                    <span class="slds-icon_container slds-visual-picker__text-check">
                        <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                        </svg>
                    </span>
                </label>
            </div>
        </div>
    </div>
    <!-- {% endmacro %} -->

       

</template>

<script>
    export default {
        name: 'SelectOrgVisualPicker',
        methods: {
            setSource: function(val){
                this.$store.commit('setAuthSource', val);
            }
        }
    }
</script>
