<style scoped>
    .custom_error {
        border-bottom: 1px solid #c23934;
    }
</style>
<template>
    
    <!-- COSHEA - this whole thing is in a div that should not be there, let's hope it doesn't mess up CSS  -->

    <div class="slds-size_1-of-1 slds-container_x-large slds-grid slds-wrap slds-gutters_direct-medium">
        <!-- {% extends "page-template.html" %}
        {% block header %} -->
        <spinner-large :size="'medium'" v-show="demoComponent == null"/>
        <spinner-large :size="'medium'" :showMessage="showMessage" :message="upsertMessage" v-show="upsertSpinner == true"/>

        <header class="slds-global-header_container">
            <div class="slds-global-header slds-p-vertical_medium height-auto">
                <div class="slds-grid slds-grid_align-spread ">
                    <div class="slds-global-header__item min-width">
                        <div class="slds-page-header__col-title">
                            <div class="slds-media slds-media_center">
                                <div class="slds-media__figure">
                                    <!-- <a href="./" class="slds-icon_container {{ app_icon_class }}" title="{{ app_icon }}">
                                        <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                            <use xlink:href="{{ slds_path }}{{ app_icon }}"></use>
                                        </svg>
                                        <span class="slds-assistive-text">{{ app_icon }}</span>
                                    </a> -->
                                    <router-link to="/" class="slds-icon_container slds-icon-custom-custom43" title="Home">
                                        <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                            <use xlink:href="/assets/icons/custom-sprite/svg/symbols.svg#custom43"></use>
                                        </svg>
                                        <span class="slds-assistive-text">Home</span>
                                    </router-link>
                                </div>
                                <div class="slds-media__body">
                                    <div class="slds-page-header__name">
                                        <div class="slds-page-header__name-title">
                                            <h1>
                                                <!-- <span class="slds-page-header__title slds-truncate" title="{{ app_name }} Admin">{{ app_name }}</span> -->
                                                <span class="slds-page-header__title slds-truncate" title="Demo Components">Demo Components</span>
                                            </h1>
                                        </div>
                                    </div>
                                    <!-- <p class="slds-page-header__name-meta slds-text-color_weak slds-show_small">{{ app_punchline }}</p> -->
                                    <p class="slds-page-header__name-meta slds-text-color_weak slds-show_small">Customized Components to add to your demo</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="slds-global-header__item">
                        <div class="slds-page-header__col-controls">
                            <div class="slds-page-header__controls">
                                <div class="slds-page-header__control">
                                    <button class="slds-button slds-button_neutral nowrap cancel-edit_button" v-on:click="navigateBack()">
                                        Cancel
                                    </button>
                                </div>
                                <div class="slds-page-header__control">
                                    <button class="slds-button slds-button_brand nowrap publish_button" @click="publishClicked()">
                                        Publish
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- {% endblock %}
        {% block content %}
        {% import "macros/_filters.html" as filters with context %} -->

        <div class="slds-col slds-align-bottom slds-size_1-of-1 slds-medium-size_2-of-3 slds-m-bottom_medium" :class="invalidName == true ?  'slds-form-element slds-has-error' : 'slds-form-element'" v-if="demoComponent != null">
            <input type="text" class="demo-cmp-title_input slds-text-heading_large slds-p-vertical_xx-small" :class="invalidName == true ?  'custom_error' : ''" placeholder="Demo Component Title" v-model="demoComponent.name" v-on:keyup="validationListener"/>
            <div class="slds-form-element__help" id="error-message-unique-id" v-if="invalidName == true">Enter a value.</div>
        </div>

        <div class="slds-col slds-align-bottom slds-size_1-of-1 slds-medium-size_1-of-3 slds-m-bottom_medium slds-grid slds-grid_align-end" v-if="demoComponent != null">
            <div class="slds-col slds-form-element">
                <div class="slds-form-element__control">
                    <div class="slds-combobox_container slds-size_x-small" @focusout="dropdownFocusOut()">
                        <div :class="showActiveDropdown ? 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open' : 'slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click'" aria-expanded="true" aria-haspopup="listbox" role="combobox"><!--  slds-is-open -->
                            <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                <input type="text" class="slds-input slds-combobox__input slds-combobox__input-value" id="combobox_demo-cmp-status" aria-controls="listbox_demo-cmp-status" autoComplete="off" role="textbox" placeholder="Select an Option" readonly="" :value="!demoComponent.draft__c ? 'Active' : 'Inactive'" @click="showActiveDropdown = !showActiveDropdown"/>
                                <span class="slds-icon_container slds-icon-utility-down slds-input__icon slds-input__icon_right" title="Status">
                                    <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                                    </svg>
                                    <span class="slds-assistive-text">Status</span>
                                </span>
                            </div>
                            <div id="listbox_demo-cmp-status" role="listbox" class="slds-dropdown slds-dropdown_fluid">
                                <ul class="slds-listbox slds-listbox_vertical" role="presentation">
                                    <!-- {% for status in ["Active", "Inactive"] %} -->
                                    <li role="presentation" class="slds-listbox__item" @click="statusChange(false)">
                                        <!-- <div id="listbox-option_demo-cmp-Active" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center {{ 'slds-is-selected' if loop.first }}" role="option"> -->
                                        <div id="listbox-option_demo-cmp-Active" :class="!demoComponent.draft__c ? 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center slds-is-selected' : 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center'" role="option">
                                            <span class="slds-media__figure">
                                                <svg class="slds-icon slds-icon_x-small slds-listbox__icon-selected" aria-hidden="true">
                                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                                </svg>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-truncate" title="Active">
                                                    <span class="slds-assistive-text" v-show="!demoComponent.draft__c">Current Selection:</span>
                                                    Active
                                                </span>
                                            </span>
                                        </div>
                                    </li>
                                    <li role="presentation" class="slds-listbox__item" @click="statusChange(true)">
                                        <div id="listbox-option_demo-cmp-Inactive" :class="demoComponent.draft__c ? 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center slds-is-selected' : 'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center'" role="option">
                                            <span class="slds-media__figure">
                                                <svg class="slds-icon slds-icon_x-small slds-listbox__icon-selected" aria-hidden="true">
                                                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                                </svg>
                                            </span>
                                            <span class="slds-media__body">
                                                <span class="slds-truncate" title="Inactive">
                                                    <span class="slds-assistive-text" v-show="demoComponent.draft__c">Current Selection:</span>
                                                    Inactive
                                                </span>
                                            </span>
                                        </div>
                                    </li>
                                    <!-- {% endfor %} -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="slds-col slds-size_1-of-1 slds-medium-size_2-of-3 slds-m-top_large" v-if="demoComponent != null">
            <section class="slds-col slds-size_1-of-1 slds-m-bottom_xx-small slds-grid slds-grid_vertical slds-m-bottom_medium">               
                <section id="select-org_form" class="slds-container_fluid slds-grid slds-grid_vertical slds-is-relative">
                    <div class="slds-form-element slds-form-element_stacked slds-is-editing has_value" id="demo-org-template" v-if="showPackageSelector==false">
                        <span class="slds-form-element__label">Demo Component Source</span>
                        <!-- <div class="slds-form-element__icon">
                        <button class="slds-button slds-button_icon field-level-help" aria-describedby="org_template-help">
                            <svg class="slds-button__icon" aria-hidden="true">
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/libs/salesforce-lightning-design-system-2.8.3/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
                            </svg>
                            <span class="slds-assistive-text">Help</span>
                        </button>
                        <div class="slds-popover slds-popover_tooltip slds-nubbin_bottom-left slds-hide" role="tooltip" id="org_template-help" style="position: absolute; top: -64px; left: -16px; width: 200px;">
                            <div class="slds-popover__body">Choose the correct demo environment based on your use case.</div>
                        </div>
                        </div> -->
                        <div class="slds-form-element__control">
                        <div class="slds-form-element__static">
                            <ul class="slds-list_horizontal slds-has-dividers_right slds-has-block-links"><li class="slds-item selected-org-name">{{demoComponent.package_url__c}}</li>
                            <li class="slds-item" @click="newPackage()"><a href="javascript:void(0);" id="open-org-selection-modal">Choose a different Demo Component source</a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div v-if="showPackageSelector==true">
                        <fieldset class="slds-form-element slds-container_fluid org-origin_visual-picker slds-m-bottom_xx-small" >
                            <legend class="slds-form-element__legend slds-form-element__label">Select the source your <em>package</em> will be pulled from</legend>
                            <div class="slds-form-element__icon slds-show_medium" @mouseover="hover = false" @mouseleave="hover = true">
                                <button class="slds-button slds-button_icon help_button-icon">
                                    <svg class="slds-button__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
                                    </svg>
                                    <span class="slds-assistive-text">Help</span>
                                </button>
                            </div>
                            <div class="slds-popover slds-popover_tooltip slds-popover_large slds-nubbin_bottom" :class="{'slds-hide' : hover}" role="tooltip" id="help" style="position:absolute;top:-95px;left:108px;">
                                <div class="slds-popover__body">
                                    <p>Demo Components can either be created out of a <em>Salesforce package</em> or from SFDX code hosted in a GitHub repo.</p>
                                    <p>To generate a <em>package</em> from your source org, go to Your Name > Setup > Create > Package.</p>                                    
                                </div>
                            </div>
                            <!-- {{ visual_picker() }} -->
                            <create-comp-visual-picker />
                        </fieldset>
                        <!-- {{ input_switcher() }} -->
                        <select-org-input-switcher v-bind:demoComponent="demoComponent" initialSource="Github"/>
                        <div v-if="authSource!='Github'">
                            <select-package />
                            <!-- {{ loading_spinner() }} -->
                            <select-org-loading-spinner />
                        </div>
                    </div>
                </section>
            </section>
            <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
                <div class="slds-box slds-box_xx-small slds-theme_shade">
                    <div class="slds-grid slds-wrap slds-gutters_direct-xx-small slds-m-horizontal_xx-small" id="new-demo-cmp-preview">
                  
                        <!-- COSHEA THE BELOW PART WAS ALREADY COMMENTED OUT -->
                        <!-- Image Preview to be generated dynamically -->
                        
                        <div class="slds-col slds-size_1-of-3 slds-m-vertical_x-small" v-for="(obj, i) in screenshots" :key="i">
                            <figure class="slds-image slds-image_card slds-is-relative" draggable @dragstart="onScreenshotDrag($event, obj)">
                                <div class="slds-is-absolute slds-container_fluid slds-grid slds-grid_align-end slds-p-bottom_medium slds-is-absolute delete-screenshot_action">
                                    <button class="slds-col slds-button slds-button_icon slds-m-around_xxx-small" title="delete" @click="removeScreenshot(i)">
                                        <svg class="slds-button__icon" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                        </svg>
                                        <span class="slds-assistive-text">Delete</span>
                                    </button>
                                </div>
                                <span class="slds-image__crop slds-image__crop_4-by-3 demo-cmp-screenshot">
                                  <img :src="obj.url__c" alt="" />
                                </span>
                            </figure>
                        </div>

                        <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-m-vertical_x-small">
                            <div :class="imageDragWrongFileType ?  'slds-form-element slds-has-error' : 'slds-form-element'">
                                <div class="slds-form-element__control">
                                    <div class="slds-file-selector slds-file-selector_images">
                                        <div :class="imageDragActive ? 'slds-file-selector__dropzone slds-theme_default slds-p-around_none slds-has-drag-over' : 'slds-file-selector__dropzone slds-theme_default slds-p-around_none'" @dragover.prevent="imageDragOver" @drop.prevent="imageDrop" @dragleave="imageDragOff">
                                            <input type="file" class="slds-file-selector__input slds-assistive-text" accept="image/*" id="file-upload-input_screenshot" aria-labelledby="file-selector-secondary-label" multiple="multiple" @change="uploadImage"/>
                                            <label class="slds-file-selector__body slds-p-around_medium" for="file-upload-input_screenshot" id="file-selector-secondary-label">
                                                <span class="slds-file-selector__button slds-button slds-button_neutral">
                                                    <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#upload"></use>
                                                    </svg>Upload&nbsp;Image</span>
                                                <span class="slds-file-selector__text slds-medium-show">or Drop Image</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="imageDragWrongFileType" class="slds-form-element__help" id="error-01">File type not supported</div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section class="slds-col slds-size_1-of-1 slds-m-bottom_medium">
                <div class="slds-box slds-box_small slds-theme_shade">
                    <div class="slds-text-align_right slds-m-bottom_xx-small" v-if="hasGitPackageUrl">
                        <button class="slds-button" @click="currentModal = 'import'">Import README from GitHub repo</button>
                    </div>
                    <textarea ref="textarea_feedback" id="textarea_feedback-body" class="slds-textarea slds-text-font_monospace" placeholder="Write a comprehensive description using Mardown formating" rows="25" v-model="demoComponent.description__c" @drop="onScreenshotDrop($event)" @dragover="onScreenshotDragOver($event)"></textarea>
                    <div class="slds-grid slds-wrap slds-grid_align-spread">
                        <button class="slds-col slds-m-top_xxx-small slds-button slds-button_neutral markdown-help_button" @click="currentModal = 'markdownHelp'">
                            <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#question"></use>
                            </svg>Markdown&nbsp;Help
                        </button>
                        <button class="slds-col slds-m-top_xxx-small slds-button slds-button_neutral markdown-preview_button" @click="currentModal = 'preview'">
                            <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#preview"></use>
                            </svg>Preview
                        </button>
                    </div>
                </div>
            </section>
        </section>
        <section class="slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-m-bottom_x-large slds-m-top_large" v-if="demoComponent != null">
            <fieldset id="demo-cmp_categories" class="slds-form-element">
                <legend class="slds-form-element__label">Select Categories</legend>
                <div :class="showProducts ? 'slds-section slds-is-open slds-m-top_none' : 'slds-section slds-m-top_none'">
                    <h3 class="slds-section__title ">
                        <button aria-controls="expando_product" aria-expanded="true" class="slds-button slds-section__title-action" @click="showProducts = !showProducts">
                            <svg class="slds-section__title-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                            </svg>
                            <span class="slds-truncate" title="Product">Product</span>
                        </button>
                    </h3>
                    <div aria-hidden="false" class="slds-section__content slds-p-horizontal_x-small" id="expando_product" v-show="showProducts">
                        <!-- {{ filters.checkbox_group('cloud', clouds) }} -->
                        <filters :type="'checkboxGroup'" :scope="'product'" :value="demoComponent.cloud__c" :values="clouds" v-on:filterChange="filterChange" />
                    </div>
                </div>
                <div :class="showIndustries ? 'slds-section slds-is-open' : 'slds-section'">
                    <h3 class="slds-section__title ">
                        <button aria-controls="expando_industry" aria-expanded="true" class="slds-button slds-section__title-action" @click="showIndustries = !showIndustries">
                            <svg class="slds-section__title-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                            </svg>
                            <span class="slds-truncate" title="Industry">Industry</span>
                        </button>
                    </h3>
                    <div aria-hidden="false" class="slds-section__content slds-p-horizontal_x-small" id="expando_industry" v-show="showIndustries">
                        <!-- {{ filters.checkbox_group('industry', industries) }} -->
                        <filters :type="'checkboxGroup'" :scope="'industry'" :value="demoComponent.industry__c" :values="industries" v-on:filterChange="filterChange" />
                    </div>
                </div>
                <div :class="showFeatures ? 'slds-section slds-is-open' : 'slds-section'">
                    <h3 class="slds-section__title ">
                        <button aria-controls="expando_feature" aria-expanded="true" class="slds-button slds-section__title-action" @click="showFeatures = !showFeatures">
                            <svg class="slds-section__title-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                            </svg>
                            <span class="slds-truncate" title="Feature">Feature</span>
                        </button>
                    </h3>
                    <div aria-hidden="false" class="slds-section__content slds-p-horizontal_x-small" id="expando_feature" v-show="showFeatures">
                        <!-- {{ filters.checkbox_group('feature', features) }} -->
                        <filters :type="'checkboxGroup'" :scope="'feature'" :value="demoComponent.feature__c" :values="features" v-on:filterChange="filterChange" />
                    </div>
                </div>
            </fieldset>
        </section>


          <!-- {% include "_categories-modal.html" %}
          {% include "_cancel-modal.html" %}
          {% include "_confirmation-modal.html" %}
          {% include "_markdown-help-modal.html" %}
          {% include "_markdown-preview-modal.html" %}
        {% endblock %} -->
        <div v-if="categoriesModal">
            <section  id="categories_modal" role="dialog" tabindex="-1" aria-labelledby="categories_modal-heading" aria-modal="true" aria-describedby="categories_modal-content" class="slds-modal slds-fade-in-open">
                <div class="slds-modal__container">
                    <header class="slds-modal__header">
                        <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close"  @click="closeCategoriesModal()">
                            <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                            </svg>
                            <span class="slds-assistive-text">Close</span>
                        </button>
                        <h2 id="categories_modal-heading" class="slds-text-heading_medium slds-hyphenate">Select Categories</h2>
                    </header>
                    <div class="slds-modal__content slds-p-around_medium" id="categories_modal-content">
                        <p>Please select a few categories that best describes your creation to help users find your Demo Component.</p>
                    </div>
                    <footer class="slds-modal__footer">
                        <button class="slds-button slds-button_neutral close-modal_button confirmation_button" @click="upsertComponent()">Publish Anyways</button>
                        <button class="slds-button slds-button_brand close-modal_button" @click="closeCategoriesModal()">Ok, I'll Set Categories</button>
                    </footer>
                </div>
            </section>
            <div class="slds-backdrop slds-backdrop_open"></div>
        </div>        
        <div v-if="confirmationModal">
            <section role="dialog" tabindex="-1" class="slds-modal slds-fade-in-open" id="confirmation_modal" aria-labelledby="confirmation_modal-label" aria-describedby="confirmation_modal-content" aria-modal="true">
                <div class="slds-modal__container">
                    <header class="slds-modal__header slds-modal__header_empty"></header>
                    <div class="slds-modal__content slds-grid slds-grid_vertical" id="confirmation_modal-content">
                        <div class="slds-col modal_illustration">
                            <img src="/assets/images/success_banner@2x.png" alt="Success Banner" />
                        </div>
                        <div class="slds-col slds-p-around_medium">
                            <div class="slds-welcome-mat__info-content">
                                <h2 class="slds-welcome-mat__info-title" id="confirmation_modal-label">Congratulations!</h2>
                                <div class="slds-welcome-mat__info-description">
                                    <div class="slds-text-longform">
                                        <h3 class="slds-text-heading_medium">Your Demo Component is published.</h3>
                                        <p class="slds-text-body_regular slds-text-color_weak">Share your Demo Component with your co-workers on Chatter with the tag <a href="javascript:void(0);"><em>#DemoComponentSpotlight</em></a>.</p>
                                    </div>
                                </div>
                                <div class="slds-welcome-mat__info-actions slds-m-bottom_medium">
                                    <a class="slds-button slds-button_brand modal-submit-button" v-bind:href="newDCURL">Go to the Demo Component Page</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="slds-backdrop slds-backdrop_open"></div>
        </div>
        <markdown-help-modal v-if="currentModal == 'markdownHelp'" v-on:closeModal="closeAllModals"/>
        <markdown-preview-modal v-if="currentModal == 'preview'" v-on:closeModal="closeAllModals" :markdownHtml="componentDescriptionMarkdown"/>
        <markdown-import-modal v-if="currentModal == 'import'" v-on:closeModal="closeAllModals" v-on:importReadme="importReadmeFromGithub" :packageUrl="demoComponent.package_url__c"/>

        <confetti-celebration ref="celebration" />
        
    </div>

</template>

<script>
    import axios from "axios";
    import Filters from './macros/Filters.vue'
    import cloudJson from '../json/clouds.json';
    import featuresJson from '../json/features.json';
    import industriesJson from '../json/industries.json';
    import SpinnerLarge from "./macros/SpinnerLarge.vue";
    import MarkdownHelpModal from './MarkdownHelpModal.vue'
    import MarkdownPreviewModal from './MarkdownPreviewModal.vue'
    import MarkdownImportModal from './MarkdownImportModal.vue'
    import CancelModal from './CancelModal.vue' 
    import CreateCompVisualPicker from './macros/CreateCompVisualPicker.vue'
    import SelectOrgInputSwitcher from './macros/SelectOrgInputSwitcher.vue'
    import SelectOrgLoadingSpinner from './macros/SelectOrgLoadingSpinner.vue'
    import SelectPackage from './PackageInput.vue'
    import ConfettiCelebration from './ConfettiCelebration.vue'

    export default {
        name: 'DemoCmpNew',
        data() {
            return {
                demoComponent: {
                    archived__c: false,
                    usage__c: '',
                    draft__c: '',
                    under_review__c: '',
                    install_link_broken_check__c: '',
                    industry__c: '',
                    number_of_attachments__c: '',
                    description__c: '',
                    rating_average__c: '',
                    lastvieweddate: '',
                    version__c: '',
                    name: '',
                    install_link_broken__c: '',
                    feature__c: '',
                    device__c: '',
                    cloud__c: '',
                    most_recent_usage_date__c: '',
                    appexchange_url__c: '',
                    package_url__c: '',
                    isdeleted: '',
                    hours_logged__c: '',
                    sum_rating__c: '',
                    image_url__c: '',
                    user__c: '',
                    author__c: '',
                    thread__c: '',
                    feedback_total__c: '',
                    review_count__c: '',
                    externalid__c: '',
                    lastreferenceddate: '',
                    limbo_mode__c: '',
                    q_approved__c: '',
                    number_of_favorites__c: '',
                    sfid: '',
                    id: ''
                },
                screenshots: null,
                attachements: null,
                showProducts: true,
                showIndustries: true,
                showFeatures: true,
                showActiveDropdown: false,
                cloudsList: '',
                featuresList: '',
                industriesList: '',
                currentModal: '', // markdownHelp / preview
                categoriesModal: false,
                confirmationModal: false,
                newDCURL: '',
                upsertSpinner: false,
                // Image upload stuff
                imageDragActive: false,
                imageDragWrongFileType: false,
                tmpHolder: 'Write a short summary to explain the purpose of your component \n\n' +
                            '##Description  \n\n' +
                            'Here comes your component description  \n\n' +
                            '##Setup Instructions  \n\n' +
                            'Here goes the setup instructions for your component \n\n',
                showPackageSelector: true,
                screenshotsToUpload: [],
                invalidName: false,
                invalidPackage: false,
                showMessage: false,
                upsertMessage: '',
                hover: true,

                collaborators: null,
            }
        },
        components: {
            filters: Filters,
            spinnerLarge: SpinnerLarge,
            markdownHelpModal: MarkdownHelpModal,
            markdownPreviewModal: MarkdownPreviewModal,
            markdownImportModal: MarkdownImportModal,
            cancelModal: CancelModal,
            createCompVisualPicker: CreateCompVisualPicker,
            selectOrgInputSwitcher: SelectOrgInputSwitcher,
            selectOrgLoadingSpinner: SelectOrgLoadingSpinner,
            selectPackage: SelectPackage,
            confettiCelebration: ConfettiCelebration
        },
        computed: {
            authSource() {return this.$store.getters.getAuthSource;},
            clouds() { return cloudJson.clouds; },
            features() { return featuresJson.features; },
            industries() { return industriesJson.industries; },
            accessToken() { return this.$store.getters.getAccessToken; },
            sessionDetails() { return this.$store.getters.sessionDetails; },
            hasGitPackageUrl() { return (this.demoComponent != null && this.demoComponent.package_url__c != null && this.demoComponent.package_url__c != '') ? true : false; },
            componentDescriptionMarkdown() {
                // var showdown = require('showdown');
                // var converter = new showdown.Converter();
                // return converter.makeHtml(this.demoComponent.description__c);

                if(this.demoComponent.description__c == null) {
                    return '';
                }

                var showdown = require('showdown');
                showdown.extension('targetlink', function() {
                    return [
                        {
                            type: 'html',
                            regex: /(<a [^>]+?)(>.*<\/a>)/g,
                            replace: '$1 target="_blank"$2'
                        }
                    ]
                });
                var converter = new showdown.Converter({
                    strikethrough: true,
                    tables: true,
                    extensions: ['targetlink']
                });
                var html = converter.makeHtml(this.demoComponent.description__c);
                html = html.replace(/<h1/g, '<h1 class="slds-text-heading--large"');
                html = html.replace(/<h2/g, '<h2 class="slds-text-heading--medium"');
                html = html.replace(/<h3/g, '<h3 class="slds-text-heading--small"');
                html = html.replace(/<h4/g, '<h4 class="slds-text-heading--caps"');
                html = html.replace(/<h5/g, '<h5 class="slds-text-title"');
                html = html.replace(/<ul/g, '<ul class="slds-list_dotted"');
                html = html.replace(/\[button-brand\]<a\s/g, '<a class="slds-button slds-button--brand" target="_blank"');
                html = html.replace(/\[button\]<a\s/g, '<a class="slds-button slds-button--neutral" target="_blank"');
                return html;
            },
        },
        methods: {
            doInit() {
                var componentId = this.$route.params.sfid;
                this.screenshotsToUpload = [];
                if(componentId == null || componentId == undefined) {
                    console.log('IT DOES NOT EXIST');
                    this.initiateNewDemoComponent();
                    this.screenshots = [];
                    this.attachements= [];
                } else {
                    console.log('IT EXISTS :: ' + componentId);
                    this.getComponentById();
                    this.getDemoComponentCollaboratorsBySFID();
                }
            },
            newPackage(){
                this.showPackageSelector = (this.showPackageSelector === true ? false : true);
                console.log(this.showPackageSelector);
                return this.showPackageSelector;
            },
            initiateNewDemoComponent() {
                // Need logic in here to create component based on template
                this.demoComponent = {
                    "archived__c": false,
                    "usage__c": 0.0,
                    "draft__c": false,
                    "under_review__c": false,
                    "install_link_broken_check__c": null,
                    "industry__c": '',
                    "number_of_attachments__c": 0.0,
                    "description__c": this.tmpHolder,
                    "rating_average__c": 4.0,
                    "lastvieweddate": null,
                    "version__c": 1.0,
                    "name": "",
                    "install_link_broken__c": false,
                    "feature__c": '',
                    "device__c": null,
                    "cloud__c": '',
                    "most_recent_usage_date__c": null,
                    "appexchange_url__c": null,
                    "package_url__c": null,
                    "isdeleted": false,
                    "hours_logged__c": 0.0,
                    "sum_rating__c": 0.0,
                    "image_url__c": "",
                    "user__c": null,
                    "author__c": "",
                    "thread__c": null,
                    "feedback_total__c": 0.0,
                    "review_count__c": 0.0,
                    "externalid__c": null,
                    "lastreferenceddate": null,
                    "limbo_mode__c": false,
                    "q_approved__c": false,
                    "number_of_favorites__c": 0.0,
                    "sfid": null,
                    "id": null
                };
            },
            getComponentById() {
                var self = this;
                var componentId = this.$route.params.sfid;
                axios.post("/v1/getDemoComponentDetail", JSON.stringify({ token: this.accessToken, componentId: componentId })).then((res) => {
                    if (!res.data.error) {
                        console.log('getComponentById :: ' , res.data.data);
                        
                        self.demoComponent = res.data.data;
                        self.attachments = res.data.data.attachments;
                        self.screenshots = res.data.data.screenshots;
                        if(self.demoComponent.cloud__c == null){
                            self.demoComponent.cloud__c = '';
                        }
                        if(self.demoComponent.industry__c == null){
                            self.demoComponent.industry__c = '';
                        }
                        if(self.demoComponent.feature__c == null){
                            self.demoComponent.feature__c = '';
                        }
                        if(self.demoComponent.package_url__c == null || self.demoComponent.package_url__c == ''){
                            this.showPackageSelector = true;
                        }else{
                            this.showPackageSelector = false;
                        }

                        this.redirectIfNotOwnerOrCollaborator();
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_ERROR', toastLengthInSeconds: 15 });
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_ERROR', toastLengthInSeconds: 15 });
                    console.log(err);
                });
            },
            selectCategories() {                
                //validation
                var packageInfo = this.$store.getters.getSelectedOrgPackage; 
                if(this.demoComponent.name == ''){
                    this.invalidName = true;                
                }
                // else if(packageInfo.packageId == ''){
                //     this.invalidPackage = true;
                // }
                else{
                    if (this.demoComponent.cloud__c=='' && this.demoComponent.industry__c=='' && this.demoComponent.feature__c==''){
                        this.categoriesModal = true;
                    }else{                    
                        this.upsertComponent();
                    }
                }
            },
            validationListener(){
                if(this.invalidName == true && this.demoComponent.name != ''){
                    this.invalidName = false;
                }
                if(this.invalidPackage == true && this.demoComponent.name != ''){
                    this.invalidPackage = false;
                }
            },
            closeCategoriesModal(){
                this.categoriesModal = (this.categoriesModal === true ? false : true);
                return this.categoriesModal;
            },
            upsertComponent() {
                // var self = this;
                // var componentId = this.$route.params.sfid;
                this.upsertMessage = '';
                this.showMessage = true;
                this.upsertSpinner = true;

                // if(this.demoComponent.package_url__c != null && this.demoComponent.package_url__c != ''){                            
                //     this.upsertDemoComponent(null);
                // }
                // else{
                    //if edit i.e. componentId exists and no package required to update then skip and go to upsertDemoComponent
                    var packageInfo = this.$store.getters.getSelectedOrgPackage;            
                    console.log('packageInfo: ', packageInfo);

                    if (packageInfo!=null){
                        var orgAuthInfo = this.$store.getters.getSelectedOrgAuthDetails;            
                        
                        var repositoryName = this.demoComponent.name.replace(/\w+/g,function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();}).replace(/\s+/g, '')+packageInfo.packageId;
                        if(this.demoComponent.package_url__c != null){
                            repositoryName = this.demoComponent.package_url__c.substring(this.demoComponent.package_url__c.indexOf("sfdc-qbranch/") + 13)
                        }                        
                        console.log('repositoryName: ', repositoryName);
                        
                        this.upsertMessage = 'Extracting package 0%';
                        axios.post("/v1/extractPackage", JSON.stringify({ accessToken: orgAuthInfo.accessToken, instanceUrl: orgAuthInfo.instanceUrl, packageId: packageInfo.packageId, repositoryName: repositoryName})).then(res => {                        
                            if (!res.data.error) {
                                console.log('extractPackage RETURNED :: ' , res.data.data);
                                this.pollExtractStatus(res.data.data.extractJobId);                                                                                                                          
                            } else {
                                console.log(res);                            
                            }
                        }).catch(err => {
                            console.log('Exception :: extractPackage :: ' + err);                        
                        });      
                    }else{
                        this.upsertDemoComponent(null);
                    }
                // }
                                
            },
            pollExtractStatus(jobId){                
                var poll = setInterval(() => {
			        axios.get("/v1/extractPackageStatus", { params: {"jobId": jobId} })
                    .then(res => {                                        
                        if (res.data.data.error !=true){
                            if (res.data.data.extractStatus =='completed'){
                                console.log('extractPackageStatus RETURNED :: ' , res.data);
                                this.upsertMessage = 'Extracting package complete';  
                                
                                this.demoComponent.package_url__c = res.data.data.extractResult;                                
                                this.upsertDemoComponent(poll);     
                                                         
                            }
                            else{
                                console.log(res.data.data);
                                this.upsertMessage = 'Extracting package '+res.data.data.extractProgress+'%';
                            }
                        }else{
                            console.log(res.data)
                            clearInterval(poll);
                            this.upsertMessage = '';
                            this.showMessage = false;
                            this.upsertSpinner = false;
                        }
                    }).catch(err => {
                        console.log('Exception :: extractPackageStatus :: ' + err);   
                        clearInterval(poll);    
                        this.upsertMessage = '';
                        this.showMessage = false;
                        this.upsertSpinner = false;                 
                    });
		        }, 5000)
            },
            upsertDemoComponent(poll){
                if(this.demoComponent.sfid == null){
                    this.upsertMessage = 'Inserting Demo Component';
                }
                else{
                    this.upsertMessage = 'Updating Demo Component';
                }

                let products = this.demoComponent.cloud__c;
                if(products !== null){
                    if(products.endsWith(";")){}
                    else{
                        if (products != ''){
                            if (products == 'null;'){
                                products = null;      
                            }else{
                                products += ';';
                            }
                        }else{
                            products = null;
                        }
                    }
                }
                
                let industries = this.demoComponent.industry__c;
                if(products !== null){
                    if(industries.endsWith(";")){}
                    else{
                        if (industries != ''){
                            if (industries == 'null;'){
                                industries = null;      
                            }else{
                                industries += ';';
                            }
                        }else{
                            industries = null;
                        }
                    }
                }

                let features = this.demoComponent.feature__c;
                if(features !== null){
                    if(features.endsWith(";")){}
                    else{
                        if (features != ''){
                            if (features == 'null;'){
                                features = null;      
                            }else{
                                features += ';';
                            }
                        }else{
                            features = null;
                        }
                    }
                }
                var sessionDetails = this.$store.state.sessionDetails;                
                axios.post("/v1/upsertDemoComponent", JSON.stringify({ token: this.accessToken, user: sessionDetails.user.email, componentId: this.demoComponent.sfid,
                        name: this.demoComponent.name, package_url: this.demoComponent.package_url__c, description: this.demoComponent.description__c,
                        products: products, industries: industries, features: features, active: this.demoComponent.draft__c })).then((res) => {
                    if (!res.data.error) {
                        console.log('upsertComponent result :: ' , res.data.data);                        
                        this.pollDemoComponentStatus(res.data.data.dc.id);                                
                                        
                        if (poll!=null){
                            clearInterval(poll);
                        }
                        // this.upsertSpinner = false;
                    } else {
                        console.log(res.data.error);
                        if (poll!=null){
                            clearInterval(poll);
                        }
                        this.upsertMessage = '';
                        this.showMessage = false;
                        this.upsertSpinner = false;
                    }
                }).catch((err) => {
                    console.log(err);
                    if (poll!=null){
                        clearInterval(poll);
                    }
                    this.upsertMessage = '';
                    this.showMessage = false;
                    this.upsertSpinner = false;
                });
            },
            pollDemoComponentStatus(dcid){
                var poll = setInterval(() => {
                    axios.get("/v1/upsertDemoComponentStatus", { params: {"token": this.accessToken, "id": dcid} })
                    .then(res => {   
                        if (!res.data.error) {
                            if (res.data.data.status =='UPDATED' || res.data.data.status =='SYNCED'){
                                console.log(res.data.data);
                                clearInterval(poll);                                
                                this.newDCURL='/#/demo-component-detail/'+res.data.data.sfid;  
                                
                                this.$store.commit('setDemoOrgPackages', null);
                                this.$store.commit('setSelectedOrgPackage', null);
                                this.$store.commit('setSelectedOrgAuthDetails', null);

                                
                                
                                if(this.screenshotsToUpload!== undefined && this.screenshotsToUpload.length>0){    
                                    this.upsertMessage = 'Uploading Images';                                                             
                                    let formData = new FormData();
                                    formData.append('demo_component_id', JSON.stringify(res.data.data.sfid));
                                    formData.append('screenshots', JSON.stringify(this.screenshotsToUpload));
                                    
                                    //axios.post("https://q-dc-core-staging-v2.herokuapp.com/api/v1/upload-images", formData,
                                    axios.post("https://q-dc-core-v2.herokuapp.com/api/v1/upload-images", formData,
                                    {
                                        headers: {
                                            "Authorization": this.accessToken,   
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    }
                                    ).then((res) => {
                                        if (!res.data.error) {
                                            console.log('CSLOG uploadImages result :: ' , res.data);
                                            this.qSearchSync();
                                            this.upsertMessage = '';
                                            this.showMessage = false;
                                            this.upsertSpinner = false;
                                            this.confirmationModal = true;
                                            this.$refs.celebration.doTheCelebration();
                                        } else {
                                            console.log('CSLOG uploadImages Error :: ' ,res.data);
                                        }
                                    }).catch((err) => {
                                        console.log('CSLOG uploadImages Error :: ' ,err);
                                        this.upsertMessage = '';
                                        this.showMessage = false;
                                        this.upsertSpinner = false;
                                    });
                                }else{
                                    this.upsertMessage = '';
                                    this.showMessage = false;
                                    this.upsertSpinner = false;
                                    this.confirmationModal = true;
                                    this.$refs.celebration.doTheCelebration();
                                }
                            }
                            else if (res.data.data.status =='FAILED'){
                                console.log(res.data);
                                clearInterval(poll);
                                this.upsertMessage = '';
                                this.showMessage = false;
                                this.upsertSpinner = false;
                            }
                            else{
                                console.log(res.data.data);
                            }                            
                        } else {
                            console.log(res.data);
                            clearInterval(poll);
                            this.upsertMessage = '';
                            this.showMessage = false;
                            this.upsertSpinner = false;
                        }
                    }).catch((err) => {
                        console.log(err);
                        clearInterval(poll);
                        this.upsertMessage = '';
                        this.showMessage = false;
                        this.upsertSpinner = false;
                    });
                }, 5000) 
            },            
            navigateBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
            },
            filterChange(scope, value) {   
                if(scope == 'product') {                                           
                    this.demoComponent.cloud__c = value;
                }
                if(scope == 'industry') {    
                    this.demoComponent.industry__c = value;
                }
                if(scope == 'feature') {  
                    this.demoComponent.feature__c = value;
                }
            },
            publishClicked() {
                this.selectCategories();         
            },
            statusChange(status) {
                this.demoComponent.draft__c = status;
            },
            dropdownFocusOut() {
                var self = this;
                setTimeout(function() { self.showActiveDropdown = false; }, 200);
            },
            closeAllModals() {
                this.currentModal = '';
            },
            importReadmeFromGithub() {
                console.log('importReadmeFromGithub called.');

                var params = {
                    packageUrl: this.demoComponent.package_url__c
                };
                console.log('params: ', params);
                axios.post("/v1/getReadmeContents", JSON.stringify(params)).then((res) => {
                    if (!res.data.error && res.data) {
                        this.demoComponent.description__c = res.data.data.body;
                        this.currentModal = '';
                    } else {
                        console.log('importReadmeFromGithub error.', res.data.error);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            onScreenshotDrag(event, item) {
                event.dataTransfer.dropEffect = 'move'
                event.dataTransfer.effectAllowed = 'move'
                event.dataTransfer.setData('text/plain', item.url__c)
            },
            onScreenshotDrop(event) {
                const imageUrl = event.dataTransfer.getData('text/plain');
                var tArea = this.$refs.textarea_feedback;
                var cursorPos = tArea.selectionStart;
                var description = this.demoComponent.description__c;
                //If cursor position is at the end of the description then insert images at the start.
                if(tArea.value.length == cursorPos){
                    this.demoComponent.description__c = imageUrl +"\n"+  description;
                }
                else{
                    this.demoComponent.description__c = description.substring(0, cursorPos) + imageUrl + description.substring(cursorPos, description.length);
                }
                
            },
            onScreenshotDragOver(event) {
                event.preventDefault();
            },



            // Image upload and drop stuff...
            imageDrop(event) {
                this.imageDragWrongFileType = false;
                this.imageDragActive = false;
                let allFiles = event.dataTransfer.files;
                for(var i = 0; i < allFiles.length; i++){
                    this.handleFile(allFiles[i]);
                }
            },
            imageDragOver(event) {
                this.imageDragWrongFileType = false;
                this.imageDragActive = true;
            },
            imageDragOff(event) {
                this.imageDragActive = false;
            },
            uploadImage(event) {
                this.imageDragWrongFileType = false;
                let allFiles = [];
                if(event.srcElement){ 
                    allFiles = event.srcElement.files; }
                else{
                    allFiles = event.target.files;
                }
                for(var i = 0; i < allFiles.length; i++){                    
                    this.handleFile(allFiles[i]);                    
                }
            },
            handleFile(file) {
                
                if(file.type == 'image/png' || file.type == 'image/gif' || file.type == 'image/jpeg') {
                    var fileName = file.name.replace(/(?!\.[^.]+$)\.|[^\w.]+/g, '');
                    var f = {
                        name: fileName,
                        type: file.type,
                        // url: '',
                        url__c: '',
                        blob: '',
                        // width: 0,
                        // height: 0,
                        width__c: 0,
                        height__c: 0,
                        lastModifiedDate: file.lastModifiedDate,
                        size: file.size
                    };
                    var self = this;
                    var reader = new FileReader();
                    reader.onload = function(fre) {
                        var img = new Image;
                        img.onload = function(){
                            // f.width = img.width;
                            // f.height = img.height;
                            f.width__c = img.width;
                            f.height__c = img.height;
                        }
                        img.src = fre.target.result;
                        var split = fre.target.result.split('base64,', 2);
                        f.blob = split[1];
                        // f.url = fre.target.result;
                        f.url__c = fre.target.result;
                        self.screenshots.push(f);
                        self.screenshotsToUpload.push(f);
                    };
                    reader.readAsDataURL(file);
                    console.log(this.screenshotsToUpload);
                } else {
                    // Need to raise some errors here - wrong image types
                    this.imageDragWrongFileType = true;
                }
            },
            removeScreenshot(indexVal) {
                if(this.demoComponent.sfid != null){
                    console.log('delete from the server');
                    this.upsertSpinner = true;
                    var screenshot = this.screenshots[indexVal];
                    console.log(screenshot);
                    if(screenshot.amazon_s3_key__c != undefined){
                        axios.post("https://q-dc-core-v2.herokuapp.com/api/v1/delete-images", JSON.stringify({ amazonS3Key: screenshot.amazon_s3_key__c, componentId: this.demoComponent.sfid }),
                            {
                                headers: {
                                    "Authorization": this.accessToken,   
                                    'Content-Type': 'application/json'
                                }
                            }
                            ).then((res) => {
                                if (!res.data.error) {
                                    console.log('removeScreenshot result :: ' , res.data);                                      
                                    this.screenshots.splice(indexVal, 1);
                                    this.upsertSpinner = false;                                
                                } else {
                                    console.log(res.data);
                                }
                            }).catch((err) => {
                                console.log(err);
                                this.upsertSpinner = false;
                            });   
                    }             
                    else{
                        console.log('delete from the client');                     
                        var found = this.screenshotsToUpload.find(element => element.name == this.screenshots[indexVal].name);
                        var i = this.screenshotsToUpload.indexOf(found);
                        this.screenshotsToUpload.splice(i, 1);
                        
                        this.screenshots.splice(indexVal, 1); 
                        this.upsertSpinner = false;    
                    }    
                }
                else{
                    console.log('delete from the client');                     
                    var found = this.screenshotsToUpload.find(element => element.name == this.screenshots[indexVal].name);
                    var i = this.screenshotsToUpload.indexOf(found);
                    this.screenshotsToUpload.splice(i, 1);
                    
                    this.screenshots.splice(indexVal, 1); 
                    this.upsertSpinner = false;    
                    
                }
            },
            redirectIfNotOwnerOrCollaborator() {
                var componentId = this.$route.params.sfid;
                if(componentId != null && componentId != undefined && this.demoComponent != null && this.collaborators != null && this.demoComponent.ownerid != null && this.demoComponent.ownerid != undefined) {
                    // In Edit Mode
                    if((this.demoComponent.ownerid == this.sessionDetails.user.sfid) || ((this.collaborators.filter((x) => { return (x.author != null && x.author.sfid == this.sessionDetails.user.sfid) }).length > 0))) {
                        console.log('theyre okay I say');
                    } else {
                        console.log('should do a redirect here...');
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'ERROR_DEMO_COMPONENT_PERMISSION', toastLengthInSeconds: 15 });
                        // window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
                        this.$router.push('/')
                    }
                }
            },
            getDemoComponentCollaboratorsBySFID() {
                var self = this;
                var componentId = this.$route.params.sfid;
                axios.post("/v1/getDemoComponentCollaborators", JSON.stringify({ token: this.accessToken, componentId: componentId })).then((res) => {
                    if (!res.data.error && res.data.data.success) {
                        // console.log('getDemoComponentCollaboratorsBySFID :: ' , res.data.data.dc_collaborators);
                        self.collaborators = JSON.parse(res.data.data.dc_collaborators);
                        self.redirectIfNotOwnerOrCollaborator();
                    } else {
                        this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_COLLABORATORS_ERROR', toastLengthInSeconds: 15 });
                        console.log(res.data.error);
                    }
                }).catch((err) => {
                    this.$store.commit('setAllPurposeToastObject', { toastState: 'error', toastMessage: 'GET_DEMO_COMPONENT_COLLABORATORS_ERROR', toastLengthInSeconds: 15 });
                    console.log(err);
                });
            },
            qSearchSync() {
                // Do the re-index for QSearch
                try {
                    var qSearchAuthToken = this.$store.getters.getQSearchAuthToken;
                    axios.post("/v1/qSearchDemoComponentsIndex", { authToken: qSearchAuthToken }).then((res) => {
                        if (!res.data.error) {
                            console.log("qSearchSync RETURNED :: ", res.data.data);
                        } else {
                            console.log(res.data.error);
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                } catch(ex) {
                    console.log('EXCEPTION :: ' + ex.message);
                }
                
                // var qSearchAuthToken = this.$store.getters.getQSearchAuthToken;
                // axios.post("/v1/qSearchDemoComponentsIndex", { authToken: qSearchAuthToken }).then((res) => {
                //     if (!res.data.error) {
                //         console.log("qSearchSync RETURNED :: ", res.data.data);
                //     } else {
                //         console.log(res.data.error);
                //     }
                // }).catch((err) => {
                //     console.log(err);
                // });
            }
        },
        created() {
            this.doInit();
        },
        watch: {
            demoComponent(newVal, oldVAl) {
                console.log('THE COMPONENT HAS CHANGED!!');
            }
        }
    }
</script>