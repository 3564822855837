<template>

    <!-- {% macro destination_form() %} -->
    <section id="select-org_form" class="slds-container_fluid slds-grid slds-grid_vertical slds-is-relative">
        <fieldset class="slds-form-element slds-container_fluid org-destination_visual-picker slds-m-bottom_xx-small">
            <legend class="slds-form-element__legend slds-form-element__label">Select the org you want to install this Demo Component to</legend>
            <!-- {{ visual_picker() }} -->
            <select-org-visual-picker />
        </fieldset>
        <!-- {{ input_switcher() }} -->
        <select-org-input-switcher initialSource="Stockpile"/>
        <!-- {{ loading_spinner() }} -->
        <select-org-loading-spinner />
    </section>


</template>

<script>
    import SelectOrgVisualPicker from './SelectOrgVisualPicker.vue'
    import SelectOrgInputSwitcher from './SelectOrgInputSwitcher.vue'
    import SelectOrgLoadingSpinner from './SelectOrgLoadingSpinner.vue'

    export default {
        name: 'SelectOrgDestinationForm',
        props: {
        
        },
        components: {
            selectOrgVisualPicker: SelectOrgVisualPicker,
            selectOrgInputSwitcher: SelectOrgInputSwitcher,
            selectOrgLoadingSpinner: SelectOrgLoadingSpinner
        },
        methods: {
            
        },
        created() {

        }
    }
</script>

